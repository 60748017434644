import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import "./DataTabel.styles.css";

const styles = {
  tabel: {
    margin: "15px 10px",
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "unset",
    "& > div:nth-child(1)": {
      padding: "0 9px",
      height: "64px",
      "& h6": {
        paddingLeft: 4,
      },
      "& button": {
        marginRight: 0,
        "&:hover": {
          color: "rgb(210, 90, 198)",
        },
      },
    },
    "& > div:nth-child(3)": {
      "& thead": {
        "& .MuiTableRow-root": {
          maxHeight: "30px",
          height: "30px",
          "& th": {
            zIndex: "10",
            background: "rgb(210, 90, 198)",
            color: "#fff",
            padding: 0,
            "& span.MuiButtonBase-root": {
              marginLeft: "0 !important",
              "& .MuiIconButton-label": {
                "& .MuiSvgIcon-root": {
                  color: "#fff",
                },
              },
            },
            "& button": {
              color: "#fff",
              fontSize: 18,
              "& .MuiButton-label": {
                "& div": {
                  "& div": {
                    color: "#fff",
                    "& span": {
                      color: "#fff",
                      "& svg": {
                        color: "#fff !important",
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      "& tbody": {
        "& .MuiTableRow-root": {
          maxHeight: "30px",
          height: "30px",
          "& td": {
            zIndex: "10",
            padding: 0,
            "& span.MuiButtonBase-root": {
              marginLeft: "0 !important",
              "& .MuiIconButton-label": {
                "& .MuiSvgIcon-root": {
                  color: "rgb(210, 90, 198)",
                },
              },
            },
          },
        },
      },
    },
  },
};

const useStyles = makeStyles(styles);

const DataTabel = ({ firstProp, secondProp }) => {
  const classes = useStyles();

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "company",
      label: "Company",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const data = [
    { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
    { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
    { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
    {
      name: "James Houston",
      company: "Test Corp",
      city: "Dallas",
      state: "TX",
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <MUIDataTable
        title={"Food Category"}
        className={classes.tabel}
        data={data}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default DataTabel;

import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import store from "../state";
// components
import Layout from "./Layout";
import OnlineLayout from "./Layout/OnlineLayout";
import { Toaster } from "react-hot-toast";
// pages

import Login from "pages/auth/Login.js";
import PageLoader from "components/Loader/PageLoader";

// context
import "../assets/css/material-dashboard-react.css?v=1.9.0";
import "../assets/css/dev-style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "../utility/interceptor";
import "jspdf-autotable";

import { getUserInfo } from "../services/user.service";
import QRCodeOrder from "pages/OnlineOrder/QRCodeOrder";
import { verifyTokenService } from "services/login.service";
import ResetPassword from "pages/auth/ResetPassword";
// import Sockets from "../common/Sockets";
import { SetUserInfo, SetUserPermission } from "state/actions/user.action";
import { setShopThemeSettingData } from "state/actions/shopSetting.types";

import {
  getStoreSetting,
  getStoreDesignSetting,
} from "services/setting.service";
import Register from "pages/auth/Register";
import VerifyOtp from "pages/auth/VerifyOtp";
import Welcome from "pages/auth/Welcome";
import { AppendCustomCss, getSubdomain } from "../utility/helpers";
import { Alert } from "react-bootstrap";
import { Notifications } from 'react-push-notification';
import { RefereshToken } from "services/onlineorder.service";
const hist = createBrowserHistory();

export default function App() {
  const [loader, setLoader] = React.useState(true);
  const [shopDomainAvl, setShopDomainAvl] = React.useState(false);
  const [userData, setUserData] = useState({});
  const [shopSettingListData, setShopSettingListData] = React.useState([]);
  let history = useHistory();
  const dispatch = useDispatch();

   // Function to clear complete cache data
   const clearCacheData = () => {
    caches?.keys().then((names) => {
      names?.forEach((name) => {
        caches?.delete(name);
      });
    });
  };

  React.useEffect(() => {
    // verifyToken();
    clearCacheData();
  }, []);

  React.useEffect(() => {
    checkIfSubdomainIsRegisterdOrNot()
  }, [])
  

  const verifyToken = async () => {
    if (getToken()) {
      try {
        const data = await verifyTokenService(getToken());
        if (data.status === 401) {
          localStorage.removeItem("token");
          history.push("/");
        }
        const settingResult = await getStoreSetting();
        if (settingResult && settingResult.status === 200) {
          setShopSettingListData(settingResult.data);
        }
        const storeTheme = await getStoreDesignSetting();
        if (storeTheme && storeTheme.status === 200) {
          if (storeTheme && storeTheme?.data && storeTheme.data.length > 0) {
            dispatch(setShopThemeSettingData(storeTheme?.data[0]));
            AppendCustomCss(storeTheme?.data[0].custom_css);
          }
        }
        setLoader(false);
      } catch (err) {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  const checkIfSubdomainIsRegisterdOrNot = async () => {
    const username = getSubdomain(window.location.hostname);
    if(username)
    {
      await RefereshToken({ username: username })
      .then((data) => {
        setUserData(data?.data);
        if(data?.data?.token)
        {
          setShopDomainAvl(true);
          setLoader(false);
        }
        else {
          setShopDomainAvl(false)
          setLoader(false);
        }
       
      }).catch(err => {
        setShopDomainAvl(false)
        setLoader(false);
      });
    }
    else {
      setShopDomainAvl(false)
      setLoader(false);
    }
  
  }
  
  if(!shopDomainAvl && !loader)
  {
    return (
      <h4 style={{textAlign: 'center', padding: '100px'}}>This Store is not registered. please visit &nbsp;
      <a rel="noreferrer" href="https://www.servofeat.com/" target={"_blank"}> servofeat.com</a> or call to our support team to create an online store</h4>
    )  
  }

  return (
    <Provider store={store}>
      {loader && !shopDomainAvl ? (
        <PageLoader />
      ) : (
        <Router history={hist}>
          <Switch>
          <Route
              path="*"
              exact
              component={() => <OnlineLayout  userData={userData}/>}
            />
            {/* <PrivateRoute path="/app/*" component={Layout} exact />
            <PublicRoute path="/login" component={Login} exact />
            <PublicRoute path="/register" component={Register} exact />
            <PublicRoute path="/verifyotp" component={VerifyOtp} exact />
            <PublicRoute path="/welcome" component={Welcome} exact />
            <PublicRoute
              path="/login/new-password/:id"
              component={ResetPassword}
              exact
            /> */}
            {/* <Route
              path="*"
              render={() => {
                return <Redirect to="/app/order/new" />;
              }}
            /> */}


          </Switch>
        </Router>
      )}
      <Notifications/>
      <Toaster
        position="top-center"
        containerStyle={{ marginTop: "50px" }}
        toastOptions={{ duration: 3000 }}
        reverseOrder={false}
      />
    </Provider>
  );

  function getToken() {
    return localStorage.getItem("token");
  }

  function PrivateRoute({ component, ...rest }) {
    const dispatch = useDispatch();
    useEffect(() => {
      if(getToken())
      {
        getUserInfo()
        .then((res) => {
          localStorage.setItem("shopId", res?.data?.data?.billing?.shop_id);
          dispatch(SetUserInfo(res?.data?.data));
          dispatch(SetUserPermission(res?.data?.data?.permissions));
        })
        .catch((err) => console.log(err));
      }
    }, []);

    return (
      <Route
        {...rest}
        render={(props) =>
          getToken() ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          getToken() ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

import { api } from "../utility/interceptor";

var config = {
  headers: { "Access-Control-Allow-Origin": "*" },
};

export const createPaymentOrder = async (customerData) => {
  try {
    let res = await api.post(`/paytm/newCreate`, customerData);
    return { status: res.status, data: res.data };
  } catch (err) {
    return { status: err.response.status, message: err.response.data.message };
  }
};

import { memo } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Container, AppBar } from '@mui/material';
// config
import { HEADER } from '../../../../configV2';
// components
import { NavSectionHorizontal } from '../../../../components/nav-section';
//
import navConfig from './NavConfig';
import Scrollbar from '../../../../components/Scrollbar';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 0),
  // boxShadow: theme.customShadows.z8,
  top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

function NavbarHorizontal() {
  return (
    // <RootStyle>
      // <Container maxWidth={false}>
      <Scrollbar
          sx={{
            height: 1,
            maxWidth: '100%',
            width: '100%',
            justifyContent: 'flex-start',
            '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' },
          }}
        >
        <NavSectionHorizontal navConfig={navConfig} />
      </Scrollbar>
      //  </Container>
      
    // </RootStyle>
  );
}

export default memo(NavbarHorizontal);

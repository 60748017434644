import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  backicon: {
    borderRadius: "50px !important",
    border: "black !important",
  },
  addrolebtn: {
    marginLeft: "70px",
    "& MuiButtonBase-root": {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  rolecard: {
    backgroundColor: "rgba(255, 56, 56, 0.1) !important",
  },
  mobileCard: {
    padding: "2px",
  },
  btnPosition: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
  },
  formcontrolwidth: {
    width: "100%",
    marginBottom: 10,
    "& > span": {
      maxWidth: 240,
      fontSize: 12,
    },
  },
  tableContainer: {
    maxHeight: 530,
  },
  rolename: {
    marginTop: "15px",
    marginLeft: "10px",
    display: "flex",
  },
  table: {
    "& thead": {
      height: "40px",
      "& tr": {
        "& th": {
          fontSize: "14px",
        },
      },
    },
    "& thead tr": {
      height: "40px",
      padding: "0 10px",
    },
    "& thead tr th": {
      padding: "0 5px",
      height: "40px",
    },
    "& tbody tr": {
      padding: "0 5px",
      height: "40px",
    },
    "& tbody tr td": {
      padding: "0 5px",
      height: "40px",
    },
  },
  header: {
    fontSize: "18px",
    backgroundColor: "#00AB55 !important",
    color: "white",
    padding: "12px 20px",
  },
  button: {
    backgroundColor: "rgb(210, 90, 198)",
    borderRadius: "5px",
    color: "white",
    marginRight: "4px",
    border: "2px solid rgb(210, 90, 198)",
    padding: "2px 10px",
  },
}));

import React, { Component } from "react";
import QRCode from "qrcode.react";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/css/qrcode.css";

const styles = (theme) => ({
  qrcode: {
    textAlign: 'center',
    margin: 'auto',
    [theme.breakpoints.down("xs")]: {
      height: "160px",
      width: "160px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "160px",
      width: "160px",
    },
  },
});

const useStyles = makeStyles(styles);

const UserQrPrints = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
      <div ref={ref}>
        <QRCode
          width={500}
          height={500}

          id="qrCodeE2"
          value={
            this?.props?.orderUrl ? this?.props?.orderUrl : "http://servofeat.com/"
          }
          bgColor={"#ffffff"}
          fgColor={"#000000"}
          level={"L"}
          includeMargin={true}
          className={`${classes.qrcode} profileqrcode`}
        />
      </div>
  );
});

export default UserQrPrints;

import React, { useEffect, useState, Children } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, FormControl, MenuItem } from "@material-ui/core";
import { Save as SaveIcon, ArrowBackIos } from "@material-ui/icons";
import { Form, Field } from "react-final-form";
import { Col, Row, Card } from "react-bootstrap";
import TextInputMui from "components/TextInputMui";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import moment from "moment";
import { useInMobile } from "themes/Device";
import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import Select from "react-select";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import {
  useCreateExpenseClassMutation,
  useUpdateExpenseClassMutation,
} from "hooks/react-query/useExpense";
import { useExpenseCategory } from "hooks/react-query/useExpenseCategory";
import { useExpenseQuery } from "hooks/react-query/useExpense";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const colourStyles = () => ({
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: state.hasValue || state.selectProps.inputValue ? 0 : "50%",
    transition: `all 300ms`,
    fontSize: state.hasValue || state.selectProps.inputValue ? 12 : 16,
    background: "white",
    paddingLeft: 5,
    paddingRight: 5,
  }),
  control: (base, state) => ({
    ...base,
    height: 56,
    border: state.selectProps.error ? "1px solid red" : "",
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: "visible",
    cursor: "text",
    minHeight: 56,
    padding: `0px 8px 2px 8px`,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
});

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      borderRadius: "8px",
      padding: "1rem 0",
    },
  },
  formcontrolwidth: {
    width: "100%",
    padding: "10px",
  },
  selectBoxControl: {
    width: "100%",
  },
  textField: {
    width: 200,
  },
  buttonPosition: {
    display: "flex",
    justifyContent: "end",
  },
  foodCatfixFooter: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    position: "absolute",
    bottom: "0",
    left: "0",
    padding: "0 15px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      position: "relative",
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
    },
  },
});

const useStyles = makeStyles(styles);

const AddEditExpense = (props) => {
  const classes = useStyles();
  const { drawerClose } = props;
  const [initialExpenseData, setInitialExpenseData] = useState();
  const [expenseCategory, setExpenseCategory] = useState();
  const [ExpenseId, setExpenseId] = useState(props.ExpenseId);
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobileView = useInMobile();
  const history = useHistory();
  const param = useParams();
  const layoutDispatch = useLayoutDispatch();
  const { data } = useExpenseQuery(ExpenseId);
  const { data: inventoyCategory } = useExpenseCategory();
  const { t } = useTranslation();

  useEffect(() => {
    let initialInterval = {
      date: moment().format("YYYY-MM-DD"),
    };
    setInitialExpenseData(initialInterval);
    if (isMobileView) {
      let screenTitle = "";
      if (param.id > 0) {
        setExpenseId(param.id);
        screenTitle = t("edit-expense-title");
      } else {
        screenTitle = t("expense-management-add-expense-label");
      }
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: screenTitle,
      });
      return () => {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      };
    }
  }, []);

  useEffect(() => {
    console.log("data", data);
    if (data) {
      setInitialExpenseData({
        ...data,
        to_date: moment(data.to_date).format("YYYY-MM-DD"),
        from_date: moment(data.from_date).format("YYYY-MM-DD"),
      });
      setExpenseCategory({
        value: data?.category_id,
        label: data?.category?.name,
      });
    }
  }, [data]);

  const { mutate: addExpense, isLoading: creating } =
    useCreateExpenseClassMutation();
  const { mutate: updateExpense, isLoading: updating } =
    useUpdateExpenseClassMutation();

  const redirectToMainPage = () => {
    if (isMobileView) {
      history.location?.state?.backToMain();
    } else {
      drawerClose();
    }
  };
  const onSubmit = (values) => {
    // values.category_id = expenseCategory?.value;
    setSubmittingBtnProcess(true);
    if (ExpenseId > 0) {
      updateExpense({
        variables: {
          id: ExpenseId,
          input: {
            ...values,
          },
        },
      });
    } else {
      addExpense({
        variables: {
          input: {
            ...values,
          },
        },
      });
    }
    redirectToMainPage();
  };

  useEffect(() => {
    if (ExpenseId > 0) {
      setIsLoading(true);
    }
  }, [data]);

  return (
    <Card className="foodcat-card">
    <Card.Body>
    <div className={classes.root}>
      {ExpenseId > 0 && !isLoading ? (
        <SkeletonLoader />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialExpenseData}
          validate={(values) => {
            const errors = {};
            if (!values?.name) {
              errors.name = t("expense-management-name-validation-message");
            } else if (values?.name?.length > 50) {
              errors.name = t("maximumlength-validation-message");
            }
            if (!values.amount) {
              errors.amount = t("expense-management-amount-validation-message");
            } else if (parseInt(values?.amount) <= 0) {
              errors.amount = t(
                "expense-management-minimum-validation-message",
              );
            } else {
              errors.amount = undefined;
            }
            if (!values?.category_id) {
              errors.category_id = t("expense-category-validation-message");
            }
            return errors;
          }}
          render={({ handleSubmit, form, submitting, values }) => (
            <form onSubmit={handleSubmit} className="mt-3">
              <Row>
                <Col xs={12} sm={12} md={12}>
                    <Field name="category_id">
                      {({ input, meta }) => (
                        <FormControl
                          className={clsx(
                            classes.selectBoxControl,
                            classes.formcontrolwidth,
                          )}
                        >
                          <TextInputMui
                            {...input}
                            select
                            label={t("select-category")}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          >
                            {inventoyCategory?.map((item) => (
                              <MenuItem value={item.id}>{item?.name}</MenuItem>
                            ))}
                          </TextInputMui>
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
               </Row>
               <Row>
               <Col xs={12} md={8}>
                  <Field name="name">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="text"
                          variant="outlined"
                          label={`${t("name-label")}${"*"}`}
                          error={meta.error && meta.touched ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col xs={12} md={4}>
                  <Field name="amount">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="number"
                          variant="outlined"
                          label={t("expense-management-amount-label")}
                          error={meta.error && meta.touched ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                 </Row>
               <Row>
                <Col xs={12} sm={12} md={12}>
                  <Field name="type">
                    {({ input, meta }) => (
                      <FormControl
                        className={clsx(
                          classes.selectBoxControl,
                          classes.formcontrolwidth,
                        )}
                      >
                        <TextInputMui
                          {...input}
                          select
                          label={t("select-unit-type")}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        >
                          {["FIXED", "VERIABLE"]?.map((item, index) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </TextInputMui>
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                        {/* <Select
                          {...input}
                          components={component}
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          placeholder="Unit Type"
                          name="unit_type"
                          value={unitType}
                          onChange={(value) => setUnitType(value)}
                          options={["KG", "LTR", "COUNT"]?.map(
                            (item, index) => ({
                              value: item,
                              label: item,
                            }),
                          )}
                          error={meta.error && meta.touched ? true : false}
                          styles={colourStyles()}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )} */}
                      </FormControl>
                    )}
                  </Field>
                  <Field name="category_id">
                    {({ input, meta }) => (
                      <FormControl
                        className={clsx(
                          classes.selectBoxControl,
                          classes.formcontrolwidth,
                        )}
                      >
                        <TextInputMui
                          {...input}
                          select
                          label={t("select-category")}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        >
                          {inventoyCategory?.map((item) => (
                            <MenuItem value={item.id}>{item?.name}</MenuItem>
                          ))}
                        </TextInputMui>
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <Field name="description">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="text"
                          variant="outlined"
                          label={t("description-label")}
                          error={meta.error && meta.touched ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Field name="from_date" allowNull={true}>
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          label={t("analytics-from-date")}
                          type="Date"
                          variant="outlined"
                          defaultValue={moment().format("yyyy-mm-dd")}
                          error={meta.error && meta.touched ? true : false}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Field name="to_date" allowNull={true}>
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          label={t("analytics-to-date")}
                          type="Date"
                          variant="outlined"
                          defaultValue={moment().format("yyyy-mm-dd")}
                          error={meta.error && meta.touched ? true : false}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
              </Row>
              <div className={classes.foodCatfixFooter}>
                <Row className="m-0">
                  <Col xs={12} sm={12} md={12}>
                    <div className="mb-4 d-flex">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        type="submit"
                        disabled={submitting}
                        style={{
                          background: "",
                          color: "#fff",
                        }}
                        startIcon={<SaveIcon />}
                      >
                        {submittingBtnProcess ? t("processing") : t("save")}
                      </Button>
                      <div className="ml-2">
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                          type="button"
                          onClick={() => {
                            if (isMobileView) history.push("/app/Expense");
                            else if (!isMobileView && drawerClose)
                              drawerClose();
                          }}
                          startIcon={<ArrowBackIos />}
                        >
                          {t("back")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          )}
        />
      )}
    </div>
    </Card.Body>
    </Card>
  );
};

export default AddEditExpense;

import React, { useEffect, useState, Children } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-hot-toast";
import { Button, FormControl, MenuItem } from "@material-ui/core";
import { Save as SaveIcon, ArrowBackIos } from "@material-ui/icons";
import { Form, Field } from "react-final-form";
import { Col, Row } from "react-bootstrap";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TextInputMui from "components/TextInputMui";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import { createInventory } from "services/inventory.service";
import { updateInventory } from "services/inventory.service";
import { getInventoryById } from "services/inventory.service";
import { checkSpaceAndRegex } from "utility/helpers";
import moment from "moment";
import { useInMobile } from "themes/Device";
import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import Select, { components } from "react-select";

//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import {
  useCreateInventoryClassMutation,
  useUpdateInventoryClassMutation,
  useInventoryQuery,
} from "hooks/react-query/useInventory";
import { useInventoryCategory } from "hooks/react-query/useInventoryCategory";
import { t } from "i18next";
const colourStyles = () => ({
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: state.hasValue || state.selectProps.inputValue ? 0 : "50%",
    transition: `all 300ms`,
    fontSize: state.hasValue || state.selectProps.inputValue ? 12 : 16,
    background: "white",
    paddingLeft: 5,
    paddingRight: 5,
  }),
  control: (base, state) => ({
    ...base,
    height: 56,
    border: state.selectProps.error ? "1px solid red" : "",
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: "visible",
    cursor: "text",
    minHeight: 56,
    padding: `0px 8px 2px 8px`,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
});

export const CustomValueContainer = (props) => {
  const { selectProps, children } = props;
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props}>
        {selectProps.placeholder}
      </components.Placeholder>
      {Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? child : null,
      )}
    </components.ValueContainer>
  );
};

const component = {
  ValueContainer: CustomValueContainer,
  ClearIndicator: null,
  IndicatorSeparator: null,
};

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      borderRadius: "8px",
      padding: "1rem 0",
    },
  },
  formcontrolwidth: {
    width: "100%",
    padding: "10px",
  },
  selectBoxControl: {
    width: "100%",
  },
  textField: {
    width: 200,
  },
  buttonPosition: {
    display: "flex",
    justifyContent: "end",
  },
  foodCatfixFooter: {
    width: "100%",
    display: "flex",
    marginLeft: "24px",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      position: "relative",
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
    },
  },
});

const useStyles = makeStyles(styles);

const AddEditInventory = (props) => {
  const classes = useStyles();
  const { drawerClose } = props;
  const [initialInventoryData, setInitialInventoryData] = useState();
  const [inventoryId, setInventoryId] = useState(props.inventoryId);
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobileView = useInMobile();
  const history = useHistory();
  const param = useParams();
  const layoutDispatch = useLayoutDispatch();
  const { data } = useInventoryQuery(inventoryId);
  const { data: inventoyCategory } = useInventoryCategory();

  useEffect(() => {
    let initialInterval = {
      date: moment().format("YYYY-MM-DD"),
    };
    setInitialInventoryData(initialInterval);
    if (isMobileView) {
      let screenTitle = "";
      if (param.id > 0) {
        setInventoryId(param.id);
        screenTitle = t("inventory-management-edit-label");
      } else {
        screenTitle = t("inventory-management-add-label");
      }
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: screenTitle,
      });
      return () => {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      };
    }
  }, []);

  useEffect(() => {
    if (data) {
      setInitialInventoryData({
        ...data,
        to_date: moment(data?.to_date).format("YYYY-MM-DD"),
        from_date: moment(data?.from_date).format("YYYY-MM-DD"),
      });
    }
  }, [data]);

  const { mutate: addInventory, isLoading: creating } =
    useCreateInventoryClassMutation();
  const { mutate: updateInventory, isLoading: updating } =
    useUpdateInventoryClassMutation();

  const redirectToMainPage = () => {
    if (isMobileView) {
      history.location?.state?.backToMain();
    } else {
      drawerClose();
    }
  };
  const onSubmit = (values) => {
    console.log(values);
    // values.category_id = inventoryCategory?.value;
    // values.unit_type = unitType?.value;
    setSubmittingBtnProcess(true);
    if (inventoryId > 0) {
      updateInventory({
        variables: {
          id: inventoryId,
          input: {
            ...values,
          },
        },
      });
    } else {
      addInventory({
        variables: {
          input: {
            ...values,
          },
        },
      });
    }
    redirectToMainPage();
  };

  useEffect(() => {
    if (inventoryId > 0) {
      setIsLoading(true);
    }
  }, [data]);

  return (
    <Card className="foodcat-card">
    <CardBody>
    <div className={classes.root}>
      {inventoryId > 0 && !isLoading ? (
        <SkeletonLoader />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialInventoryData}
          validate={(values) => {
            const errors = {};
            if (!values?.name) {
              errors.name = t("inventory-management-name-validation-message");
            } else if (values?.name?.length > 50) {
              errors.name = t("maximumlength-validation-message");
            }
            if (!values.unit) {
              errors.unit = t("inventory-management-unit-validation-message");
            } else if (parseInt(values?.unit) <= 0) {
              errors.unit = t("inventory-management-amount-validation-message");
            } else {
              errors.unit = undefined;
            }
            if (!values.price) {
              errors.price = t("inventory-management-price-validation-message");
            } else if (parseInt(values?.price) <= 0) {
              errors.price = t("inventory-management-amount-validation-message");
            } else {
              errors.price = undefined;
            }
            if (!values.minimum_alert_unit) {
              errors.minimum_alert_unit = t("inventory-management-minimum_alert_unit-validation-message");
            } else if (parseInt(values?.minimum_alert_unit) <= 0) {
              errors.minimum_alert_unit = t("inventory-management-minimum_alert_unit-validation-message");
            } else {
              errors.minimum_alert_unit = undefined;
            }
            if (!values?.unit_type) {
              errors.unit_type = t(
                "inventory-management-unit-type-validation-message",
              );
            }
            if (!values?.category_id) {
              errors.category_id = t(
                "inventory-management-category-validation-message",
              );
            }
            return errors;
          }}          
          render={({ handleSubmit, form, submitting, values }) => (
            <form onSubmit={handleSubmit} className="mt-3">
                <Row>
                  <Col xs={12} sm={12} md={12}> 
                  <Field name="category_id">
                    {({ input, meta }) => (
                      <FormControl
                        className={clsx(
                          classes.selectBoxControl,
                          classes.formcontrolwidth,
                        )}
                      >
                        <TextInputMui
                          {...input}
                          select
                          label={t("select-category")}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        >
                          {inventoyCategory?.map((item) => (
                            <MenuItem value={item.id}>{item?.name}</MenuItem>
                          ))}
                        </TextInputMui>
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                        {/* <Select
                          {...input}
                          components={component}
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          placeholder="Category"
                          name="category"
                          value={inventoryCategory}
                          onChange={(value) => setInventoryCategory(value)}
                          options={inventoyCategory?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          error={meta.error && meta.touched ? true : false}
                          styles={colourStyles()}
                          menuPortalTarget={document.body}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )} */}
                      </FormControl>
                    )}
                  </Field>
                </Col>
               </Row>
                <Row>
                <Col xs={12} sm={12} md={8}>
                  <Field name="name">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="text"
                          variant="outlined"
                          label=
                          {`${t("name-label")}${"*"}`}
                          error={meta.error && meta.touched ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col xs={12} sm={12} md={4}>
                  <Field name="price">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="number"
                          variant="outlined"
                          label={t("price-label")}
                          error={meta.error && meta.touched ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={4}>
                    <Field name="unit">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="text"
                            variant="outlined"
                            label={t("unit-inventory-management-label")}
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col xs={12} sm={12} md={4}>
                    <Field name="minimum_alert_unit">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="number"
                            variant="outlined"
                            label={`${t(
                              "inventory-management-alert-unit-validation-label",
                            )}${"*"}`}
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col xs={12} sm={12} md={4}>
                  <Field name="unit_type">
                    {({ input, meta }) => (
                      <FormControl
                        className={clsx(
                          classes.selectBoxControl,
                          classes.formcontrolwidth,
                        )}
                      >
                        <TextInputMui
                          {...input}
                          select
                          label={t("select-unit-type")}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        >
                          {["KG", "LTR", "COUNT"]?.map((item, index) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </TextInputMui>
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  </Col> 
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <Field name="from_date" allowNull={true}>
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            label={t("analytics-from-date")}
                            type="date"
                            variant="outlined"
                            defaultValue={moment().format("yyyy-mm-dd")}
                            error={meta.error && meta.touched ? true : false}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Field name="to_date" allowNull={true}>
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            label={t("analytics-to-date")}
                            type="date"
                            variant="outlined"
                            defaultValue={moment().format("yyyy-mm-dd")}
                            error={meta.error && meta.touched ? true : false}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                </Row>
               <Row>
               <Col xs={12} sm={12} md={12}>
                  <Field name="description">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="text"
                          variant="outlined"
                          label={t("description-label")}
                          error={meta.error && meta.touched ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
               </Row>
               
                
                <Row className="mt-2">
                  <Col xs={12} sm={12} md={12}>
                    <div className="mb-4 d-flex">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        type="submit"
                        disabled={submitting}
                        style={{
                          background: "",
                          color: "#fff",
                        }}
                        startIcon={<SaveIcon />}
                      >
                        {submittingBtnProcess ? t("processing") : t("save")}
                      </Button>
                      <div className="ml-2">
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                          type="button"
                          onClick={() => {
                            if (isMobileView) history.push("/app/inventory");
                            else if (!isMobileView && drawerClose)
                              drawerClose();
                          }}
                          startIcon={<ArrowBackIos />}
                        >
                           {t("back")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
            </form>
          )}
        />
      )}
    </div>
    </CardBody>
    </Card>
  );
};

export default AddEditInventory;

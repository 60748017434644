import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import { Container } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import KitchenOutlinedIcon from "@material-ui/icons/KitchenOutlined";
import DeckIcon from "@material-ui/icons/Deck";
import { useHistory } from "react-router-dom";
import AddShoppingCartSharpIcon from "@material-ui/icons/AddShoppingCartSharp";
import { ServiceEnabled } from "utility/helpers";
import { useSelector } from "react-redux";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#ffffff",
    paddingBottom: "6px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px -5px 19px rgba(80, 17, 0, 0.12)",
    borderRadius: "20px 20px 0px 0px",
    position: "absolute",
    bottom: 0,
    zIndex: 999,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  tabname: {
    fontSize: 12,
    fontWeight: "400",
    color: "#8e8e8e",
  },
  footerbtn: {
    textTransform: "capitalize",
    minWidth: "0px",
  },
  buttondiv: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  buttonicon: {
    color: "#8e8e8e",
  },
});

export default function MobileFooterOnline() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  let history = useHistory();
  const [activeButton, setActiveButton] = useState({
    DINEIN: false,
    ORDER: false,
    HISTORY: false,
    DASHBOARD: false,
    KITCHEN: false,
  });

  return (
    <div>
      <Container className={classes.root}>
          <RoleBasedGuard resource={RESOURCE.PARCEL_ORDER} action={ACTION.ADD}>
            <Button
              size="small"
              style={{
                position: "relative",
                minWidth: "41px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                setActiveButton({ ORDER: true });
                history.push("/app/order/new");
              }}
            >
              <AddShoppingCartSharpIcon
                style={{
                  width: "45px",
                  height: "45px",
                  minWidth: "none !important",
                  color: activeButton?.ORDER ? theme.palette.primary.main : "",
                }}
              />
              {/* <AddCircleIcon style={{ width: '57px', height: '57px',minWidth:'none !important',color: activeButton?.ORDER ? '#976aa9' : 'd25ac6' }} /> */}
            </Button>
          </RoleBasedGuard>       
      </Container>
    </div>
  );
}

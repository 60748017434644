import React, { Fragment, useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Importing toastify module
import { toast } from "react-hot-toast";

import { Badge, Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel,
  Delete,
  Category,
} from "@material-ui/icons";

import PopUpModel from "components/Popup/PopUpModel";
import TemporaryDrawer from "components/TemporaryDrawer";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";
import { DeskTopContainer } from "themes/Device";
import { MobileContainer } from "themes/Device";
import MobileTitle from "components/MobileTitle";
import SearchBarInput from "components/SearchBarInput";
import MobileCategory from "components/MobileCategory";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { useTheme } from "@material-ui/core/styles";
import Label from "components/Label";

//context
import {
  useLayoutState,
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import AddEditInventoryCategory from "./AddEditInventoryCategory";
import {
  useDeleteInventoryCategoryMutation,
  useInventoryCategory,
} from "hooks/react-query/useInventoryCategory";
import { useTranslation } from "react-i18next";
const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
  },
  tabel: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
      "& div": {
        padding: "0",
      },
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },
  prioritybtn: {
    "& a:hover,& a:focus": {
      backgroundColor: "#FF5C93",
    },
    marginLeft: "5px",
    backgroundColor: "#FF5C93",
    color: "white",
  },
  mobileCard: {
    padding: "2px",
  },
  mobileCategoryCard: {
    margin: '0 auto',
    display: 'grid',
    gap: '7px',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme?.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    }
  },
});
const useStyles = makeStyles(styles);
const InventoryCategoryList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { data: inventoryCategoryDataList, loading: inventoryCategoryLoader } =
    useInventoryCategory();
  const { mutate: deleteInventoryCategory } =
    useDeleteInventoryCategoryMutation();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteInventoryCategoryId, setDeleteInventoryCategoryId] = useState(0);
  const [deletingBtnProcess, setDeletingBtnProcess] = useState(false);
  const [addEditDrawerShow, setAddEditDrawerShow] = useState(false);
  const [addEditInventoryCategoryId, setAddEditInventoryCategoryId] =
    useState(0);
  const [searchInventoryCategory, setSearchInventoryCategory] = useState("");
  const [filterInventoryCategoryList, setFilterInventoryCategoryList] =
    useState([]);

  const history = useHistory();
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (layoutState?.currentScreenTitle !== "Inventory Category") {
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: t("inventory-category-title"),
      });
    }
    return () => {
      if (layoutState?.currentScreenTitle === "Inventory Category") {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      }
    };
  }, []);

  const deleteFoodCategoryData = (foodCategoryId) => {
    if (foodCategoryId > 0) {
      setDeletingBtnProcess(true);
      deleteInventoryCategory(foodCategoryId);
      setDeletingBtnProcess(false);
      setDeleteModalShow(false);
    }
  };

  const addEditDrawerClose = () => {
    setAddEditDrawerShow(false);
    setAddEditInventoryCategoryId(0);
  };

  const deleteData = (deleteid) => {
    setDeleteInventoryCategoryId(deleteid);
    setDeleteModalShow(true);
  };
  const editData = (editid) => {
    history.push({
      pathname: `/app/inventory-category/edit/${editid}`,
      state: {
        foodCategoryId: editid,
        backToMain: () => history.push(`/app/inventory-category`),
      },
    });
  };
  const addDrawerShow = () => {
    history.push({
      pathname: `/app/inventory-category/add`,
      state: {
        foodCategoryId: 0,
        backToMain: () => history.push(`/app/inventory-category`),
      },
    });
  };

  const clearTxt = () => {
    setSearchInventoryCategory("");
  };

  useEffect(() => {
    setFilterInventoryCategoryList(inventoryCategoryDataList);
  }, [inventoryCategoryDataList]);

  useEffect(() => {
    if (searchInventoryCategory) {
      let filterCategoryList = inventoryCategoryDataList?.filter(
        (item) =>
          item.name &&
          item.name
            ?.toLowerCase()
            ?.includes(searchInventoryCategory.toLowerCase()),
      );
      setFilterInventoryCategoryList(filterCategoryList);
    } else {
      setFilterInventoryCategoryList(inventoryCategoryDataList);
    }
  }, [searchInventoryCategory]);

  return (
    <>
      <DeskTopContainer>
        <div className={classes.tabel}>
          <MaterialTable
            title={
              <h5>
                <b className="mr-3">{t("inventory-category-title")}</b>
              </h5>
            }
            columns={[
              {
                title: t("inventory-category-name-label"),
                field: "name",
                cellStyle: {
                  width: "45%",
                },
              },
              {
                title: t("description-label"),
                field: "description",
                cellStyle: {
                  width: "45%",
                },
              },
              {
                title: t("action"),
                field: "action",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) => (
                  <Fragment>
                    <RoleBasedGuard
                      resource={RESOURCE.FOOD_CATEGORY}
                      action={ACTION.UPDATE}
                    >
                      <IconButton
                        color="primary"
                        title={t("inventory-edit-category-label")}
                        aria-label={t("inventory-edit-category-label")}
                        onClick={() => {
                          setAddEditInventoryCategoryId(rowData.id);
                          setAddEditDrawerShow(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </RoleBasedGuard>
                    <RoleBasedGuard
                      resource={RESOURCE.FOOD_CATEGORY}
                      action={ACTION.DELETE}
                    >
                      <IconButton
                        color="secondary"
                        title={t("inventory-delete-category-label")}
                        aria-label={t("inventory-delete-category-label")}
                        onClick={() => {
                          setDeleteInventoryCategoryId(rowData.id);
                          setDeleteModalShow(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </RoleBasedGuard>
                  </Fragment>
                ),
              },
            ]}
            data={inventoryCategoryDataList?.map((item, index) => {
              return {
                name: item.name,
                id: item.id,
                description: item.description,
              };
            })}
            options={{
              paging: true,
              pageSize: 10,
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 20, 30],
              sorting: true,
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              searchFieldStyle: { color: theme.palette.grey[500] },
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              },
              // rowStyle: (rowData, index) => {
              //   if (index % 2) {
              //     return { backgroundColor: "#f2f2f2" };
              //   }
              // },
            }}
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: inventoryCategoryLoader ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>
                      {t("inventory-empty-data-message")}
                    </p>
                  </NotFoundComponent>
                ),
              },
            }}
            // other props
            components={{
              Toolbar: (props) => (
                <div className="custom-material-table-toolbar ">
                  <MTableToolbar {...props} />
                  <div style={{ padding: "0px 10px" }}>
                    <RoleBasedGuard
                      resource={RESOURCE.FOOD_CATEGORY}
                      action={ACTION.ADD}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        // onClick={addEditModelToggle}
                        startIcon={<AddCircleOutlineIcon />}
                        style={{ background: "", color: "#fff" }}
                        onClick={() => {
                          setAddEditDrawerShow(true);
                        }}
                      >
                        {t("inventory-add-button-label")}
                      </Button>
                    </RoleBasedGuard>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </DeskTopContainer>
      <MobileContainer>
        <div className={classes.mobileCard}>
          <MobileTitle
            title={t("inventory-title-screen")}
            addButton={addDrawerShow}
          />
          <SearchBarInput
            onChange={(e) => {
              setSearchInventoryCategory(e.target.value);
            }}
            value={searchInventoryCategory}
            placeholder={t("inventory-search-category-label")}
            clearTxt={clearTxt}
          />
          {inventoryCategoryLoader ? (
            <SkeletonLoader count={3} height="10rem" />
          ) : (
            <div className={classes.mobileCategoryCard}>
              {filterInventoryCategoryList &&
                filterInventoryCategoryList.map((item, index) => (
                  <MobileCategory
                    name={item.name}
                    active={item.is_active}
                    deleteData={deleteData}
                    editData={editData}
                    itemId={item?.id}
                  />
                ))}
            </div>
          )}
        </div>
        {filterInventoryCategoryList?.length < 1 && !inventoryCategoryLoader && (
          <NotFoundComponent imgUrl={noFoundImg}>
            <p style={{ fontWeight: 600 }}>
              {t("inventory-empty-data-message")}
            </p>
          </NotFoundComponent>
        )}
      </MobileContainer>
      <PopUpModel
        show={deleteModalShow}
        heading={t("inventory-delete-popup-title")}
        body={t("inventory-delete-popup-body")}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeleteModalShow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteFoodCategoryData(deleteInventoryCategoryId)}
            >
              {" "}
              <Delete />{" "}
              {deletingBtnProcess ? <CircularProgress /> : t("delete")}{" "}
            </Button>
          </>
        }
        onHide={() => setDeleteModalShow(false)}
      />
      <TemporaryDrawer
        drawerClose={addEditDrawerClose}
        isOpen={addEditDrawerShow}
        drawerWidth="50%"
        icon={<Category />}
        title={t("inventory-category-title")}
      >
        <AddEditInventoryCategory
          category_id={addEditInventoryCategoryId}
          drawerClose={addEditDrawerClose}
        />
      </TemporaryDrawer>
    </>
  );
};

export default InventoryCategoryList;

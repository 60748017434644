import { capitalCase } from "change-case";
import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { Link as RouterLink, useHistory } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Link,
  Container,
  Tooltip,
  Typography,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import { NotificationImportant } from "@material-ui/icons";
import { useTheme, Checkbox } from "@material-ui/core";

// hooks
// import useAuth from '../../hooks/useAuth';
import useResponsive from "../../hooks/useResponsive";
// routes
// import { PATH_AUTH } from '../../routes/paths';
// components
import Page from "../../components/Page";
// import Logo from '../../components/Logo';
import Image from "../../components/Image";
// sections
// import { RegisterForm } from '../../sections/auth/register';
import clsx from "clsx";
import {
  Visibility as HidePassIcon,
  VisibilityOff as ShowPassIcon,
  ErrorOutline as ErrorIcon,
} from "@material-ui/icons";

import { adminLogin, forgotPassword } from "services/login.service";
import useStyles from "./styles";
import "assets/css/style.css";
import "assets/css/theme.css";
import TextInputMui from "components/TextInputMui";
import loginImg from "../../assets/img/login_vector.png";
import forgetImg from "../../assets/img/forgot-password.png";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { adminTryNow } from "services/login.service";
import { values } from "lodash";
import { toast } from "react-hot-toast";
import { checkSpaceAndRegex, checkUserName } from "utility/helpers";
import cake1 from "../../assets/img/foods/cake1.png";
import cakeslice from "../../assets/img/foods/cakeslice.png";
import pizza from "../../assets/img/foods/pizza.png";
import coffee from "../../assets/img/foods/coffee.png";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const TermSectionStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignContent: "center",
  marginTop: "40px",
}));

// ----------------------------------------------------------------------

export default function Register(props) {
  // const { method } = useAuth();

  const smUp = useResponsive("down", "md");

  const mdUp = useResponsive("up", "md");

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [isNewPassSet, setIsNewPassSet] = useState(false);
  const [forgotPasswordToggle, setForgotPasswordToggle] = useState(false);
  const [isForgotLinkSent, setIsForgotLinkSent] = useState(false);
  const params = new URLSearchParams(props?.location?.search);
  let emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const [settings, setSettings] = useState([]);
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [termCheck, setTermCheck] = useState(false);

  const uNameHelperText =
    "Note: once you sign up you will get an unique username in your email.";

  useEffect(() => {
    setForgotPasswordToggle(false);
    setError("");
    if (params.get("new-pass")) {
      setIsNewPassSet(true);
      setInterval(() => {
        setIsNewPassSet(false);
      }, 7000);
    }
  }, []);

  const onSubmit = (values) => {
    // if (forgotPasswordToggle) {
    //   sendConfirmationMail(values?.email_uname);
    // } else {
    //   setIsLoading(true);
    //   adminLogin({ username: values?.email_uname.toLowerCase(), password: values?.password })
    //     .then((res) => {
    //       if (res?.status === 200 || res?.data) {
    //         setIsLoading(false);
    //         localStorage.setItem("token", res.data.data.token);
    //         // window.location.href = "/app/dashboard";
    //         if (settings === true) {
    //           props.history.push("/");
    //         } else {
    //           props.history.push("/app/order/new");
    //         }
    //       } else {
    //         setIsLoading(false);
    //         setError("Username / Email or password incorrect");
    //       }
    //     })
    //     .catch((err) => {
    //       setError(err?.message);
    //       setIsLoading(false);
    //     });
    // }
  };

  const resetAllState = () => {
    setForgotPasswordToggle(false);
    setIsForgotLinkSent(false);
    setError(false);
  };

  const handleShopCreate = (values) => {
    if (!termCheck) {
      return;
    }
    setSubmittingBtnProcess(true);
    const shopinfo = {
      shop_name: values.shop_name.trim(),
      ...values,
    };
    adminTryNow(shopinfo)
      .then((res) => {
        if (res?.status === 200 || res?.data || res?.data?.status === 200) {
          setSubmittingBtnProcess(false);
          toast.success(res.data.message);
          props.history.push({
            pathname: `/verifyotp`,
            details: shopinfo,
          });
        } else {
          setSubmittingBtnProcess(false);
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
        toast.error(err?.message);
      });
  };

  return (
    <Page title="Register">
        <Alert variant="warning" className="online-offline-alert">
          <NotificationImportant /> <span>
            You are offline. Your data can’t be saved and may not be up to date. Until you’re back online.
          </span>
        </Alert>
      <RootStyle>
       
        <HeaderStyle>
          {/* <Logo /> */}
          <Box>
            <Image
              disabledEffect
              src={
                "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
              }
              sx={{ width: 50, height: 50 }}
              className={classes.img}
            />
          </Box>
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 10, mb: 5, fontSize: "2rem" }}
            >
              Manage the resturant more effectively with ServoFeat
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              alt="register"
              src="assets/illustrations/illustration_register.png"
            />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Box sx={{ mb: 5, display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontSize: "1.5rem" }}
                >
                  Get started absolutely free.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mt: 2, color: theme.palette.grey[600] }}
                >
                  Already have an account?{" "}
                  <Link
                    variant="subtitle2"
                    to={"/login"}
                    component={RouterLink}
                  >
                    Login
                  </Link>
                </Typography>
              </Box>
              <Box>
                <Image
                  disabledEffect
                  src={
                    "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
                  }
                  sx={{ width: 50, height: 50 }}
                  className={classes.img}
                />
              </Box>
            </Box>

            <Form
              onSubmit={handleShopCreate}
              validate={(values) => {
                const errors = {};
                const whitespaceRegExp = /^$|\s+/;
                const poorRegExp = /[a-z]/;
                const weakRegExp = /(?=.*?[0-9])/;
                const strongRegExp = /(?=.*?[#?!@$%^&*-])/;

                const poorPassword = poorRegExp.test(values.password);
                const weakPassword = weakRegExp.test(values.password);
                const strongPassword = strongRegExp.test(values.password);
                const whiteSpace = whitespaceRegExp.test(values.password);

                const emailRegex =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                // const checkerror = checkSpaceAndRegex(values?.firstname);
                // if (checkerror != "") errors.firstname = checkerror;
                if (!values.firstname?.trim()) {
                  errors.firstname = "First name is required.";
                } else if (values?.firstname?.length > 50) {
                  errors.firstname = "Maximum length should be less then 50.";
                }

                // const checkerrorforlname = checkSpaceAndRegex(values?.lastname);
                // if (checkerrorforlname != "")
                //   errors.lastname = checkerrorforlname;

                if (!values.lastname?.trim()) {
                  errors.lastname = "Last name is required.";
                } else if (values?.lastname?.length > 50) {
                  errors.lastname = "Maximum length should be less then 50.";
                }

                var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

                const checkForUserName = checkUserName(values?.shop_name);
                if (checkForUserName != "") errors.shop_name = checkForUserName;
                if (!values.shop_name) {
                  errors.shop_name = "User name is required.";
                } else if (values?.shop_name?.length > 50) {
                  errors.shop_name = "Maximum length should be less then 50.";
                } else if (format.test(values.shop_name)) {
                  errors.shop_name = "Special Characters are not allowed.";
                }

                if (!values.password) {
                  errors.password = "Password is required.";
                } else if (whiteSpace) {
                  errors.password = "Whitespaces are not allowed";
                } else if (
                  values.password.length <= 3 &&
                  (poorPassword || weakPassword || strongPassword)
                ) {
                  errors.password = "Password is Poor.";
                }

                if (!values?.email) {
                  errors.email = "Email is required.";
                } else if (values?.email?.length > 50) {
                  errors.email = "Maximum length should be less then 50.";
                } else if (!emailRegex.test(values?.email)) {
                  errors.email = "Email is invalid";
                }

                return errors;
              }}
              render={({ handleSubmit, form, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Stack spacing={2}>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                      >
                        <Field name="firstname">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              className={clsx(
                                classes.textField,
                                classes.textFieldUnderline,
                              )}
                              label="First Name"
                              type="text"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>

                        <Field name="lastname">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              className={clsx(
                                classes.textField,
                                classes.textFieldUnderline,
                              )}
                              label="Last Name"
                              type="text"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>
                      </Stack>
                      <Stack>
                        <Field name="email">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              className={clsx(
                                classes.textField,
                                classes.textFieldUnderline,
                              )}
                              label="Email"
                              type="text"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>
                      </Stack>

                      <Stack>
                        <Field name="shop_name">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              // className={clsx(
                              //   classes.textField,
                              //   classes.textFieldUnderline,
                              // )}
                              label="User Name"
                              type="text"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error
                                  ? `${meta.error}`
                                  : uNameHelperText
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>
                      </Stack>

                      <Stack>
                        <Field name="password">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              // className={clsx(
                              //   classes.textField,
                              //   classes.textFieldUnderline,
                              // )}
                              label="Password"
                              type="password"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>
                      </Stack>

                      {/* <Stack>
                        <Field name="referralcode">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              // className={clsx(
                              //   classes.textField,
                              //   classes.textFieldUnderline,
                              // )}
                              label="Referral Code"
                              type="number"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>
                      </Stack> */}

                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isLoading}
                        disabled={!termCheck}
                      >
                        {submittingBtnProcess ? "Processing.." : "Register"}
                      </LoadingButton>
                    </Stack>
                  </Box>
                </form>
              )}
            />

            {/* <RegisterForm /> */}
            <TermSectionStyle>
              <Checkbox
                name="term"
                value={termCheck}
                onChange={() => {
                  setTermCheck(!termCheck);
                }}
                style={{ paddingLeft: "0px" }}
              />

              <Typography
                variant="body2"
                align="left"
                sx={{ color: "text.secondary" }}
              >
                By registering, I agree to ServoFeat &nbsp;
                <Link
                  underline="always"
                  color="text.primary"
                  target={"_blank"}
                  href="https://www.servofeat.com/termsandcondition"
                >
                  Terms of Service &nbsp;
                </Link>
                {""}and{""}
                <Link
                  underline="always"
                  color="text.primary"
                  target={"_blank"}
                  href="https://www.servofeat.com/privacypolicy"
                >
                  &nbsp; Privacy Policy
                </Link>
                .
              </Typography>
            </TermSectionStyle>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
// import AsyncCreatableSelect from 'react-select/async-creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import { TextField, IconButton } from "@material-ui/core";
import { isObject } from 'lodash';
import { isJson } from "utility/helpers";

const foodOption = [
    {
        value: "Spicy",
        label: "Spicy"
    },
    {
      value: "Light",
      label: "Light"
    },
    {
      value: "Medium",
      label: "Medium"
    },
    {
      value: "Medium Light",
      label: "Medium Light"
    },
    {
      value: "Medium Spicy",
      label: "Medium Spicy"
    },
    {
      value: "Half",
      label: "Half"
    },
    {
      value: "Full",
      label: "Full"
    }
]
export default function MultiValueInputSelect(props) {

  const { onChange, placeHolder, initialValue }= props
  
    const handleChange = (newValue, actionMeta) => {
      
        if(isObject(newValue))
        {
          onChange(JSON.stringify(newValue))
        }
       
        console.groupEnd();
      };

  return (
    <CreatableSelect
      isMulti
      value={isJson(initialValue) && JSON.parse(initialValue)?.length > 0 && JSON.parse(initialValue)}
      onChange={handleChange}
      options={foodOption}
      placeholder={placeHolder}
    />
  )
}

import React from "react";
import pleaseWaitUrl from "./../../assets/img/please-wait.png";

import "./Loader.styles.css";

const PageLoader = () => {
  return (
    <div className="full-screen-container">
      <img src={pleaseWaitUrl} className="wait-image" alt="Loading..." />
      <div>
        <span className="text-loader">Load&nbsp;ng</span>
      </div>
      <h5 style={{ fontWeight: 600, opacity: 0.6 }}>Please wait a moment </h5>
    </div>
  );
};

export default PageLoader;

import React from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  modelHeader: {
    padding: 0,
  },
};

const useStyles = makeStyles(styles);

function PopUpModel(props) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        {...props}
        size={props?.size ? props?.size : "md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className={classes.modelHeader} closeButton>
          <Modal.Title as={"h5"} id="contained-modal-title-vcenter">
            {props.heading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>{props.body}</Modal.Body>
        {
          props.footer &&  <Modal.Footer style={{ justifyContent: "center" }}>
          {props.footer}
        </Modal.Footer>
        }
       
      </Modal>
    </div>
  );
}

export default PopUpModel;

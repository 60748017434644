const initialState = {
  category: [],
  food_meal_list: [],
  food_variant_list: [],
  diningTable: [],
  diningTableLoder: false,
  foodMenuLoader: true,
  foodCategoryLoader: true,
  foodVariantLoder: true,
  kitchenQueueLoader: true,
  kitchenQueue: [],
  orderHistoryData: [],
  billing: {}
};

export const ApiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CATEGORY_LIST": {
      return {
        ...state,
        category: action.payLoad,
        foodCategoryLoader: false
      };
    }
    case "FOOD_MEAL_LIST": {
      return {
        ...state,
        food_meal_list: action.payLoad,
        foodMenuLoader: false
      };
    }
    case "FOOD_VARIANT_LIST": {
      return {
        ...state,
        food_variant_list: action.payLoad,
        foodVariantLoder: false
      };
    }
    case "DINING_TABLE": {
      return {
        ...state,
        diningTable: action.payLoad,
      };
    }
    case "DINING_TABLE_LOADER": {
      return {
        ...state,
        diningTableLoder: action.payLoad,
      };
    }
    case "FOOD_VARIANT_LOADER": {
      return {
        ...state,
        foodVariantLoder: action.payLoad,
      };
    }
    case "KITCHEN_QUEUE": {
      return {
        ...state,
        kitchenQueue: action.payLoad,
      };
    }
    case "KITCHEN_QUEUE_LOADER": {
      return {
        ...state,
        kitchenQueueLoader: action.payLoad,
      };
    }
    case "GET_BILLING_INFO": {
      return {
        ...state,
        billing: action.payLoad,
      };
    }
    case "GET_ORDER_HISTORY_LIST": {
      return {
        ...state,
        orderHistoryData: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

export default ApiReducer;

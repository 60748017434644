import React, { Fragment, useCallback, useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Importing toastify module
import { toast } from "react-hot-toast";

import { Badge, Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import DataTabel from "../../components/DataTabel";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel,
  Delete,
  Category,
} from "@material-ui/icons";
import LowPriorityIcon from "@material-ui/icons/LowPriority";

import { deleteFoodCategory } from "services/foodCategory.service";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoryList } from "state/thunk-async";
import PopUpModel from "components/Popup/PopUpModel";
import TemporaryDrawer from "components/TemporaryDrawer";
import AddEditFoodCategory from "./AddEditFoodCategory";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";
import { fetchFoodMealList } from "state/thunk-async";
import PriorityFoodCategory from "./PriorityFoodCategory";
import { DeskTopContainer } from "themes/Device";
import { MobileContainer } from "themes/Device";
import MobileTitle from "components/MobileTitle";
import SearchBarInput from "components/SearchBarInput";
import MobileCategory from "components/MobileCategory";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { useTheme } from "@material-ui/core/styles";
import Label from "components/Label";

//context
import {
  useLayoutState,
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";
const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  mobileCategoryCard: {
    margin: '0 auto',
    // display: 'grid',
    // gap: '7px',
    // gridTemplateColumns: 'repeat(2, 1fr)',
    // [theme?.breakpoints.up('sm')]: {
    //   gridTemplateColumns: 'repeat(3, 1fr)'
    // }
  },
  root: {
    width: "100%",
  },
  tabel: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },
  prioritybtn: {
    marginRight: "5px",
  },
  mobileCard: {
    padding: "2px",
    marginTop: "9px"
  },
});

const useStyles = makeStyles(styles);

const FoodCategoryList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const foodCategoryDataList = useSelector((state) => state.api.category);
  const foodCategoryLoader = useSelector(
    (state) => state.api.foodCategoryLoader,
  );
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteFoodCategoryId, setDeleteFoodCategoryId] = useState(0);
  const [deletingBtnProcess, setDeletingBtnProcess] = useState(false);
  const [addEditDrawerShow, setAddEditDrawerShow] = useState(false);
  const [beautifulDrawerShow, setBeautifulDrawerShow] = useState(false);
  const [addEditFoodCategoryId, setAddEditFoodCategoryId] = useState(0);
  const [searchFoodCategory, setSearchFoodCategory] = useState("");
  const [filterFoodCategoryList, setFilterFoodCategoryList] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (layoutState?.currentScreenTitle !== "Food Catogory") {
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: "Food Catogory",
      });
    }
    return () => {
      if (layoutState?.currentScreenTitle === "Category") {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      }
    };
  }, []);

  useCallback(() => {
    dispatch(fetchCategoryList());
  }, [])

  const deleteFoodCategoryData = (foodCategoryId) => {
    if (foodCategoryId > 0) {
      setDeletingBtnProcess(true);
      deleteFoodCategory(foodCategoryId)
        .then((res) => {
          if (res?.data) {
            setDeletingBtnProcess(false);
            toast.success(res?.message);
          } else {
            setDeletingBtnProcess(false);
            toast.success(res?.message);
          }
          setDeleteModalShow(false);
          dispatch(fetchCategoryList());
          dispatch(fetchFoodMealList());
        })
        .catch((err) => {
          toast.success(err?.message);
        });
    }
  };

  const addEditDrawerClose = () => {
    setAddEditDrawerShow(false);
    setBeautifulDrawerShow(false);
    setAddEditFoodCategoryId(0);
  };

  function sortByPriority(a, b) {
    if (a.is_priority < b.is_priority) {
      return -1;
    }
    if (a.is_priority > b.is_priority) {
      return 1;
    }
    return 0;
  }

  const deleteData = (deleteid) => {
    setDeleteFoodCategoryId(deleteid);
    setDeleteModalShow(true);
  };

  const editData = (editid) => {
    // setAddEditFoodCategoryId(editid);
    // setAddEditDrawerShow(true);
    history.push({
      pathname: `/app/food-category/edit/${editid}`,
      state: {
        foodCategoryId: editid,
        backToMain: () => history.push(`/app/food-category`),
      },
    });
  };

  const addDrawerShow = () => {
    // setAddEditDrawerShow(true);
    history.push({
      pathname: `/app/food-category/add`,
      state: {
        foodCategoryId: 0,
        backToMain: () => history.push(`/app/food-category`),
      },
    });
  };

  const addSetPriorityDrawerShow = () => {
    setBeautifulDrawerShow(true);
  };

  const clearTxt = () => {
    setSearchFoodCategory("");
  };

  useEffect(() => {
    setFilterFoodCategoryList(foodCategoryDataList);
  }, [foodCategoryDataList]);

  useEffect(() => {
    if (searchFoodCategory) {
      let filterCategoryList = foodCategoryDataList.filter(
        (item) =>
          item.name &&
          item.name?.toLowerCase()?.includes(searchFoodCategory.toLowerCase()),
      );
      setFilterFoodCategoryList(filterCategoryList);
    } else {
      setFilterFoodCategoryList(foodCategoryDataList);
    }
  }, [searchFoodCategory]);

  return (
    <>
      <PopUpModel
        show={deleteModalShow}
        heading={t("food-category-popup-delete-title")}
        body={t("food-category-popup-delete-body")}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeleteModalShow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteFoodCategoryData(deleteFoodCategoryId)}
            >
              <Delete />
              {deletingBtnProcess ? <CircularProgress /> : t("delete")}
            </Button>
          </>
        }
        onHide={() => setDeleteModalShow(false)}
      />

      <TemporaryDrawer
        drawerClose={addEditDrawerClose}
        isOpen={addEditDrawerShow}
        drawerWidth="50%"
        icon={<Category />}
        title={t("food-category-title")}
      >
        <AddEditFoodCategory
          categoryId={addEditFoodCategoryId}
          drawerClose={addEditDrawerClose}
        />
      </TemporaryDrawer>

      <TemporaryDrawer
        drawerClose={addEditDrawerClose}
        isOpen={beautifulDrawerShow}
        drawerWidth="35%"
        icon={<LowPriorityIcon />}
        title={t("priority-label")}
      >
        <PriorityFoodCategory
          drawerClose={addEditDrawerClose}
          foodCategoryDataList={foodCategoryDataList}
        />
      </TemporaryDrawer>

      <DeskTopContainer>
        <div className={classes.tabel}>
          <MaterialTable
            title={
              <h5>
                <b className="mr-3">{t("food-category-title")}</b>
              </h5>
            }
            columns={[
              {
                title: t("food-category-name"),
                field: "name",
                cellStyle: {
                  width: "45%",
                },
              },
              {
                title: t("available-unavailable"),
                field: "active",
                cellStyle: {
                  width: "45%",
                },
              },
              {
                title: t("action"),
                field: "action",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) => (
                  <Fragment>
                    <RoleBasedGuard
                      resource={RESOURCE.FOOD_CATEGORY}
                      action={ACTION.UPDATE}
                    >
                      <IconButton
                        color="primary"
                        title={t("food-category-edit")}
                        aria-label={t("food-category-edit")}
                        onClick={() => {
                          setAddEditFoodCategoryId(rowData.id);
                          setAddEditDrawerShow(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </RoleBasedGuard>
                    <RoleBasedGuard
                      resource={RESOURCE.FOOD_CATEGORY}
                      action={ACTION.DELETE}
                    >
                      <IconButton
                        color="secondary"
                        title={t("food-category-delete-icon")}
                        aria-label={t("food-category-delete-icon")}
                        onClick={() => {
                          setDeleteFoodCategoryId(rowData.id);
                          setDeleteModalShow(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </RoleBasedGuard>
                  </Fragment>
                ),
              },
            ]}
            data={
              foodCategoryDataList &&
              foodCategoryDataList.sort(sortByPriority).map((item, index) => {
                return {
                  name: item?.name,
                  id: item?.id,
                  active: item?.is_active ? (
                    <>
                      <div>
                        <Label
                          variant={
                            theme.palette.mode === "light" ? "ghost" : "filled"
                          }
                          color={"success"}
                        >
                          {item?.is_active ? "Available" : item?.is_active}
                        </Label>
                      </div>
                    </>
                  ) : (
                    <Label
                      variant={
                        theme.palette.mode === "light" ? "ghost" : "filled"
                      }
                      color="error"
                    >
                      {!item?.is_active ? "Unavailable" : item?.is_active}
                    </Label>
                  ),
                };
              })
            }
            options={{
              paging: true,
              pageSize: 10,
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 20, 30],
              sorting: true,
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                color: theme.palette.grey[500],
                borderColor: "rgba(0, 0, 0, 0.23)",
                border: "0.8px solid",
                borderRadius: "6px",
                padding: "11px",
                "&:before": {
                  borderBottom: "0px",
                },
                "&:after": {
                  borderBottom: "0px",
                },
              },
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              },
            }}
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: foodCategoryLoader ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>{t("food-category-empty-data-message")}</p>
                  </NotFoundComponent>
                ),
              },
            }}
            // other props
            components={{
              Toolbar: (props) => (
                <div className="custom-material-table-toolbar ">
                  <MTableToolbar {...props} />
                  <div style={{ padding: "0px 10px" }}>
                    <RoleBasedGuard
                      resource={RESOURCE.FOOD_CATEGORY}
                      action={ACTION.UPDATE}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.prioritybtn}
                        onClick={() => {
                          setBeautifulDrawerShow(true);
                        }}
                        startIcon={<LowPriorityIcon />}
                        disabled={
                          foodCategoryDataList.length > 0 ? false : true
                        }
                      >
                        {t("priority-label")}
                      </Button>
                    </RoleBasedGuard>
                    <RoleBasedGuard
                      resource={RESOURCE.FOOD_CATEGORY}
                      action={ACTION.ADD}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => {
                          setAddEditDrawerShow(true);
                        }}
                      >
                        {t("food-category-add")}
                      </Button>
                    </RoleBasedGuard>

                  </div>
                </div>
              ),
            }}
          />
        </div>
      </DeskTopContainer>
      <MobileContainer>
        <div className={classes.mobileCard}>
          <SearchBarInput
            onChange={(e) => {
              setSearchFoodCategory(e.target.value);
            }}
            value={searchFoodCategory}
            placeholder={t("search-food-category")}
            clearTxt={clearTxt}
          />
          {foodCategoryLoader ? (
            <SkeletonLoader count={3} height="10rem" />
          ) : (
            <div className={classes.mobileCategoryCard}>
              {filterFoodCategoryList &&
                filterFoodCategoryList.map((item, index) => (
                  <MobileCategory
                    name={item.name}
                    active={item.is_active}
                    deleteData={deleteData}
                    editData={editData}
                    itemId={item?.id}
                    isMenuBar
                  />
                ))}
            </div>
          )}
          <MobileTitle
            title={t("food-category-title")}
            addButton={addDrawerShow}
            setPriorityButton={addSetPriorityDrawerShow}
          />
        </div>
        {filterFoodCategoryList.length < 1 && !foodCategoryLoader && (
          <NotFoundComponent imgUrl={noFoundImg}>
            <p style={{ fontWeight: 600 }}>{t("food-category-empty-data-message")}</p>
          </NotFoundComponent>
        )}
      </MobileContainer>
    </>
  );
};

export default FoodCategoryList;

import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButtonComponent from "components/IconButtonComponent";
import { makeStyles } from "@material-ui/styles";
import { Button, IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    padding: "6px !important",
    minHeight: "30px !important",
  },

  mainButton: {
    marginLeft: "auto",
  },
}));

const MenuButtonEditDelete = (props) => {
  const classes = useStyles();
  const { deleteClick, editClick, itemId, deleteByname, isEdit, isDelete, isMenuBar } =
    props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="d-flex">
      {
        isMenuBar ?
          <>
            <div className="d-flex ml-auto">
              {deleteByname ? (
                <Button
                  color="secondary"
                  size="small"
                  variant="outlined"
                  title="Delete Customer"
                  aria-label="Delete Customer"
                  onClick={() => {
                    deleteClick(deleteByname);
                  }}
                >
                  <Delete
                  />{" "}
                  {t("delete")}
                </Button>
              ) : (
                <Button
                  color="secondary"
                  title="Delete Customer"
                  size="small"
                  variant="outlined"
                  aria-label="Delete Customer"
                  onClick={() => {
                    deleteClick(itemId);
                  }}
                  style={{
                    color: '#8b0000b8',
                   border: '1px solid #6c0707',
                  }}
                >
                  <Delete
                  />{" "}
                  {t("delete")}
                </Button>
              )}

              <Button
                color="primary"
                aria-label="Edit Customer"
                onClick={() => {
                  editClick(itemId);
                }}
                size="small"
                variant="outlined"
                style={{
                  marginLeft: '10px'
                }}
              >
                <Edit
                />
                {t("edit")}
              </Button>
            </div>
          </>
          :
          <>
            <IconButtonComponent
              onClick={handleClick}
              style={{ marginLeft: "auto", padding: "0px" }}
            >
              <MoreVertIcon />
            </IconButtonComponent>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem className={classes.buttonStyle} onClick={handleClose}>
                {deleteByname ? (
                  <Button
                    color="secondary"
                    title="Delete Customer"
                    aria-label="Delete Customer"
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      deleteClick(deleteByname);
                    }}
                    style={{
                      color: '#8b0000b8',
                     border: '1px solid #6c0707',
                    }}
                  >
                    <Delete
                    />{" "}
                    {t("delete")}
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    title="Delete Customer"
                    aria-label="Delete Customer"
                    onClick={() => {
                      deleteClick(itemId);
                    }}
                    style={{
                      color: '#8b0000b8',
                     border: '1px solid #6c0707',
                    }}
                  >
                    <Delete
                    />{" "}
                    {t("delete")}
                  </Button>
                )}
              </MenuItem>
              <MenuItem className={classes.buttonStyle} onClick={handleClose}>
                <IconButton
                  color="primary"
                  aria-label="Edit Customer"
                  size="small"
                variant="outlined"
                  onClick={() => {
                    editClick(itemId);
                  }}
                >
                  <Edit
                  />
                  {t("edit")}
                </IconButton>
              </MenuItem>
            </Menu></>
      }

    </div>
  );
};

export default MenuButtonEditDelete;

import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Fab,
} from "@material-ui/core";
import { toast } from "react-hot-toast";
import {
  Menu as MenuIcon,
  Send as SendIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  ExitToApp,
  QuestionAnswer,
  Settings,
  FullscreenExit,
  FullscreenOutlined,
  Print,
  NotificationImportantSharp,
} from "@material-ui/icons";
import classNames from "classnames";
import { Navbar, Nav, NavDropdown, Form, Row } from "react-bootstrap";
import { NavLink, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RouteLinks from "common/routes";
import { signOut } from "utility/helpers";
import useStyles from "./styles";
import { Badge, Typography } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import InfoIcon from "@material-ui/icons/Info";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { isDesktop } from "react-device-detect";
import AddIcon from "@mui/icons-material/Add";
import {
  DeskTopContainer,
  MobileContainer,
  useInMobile,
} from "../../themes/Device";
import LockIcon from "@material-ui/icons/Lock";
import { Box, Button, Drawer } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useTranslation, initReactI18next } from "react-i18next";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  toggleOrderSectionView,
} from "context/LayoutContext";

import "assets/css/header.css";
import InfoModel from "components/InfoModel";
import servoFeatLogo from "assets/img/servofeatlogo-preview.png";
import { ServiceEnabled } from "utility/helpers";
import { useTheme } from "@material-ui/core/styles";
import NavbarHorizontal from "components/Layouts/dashboard/navbar/NavbarHorizontal";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { ACTION, RESOURCE } from "common/constant";
import HelpIcon from "@material-ui/icons/Help";
import ReactPlayer from "react-player";
import PopUpModel from "components/Popup/PopUpModel";
import Label from "components/Label";
import NotificationsPopover from "components/Layouts/dashboard/header/NotificationsPopover";
import ContactsPopover from "components/Layouts/dashboard/header/ContactsPopover";
import { getSubdomain } from "utility/helpers";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { SelectItems } from "state/actions/customer.types";
import { isJson } from "utility/helpers";
import checkAddonsMatch from "common/order-common";
import { OrderDetailCommonMessage } from "common/constant";
import { checkOutPageStatus } from "state/actions/customer.types";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

export default function OnlineLayoutHeader(props) {
  const { userData } = props;
  var classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const dispatch = useDispatch();
  const theme = useTheme();
  const orderHistoryDataList = useSelector(
    (state) => state?.api?.orderHistoryData,
  );
  const selectItemsData = useSelector((state) => state?.customer?.selectItem);
  const finelBillPrice = useSelector((state) => state?.customer?.finelBill);
  const checkoutPageGloble = useSelector((state) => state.customer.checkoutPage);
  const userName = getSubdomain(window?.location?.hostname);
  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  const [subTotal, setSubTotal] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [videomodalShow, setVideomodalShow] = React.useState(false);
  const [orderNotification, setorderNotification] = useState([]);
  const [logoError, setLogoError] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const deleteFoodItem = (item, itemIndex) => {
    //####### Delete By Item Index ##########
    dispatch(
      SelectItems(
        selectItemsData?.filter((element, index) => index !== itemIndex),
      ),
    );
    dispatch(
      SelectItems(
        selectItemsData?.filter((element, index) => index !== itemIndex),
      ),
    );
  };

  const foodItemsArrayAdded = (foodItem, existing_index) => {
    let availableFoodItemIndex = -1;
    let availableTableListIndex = -1;
    if (existing_index === undefined) {
      availableFoodItemIndex = selectItemsData.findIndex((item) => {
        let foodItemAddons = foodItem?.addons;
        let selectedItemAddons = item?.addons;
        if (selectedItemAddons) {
          if (isJson(selectedItemAddons)) {
            selectedItemAddons = JSON.parse(selectedItemAddons);
          }
        } else {
          selectedItemAddons = [];
        }
        if (foodItemAddons) {
          if (isJson(foodItemAddons)) {
            foodItemAddons = JSON.parse(foodItemAddons);
          }
        } else {
          foodItemAddons = [];
        }
        return (
          checkAddonsMatch(foodItemAddons, selectedItemAddons) &&
          item?.food_item_id === foodItem?.food_item_id &&
          item?.varient_id === foodItem.varient_id
        );
      });
      availableTableListIndex = selectItemsData.findIndex((item) => {
        let foodItemAddons = foodItem?.addons;
        let selectedItemAddons = item?.addons;
        if (selectedItemAddons) {
          if (isJson(selectedItemAddons)) {
            selectedItemAddons = JSON.parse(selectedItemAddons);
          }
        } else {
          selectedItemAddons = [];
        }
        if (foodItemAddons) {
          if (isJson(foodItemAddons)) {
            foodItemAddons = JSON.parse(foodItemAddons);
          }
        } else {
          foodItemAddons = [];
        }
        return (
          checkAddonsMatch(foodItemAddons, selectedItemAddons) &&
          item?.food_item_id === foodItem?.food_item_id &&
          item?.varient_id === foodItem.varient_id
        );
      });
    }

    // Foood Item update logic
    const orderMealItemData = {
      id: foodItem.id || 0,
      qty: foodItem?.qty,
      food_item_id: foodItem?.food_item_id || 0,
      price: foodItem?.price,
      total_qty_price: foodItem?.total_qty_price,
      addon_price: foodItem?.addon_price,
      total_addon_qty_price: foodItem?.total_addon_qty_price,
      sub_total: foodItem?.sub_total,
      varient_id: foodItem?.varient_id,
      is_non_veg: foodItem?.is_non_veg,
      addons: foodItem?.addons || "",
      instruction_note: foodItem?.instruction_note,
      fooditem: foodItem?.fooditem,
    };
    if (existing_index > -1 && existing_index !== undefined) {
      const updatedSelectedFoodItemListData = [...selectItemsData];
      const updatedSelectedFoodItemData = [...selectItemsData];
      updatedSelectedFoodItemListData[existing_index] = foodItem;
      updatedSelectedFoodItemData[existing_index] = orderMealItemData;
      dispatch(SelectItems(updatedSelectedFoodItemListData));
      dispatch(SelectItems(updatedSelectedFoodItemData));
    } else if (availableFoodItemIndex >= 0 && availableTableListIndex >= 0) {
      // ######## Increase Qty When Food Item is same also varient and addons same  #######
      const updatedSelectedFoodItems = [...selectItemsData];
      let selectedItem = updatedSelectedFoodItems[availableFoodItemIndex];
      plusItemQty(selectedItem, availableFoodItemIndex, foodItem?.qty);
      toast.info(OrderDetailCommonMessage.QUANTITY_ADDED);
    } else {
      dispatch(SelectItems((r) => [...r, foodItem]));
      dispatch(SelectItems((r) => [...r, orderMealItemData]));
      // setSelectedFoodItemListData((r) => [...r, foodItem]);
      // setSelectedFoodItemData((r) => [...r, orderMealItemData]);
    }
    // onchange of order id it will calculate the Subtotal amount*qty
    subTotalCalculate();
  };

  const subTotalCalculate = () => {
    let amount = 0;
    selectItemsData.forEach((order) => {
      amount += parseFloat(order?.sub_total);
    });
    setSubTotal(parseFloat(amount).toFixed(2));
  };


  const plusItemQty = (item, index, updateQty = 0) => {
    const totalQty =
      updateQty > 0
        ? parseFloat(item?.qty) + parseFloat(updateQty)
        : parseFloat(item?.qty) + 1;

    const formatFoodItem = {
      ...item,
      qty: totalQty,
      id: item?.id || 0,
      price: parseFloat(item?.price),
      total_qty_price:
        parseFloat(item?.price) * parseFloat(parseFloat(item?.qty) + 1),
      addon_price: parseFloat(item?.addon_price),
      total_addon_qty_price:
        parseFloat(item?.addon_price) * parseFloat(parseFloat(item?.qty) + 1),
      sub_total:
        parseFloat(item?.addon_price) * parseFloat(parseFloat(item?.qty) + 1) +
        parseFloat(item?.price) * parseFloat(parseFloat(item?.qty) + 1),
    };

    if (formatFoodItem.qty > 0) {
      foodItemsArrayAdded(formatFoodItem, index);
    }
  };

  const minusItemQty = (item, index) => {
    const formatFoodItem = {
      ...item,
      qty: parseFloat(item?.qty) - 1,
      id: item?.id || 0,
      price: parseFloat(item?.price),
      total_qty_price: parseFloat(item?.price) * parseFloat(item?.qty) - 1,
      addon_price: parseFloat(item?.addon_price),
      total_addon_qty_price:
        parseFloat(item?.addon_price) * parseFloat(item?.qty) - 1,
      sub_total:
        parseFloat(item?.addon_price) * parseFloat(item?.qty - 1) +
        parseFloat(item?.price) * parseFloat(item?.qty - 1),
    };

    if (formatFoodItem.qty > 0) {
      foodItemsArrayAdded(formatFoodItem, index);
    } else if (formatFoodItem.qty === 0) {
      deleteFoodItem(formatFoodItem, index);
    }
  };

  const getScreenTitle = () => {
    const path = history.location.pathname;
    if (path === "/app/customer-queue") {
      return t("customer-queue-title");
    }
    return "";
  };


  const OpenCheckoutPage = async () => {
    await dispatch(checkOutPageStatus(true))
  }
  return (
    <AppBar
      className={classes.appBar}
      style={{
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        padding: useInMobile() ? "4px" : "0px",
      }}
    >
      <PopUpModel
        size="lg"
        onHide={() => {
          setVideomodalShow(false);
        }}
        show={videomodalShow}
        heading={"Help Video"}
        body={
          <Row>
            <ReactPlayer
              controls
              style={{ paddingTop: "0px" }}
              width="800px"
              height="480px"
              onError={() => console.log("onError CallBack")}
              onPause={() => console.log("onPause CallBack")}
              onStart={() => console.log("onStart CallBack")}
              onReady={() => console.log("OnReady CallBack")}
              onEnded={() => console.log("onEnded CallBack")}
              url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
            />
          </Row>
        }
      />
      <Toolbar className={classes.toolbar}>
        <Navbar.Brand className={`${classes.navBarLogo}`}>
          <Link to={'/'} style={{ textDecoration: 'none', color: '#fff'}}>
            <img
              src={
                !props.userData?.shop?.billing?.logo || logoError === true
                  ? "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
                  : props.userData?.shop?.billing?.logo
              }
              style={{ width: "37px" }}
              onError={() => {
                setLogoError(true);
              }}
            />
            &nbsp; {userData?.shop?.name}
          </Link>
        </Navbar.Brand>

        <MobileContainer>
          <div className={classes?.screenTitle}>
            {layoutState?.currentScreenTitle || getScreenTitle()}
          </div>
        </MobileContainer>
        <Navbar variant="light" expand="md" sticky="top" className="smr-navbar">
          <div className="d-flex ml-auto align-items-center">
            <DeskTopContainer>
              {/* <div style={{marginLeft: "8px"}}>
                <Label color="info" variant='ghost'>
                  { `Welcome to ${userData?.shop?.name}`}
                </Label>
              </div> */}
              <IconButton
                className={classes.iconButton}
                onClick={() => setModalShow(true)}
              >
                <InfoIcon classes={{ root: classes.phoneIcon }} />
              </IconButton>
            </DeskTopContainer>
            <div className={classes.cartMain}>
              {selectItemsData.length > 0 ? (
                <p className={classes.itemNumber}>{selectItemsData.length}</p>
              ) : null}
              <IconButton
                className={classes.iconButton}
                onClick={toggleDrawer("right", true)}
              >
                <ShoppingCartIcon classes={{ root: classes.phoneIcon }} />
              </IconButton>
            </div>
            <IconButton className={classes.iconButton}>
              <NotificationsIcon
                orderHistoryDataList={orderHistoryDataList?.data}
                classes={{ root: classes.phoneIcon }}
              />
            </IconButton>

            {/* <IconButton
              aria-haspopup="true"
              color="inherit"
              className={classes.headerMenuButton}
              aria-controls="profile-menu"
              onClick={(e) => setProfileMenu(e.currentTarget)}
            >
              <AccountIcon classes={{ root: classes.headerIcon }} />
            </IconButton> */}
            <InfoModel show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </Navbar>

        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {t("header-new-message-label")}
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} {t("header-new-message-label")}
            </Typography>
          </div>
          {messages.map((message) => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>
        {orderNotification.length > 0 ? (
          <Menu
            id="notifications-menu"
            open={Boolean(notificationsMenu)}
            anchorEl={notificationsMenu}
            onClose={() => {
              setNotificationsMenu(null);
              setorderNotification([]);
            }}
            className={classes.headerMenu}
            disableAutoFocusItem
          >
            {orderNotification.map((notification) => (
              <MenuItem
                key={notification.id}
                onClick={() => {
                  setNotificationsMenu(null);
                  setorderNotification([]);
                }}
                className={classes.headerMenuItem}
              >
                <Notification {...notification} typographyVariant="inherit" />
              </MenuItem>
            ))}
          </Menu>
        ) : (
          <Menu
            id="notifications-menu"
            open={Boolean(notificationsMenu)}
            anchorEl={notificationsMenu}
            onClose={() => {
              setNotificationsMenu(null);
              setorderNotification([]);
            }}
            className={classes.headerMenu}
            disableAutoFocusItem
          >
            <MenuItem
              // key={notification.id}
              onClick={() => {
                setNotificationsMenu(null);
                setorderNotification([]);
              }}
              className={classes.headerMenuItem}
            >
              <Notification
                message="No any notification"
                typographyVariant="inherit"
              />
            </MenuItem>
          </Menu>
        )}
      </Toolbar>
      <Box sx={{ width: 250 }} role="presentation">
        <React.Fragment key="right">
          <Drawer
            anchor={"right"}
            open={state["right"]}
            className={classes.muiDrawer}
            onClose={toggleDrawer("right", false)}
          >
            <div className={classes.sidebarmenu}>
              <div className={classes.close}>
                <h5>
                  <b>Shopping Cart</b>
                </h5>
                <ClearIcon onClick={toggleDrawer("right", false)} />
              </div>
              <div className={classes.itemsMenu}>
                {selectItemsData.map((val, i) => {
                  return (
                    <div className={classes.product}>
                      <div className={classes.productDetails}>
                        {/* <img
                        className={classes.productImage}
                        src="https://images.unsplash.com/photo-1550258987-190a2d41a8ba?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGluZWFwcGxlfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
                        height={50}
                        width={50}
                      /> */}
                        <div>
                          <span>{val?.fooditem?.name}</span><br />
                          <span className={classes.foodCatogary}>({val?.fooditem?.category?.name})</span>
                          &nbsp;&nbsp;
                          <span className={classes.Item}>
                            Item Price {val?.price}
                          </span>
                          <p style={{ fontSize: "14px", display: 'flex', alignItems: 'center' }}>
                            <b><CurrencyRupeeIcon style={{ fontSize: 'medium' }} />{val?.sub_total}</b>
                          </p>
                        </div>
                      </div>
                      <div className={classes.productbutton}>
                        <RemoveIcon
                          onClick={() => {
                            minusItemQty(val, i);
                          }}
                          className={classes.Removeicon}
                        />
                        &nbsp;&nbsp;
                        <span>
                          <b className={classes.qty}>{val?.qty}</b>
                        </span>
                        &nbsp;&nbsp;
                        <AddIcon
                          onClick={() => {
                            plusItemQty(val, i);
                          }}
                          className={classes.Removeicon}
                        />
                      </div>
                      <div
                        className={classes.deliconButton}
                        onClick={() => {
                          deleteFoodItem(val, i);
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div onClick={toggleDrawer("right", false)}>
                <button className={classes.checkOutBtn} onClick={() => {history.push('/checkout') }}>
                  Proceed To Checkout
                  <p className={classes.checkOutBtnTotal}>{finelBillPrice}</p>
                </button>
              </div>
            </div>
          </Drawer>
        </React.Fragment>
      </Box>
    </AppBar>
  );
}

import { Card, Container, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { Switch, Button, MenuItem } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import TextInputMui from "components/TextInputMui";
import { makeStyles } from "@material-ui/core/styles";
import arrayMutators from "final-form-arrays";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { updateSettingData } from "services/setting.service";
import toast from "react-hot-toast";
import { geSettingsnew } from "services/setting.service";
import { refreshShopToken } from "services/shop.service";
import { fetchShopSettingInfo } from "state/thunk-async";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setShopSettingData as setShopSettingDataDispatch } from "state/actions/shopSetting.types";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PopUpModel from "components/Popup/PopUpModel";
import AddEditPrinter from "pages/AdditionalSetting/Printer/AddEditPrinter";
import { useTranslation } from "react-i18next";

const styles = {
  formcontrolwidth: {
    width: "100%",
    marginBottom: 10,
    paddingLeft: 58,
    "& > span": {
      maxWidth: 240,
      fontSize: 12,
    },
  },
};

const useStyles = makeStyles(styles);

export default function Settings() {
  const classes = useStyles();
  const [isUpdating, setIsUpdating] = useState(false);
  // const [shopSettingsData, setShopSettingsData] = useState({});
  const dispatch = useDispatch();
  const shopSettingsData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const [printerModalShow, setPrinterModalShow] = useState(false);
  const { t,i18n } = useTranslation();

  useEffect(() => {
    getSettingsData();
  }, []);

  const getSettingsData = async () => {
    // const result = await geSettingsnew();
    dispatch(fetchShopSettingInfo());
    // if (result.status === 200) {
    //   setShopSettingsData(result?.data);
    //   dispatch(setShopSettingDataDispatch(result?.data));
    //   setIsUpdating(false);
    // } else if (result.status === 400) {
    //   setIsUpdating(false);
    // }
  };

  const onSubmit = async (values) => {
    setIsUpdating(true);
    i18n.changeLanguage(values.STORE_LANGUAGE);
    const shopId = localStorage.getItem("shopId");
    const result = await updateSettingData(values, shopId);
    if (result.status === 200) {
      setIsUpdating(false);
      getSettingsData();
      toast.success(result.data.message);
    } else if (result.status === 400) {
      setIsUpdating(false);
      toast.error(result?.message);
    }
    setRefreshShopToken();
  };

  const setRefreshShopToken = async () => {
    const bodyPayload = {
      username: localStorage.getItem("username"),
    };
    const result = await refreshShopToken(bodyPayload);
    if (result.statusCode === 200) {
      localStorage.setItem("token", result?.data?.token);
    } else {
      toast.error(result?.message);
    }
  };

  return (
    <>
      <PopUpModel
        size="lg"
        onHide={() => {
          setPrinterModalShow(false);
        }}
        show={printerModalShow}
        heading={t("available-printer-title")}
        body={<AddEditPrinter />}
      />
      <Form
        onSubmit={onSubmit}
        initialValues={shopSettingsData}
        validate={(values) => {
          const errors = {};
          if (
            values?.NOTIFICATION_TIME?.trim() &&
            parseInt(values?.NOTIFICATION_TIME) > 24
          ) {
            errors.NOTIFICATION_TIME = t(
              "settings-time-max-validation-message",
            );
          }
          if (
            values?.NOTIFICATION_TIME?.trim() &&
            parseInt(values?.NOTIFICATION_TIME) < 0
          ) {
            errors.NOTIFICATION_TIME = t(
              "settings-time-min-validation-message",
            );
          }
          if (
            values?.ORDER_TOKEN_RESET_TIME?.trim() &&
            parseInt(values?.ORDER_TOKEN_RESET_TIME) > 24
          ) {
            errors.NOTIFICATION_TIME = t(
              "settings-time-max-validation-message",
            );
          }
          if (
            values?.ORDER_TOKEN_RESET_TIME?.trim() &&
            parseInt(values?.ORDER_TOKEN_RESET_TIME) < 0
          ) {
            errors.NOTIFICATION_TIME = t(
              "settings-time-min-validation-message",
            );
          }

          return errors;
        }}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className="mt-3">
            <Container className="profile-container">
              <Card sx={{ p: 3 }}>
                <Stack spacing={3} alignItems="flex-end">
                  <Stack spacing={2} sx={{ width: 1 }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack>
                        <Typography
                          variant="overline"
                          sx={{ color: "text.secondary" }}
                        >
                          {t("settings-screen-title")}
                        </Typography>
                      </Stack>
                      <Stack>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          style={{ backgroundColor: "#00AB55" }}
                          loading={isUpdating}
                        >
                          {t("settings-save-button-label")}
                        </LoadingButton>
                      </Stack>
                    </Stack>

                    <Stack spacing={1}>
                      <div>
                        <Row>
                          <GridItem xs={12} sm={12} md={6}>
                            {/* <Field name="SMS_ENABLED">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label="IS SMS ENABLED ?"
                              />
                            )}
                          </Field>
                          {values?.SMS_ENABLED && (
                            <Field name="SMS">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    type="number"
                                    label="Sms Credit Shop *"
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          )} */}
                            <Field name="WHATSAPP_ENABLED">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={t("settings-whatsapp-enabled-label")}
                                />
                              )}
                            </Field>
                            {values.WHATSAPP_ENABLED && (
                              <Field name="WHATSAPP">
                                {({ input, meta }) => (
                                  <FormControl
                                    className={classes.formcontrolwidth}
                                  >
                                    <TextInputMui
                                      {...input}
                                      disabled
                                      type="number"
                                      label={`${t(
                                        "settings-whatsapp-credit-shop-label",
                                      )}${" *"}`}
                                      variant="outlined"
                                      error={
                                        meta.error && meta.touched
                                          ? true
                                          : false
                                      }
                                      helperText={t(
                                        "settings-whatsapp-alert-message",
                                      )}
                                    />
                                    {meta.error && meta.touched && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                            )}
                            <Field name="KITCHAN_SERVICE">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={t(
                                    "settings-kitchen-service-enabled-label",
                                  )}
                                />
                              )}
                            </Field>
                            {values.KITCHAN_SERVICE && (
                              <>
                                <Field name="KITCHEN_IP">
                                  {({ input, meta }) => (
                                    <FormControl
                                      className={classes.formcontrolwidth}
                                    >
                                      <TextInputMui
                                        {...input}
                                        type="text"
                                        label={`${t(
                                          "settings-kitchen-ip-name-label",
                                        )}${" *"}`}
                                        variant="outlined"
                                        error={
                                          meta.error && meta.touched
                                            ? true
                                            : false
                                        }
                                      />
                                      {meta.error && meta.touched && (
                                        <span style={{ color: "red" }}>
                                          {meta.error}
                                        </span>
                                      )}
                                    </FormControl>
                                  )}
                                </Field>
                                <Field name="KITCHEN_PRINTER_NAME">
                                  {({ input, meta }) => (
                                    <FormControl
                                      className={classes.formcontrolwidth}
                                    >
                                      <TextInputMui
                                        {...input}
                                        type="text"
                                        label={`${t(
                                          "settings-kitchen-printer-name-label",
                                        )}${" *"}`}
                                        variant="outlined"
                                        error={
                                          meta.error && meta.touched
                                            ? true
                                            : false
                                        }
                                      />
                                      {meta.error && meta.touched && (
                                        <span style={{ color: "red" }}>
                                          {meta.error}
                                        </span>
                                      )}
                                    </FormControl>
                                  )}
                                </Field>
                                <Field name="KITCHEN_PRINTER_SIZE">
                                  {({ input, meta }) => (
                                    <FormControl
                                      className={classes.formcontrolwidth}
                                    >
                                      <TextInputMui
                                        {...input}
                                        type="text"
                                        label={`${t(
                                          "settings-kitchen-printer-size-label",
                                        )}${" *"}`}
                                        variant="outlined"
                                        error={
                                          meta.error && meta.touched
                                            ? true
                                            : false
                                        }
                                      />
                                      {meta.error && meta.touched && (
                                        <span style={{ color: "red" }}>
                                          {meta.error}
                                        </span>
                                      )}
                                    </FormControl>
                                  )}
                                </Field>
                              </>
                            )}
                            <Field name="PRINTER_ENABLED">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={t("settings-printer-enabled-label")}
                                />
                              )}
                            </Field>
                            {values?.PRINTER_ENABLED && (
                              <Field name="PRINTER_NAME">
                                {({ input, meta }) => (
                                  <FormControl
                                    className={classes.formcontrolwidth}
                                  >
                                    <TextInputMui
                                      {...input}
                                      type="text"
                                      label="Printer Name *"
                                      variant="outlined"
                                      error={
                                        meta.error && meta.touched
                                          ? true
                                          : false
                                      }
                                    />
                                    {meta.error && meta.touched && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                    <div>
                                      <Button
                                        size="small"
                                        type="button"
                                        onClick={() =>
                                          setPrinterModalShow(true)
                                        }
                                      >
                                        {t("settings-click-here-label")}
                                      </Button>{" "}
                                      {t("settings-available-printer-label")}
                                    </div>
                                  </FormControl>
                                )}
                              </Field>
                            )}
                            {values?.PRINTER_ENABLED && (
                              <Field name="PRINTER_TYPE">
                                {({ input, meta }) => (
                                  <FormControl
                                    className={classes.formcontrolwidth}
                                  >
                                    <TextInputMui
                                      {...input}
                                      type="text"
                                      label={`${t(
                                        "settings-printer-type-label",
                                      )}${" *"}`}
                                      variant="outlined"
                                      error={
                                        meta.error && meta.touched
                                          ? true
                                          : false
                                      }
                                    />
                                    {meta.error && meta.touched && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                            )}
                            {values?.PRINTER_ENABLED && (
                              <Field name="PRINTER_SIZE">
                                {({ input, meta }) => (
                                  <FormControl
                                    className={classes.formcontrolwidth}
                                  >
                                    <TextInputMui
                                      {...input}
                                      type="number"
                                      label={t(
                                        "settings-printer-paper-size-label",
                                      )}
                                      variant="outlined"
                                      error={
                                        meta.error && meta.touched
                                          ? true
                                          : false
                                      }
                                      helperText={`${t(
                                        "settings-example",
                                      )}${"140, 170(40MM), 200(40MM), 250, 270(80MM), 300"}`}
                                    />
                                    {meta.error && meta.touched && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                            )}
                            {values?.PRINTER_ENABLED && (
                              <Field name="THERMAL_PRINTER_PAPER_SIZE">
                                {({ input, meta }) => (
                                  <FormControl
                                    className={classes.formcontrolwidth}
                                  >
                                    <TextInputMui
                                      {...input}
                                      type="number"
                                      label={`${t(
                                        "settings-thermal-printer-paper-size-label",
                                      )}${" *"}`}
                                      variant="outlined"
                                      error={
                                        meta.error && meta.touched
                                          ? true
                                          : false
                                      }
                                      helperText={`${t(
                                        "settings-example",
                                      )}${"140, 170(40MM), 200(40MM), 250, 270(80MM), 300"}`}
                                    />
                                    {meta.error && meta.touched && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                            )}
                            <Field name="DINE_IN_SERVICE">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={t(
                                    "settings-dine-in-service-enabled-label",
                                  )}
                                />
                              )}
                            </Field>
                            <Field name="DEFAULT_PARCEL_ORDER">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={t(
                                    "settings-default-parcel-order-enabled-label",
                                  )}
                                />
                              )}
                            </Field>
                            <Field name="SAME_PRINTER_KOT_PRINT_ENABLED">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={t(
                                    "settings-kot-billing-printer-enabled-label",
                                  )}
                                />
                              )}
                            </Field>
                            <Field name="IS_SHOP_VEGETARIAN">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={t("settings-vegetarian-shop-label")}
                                />
                              )}
                            </Field>
                            <Field name="IS_ENABLE_KOT">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={t("settings-kot-order-enabled-label")}
                                />
                              )}
                            </Field>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Field name="PRINT_ON_SETTLE">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={t(
                                    "settings-printer-settle-order-label",
                                  )}
                                />
                              )}
                            </Field>
                            <Field name="AUTOMATIC_ORDER_SETTLE">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={"Automatically Settle Order ?"}
                                />
                              )}
                            </Field>
                            <Field name="ONLINE_ORDER_START">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={"Start Online Order?"}
                                />
                              )}
                            </Field>
                            <Field name="PAYMENT_ACEEPT_ONLINE">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={"Accept Online Payment?"}
                                />
                              )}
                            </Field>
                            <br></br>
                            <Field name="EMAIL">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    type="number"
                                    label={`${t(
                                      "settings-email-credit-shop-label",
                                    )}${" *"}`}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="BILL_IP">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    type="text"
                                    label={t("settings-bill-ip-label")}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="NOTIFICATION_TIME">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    type="number"
                                    label={t(
                                      "settings-daily-report--notification-label",
                                    )}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="REDIRECT_ON_BILLS">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    select
                                    label={t(
                                      "settings-redirect-page-after-bill-label",
                                    )}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  >
                                    <MenuItem value={"DEFAULT"}>
                                      {t("settings-default-label")}
                                    </MenuItem>
                                    <MenuItem value={"DINE_IN_SCREEN"}>
                                      {t("settings-dine-in-screen-label")}
                                    </MenuItem>
                                    <MenuItem value={"SALES_HISTORY"}>
                                      {t("settings-sales-history-label")}
                                    </MenuItem>
                                    <MenuItem value={"SAME_SCREEN"}>
                                      {t("settings-same-screen-label")}
                                    </MenuItem>
                                  </TextInputMui>
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="STORE_LANGUAGE">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    select
                                    label={t("settings-shop-lang-label")}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  >
                                    <MenuItem value={"en"}>
                                      {t("english-lang")}
                                    </MenuItem>
                                    <MenuItem value={"gu"}>
                                      {t("gujarati-lang")}
                                    </MenuItem>
                                  </TextInputMui>
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="ORDER_TOKEN_RESET_TIME">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    type="text"
                                    helperText={"Eg values. 24:00, 03:00, 15:00 "}
                                    label={t(
                                      "settings-order-token-reset-time-label",
                                    )}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </GridItem>
                        </Row>
                      </div>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
            </Container>
          </form>
        )}
      />
    </>
  );
}
// label={t("settings-shop-lang-label")}
// STORE_LANGUAGE
import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Fab,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Send as SendIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  ExitToApp,
  QuestionAnswer,
  Settings,
  FullscreenExit,
  FullscreenOutlined,
  Print,
  NotificationImportantSharp,
} from "@material-ui/icons";
import classNames from "classnames";
import { Navbar, Nav, NavDropdown, Form, Row } from "react-bootstrap";
import { NavLink, Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import RouteLinks from "common/routes";
import { signOut } from "utility/helpers";
import useStyles from "./styles";
import { Badge, Typography } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import InfoIcon from "@material-ui/icons/Info";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { isDesktop } from "react-device-detect";
import {
  DeskTopContainer,
  MobileContainer,
  useInMobile,
} from "../../themes/Device";
import LockIcon from "@material-ui/icons/Lock";
import { Button } from "@mui/material";
import { useTranslation, initReactI18next } from "react-i18next";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  toggleOrderSectionView,
} from "context/LayoutContext";

import "assets/css/header.css";
import InfoModel from "components/InfoModel";
import servoFeatLogo from "assets/img/servofeatlogo-preview.png";
import { ServiceEnabled } from "utility/helpers";
import { useTheme } from "@material-ui/core/styles";
import NavbarHorizontal from "components/Layouts/dashboard/navbar/NavbarHorizontal";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { ACTION, RESOURCE } from "common/constant";
import HelpIcon from "@material-ui/icons/Help";
import ReactPlayer from "react-player";
import PopUpModel from "components/Popup/PopUpModel";
import Label from "components/Label";
import NotificationsPopover from "components/Layouts/dashboard/header/NotificationsPopover";
import ContactsPopover from "components/Layouts/dashboard/header/ContactsPopover";

const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

// const notifications = [
//   { id: 0, color: "warning", message: "Check out this awesome ticket" },
//   {
//     id: 1,
//     color: "success",
//     type: "info",
//     message: "What is the best way to get ...",
//   },
//   {
//     id: 2,
//     color: "secondary",
//     type: "notification",
//     message: "This is just a simple notification",
//   },
//   {
//     id: 3,
//     color: "primary",
//     type: "e-commerce",
//     message: "12 new orders has arrived today",
//   },
// ];

export default function Header(props) {
  var classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { structure } = RouteLinks();
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const theme = useTheme();
  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const orderHistoryDataList = useSelector(
    (state) => state?.api?.orderHistoryData,
  );
  const userInfo = useSelector((state) => state.user.userData);
  // var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [profileMenu, setProfileMenu] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [videomodalShow, setVideomodalShow] = React.useState(false);
  const [orderNotification, setorderNotification] = useState([]);
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  };

  // useEffec<t(>() => {
  //   SocketEndPoint.on("sendNotification", (payload) => {
  //     setorderNotification((pre) => [...pre, payload]);
  //   });
  // }, [SocketEndPoint]);

  const getScreenTitle = () => {
    const path = history.location.pathname;
    if (path === "/app/customer-queue") {
      return t("customer-queue-title");
    }
    return "";
  };

  return (
    <AppBar
      className={classes.appBar}
      style={{
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        padding: useInMobile() ? "4px" : "0px",
      }}
    >
      <PopUpModel
        size="lg"
        onHide={() => {
          setVideomodalShow(false);
        }}
        show={videomodalShow}
        heading={"Help Video"}
        body={
          <Row>
            <ReactPlayer
              controls
              style={{ paddingTop: "0px" }}
              width="800px"
              height="480px"
              onError={() => console.log("onError CallBack")}
              onPause={() => console.log("onPause CallBack")}
              onStart={() => console.log("onStart CallBack")}
              onReady={() => console.log("OnReady CallBack")}
              onEnded={() => console.log("onEnded CallBack")}
              url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
            />
          </Row>
        }
      />
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        {/* <Navbar.Brand className={`${classes.navBarLogo} mx-auto`}>
          <Link
            to={
              shopSettingListData.some(
                ({ key, value }) =>
                  key === "DINE_IN_SERVICE" && value === "true",
              )
                ? "/app/dine-in"
                : "/app/order/new"
            }
          >
            <img
              src={
                "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png" ||
                servoFeatLogo
              }
              style={{ width: "37px" }}
            />
          </Link>
        </Navbar.Brand> */}
        <DeskTopContainer>
          <NavbarHorizontal />
        </DeskTopContainer>
        <MobileContainer>
          <div className={classes?.screenTitle}>
            {layoutState?.currentScreenTitle || getScreenTitle()}
          </div>
        </MobileContainer>
        <Navbar variant="light" expand="md" sticky="top" className="smr-navbar">
          {/* <DeskTopContainer>
            <Nav
              className={`${classes.mobileNavBar}`}
              variant="pills"
              defaultActiveKey="app/dine-in"
            >
              {structure.map((route, routeIndex) => {
                return (
                  !route?.isSidebar &&
                  (route?.children && route?.children?.length > 0 ? (
                    <NavDropdown
                      title={
                        <>
                          {route?.icon}{" "}
                          <span className="ml-1">{route?.label}</span>
                        </>
                      }
                      id={`basic-nav-dropdown-${route?.id}`}
                    >
                      {route?.children.map((childRoute, index) => (
                        <NavDropdown.Item
                          as={NavLink}
                          key={`nav-item-${index}`}
                          to={childRoute?.link}
                        >
                          {childRoute?.icon}{" "}
                          <span className="ml-1">{childRoute?.label}</span>
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  ) : (
                    <ServiceEnabled
                      envVariableList={shopSettingListData}
                      serviceName={route?.serviceName}
                    >
                      <Nav.Item key={`nav-item-${routeIndex}`}>
                        <Nav.Link as={NavLink} to={route?.link}>
                          {route?.home ? (
                            <div style={{ padding: "4.8px 5px" }}>
                              {route?.icon}
                            </div>
                          ) : (
                            <span
                              style={{
                                marginTop: "0px",
                                fontSize: "15px",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                style={{ fontSize: "20px", marginRight: "2px" }}
                              >
                                {route?.icon}
                              </span>
                              <DeskTopContainer>
                                {route?.label}
                              </DeskTopContainer>
                            </span>
                          )}
                        </Nav.Link>
                      </Nav.Item>
                    </ServiceEnabled>
                  ))
                );
              })}
            </Nav>
          </DeskTopContainer> */}

          <div className="d-flex ml-auto align-items-center">
            <DeskTopContainer>
              {userInfo?.user?.is_trial && (
                <div className="pr-2">
                  <Label color="error">
                    {t("header-trail-day-label")}:{userInfo?.user?.trial_day}
                  </Label>
                </div>
              )}
              {Number(userInfo?.user?.number_of_order) !== -1 ? (
                <Label color="error">
                  {t("available-order")}: {userInfo?.user?.number_of_order}
                </Label>
              ) : null}

              {/* <Button
                variant="contained"
                onClick={() => props.history.push("/app/pricing")}
                startIcon={<LockIcon />}
                sx={{
                  backgroundColor: "#00AB55",
                  textTransform: "capitalize",
                  marginLeft: "8px",
                  fontSize: "10px",
                  padding: "4px 20px",
                }}
              >
                Upgrade Plan
              </Button> */}
              <div style={{marginLeft: "8px"}}>
                <Label color="success" variant='filled'>
                  { `Welcome ${userInfo?.user?.name}`}
                </Label>
              </div>
              <IconButton
                className={classes.iconButton}
                onClick={() => setModalShow(true)}
              >
                <InfoIcon classes={{ root: classes.phoneIcon }} />
              </IconButton>

              {/* <IconButton
                className={classes.iconButton}
                onClick={() => setVideomodalShow(true)}
              >
                <HelpIcon classes={{ root: classes.phoneIcon }} />
              </IconButton> */}

              {fullscreen ? (
                <IconButton
                  className={classes.iconButton}
                  onClick={toggleFullscreen}
                >
                  <FullscreenOutlined
                    classes={{ root: classes.fullScreenIcon }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  className={classes.iconButton}
                  onClick={toggleFullscreen}
                >
                  <FullscreenExit classes={{ root: classes.fullScreenIcon }} />
                </IconButton>
              )}
            </DeskTopContainer>
              <NotificationsPopover 
                orderHistoryDataList = {orderHistoryDataList?.data}/>
              {/* <ContactsPopover/> */}
            {/* <IconButton
              color="inherit"
              aria-haspopup="true"
              aria-controls="mail-menu"
              onClick={(e) => {
                setNotificationsMenu(e.currentTarget);
                // setIsNotificationsUnread(false);
              }}
              className={classes.headerMenuButton}
            >
              <Badge
                badgeContent={
                  orderNotification.length
                }
                color="primary"
              >
                <NotificationImportantSharp classes={{ root: classes.headerIcon }} />
              </Badge>
            </IconButton> */}
            <IconButton
              aria-haspopup="true"
              color="inherit"
              className={classes.headerMenuButton}
              aria-controls="profile-menu"
              onClick={(e) => setProfileMenu(e.currentTarget)}
            >
              <AccountIcon classes={{ root: classes.headerIcon }} />
            </IconButton>
            <InfoModel show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </Navbar>
        {/* <div className={classes.grow} /> */}
        {/* <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div> */}
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {t("header-new-message-label")}
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} {t("header-new-message-label")}
            </Typography>
          </div>
          {messages.map((message) => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>
        {orderNotification.length > 0 ? (
          <Menu
            id="notifications-menu"
            open={Boolean(notificationsMenu)}
            anchorEl={notificationsMenu}
            onClose={() => {
              setNotificationsMenu(null);
              setorderNotification([]);
            }}
            className={classes.headerMenu}
            disableAutoFocusItem
          >
            {orderNotification.map((notification) => (
              <MenuItem
                key={notification.id}
                onClick={() => {
                  setNotificationsMenu(null);
                  setorderNotification([]);
                }}
                className={classes.headerMenuItem}
              >
                <Notification {...notification} typographyVariant="inherit" />
              </MenuItem>
            ))}
          </Menu>
        ) : (
          <Menu
            id="notifications-menu"
            open={Boolean(notificationsMenu)}
            anchorEl={notificationsMenu}
            onClose={() => {
              setNotificationsMenu(null);
              setorderNotification([]);
            }}
            className={classes.headerMenu}
            disableAutoFocusItem
          >
            <MenuItem
              // key={notification.id}
              onClick={() => {
                setNotificationsMenu(null);
                setorderNotification([]);
              }}
              className={classes.headerMenuItem}
            >
              <Notification
                message="No any notification"
                typographyVariant="inherit"
              />
            </MenuItem>
          </Menu>
        )}
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          onClick={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <RoleBasedGuard resource={RESOURCE.PROFILE} action={ACTION.VIEW}>
            <NavLink to="/app/profile" style={{ width: "100%" }}>
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <AccountIcon />
                &nbsp;{t("header-profile-label")}
              </MenuItem>
            </NavLink>
          </RoleBasedGuard>
          {/* <RoleBasedGuard
            resource={RESOURCE.SETTING}
            action={ACTION.VIEW}
          >
            <NavLink to="/app/setting" style={{ width: "100%" }}>
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <Settings />
                &nbsp;Setting
              </MenuItem>
            </NavLink>
          </RoleBasedGuard> */}
          <RoleBasedGuard resource={RESOURCE.SUPPORT} action={ACTION.VIEW}>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
              onClick={() => setModalShow(true)}
            >
              <QuestionAnswer />
              &nbsp;{t("header-support-label")}
            </MenuItem>
          </RoleBasedGuard>
          <RoleBasedGuard resource={RESOURCE.ROLES} action={ACTION.VIEW}>
            <NavLink to="/app/roles" style={{ width: "100%" }}>
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <SupervisedUserCircleIcon />
                &nbsp;{t("header-roles-label")}
              </MenuItem>
            </NavLink>
          </RoleBasedGuard>
          {!isDesktop && (
            <NavLink to="/app/mobile-printer" style={{ width: "100%" }}>
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <Print />
                &nbsp;{t("header-printer-label")}
              </MenuItem>
            </NavLink>
          )}
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            onClick={() => signOut(props)}
          >
            <ExitToApp />
            &nbsp;{t("header-sign-out-label")}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

import { combineReducers } from "redux";
import ApiReducer from "./api.reducer";
import CustomerReducer from "./customer.reducer";
import UserReducers from "./user.reducer";
import shopsettingReducer from "./shopsetting.reducer";

const combineReducer = combineReducers({
  user: UserReducers,
  api: ApiReducer,
  customer: CustomerReducer,
  shopSettingReducer: shopsettingReducer
});

export default combineReducer;

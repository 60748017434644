export const SET_USER_INFO = "SET_USER_INFO";

export const SET_USER_PERMISSION = "SET_USER_PERMISSION";

// STAFF ACTION TYPES
export const GET_STAFF_LIST = "GET_STAFF_LIST";

// GET BIILING
export const GET_BILLING_INFO = "GET_BILLING_INFO";

//STORE THEME   
export const SET_STORE_THEME = "SET_STORE_THEME";

export const SET_SHOP_REFERESH_DATA = "SET_SHOP_REFERESH_DATA";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-hot-toast";
import { Button, FormControl, Checkbox, InputLabel } from "@material-ui/core";
import { Save as SaveIcon, ArrowBackIos } from "@material-ui/icons";
import { Form, Field } from "react-final-form";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import {
  createCustomer,
  getCustomerById,
  updateCustomer,
} from "services/customers.service";
import TextInputMui from "components/TextInputMui";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import { validName } from "utility/helpers";
import { checkSpaceAndRegex } from "utility/helpers";
import { useInMobile } from "themes/Device";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      borderRadius: "8px",
      padding: "1rem 0",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formcontrolwidth: {
    width: "100%",
    padding: "10px",
  },
  selectBoxControl: {
    width: "100%",
  },
  textField: {
    width: 200,
  },
  buttonPosition: {
    display: "flex",
    justifyContent: "end",
  },
  footerButton: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    position: "absolute",
    bottom: "0",
    left: "0",
    padding: "0 15px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      position: "relative",
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
    },
  },
  button: {
    background: "",
    color: "#fff",
    margin: 5,
    height: 30,
  },
});

const useStyles = makeStyles(styles);

const AddEditCustomer = (props) => {
  const classes = useStyles();
  const param = useParams();
  const { drawerClose, customerList, isOrderScreen } = props;
  const [customerId, setCustomerId] = useState(props?.customerId);
  const [initialCustomerData, setInitialCustomerData] = useState();
  const isNew = isOrderScreen
    ? true
    : customerId > 0 || param?.customerId > 0
    ? false
    : true;
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobileView = useInMobile();
  const history = useHistory();
  const layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (isMobileView) {
      let screenTitle = "";
      if (param.customerId > 0) {
        screenTitle = t("customer-edit-label");
        setCustomerId(param.customerId);
      } else {
        screenTitle = t("customer-add-label");
      }

      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: screenTitle,
      });
      return () => {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      };
    }
  }, []);

  useEffect(() => {
    if (isOrderScreen) {
      setCustomerId(0);
    }
  }, [isOrderScreen]);

  const redirectToMainPage = (data) => {
    if (isMobileView) {
      if (drawerClose) {
        drawerClose(data);
      }
      if (!isOrderScreen) history.location?.state?.backToMain();
    } else {
      customerList();
      drawerClose(data);
    }
  };

  const onSubmit = async (values) => {
    setSubmittingBtnProcess(true);
    if (isNew) {
      const result = await createCustomer(values);
      if (result.data?.status === 200) {
        setSubmittingBtnProcess(false);
        toast.success(result.data.message);
        redirectToMainPage(result.data?.data);
      } else if (result.status === 400) {
        setSubmittingBtnProcess(false);
        toast.error(result?.message);
      }
    } else {
      const result = await updateCustomer(customerId, values);
      if (result.status === 200) {
        setSubmittingBtnProcess(false);
        toast.success(result.data.message);
        redirectToMainPage();
      } else if (result.status === 400) {
        setSubmittingBtnProcess(false);
        toast.error(result?.message);
      }
    }
  };

  useEffect(() => {
    if (customerId > 0 && !isOrderScreen) {
      setIsLoading(true);
      getCustomerById(customerId)
        .then((res) => {
          setInitialCustomerData(res.data);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, [customerId]);

  return (
    <div className={classes.root}>
      {customerId > 0 && isLoading ? (
        <SkeletonLoader />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialCustomerData}
          validate={(values) => {
            const errors = {};
            const emailRegex =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            // const checkerror = checkSpaceAndRegex(values?.name);
            // if (checkerror != "") errors.name = checkerror;
            if (!values?.name?.trim()) {
              errors.name = t("customer-queue-name-validation-message");
            } else if (values?.name?.length > 50) {
              errors.name = t("maximumlength-validation-message");
            }

            if (!values?.mobile) {
              errors.mobile = t("phone-number-error-message");
            } else if (isNaN(values?.mobile)) {
              errors.mobile = t("phone-number-validation-message");
            } else if (values.mobile?.length !== 10) {
              errors.mobile = t("phone-number-digit-validation-message");
            }

            if (values.email) {
              if (values?.email?.length > 50) {
                errors.email = t("maximumlength-validation-message");
              } else if (!emailRegex.test(values?.email)) {
                errors.email = t("email-invalid-validation-message");
              }
            }

            /*if (!values?.address) {
              errors.address = "Address is required.";
            } else if (values?.address?.length > 50) {
              errors.address = "Maximum length should be less then 50.";
            }*/

            return errors;
          }}
          render={({ handleSubmit, form, submitting, values }) => (
            <form onSubmit={handleSubmit} className="mt-3">
              <Row className="m-0">
                <Col xs={12} sm={12} md={12}>
                  <Field name="name">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="text"
                          variant="outlined"
                          label={`${t("customer-name-label")}${"*"}`}
                          error={meta.error && meta.touched ? true : false}
                        />
                        {/* <TextField
                              {...input}
                              type="text"
                              variant="outlined"
                              label="Customer Name*"
                              error={
                                meta.error && meta.touched ? true : false
                              }
                            /> */}
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <Field name="mobile">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="number"
                          variant="outlined"
                          label={`${t("phone-label")}${"*"}`}
                          error={
                            (meta.touched || meta.visited) && meta.error
                              ? true
                              : false
                          }
                        />
                        {/* <TextField
                              {...input}
                              type="number"
                              variant="outlined"
                              label="Phone Number"
                              error={
                                meta.error && meta.touched ? true : false
                              }
                            /> */}
                        {(meta.touched || meta.visited) && meta.error && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>

                <Col xs={12} sm={12} md={12}>
                  <Field name="email">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="email"
                          name="email"
                          variant="outlined"
                          label={t("email")}
                          error={meta.error && meta.touched ? true : false}
                        />
                        {/* <TextField
                              {...input}
                              type="email"
                              name="email"
                              variant="outlined"
                              label="Email"
                              error={
                                meta.error && meta.touched ? true : false
                              }
                            /> */}
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <Field name="address">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="textarea"
                          variant="outlined"
                          label={t("address-label")}
                          multiline={true}
                          error={meta.error && meta.touched ? true : false}
                        />
                        {/* <TextField
                              {...input}
                              type="textarea"
                              variant="outlined"
                              label="Address"
                              multiline
                              error={
                                meta.error && meta.touched ? true : false
                              }
                            /> */}
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
              </Row>

              <Row className="m-0">
                {customerId > 0 && (
                  <Col xs={12} sm={12} md={12}>
                    <Field name="isBlocked" type="checkbox">
                      {({ input, meta }) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            color="primary"
                            {...input}
                            style={{ paddingLeft: "0px" }}
                          />
                          <InputLabel
                            htmlFor="isBlocked"
                            style={{ marginBottom: "0px" }}
                          >
                            {t("customer-email-sms-falg-label")}
                          </InputLabel>
                        </div>
                      )}
                    </Field>
                    <p>
                      <b>{t("note-label")}: </b>
                      {t("customer-note")}
                    </p>
                  </Col>
                )}
              </Row>

              <div className={classes.footerButton}>
                <Row className="m-0">
                  <Col xs={12} sm={12} md={12}>
                    <div className="d-flex">
                      <div className="mb-4">
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          type="submit"
                          disabled={submitting}
                          style={{
                            background: "",
                            color: "#fff",
                          }}
                          startIcon={<SaveIcon />}
                        >
                          {submittingBtnProcess ? t("processing") : t("save")}
                        </Button>
                      </div>
                      <div className="ml-2 mb-4">
                        <Button
                          variant="outlined"
                          color="secondary"
                          className="backButton"
                          type="button"
                          onClick={() => {
                            if (isOrderScreen && drawerClose) {
                              drawerClose();
                            } else if (isMobileView)
                              history.push("/app/customers");
                            else if (!isMobileView && drawerClose)
                              drawerClose();
                          }}
                          startIcon={<ArrowBackIos />}
                        >
                          {t("back")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          )}
        />
      )}
    </div>
  );
};

export default AddEditCustomer;

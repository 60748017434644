import { api } from "./../utility/interceptor";
import configData from "../config.js";
var config = {
  headers: { "Access-Control-Allow-Origin": "*" },
  // crossDomain: true,
  // Accept: 'application/json',
};

export const adminLogin = async (loginData) => {
  let res = await api.post(`/auth/login`, loginData, config).catch((error) => {
    return error;
  });
  return res;
};

export const adminTryNow = async (shopData) => {
  try {
    let res = await api.post(`/admin/shop/trynow`, shopData);
    return { status: res.status, data: res.data };
  } catch (err) {
    return { status: err.response.status, message: err.response.data.message };
  }
};

export const changePassword = async (data) => {
  let res = await api
    .post(`/auth/change-password`, data, config)
    .catch((error) => {
      return error;
    });
  return res;
};

export const forgotPassword = async (email) => {
  let res = await api
    .post(`/auth/forgot-password`, { email }, config)
    .catch((error) => {
      return error;
    });
  return res;
};

export const forgotPasswordVerify = async (url) => {
  let res = await api
    .post(`/auth/forgot-password-verify`, { url }, config)
    .catch((error) => {
      return error;
    });
  return res;
};

export const forgotPasswordChange = async (newChangeData) => {
  let res = await api
    .post(`/auth/forgot-password-change`, newChangeData, config)
    .catch((error) => {
      return error;
    });
  return res;
};

export const verifyTokenService = async (accesstoken) => {
  let res = await api
    .post(`/auth/verify-token`, {
      headers: { "Access-Control-Allow-Origin": "*" },
      Authorization: `Bearer ${accesstoken}`,
    })
    .catch((error) => {
      return error;
    });
  return res;
};

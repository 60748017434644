import { isJson } from "./helpers";

const checkPermission = (permissions, resource, action) => {
  if (permissions?.length <= 0 || !permissions) {
    return true;
  }
  const mathPermission = permissions.find((per) => per.resource === resource);
  if (mathPermission && isJson(mathPermission?.permission)) {
    if (JSON.parse(mathPermission?.permission)[action]) {
      return true;
    }
  }
  return false;
};

export default checkPermission;

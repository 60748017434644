import React from "react";

var LayoutStateContext = React.createContext();
var LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    case "TOGGLE_ORDER_SECTION_VIEW":
      return { ...state, isOrderSectionViewToggle: !state.isOrderSectionViewToggle };
    case "ADD_CURRENT_SCREEN_TITLE":
      return { ...state, currentScreenTitle: action?.payload?.currentScreenTitle};
    case "REMOVE_CURRENT_SCREEN_TITLE":
      return { ...state, currentScreenTitle: state.currentScreenTitle };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Layout Provider for class components 
function LayoutProvider({ children }) {
  var [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: false,
    isOrderSectionViewToggle: false,
    currentScreenTitle: ""
  });
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

// for functional components created a custom hooks
function useLayoutState() {
  var context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}

// for functional components created a custom hooks
function useLayoutDispatch() {
  var context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}

function toggleSidebar(dispatch) {
  dispatch({
    type: "TOGGLE_SIDEBAR",
  });
}

function addCurrentScreenTitle(dispatch, payload) {
  dispatch({
    type: "ADD_CURRENT_SCREEN_TITLE",
    payload: payload
  });
}

function toggleOrderSectionView(dispatch) {
  dispatch({
    type: "TOGGLE_ORDER_SECTION_VIEW",
  });
}

export {
  LayoutProvider, 
  useLayoutState, 
  useLayoutDispatch, 
  toggleSidebar, 
  toggleOrderSectionView,
  addCurrentScreenTitle };
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import { Container } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import KitchenOutlinedIcon from "@material-ui/icons/KitchenOutlined";
import DeckIcon from "@material-ui/icons/Deck";
import { useHistory } from "react-router-dom";
import AddShoppingCartSharpIcon from "@material-ui/icons/AddShoppingCartSharp";
import { ServiceEnabled } from "utility/helpers";
import { useSelector } from "react-redux";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#ffffff",
    paddingBottom: "6px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px -5px 19px rgba(80, 17, 0, 0.12)",
    borderRadius: "20px 20px 0px 0px",
    position: "absolute",
    bottom: 0,
    zIndex: 999,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  tabname: {
    fontSize: 12,
    fontWeight: "400",
    color: "#8e8e8e",
  },
  footerbtn: {
    textTransform: "capitalize",
    minWidth: "0px",
  },
  buttondiv: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  buttonicon: {
    color: "#8e8e8e",
    fontSize: "2rem"
  },
});

const isActiveButton = (buttonType) => {};

export default function MobileFooterComp() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const [isDinInService, setIsDinInService] = useState(false);

  useEffect(() => {
    if (shopSettingListData?.DINE_IN_SERVICE) {
      setIsDinInService(true);
    } else {
      setIsDinInService(false);
    }
  }, [shopSettingListData]);

  let history = useHistory();
  const [activeButton, setActiveButton] = useState({
    DINEIN: false,
    ORDER: false,
    HISTORY: false,
    DASHBOARD: false,
    KITCHEN: false,
  });

  return (
    <div>
      <Container className={classes.root}>
        <RoleBasedGuard resource={RESOURCE.DASHBOARD} action={ACTION.VIEW}>
          <Button
            className={classes.footerbtn}
            onClick={() => {
              setActiveButton({ DASHBOARD: true });
              history.push("/app/dashboard");
            }}
          >
            <div className={classes.buttondiv}>
              <HomeIcon
                className={classes.buttonicon}
                style={{
                  color: activeButton?.DASHBOARD
                    ? theme.palette.primary.main
                    : "",
                }}
              />
              <Typography
                className={classes.tabname}
                style={{
                  color: activeButton?.DASHBOARD
                    ? theme.palette.primary.main
                    : "",
                }}
              >
                {t("home-button-label")}
              </Typography>
            </div>
          </Button>
        </RoleBasedGuard>
        <RoleBasedGuard resource={RESOURCE.DINE_IN_TABLE} action={ACTION.VIEW}>
          <ServiceEnabled
            envVariableList={shopSettingListData}
            serviceName={"DINE_IN_SERVICE"}
          >
            <Button
              className={classes.footerbtn}
              onClick={() => {
                setActiveButton({ DINEIN: true });
                history.push("/app/dine-in");
              }}
            >
              <div className={classes.buttondiv}>
                <DeckIcon
                  className={classes.buttonicon}
                  style={{
                    color: activeButton?.DINEIN
                      ? theme.palette.primary.main
                      : "",
                  }}
                />
                <Typography
                  className={classes.tabname}
                  style={{
                    color: activeButton?.DINEIN
                      ? theme.palette.primary.main
                      : "",
                  }}
                >
                  {t("table-title")}
                </Typography>
              </div>
            </Button>
          </ServiceEnabled>
        </RoleBasedGuard>

        {isDinInService === false ? (
          <RoleBasedGuard resource={RESOURCE.PARCEL_ORDER} action={ACTION.ADD}>
            <Button
              size="small"
              style={{
                position: "relative",
                minWidth: "41px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                setActiveButton({ ORDER: true });
                history.push("/app/order/new");
              }}
            >
              <div className={classes.buttondiv}>
                <AddShoppingCartSharpIcon
                  style={{
                    color: activeButton?.ORDER
                      ? theme.palette.primary.main
                      : "",
                  }}
                />
                <Typography
                  className={classes.tabname}
                  style={{
                    color: activeButton?.ORDER
                      ? theme.palette.primary.main
                      : "",
                  }}
                >
                  {t("new-order-route-title")}
                </Typography>
              </div>
            </Button>
          </RoleBasedGuard>
        ) : (
          <RoleBasedGuard resource={RESOURCE.PARCEL_ORDER} action={ACTION.ADD}>
            <Button
              size="small"
              style={{
                position: "relative",
                minWidth: "41px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                setActiveButton({ ORDER: true });
                history.push("/app/order/new");
              }}
            >
              <AddShoppingCartSharpIcon
                style={{
                  width: "45px",
                  height: "45px",
                  fontSize: "2rem",
                  minWidth: "none !important",
                  color: activeButton?.ORDER ? theme.palette.primary.main : "",
                }}
              />
            </Button>
          </RoleBasedGuard>
        )}
        <RoleBasedGuard resource={RESOURCE.ORDER_HISTORY} action={ACTION.VIEW}>
          <Button
            className={classes.footerbtn}
            onClick={() => {
              setActiveButton({ HISTORY: true });
              history.push("/app/history/order");
            }}
          >
            <div className={classes.buttondiv}>
              <AccessTimeIcon
                className={classes.buttonicon}
                style={{
                  color: activeButton?.HISTORY
                    ? theme.palette.primary.main
                    : "",
                }}
              />
              <Typography
                className={classes.tabname}
                style={{
                  color: activeButton?.HISTORY
                    ? theme.palette.primary.main
                    : "",
                }}
              >
                {t("history-title")}
              </Typography>
            </div>
          </Button>
        </RoleBasedGuard>
        <RoleBasedGuard resource={RESOURCE.KITCHEN_QUEUE} action={ACTION.VIEW}>
          <Button
            className={classes.footerbtn}
            onClick={() => {
              setActiveButton({ KITCHEN: true });
              history.push("/app/kitchen");
            }}
          >
            <div className={classes.buttondiv}>
              <KitchenOutlinedIcon
                className={classes.buttonicon}
                style={{
                  color: activeButton?.KITCHEN
                    ? theme.palette.primary.main
                    : "",
                }}
              />
              <Typography
                className={classes.tabname}
                style={{
                  color: activeButton?.KITCHEN
                    ? theme.palette.primary.main
                    : "",
                }}
              >
                {t("kitchen-screen-title")}
              </Typography>
            </div>
          </Button>
        </RoleBasedGuard>
      </Container>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";

import { withRouter } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  Visibility as HidePassIcon,
  VisibilityOff as ShowPassIcon,
} from "@material-ui/icons";

import {
  forgotPasswordVerify,
  forgotPasswordChange,
} from "services/login.service";
import useStyles from "./styles";
import "assets/css/style.css";
import "assets/css/theme.css";
import TextInputMui from "components/TextInputMui";
import forgetImg from "../../assets/img/forgot-password.png";
import PageLoader from "components/Loader/PageLoader";

const ResetPassword = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPwdURLVerify, setIsForgotPwdURlVerify] = useState(false);
  const [urlVerifying, setUrlVerifying] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const forgotPasswordUrl = props?.match?.params?.id;

  useEffect(() => {
    setUrlVerifying(true);
    if (forgotPasswordUrl) {
      forgotPasswordVerify(forgotPasswordUrl)
        .then((res) => {
          setUrlVerifying(false);
          if (res?.status === 200 || res?.data) {
            if (res.data?.data?.success === 1) {
              setIsForgotPwdURlVerify(true);
            } else {
              setIsForgotPwdURlVerify(false);
            }
          }
        })
        .catch((err) => {
          setIsForgotPwdURlVerify(false);
        });
    }
  }, [forgotPasswordUrl]);

  const onSubmit = (values) => {
    setIsLoading(true);
    forgotPasswordChange({ url: forgotPasswordUrl, password: values.password })
      .then((res) => {
        if (res?.status === 200 || res?.data) {
          setIsLoading(false);
          toast.info(res?.data.message);
        } else {
          setIsLoading(false);
          toast.error(res?.message);
        }
        if (
          res?.data?.message
            .toString()
            .includes("Password changed successfully.")
        ) {
          setTimeout(() => {
            props.history.replace({
              pathname: "/login",
              search: "?new-pass=true",
            });
          }, 1000);
        } else {
          setTimeout(() => {
            props.history.replace("/login");
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("something went wrong. please try after some time");
        setIsLoading(false);
        setTimeout(() => {
          props.history.replace("/login");
        }, 3000);
      });
  };

  return (
    <Box className={classes.container}>
      {!urlVerifying ? (
        <Grid container className={classes.gridContainer} spacing={1}>
          <Grid item xs={12} md={4}>
            <Box className={classes.leftWrapper}>
              <img src={forgetImg} alt="login" className={classes.loginImg} />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Container maxWidth="xs" className={classes.loginContainer}>
              {isForgotPwdURLVerify ? (
                <>
                  <Box sx={{ my: 4 }}>
                    <Typography
                      className={classes.heading}
                      style={{ padding: 0 }}
                      variant="h4"
                    >
                      Set New Password
                    </Typography>
                  </Box>
                  <Box>
                    <Form
                      onSubmit={onSubmit}
                      validate={(values) => {
                        const errors = {};
                        if (!values.password) {
                          errors.password = "Password is required.";
                        }
                        if (!values.confirm_password) {
                          errors.confirm_password =
                            "Confirm password is required.";
                        }
                        if (values.password && values.confirm_password) {
                          if (values.password !== values.confirm_password) {
                            errors.confirm_password =
                              "Password and confirm password does not match.";
                          }
                        }
                        return errors;
                      }}
                      render={({ handleSubmit, form, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                          <Box style={{ marginTop: 7 }}>
                            <Field name="password">
                              {({ input, meta }) => (
                                <TextInputMui
                                  {...input}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() =>
                                            setShowPassword(!showPassword)
                                          }
                                          edge="end"
                                        >
                                          {showPassword ? (
                                            <ShowPassIcon />
                                          ) : (
                                            <HidePassIcon />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    classes: {
                                      input: classes.textField,
                                    },
                                  }}
                                  margin="normal"
                                  label={"Password"}
                                  type={showPassword ? "text" : "password"}
                                  autoComplete="new-password"
                                  variant="outlined"
                                  fullWidth
                                  error={
                                    meta.touched && meta.error ? true : false
                                  }
                                  helperText={
                                    meta.touched &&
                                    meta.error &&
                                    `${meta.error}`
                                  }
                                  FormHelperTextProps={{
                                    style: { margin: "5px" },
                                  }}
                                />
                              )}
                            </Field>
                          </Box>
                          <Box style={{ marginTop: 7 }}>
                            <Field name="confirm_password">
                              {({ input, meta }) => (
                                <TextInputMui
                                  {...input}
                                  InputProps={{
                                    classes: {
                                      input: classes.textField,
                                    },
                                  }}
                                  margin="normal"
                                  label={"Confirm Password"}
                                  type={showPassword ? "text" : "password"}
                                  autoComplete="new-password"
                                  variant="outlined"
                                  fullWidth
                                  error={
                                    meta.touched && meta.error ? true : false
                                  }
                                  helperText={
                                    meta.touched &&
                                    meta.error &&
                                    `${meta.error}`
                                  }
                                  FormHelperTextProps={{
                                    style: { margin: "5px" },
                                  }}
                                />
                              )}
                            </Field>
                          </Box>

                          <Box>
                            {isLoading ? (
                              <div style={{ textAlign: "center" }}>
                                <CircularProgress size={26} />
                              </div>
                            ) : (
                              <Button
                                className={classes.muiBtn}
                                type="submit"
                                fullWidth
                                style={{
                                  backgroundColor: "",
                                  margin: "20px auto 10px auto",
                                }}
                              >
                                Change Password
                              </Button>
                            )}
                          </Box>
                        </form>
                      )}
                    />
                  </Box>
                </>
              ) : (
                <Box
                  style={{
                    margin: "40px 0",
                  }}
                >
                  <Typography
                    className={classes.heading}
                    style={{ padding: 0, textAlign: "center" }}
                    variant="h4"
                  >
                    Password reset link is invalid or expired
                  </Typography>
                  <Button
                    className={clsx(classes.outlineBtn, "mt-3 d-block")}
                    onClick={() => props.history.replace("/login")}
                    fullWidth
                    focusRipple
                  >
                    Sign in
                  </Button>
                </Box>
              )}
            </Container>
          </Grid>
        </Grid>
      ) : (
        <PageLoader />
      )}
    </Box>
  );
};

export default withRouter(ResetPassword);

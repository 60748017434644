import React from "react";

import "./mobilestaffmembers.styles.css";
import { Card, Container, Row, Col, Button } from "react-bootstrap";

import call from "../../assets/img/call.png";
import MenuButtonEditDelete from "components/MenuButtonEditDelete";
import { useTranslation } from "react-i18next";

function MobileStaffMembersCard(props) {
  const {
    deleteData,
    editData,
    itemId,
    name,
    email,
    phoneNo,
    joiningDate,
    BirthDate,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Container fluid className="p-0">
        <Card className="card1">
          <Row>
            <Col style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <h6 className="tale">{name}</h6>
                </div>
              </div>

              <div
                className="callp"
                style={{ alignItems: "center !important" }}
              >
                <img src={call} className="call" />
                <div>
                  <p style={{ marginBottom: "10px" }}>{phoneNo}</p>
                </div>
              </div>
            </Col>
          </Row>
          {(joiningDate !== 'Invalid date' || BirthDate !== 'Invalid date') && (
            <Row>
              <Col>
                <div className="row2">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="para3">
                      {joiningDate !== 'Invalid date' && (
                        <>
                          {t("staff-joining-date-label")}:-<span>{joiningDate}</span>
                        </>
                      )}
                    </p>
                    <p className="para3">
                      {BirthDate !== 'Invalid date' && (
                        <>
                          {t("date-of-birth-label")}:-<span>{BirthDate}</span>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row className="align-items-center">
            <Col>
              <p style={{ fontWeight: "600", margin: "0px" }}>{email}</p>
            </Col>
            <Col>
              <MenuButtonEditDelete
                deleteClick={deleteData}
                editClick={editData}
                itemId={itemId}
                isMenuBar={true}
              />
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
}
export default MobileStaffMembersCard;

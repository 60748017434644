export const setCustomerFoodMenuList = (dispatch) => ({
  type: "GET_FOOD_MENU",
  payload: dispatch,
});

export const SelectItems = (dispatch) => ({
  type: "GET_SELECT_ITEM",
  payload: dispatch,
});

export const finelBil = (dispatch) => ({
  type: "GET_FINEL_BILL",
  payload: dispatch,
});

export const checkOutPageStatus = (dispatch) => ({
  type: "GET_CHECKOUT",
  payload: dispatch,
});



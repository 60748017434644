import { SET_STORE_THEME, SET_SHOP_REFERESH_DATA } from "../actions/action.types";
const initialState = {
    shopSettingData: {},
    shopSettingLoading: true,
    shopThemeSettingData: [],
    shopSettingOnRefereshData: {},
    shopSettingOnRefereshDataLoading: true
}

export const shopsettingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case "SET_SHOP_SETTING":
            return {
                ...state,
                shopSettingData: payload,
                shopSettingLoading: false,
            }
        case SET_STORE_THEME:
            return {
                ...state,
                shopThemeSettingData: payload,
                shopSettingLoading: false,
            }
        case SET_SHOP_REFERESH_DATA:
            return {
                ...state,
                shopSettingOnRefereshData: payload,
                shopSettingOnRefereshDataLoading: false,
            }
        default:
            return state
    }
}

export default shopsettingReducer;
import React from "react";

import "./mobileinventorycard.styles.css";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import call from "../../assets/img/call.png";
import dots from "../../assets/img/dots.png";
import MenuButtonWithIconMui from "components/MenuButtonWithIconMui";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuButtonEditDelete from "components/MenuButtonEditDelete";
import { useTranslation } from "react-i18next";

function Mobileinventorycard(props) {
  const { type, data, deleteData, editData, itemId } = props;
  const { t } = useTranslation();
  return (
    <>
      <Container fluid className="p-0">
        <Card className="card1">
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <div>
                <h6 className="tale">{data?.name}</h6>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="para">{data?.date}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row2">
                <h5
                  className="rupee"
                  style={{ marginTop: "11px", marginBottom: "5px" }}
                >
                  {data?.category?.name}
                </h5>
                {data?.unit && data?.unit_type && (
                  <div>
                    <p className="para" style={{ marginBottom: "15px" }}>
                      {t("expense-management-quantity")}:
                      <span>
                        {" "}
                        {data?.unit} {data?.unit_type}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <MenuButtonEditDelete
                deleteClick={deleteData}
                editClick={editData}
                itemId={itemId}
                isMenuBar={true}
              />
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
}
export default Mobileinventorycard;

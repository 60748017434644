const checkAddonsMatch = (addonsList, selectedAddonsList) => {
  if (addonsList?.length !== selectedAddonsList?.length) {
    return false;
  }
  //make addOns Id List
  const selectedAddonsIds = selectedAddonsList?.map((addOn) => addOn?.id);

  if ( addonsList && addonsList?.length > 0 && addonsList?.some((addon) => !selectedAddonsIds.includes(addon?.id))) {
    return false;
  }

  return true;
};

export default checkAddonsMatch;

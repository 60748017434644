import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-hot-toast";
import ReactToPrint from "react-to-print";
import {
  Save as SaveIcon,
  Print,
  GetApp,
  ArrowBackIos,
} from "@material-ui/icons";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import { Button, Checkbox, FormControl, InputLabel } from "@material-ui/core";
import { useParams, useHistory, useLocation } from "react-router-dom";

import {
  CreateDiningTable,
  getDiningTableById,
  updateDiningTable,
} from "services/diningTable.service";
import { useInMobile } from "themes/Device";
import { fetchDiningTable } from "state/thunk-async";
import DineInQrPrint from "./DineInQrPrint";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import TextInputMui from "components/TextInputMui";
import { Col, Container, Row } from "react-bootstrap";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";
import { isInteger } from "lodash";
const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formcontrolwidth: {
    width: "100%",
  },
  root: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      borderRadius: "8px",
      padding: "1rem 0",
    },
  },
  main: {
    width: "60%",
  },
  rowHeight: {
    height: "100%",
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    minWidth: "0",
    background: " #FFF",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
    borderRadius: "6px",
  },
  textfield: {
    margin: "2%",
    display: "flex",
    justifyContent: "center",
  },

  centerProperty: {
    display: "flex",
    justifyContent: "center",
  },

  centerButton: {
    display: "flex",
    alignItems: "center",
  },
  buttonPosition: {
    display: "flex",
    justifyContent: "end",
  },
});

const useStyles = makeStyles(styles);

const AddEditDiningTable = (props) => {
  //hooks
  const classes = useStyles();
  const param = useParams();
  const isMobileView = useInMobile();
  const history = useHistory();
  const location = useLocation();

  let { diningTableId = 0, drawerClose } = props;

  // state
  const [initialDiningTableData, setInitialDiningTableData] = useState();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const dispatch = useDispatch();
  let refQRCODE = useRef(null);
  const layoutDispatch = useLayoutDispatch();
  const isNew = diningTableId > 0 ? false : true;
  const { t } = useTranslation();

  useEffect(() => {
    if (param.id > 0) {
      diningTableId = param.id;
    }
  }, [props]);

  const onSubmit = async (values) => {
    setSubmittingBtnProcess(true);
    if (values.is_available === undefined) {
      values.is_available = false;
    }
    if (isNew) {
      const result = await CreateDiningTable(values);
      dispatch(fetchDiningTable());
      console.log(result);
      if (result.status === 200 || result?.data?.status === 200) {
        setSubmittingBtnProcess(false);
        toast.success(result.data.message);
      } else if (result.status === 400) {
        setSubmittingBtnProcess(false);
        toast.error(result?.message);
      }
      if (!isMobileView && drawerClose) drawerClose();
      else history.push("/app/dining-table");
    } else {
      const result = await updateDiningTable(diningTableId, values);
      dispatch(fetchDiningTable());
      if (result.status === 200 || result?.data?.status === 200) {
        setSubmittingBtnProcess(false);
        toast.success(result.data.message);
      } else if (result.status === 400) {
        setSubmittingBtnProcess(false);
        toast.error(result?.message);
      }
      if (!isMobileView && drawerClose) drawerClose();
      else history.push("/app/dining-table");
    }
  };

  useEffect(() => {
    let screenTitle = "";
    if (diningTableId > 0) {
      getDiningTableById(diningTableId).then((res) => {
        setInitialDiningTableData(res.data);
      });
      screenTitle = t("dining-table-edit");
    } else {
      screenTitle = t("dining-table-add");
    }
    if (isMobileView) {
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: screenTitle,
      });
      return () => {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      };
    }
  }, []);

  const downloadQR = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <div className={classes.root}>
      {initialDiningTableData || isNew ? (
        <>
          <Form
            onSubmit={onSubmit}
            initialValues={initialDiningTableData}
            validate={(values) => {
              const errors = {};
              console.log(values?.capacity);
              console.log(parseInt(values?.capacity))
              
              if (!values?.name_of_table?.trim()) {
                errors.name_of_table = t("name-validation-message");
              } else if (values?.name_of_table?.length > 10) {
                errors.name_of_table = t("maximumlength-validation-message-10");
              }
              if (!values.capacity) {
                errors.capacity = t("dining-table-capacity-validation-message");
              } else if (isNaN(values.capacity)) {
                errors.capacity = t(
                  "dining-table-capacity-number-validation-message",
                );
              } else if (values?.capacity > 50) {
                errors.capacity = t(
                  "dining-table-capacity-maximumlength-validation-message",
                );
              } else if (values?.capacity < 0) {
                errors.capacity = t(
                  "dining-table-capacity-minimumlength-validation-message",
                );
              } else if (!Number.isInteger(Number(values.capacity)))
              {
                errors.capacity = "capacity should be 1,2,3,4,5.."
              }

              if (values?.description && values?.description?.length > 50) {
                errors.description = t("maximumlength-validation-message");
              }
              return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form
                onSubmit={(event) => {
                  handleSubmit(event)?.then(() => {
                    form.reset();
                    form.restart();
                  });
                }}
                className="mt-2"
              >
                {!isNew && (
                  <>
                    <Row className="m-0">
                      <Col xs={12} sm={12} md={12} ref={refQRCODE}>
                        <Row className="m-0">
                          <Col className={classes.centerProperty}>
                            <DineInQrPrint
                              ref={refQRCODE}
                              orderUrl={`${window.location.origin}/online-order/dine-in/${initialDiningTableData?.table_encypt_id}`}
                            />
                          </Col>
                          <Col className={classes.centerButton}>
                            <Button
                              variant="contained"
                              color="primary"
                              className="mr-3"
                              style={{
                                background: "",
                                color: "#fff",
                              }}
                              onClick={downloadQR}
                            >
                              <GetApp />
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    background: "",
                                    color: "#fff",
                                  }}
                                >
                                  <Print />
                                </Button>
                              )}
                              content={() => refQRCODE.current}
                            />
                          </Col>
                        </Row>
                        <Row className="m-0">
                          <Col>
                            <a
                              href={`${window.location.origin}/online-order/dine-in/${initialDiningTableData?.table_encypt_id}`}
                              target="_blank"
                              rel="noreferrer"
                              className="mb-2"
                            >
                              {`${window.location.origin}/online-order/dine-in/${initialDiningTableData?.table_encypt_id}`}
                            </a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="m-0">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.buttonPosition}
                  ></Col>
                </Row>
                <Row className="m-0">
                  <Col xs={12} sm={12} md={12}>
                    <Field name="name_of_table">
                      {({ input, meta }) => (
                        <FormControl className={classes.textfield}>
                          <TextInputMui
                            {...input}
                            type="text"
                            variant="outlined"
                            label={t("dining-table-name-label")}
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>

                  <br></br>

                  <Col xs={12} sm={12} md={12}>
                    <Field name="capacity">
                      {({ input, meta }) => (
                        <FormControl className={classes.textfield}>
                          <TextInputMui
                            {...input}
                            type="number"
                            variant="outlined"
                            label={t("dining-table-capacity-label")}
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>

                  <br></br>

                  <Col xs={12} sm={12} md={12}>
                    <Field name="description">
                      {({ input, meta }) => (
                        <FormControl className={classes.textfield}>
                          <TextInputMui
                            {...input}
                            type="textarea"
                            label={t("description-label")}
                            variant="outlined"
                            multiline={true}
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    {!isNew && (
                      <>
                        <Field name="is_active" type="checkbox">
                          {({ input, meta }) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Checkbox
                                color="primary"
                                {...input}
                                style={{ paddingLeft: "0px" }}
                              />
                              <InputLabel
                                htmlFor="is_active"
                                style={{ marginBottom: "0px" }}
                              >
                                {t("dining-table-is-active-label")}
                              </InputLabel>
                            </div>
                          )}
                        </Field>
                        <p>
                          <b>{t("note-label")}: </b>
                          {t("dining-table-note")}
                        </p>
                      </>
                    )}
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <div className="d-flex">
                      <div className="ml-2">
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          type="submit"
                          disabled={submitting}
                          startIcon={<SaveIcon />}
                        >
                          {submittingBtnProcess ? t("processing") : t("save")}
                        </Button>
                      </div>
                      <div className="ml-2">
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                          type="button"
                          onClick={() => {
                            if (isMobileView) history.push("/app/dining-table");
                            else if (!isMobileView && drawerClose)
                              drawerClose();
                          }}
                          startIcon={<ArrowBackIos />}
                        >
                          {t("back")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            )}
          />
        </>
      ) : (
        <SkeletonLoader count={3} height={50} />
      )}
    </div>
  );
};

export default AddEditDiningTable;

import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  iconButtonRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "9px",
    fontSize: "12px",
    height: "54px",
    width: "55px",
    backgroundColor: "white",
  },
  icon: {
    "& > svg": {
      fontSize: "20px",
    },
  },
}));

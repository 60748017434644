import { api } from "./../utility/interceptor";
import configData from "../config.js";

var config = {
  headers: { "Access-Control-Allow-Origin": "*" },
};

export const getAllCustomer = async () => {
  let res = await api.get(`/customer`, config);
  let data = res.data;
  return data;
};

export const createCustomer = async (customerData) => {
  try {
    let res = await api.post(`/customer`, customerData);
    return { status: res.status, data: res.data };
  } catch (err) {
    return { status: err.response.status, message: err.response.data.message };
  }
};

export const getCustomerById = async (id) => {
  let res = await api.get(`/customer/${id}`, config);
  let data = res.data;
  return data;
};


export const updateCustomer = async (id, customerData) => {
  try {
    let res = await api.put(`/customer/${id}`, customerData);
    return { status: res.status, data: res.data };
  } catch (err) {
    return { status: err.response.status, message: err.response.data.message };
  }
};

export const deleteCustomer = async (id) => {
  let res = await api.delete(`/customer/${id}`, config);
  let data = res.data;
  return data;
};



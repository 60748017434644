import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import whatsapp from "../../assets/img/whatsapp.png";
import gmail from "../../assets/img/gmail.png";
import "./InfoModel.styles.css";
import { useTranslation } from "react-i18next";

const style = {
  wpDesign: {
    border: "1px solid #39b54a",
    borderTopLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    display: "block",
    color: "#39b54a",
  },
  gmDesign: {
    border: "1px solid #de1f1f",
    borderTopLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    display: "block",
    color: "#de1f1f",
    textTransform: "none !important",
  },
  centerProperty: {
    display: "flex",
    justifyContent: "center",
  },
  underLine: {
    borderBottom: "2px solid  ",
  },
  closeButtonDesign: {
    border: "1px solid ",
    color: "",
  },
};
const useStyles = makeStyles(style);
const InfoModel = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className={classes.underLine}
          >
            {t("about-order")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={classes.centerProperty}>
              <h7>
                {t("website-label")} :{" "}
                <a target="_blank" href="https://www.servofeat.com">
                  https://www.servofeat.com
                </a>
              </h7>
            </Col>
          </Row>
          <Row>
            <Col className={classes.centerProperty}>
              <h7>
                {t("call-us-label")} :{" "}
                <span style={{ color: "" }}>+91 9773426554</span>
              </h7>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className={classes.centerProperty}>
              <a href="mailto: info@servofeat.com" target="_blank">
                <Button
                  variant="outlined"
                  startIcon={<img src={gmail} style={{ width: "20px" }} />}
                  className={classes.gmDesign}
                >
                  info@servofeat.com
                </Button>
              </a>
            </Col>
            <Col className={classes.centerProperty}>
              <a href="https://wa.me/919773426554" target="_blank">
                <Button
                  variant="outlined"
                  startIcon={<img src={whatsapp} style={{ width: "20px" }} />}
                  className={classes.wpDesign}
                >
                  +91 9773426554
                </Button>
              </a>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            className={classes.closeButtonDesign}
            onClick={props.onHide}
          >
            {t("close-button-label")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InfoModel;

import React, { useEffect, useState } from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import { NotificationImportant } from "@material-ui/icons";
import addNotification from "react-push-notification";
import { Stack } from "@mui/material";

// components
import OnlineLayoutHeader from "../Header/OnlineLayoutHeader";
import OnlineLayoutFooter from "../onlineLayoutFooter/OnlineLayoutFooter";
// context
import "@fortawesome/fontawesome-svg-core/styles.css";
import useStyles from "./styles";
import moment from "moment";
import MobileFooterOnline from "components/MobileFooterComp/MobileFooterOnline";
import { MobileContainer } from "themes/Device";
import { useInMobile } from "themes/Device";
import { toast } from "react-hot-toast";
import NotificationOne from "components/Notification/NotificationOne";
import { onlineOrderAccepted } from "services/order.service";
import { onlineOrderDecline } from "services/order.service";
import { NotificationItem } from "components/Layouts/dashboard/header/NotificationsPopover";
// import smartlookClient from 'smartlook-client'

import OnlineQR from "pages/OnlineOrder/OnlineQR";

function OnlineLayout(props) {
  const { userData } = props;
  var classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <div className={classes.root}>
      <NotificationOne open={open} setOpen={setOpen} />
      <OnlineLayoutHeader
        userData={userData}
        history={props?.history}
        className={classes.header}
      />
      <div className={classnames(classes.onlineContent)}>
        <OnlineQR userData={userData} />
        <div className={classnames(classes.onlineContentFooter)}>
          <OnlineLayoutFooter userData={userData} history={props?.history} />
        </div>
      </div>
    </div>
  );
}

export default withRouter(OnlineLayout);

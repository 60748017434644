import React from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Help,
  Remove as MinusIcon,
} from "@material-ui/icons";
import "./onlineorder.scss";
import { useState } from "react";
import { Table, Card } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import EmptyDataImage from "assets/img/empty_data_vector.svg";
import CardBody from "components/Card/CardBody";
import MuiIconButton from "components/IconButtonComponent";
import TextInputMui from "components/TextInputMui";
import CustomHtmlToolTip from "components/Tooltip/index.js";
import { useLayoutState } from "context/LayoutContext";
import ThankYou from "../../assets/img/thank-you.png";
import Label from "components/Label";
import { useTranslation } from "react-i18next";
import { getShopSettingFromLocalStorage } from "utility/helpers";
import { useDispatch } from "react-redux";
import { checkOutPageStatus } from "state/actions/customer.types";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  addItemBox: {
    background: "#f4f4f4",
    borderColor: "#f4f4f4",
    width: "100%",
    borderRadius: 10,
    padding: "5px 5px",
    "&:hover": {
      color: "#212529",
      backgroundColor: "#e2e6ea",
      borderColor: "#dae0e5",
    },
    textAlign: "center",
  },
  addBtn: {
    fontSize: 12,
    cursor: "pointer",
    "&:hover": {
      fontWeight: 800,
    },
  },
  qtyBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  qtyMinusIconButton: {
    padding: "4px",
    width: "35%",
    color: "rgb(122, 12, 46)",
    backgroundColor: "rgb(255, 231, 217)",
    borderRadius: "6px",
    height: "25px",
    "&:hover": {
      background: "#6a3434e3",
      color: "#fff",
    },
  },
  formcontrolwidth: {
    width: '100%'
  },
  qtyPlusIconButton: {
    padding: "4px",
    width: "35%",
    color: "rgb(0, 82, 73)",
    backgroundColor: "rgb(200, 250, 205)",
    borderRadius: "6px",
    height: "25px",
    "&:hover": {
      background: "#399c39",
      color: "#fff",
    },
  },
  noCategoy: {
    height: "100%",
    backgroundColor: "#e8deec",
    flexWrap: "wrap",
    alignContent: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "0.75rem",
    [theme.breakpoints.down("sm")]: {
      height: "40%",
      backgroundColor: "#e8deec",
      flexWrap: "wrap",
      alignContent: "center",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      borderRadius: "0.75rem",
      justifyContent: "center",
    },
  },
  customFoodMenuStyle4: {
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 25% !important",
      maxWidth: "25%!important",
    },
  },
  customFoodMenuStyle5: {
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 20% !important",
      maxWidth: "20%!important",
    },
  },
  calculateSubmitBtn: {
    "@media (max-width: 604px)": {
      marginTop:20
    },
  }
}));


export default function OnlineCheckout({
  shopSettingListData,
  selectedFoodItemListData,
  setCheckoutPageToggle,
  deleteFoodItem,
  isPageLoaderFirst = false,
  payNow,
  selectedFoodItemData,
  plusItemQty,
  minusItemQty,
  subTotal,
  finalBill,
  discountValue,
  discountSignValue,
  taxValue,
  submitOrder,
  thankYouPage,
  checkoutFormValue,
}) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory();
  var layoutState = useLayoutState();
  const [currentFormValue, setCurrentFormValue] = useState(checkoutFormValue); // initial form value
  const { t } = useTranslation();
  const removeFoodItem = (item, index) => {
    deleteFoodItem(item, index);
  };

  return (
    <>
      {thankYouPage ? (
        <Card>
          <Card.Body>
            <div>
              <img src={ThankYou} alt="" height={50} width={50} />
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div className="row">
          <div className="col-sm-4">
            <Card>
              <Card.Body className="billing-details-section">
                <Table responsive="sm" className="order-bill-table">
                  <thead>
                    <tr>
                      <th>{t("item-label")}</th>
                      <th>{t("qty-label")}</th>
                      <th>{t("order-history-total-label")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isPageLoaderFirst && selectedFoodItemListData.length > 0 ? (
                      selectedFoodItemListData?.map((item, index) => {
                        let variantName = "";
                        if (item.varient_id > 0) {
                          const is_default_variant =
                            item?.fooditem?.food_variants.filter(
                              (f) => f.id === item?.varient_id,
                            );
                          if (
                            is_default_variant &&
                            is_default_variant.length > 0
                          ) {
                            variantName = is_default_variant[0].name;
                          } else {
                            variantName = item?.fooditem?.food_variants?.[0].name;
                          }
                        }
                        return (
                          <tr>
                            <td>
                              <div className="food-text mx-1" style={{ wordBreak: 'break-all' }}>
                                {/* {!item?.is_non_veg ? (
                            <img
                              src={vegImg}
                              alt="Veg"
                              className="sf-veg-nonveg-icon-billing"
                            />
                          ) : (
                            <img
                              src={nonVegImg}
                              alt="Non Veg"
                              className="sf-veg-nonveg-icon-billing"
                            />
                          )} */}

                                <div>
                                  <b>
                                    {item?.fooditem?.name}
                                    {variantName !== "" &&
                                      " (" + variantName + ")"}
                                  </b>
                                  <span style={{ fontSize: "11px" }}>
                                    {item?.addon_price > 0 && (
                                      <CustomHtmlToolTip
                                        interactive
                                        placement="right"
                                        arrow
                                        enterDelay={0}
                                        title={
                                          <div style={{ padding: "8px" }}>
                                            {t("customized-product-label")}
                                          </div>
                                        }
                                      >
                                        <Help style={{ fontSize: "1rem" }} />
                                      </CustomHtmlToolTip>
                                    )}
                                  </span>
                                  <CustomHtmlToolTip
                                    interactive
                                    placement="right"
                                    arrow
                                    enterDelay={0}
                                    title={
                                      <div style={{ padding: "8px" }}>
                                        <span style={{ fontSize: "11px" }}>
                                          {`${t("category-tab-label")}${": ("}`}
                                          {item?.fooditem?.category?.name}
                                          {")"}
                                        </span>
                                        <Card.Text
                                          style={{
                                            fontSize: "11px",
                                            marginBottom: 2,
                                          }}
                                        >
                                          {item?.qty}
                                        </Card.Text>
                                        <Card.Text style={{ fontSize: "11px" }}>
                                          ₹{" "}
                                          {parseFloat(item?.sub_total).toFixed(2)}
                                        </Card.Text>
                                      </div>
                                    }
                                  >
                                    <Help style={{ fontSize: "1rem" }} />
                                  </CustomHtmlToolTip>
                                </div>
                              </div>
                            </td>
                            <td style={{
                              maxWidth: "30%",
                              width: "30%",
                              paddingLeft: "0px",
                              paddingRight: "2px",
                            }}>
                              <Box className={classes.addItemBox}>
                                <Box className={classes.qtyBox}>
                                  <MuiIconButton
                                    size="small"
                                    className={classes.qtyMinusIconButton}
                                    onClick={() => minusItemQty(item, index)}
                                  >
                                    <MinusIcon style={{ fontSize: 14 }} />
                                  </MuiIconButton>
                                  <div>{item?.qty}</div>
                                  <MuiIconButton
                                    size="small"
                                    className={classes.qtyPlusIconButton}
                                    onClick={() => plusItemQty(item, index)}
                                  >
                                    <AddIcon style={{ fontSize: 14 }} />
                                  </MuiIconButton>
                                </Box>
                              </Box>
                            </td>
                            <td>
                              <div className="total-price-td">
                                <div className="price-box">
                                  ₹ {parseFloat(item?.sub_total).toFixed(2)}
                                </div>
                                <IconButton
                                  title={t("order-delete-food-item")}
                                  aria-label={t("order-delete-food-item")}
                                  className="icon-button"
                                  onClick={() => removeFoodItem(item, index)}
                                >
                                  <DeleteIcon
                                    className="icon"
                                    style={{ color: "darkred" }}
                                  />
                                </IconButton>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div className="no-item-box">
                        {isPageLoaderFirst ? (
                          <div style={{ marginTop: 30, marginBottom: 30 }}>
                            <CircularProgress />
                            <br></br>
                            <b>{t("data-fetch-message")}</b>
                          </div>
                        ) : (
                          <>
                            <img
                              alt=""
                              src={EmptyDataImage}
                              style={{ height: "250px", width: "100%" }}
                            />
                            <h5
                              style={{
                                marginTop: 14,
                                fontSize: 16,
                                paddingBottom: 14,
                                textAlign: "center",
                              }}
                            >
                              {t("no-item-selected-data-message")}
                            </h5>
                          </>
                        )}
                      </div>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-8">
            <Card className={classes.calculateSubmitBtn}>
              <CardBody>
                <div className="">
                  <div
                    style={{
                      display: "flex",
                      fontSize: "17px",
                      alignItems: "center",
                      justifyContent: "right",
                    }}
                  >
                    <b> {t("order-detail-total-payable-amount-label")}: &nbsp;</b>{" "}
                    <Label variant={"ghost"} color={"info"}>
                      ₹ {finalBill}
                    </Label>
                  </div>
                </div>
                <br />
                <Form
                  onSubmit={submitOrder}
                  initialValues={currentFormValue}
                  validate={(values) => {
                    const errors = {};
                    if (!values?.phone) {
                      errors.phone = t("phone-number-error-message");
                    }
                    if (values?.phone && isNaN(values.phone)) {
                      errors.phone = t("phone-number-error-message");
                    } else if (values.phone && values?.phone?.length !== 10) {
                      errors.phone = t("phone-number-digit-validation-message");
                    }
                    if (!values?.name_of_customer) {
                      errors.name_of_customer = t("name-validation-message");
                    }
                    if (
                      values?.name_of_customer &&
                      values?.name_of_customer?.length > 50
                    ) {
                      errors.name_of_customer = t("maximumlength-validation-message");
                    }

                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    touched,
                    errors,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit} style={{ height: "100%" }}>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Field name="name_of_customer">
                              {({ input, meta }) => (
                                <FormControl style={{ width: "100%" }}>
                                  <TextInputMui
                                    {...input}
                                    type="text"
                                    label="Name"
                                    variant="outlined"
                                    error={meta.error && meta.touched ? true : false}
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>{meta.error}</span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={6}>
                            <Field name="phone" className={classes.formcontrolwidth}>
                              {({ input, meta }) => (
                                <FormControl className={classes.formcontrolwidth}>
                                  <TextInputMui
                                    {...input}
                                    type="number"
                                    label={t("phone-label")}
                                    variant="outlined"
                                    error={
                                      (meta.touched || meta.visited) && meta.error
                                        ? true
                                        : false
                                    }
                                  />
                                  {(meta.touched || meta.visited) && meta.error && (
                                    <span style={{ color: "red" }}>{meta.error}</span>
                                  )}
                                  <br />
                                </FormControl>
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Field name="email" className={classes.formcontrolwidth}>
                              {({ input, meta }) => (
                                <FormControl className={classes.formcontrolwidth}>
                                  <TextInputMui
                                    {...input}
                                    type="email"
                                    label={"Email"}
                                    variant="outlined"
                                    error={
                                      (meta.touched || meta.visited) && meta.error
                                        ? true
                                        : false
                                    }
                                  />
                                  {(meta.touched || meta.visited) && meta.error && (
                                    <span style={{ color: "red" }}>{meta.error}</span>
                                  )}
                                  <br />
                                </FormControl>
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Field name="address"
                              type="text-area"
                              className={classes.formcontrolwidth}>
                              {({ input, meta }) => (
                                <FormControl className={classes.formcontrolwidth}>
                                  <TextInputMui
                                    {...input}
                                    label={"Address"}
                                    variant="outlined"
                                    error={
                                      (meta.touched || meta.visited) && meta.error
                                        ? true
                                        : false
                                    }
                                  />
                                  {(meta.touched || meta.visited) && meta.error && (
                                    <span style={{ color: "red" }}>{meta.error}</span>
                                  )}
                                  <br />
                                </FormControl>
                              )}
                            </Field>
                          </Grid>
                        </Grid>


                      </div>
                      <div className="d-flex justify-content-between">
                        <Button
                          variant="outlined"
                          size="large"
                          onClick={() => { history.push('/'); dispatch(checkOutPageStatus(false)) }}
                        >
                          {"<"} {t("back")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className="ml-2"
                          type="submit">
                          {
                            getShopSettingFromLocalStorage("PAYMENT_ACEEPT_ONLINE") ?
                              "Save & Pay Order"
                              :
                              "Pay Order"
                          }
                        </Button>
                      </div>
                    </form>
                  )}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </>
  );
}

import { api } from "./../../utility/interceptor";
import {
  setCategoryList,
  setFoodMealList,
  setDiningTableList,
  setDiningTableLoader,
  setKitchenQueue,
  setKitchenQueueLoaded,
  setBillingInfo,
  setOrderHistoryData
} from "../actions/api.action";
import configData from "../../config.js";
import { setCustomerFoodMenuList } from "state/actions/customer.types";
import { setShopSettingData, setShopThemeSettingData, setShopDetailsRefereshData } from "state/actions/shopSetting.types";
import { toast } from "react-hot-toast";
import { setShopSettingInLocalStorage } from "utility/helpers";

export function fetchCategoryList() {
  return function (dispatch) {
    return api
      .get(`/category`)
      .then(({ data }) => {
        dispatch(setCategoryList(data?.data || []));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchFoodMealList() {
  return function (dispatch) {
    return api
      .get(`/food-item`)
      .then(({ data }) => {
        if (data.data && data.data.length > 0) {
          const allMeals = data.data;
          const filteredMealList = allMeals.map((meal) => {
            if (meal?.category?.food_item_addons.length > 0) {
              meal.food_item_addons = [
                ...meal?.food_item_addons,
                ...meal?.category?.food_item_addons,
              ];
            }
            return meal;
          });
          dispatch(setFoodMealList(filteredMealList));
        } else {
          dispatch(setFoodMealList([]));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchDiningTable() {
  return function (dispatch) {
    return api
      .get(`/dining-table`)
      .then(({ data }) => {
        dispatch(setDiningTableList(data?.data || []));
        dispatch(setDiningTableLoader(false));
      })
      .catch((err) => {
        dispatch(setDiningTableLoader(false));
      });
  };
}

export function fetchKitchenQueue(req) {
  return function (dispatch) {
    return api
      .post(`/kitchan`, req)
      .then(({ data }) => {
        if (data.data && data.data.length > 0) {
          const allFoodMeals = data.data;
          const filteredOrderMealList = allFoodMeals.map((meal) => {
            if (meal?.order_meal_item?.addons) {
              meal.order_meal_item.addons = JSON.parse(
                meal.order_meal_item?.addons,
              );
            } else if (meal?.order_meal_item) {
              meal.order_meal_item.addons = [];
            }
            return meal;
          });
          dispatch(setKitchenQueue(filteredOrderMealList));
        } else {
          dispatch(setKitchenQueue([]));
        }
        dispatch(setKitchenQueueLoaded(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchBillingInfo(req) {
  return function (dispatch) {
    return api
      .get(`/bill-print`, req)
      .then(({ data }) => {
        dispatch(setBillingInfo(data?.data || []));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchShopSettingInfo(req) {
  return function (dispatch) {
    return api
      .get(`/settingnew`, req)
      .then(({ data }) => {
        dispatch(setShopSettingData(data?.data || {}));
        Object.keys(data?.data).forEach((element) => {
          setShopSettingInLocalStorage(element, data?.data[element] || "");
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };
}

export function fetchRefereshToken(req) {
  return function (dispatch) {
    return api
      .post(`/auth/referesh_token`, req)
      .then(({ data }) => {
        dispatch(setShopDetailsRefereshData(data?.data || {}));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };
}

export function fetchShopDesign(req) {
  return function (dispatch) {
    return api
      .get(`/design`, req)
      .then(({ data }) => {
        dispatch(setShopThemeSettingData((data?.data[0])));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };
}

export function fetchOrderHistoryList(orderDateRange) {
  return function (dispatch) {
    return api
      .post(`/order/history`, orderDateRange)
      .then(({ data }) => {
        if (data.data && data.data.length > 0) {
          dispatch(setOrderHistoryData(data));
        } else {
          dispatch(setOrderHistoryData([]));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

// CUSTOMER SIDE QR CODE APIS
export function fetchQRCodeFoodMenuList(id) {
  return function (dispatch) {
    return api
      .get(`/public/customer/${id}`)
      .then(({ data }) => {
        if (data.data && data.data.foodItems && data.data.category) {
          const allMeals = data?.data?.foodItems;
          const filteredMealList = allMeals.map((meal) => {
            if (meal?.category?.food_item_addons.length > 0) {
              meal.food_item_addons = [
                ...meal?.food_item_addons,
                ...meal?.category?.food_item_addons,
              ];
            }
            return meal;
          });
          dispatch(
            setCustomerFoodMenuList({
              ...data?.data,
              foodItems: filteredMealList,
            }),
          );
        } else {
          dispatch(setCustomerFoodMenuList([]));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

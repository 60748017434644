import React, { useRef, useState } from "react";
import { Modal, Tabs, Tab, ListGroup, Table } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import { isJson } from "utility/helpers";
import { makeStyles } from "@material-ui/core/styles";
// import CustomHtmlToolTip from "components/Tooltip";
import { Print as PrintIcon, Info as InfoIcon, CallToAction } from "@material-ui/icons";
import ButtonIcon from "components/ButtonComponent/ButtonIcon";
import { useTheme } from "@material-ui/core/styles";
import Label from "components/Label";
import { Box } from "@material-ui/core";
import ElectronPrintFromOrder from "pages/PrintSection/ElectronPrintFromOrder";
import { getShopSettingFromLocalStorage } from "utility/helpers";
import { checkIfElectronLoaded } from "utility/helpers";
import { PRINTER_TYPE } from "common/constant";
import {
  usbPrinter,
  bluetoothPrinter,
  tcpPrinter,
  usbPrinterForKOT,
  bluetoothPrinterForKOT,
  tcpPrinterForKOT,
} from "utility/posPrinterReceipt";
import { isAndroid } from "react-device-detect";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  badge: {
    fontSize: "12px",
    width: "auto",
    height: "20px",
    paddingTop: "5px",
    marginLeft: "6px",
  },
  listitem: {
    display: "flex !important",
    justifyContent: "space-between",
    alignItem: "center",
  },
  phonetitle: {
    display: "flex !important",
    justifyContent: "start",
    alignItem: "center",
  },
  phonenumber: {
    marginLeft: "70px",
  },
  billbtn: {
    float: "right",
    marginRight: "10px",
  },
  newbadge: {
    display: "flex",
  },
  tablehead: {
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[200],
  },
  item: {
    fontSize: "14px",
  },
  avatar: {
    margin: 8,
    display: "inline-block",
    cursor: "pointer",
    zIndex: "1000",
  },
  billDetail: {
    backgroundColor: "rgba(46, 125, 50, 0.16)",
    borderRadius: "10px",
    padding: "12px",
  },
});

const useStyles = makeStyles(styles);

const OrderViewPopup = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const orderData = props.orderDetails;
  // const printComponentRef = useRef();
  const billingData = useSelector((state) => state.api.billing);
  // const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);

  const addOnDetails = (foodMealData) => {
    const addOnData = foodMealData?.addons;
    if (addOnData && addOnData !== "") {
      if (isJson(addOnData)) {
        return JSON.parse(addOnData).map((data) => {
          return (
            <div>
              {data?.name} - {data?.price}
            </div>
          );
        });
      }
    }

    return "";
  };

  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );

  const mobileBillPrint = async (isKOT) => {
    const printFoodItemArray =
      orderData.order_meal_items.length > 0 &&
      orderData.order_meal_items.map((item) => {
        let foodFullName = "";
        let foodItemName = item?.fooditem?.name
          ? item.fooditem?.name
          : item?.food_item_id?.label;
        let foodVariantName = "";
        let foodAddOnObj = isJson(item?.addons)
          ? JSON.parse(item?.addons)
            ?.map((addOn) => addOn.name)
            .join(", ")
          : "";
        if (item?.varient_id > 0 && item?.fooditem?.food_variants?.length > 0) {
          foodVariantName = item?.fooditem?.food_variants.find(
            ({ id }) => id === item?.varient_id,
          )?.name;
        }
        foodFullName = `${foodItemName +
          (foodVariantName ? "( " + foodVariantName + ") " : "") +
          foodAddOnObj
          }`;
        return {
          foodName: foodFullName,
          qty: item?.qty,
          price: `Rs.${parseFloat(item?.sub_total).toFixed(2)}`,
        };
      });
    const billing = billingData && billingData.length > 0 ? billingData[0] : {};
    const printModal = {
      shopName: billing?.name || "",
      shopAddress: billing?.address
        ? billing?.address + "- " + billing?.phone
        : billing?.phone
          ? billing?.phone
          : "",
      customerName: orderData?.name_of_customer
        ? orderData?.name_of_customer
        : " ",
      date: new Date().toDateString() + " " + new Date().toLocaleTimeString(),
      tokenNo:
        (orderData?.order_type === "DINE_IN"
          ? orderData?.diningtable?.name_of_table
          : orderData?.token_no
            ? parseInt(orderData?.token_no)
            : parseInt(orderData?.token)) +
        " (" +
        orderData?.order_type +
        ")",
      printFoodItemArray: printFoodItemArray,
      subTotal: orderData?.sub_total,
      tax:
        parseFloat(orderData?.tax) > 0
          ? `GST : Rs.${parseFloat(orderData?.tax)}`
          : " ",
      discount: orderData?.discount
        ? `Discount ${orderData?.discount_sign == null ||
          orderData?.discount_sign === "INR"
          ? "Rs."
          : orderData?.discount_sign
        }: ` + orderData?.discount
        : "",
      netTotal: orderData?.total_bill,
    };
    const PRINTER_NAME = getShopSettingFromLocalStorage("PRINTER_NAME");
    const KITCHEN_IP = getShopSettingFromLocalStorage("KITCHEN_IP");
    const printerType = getShopSettingFromLocalStorage("PRINTER_TYPE");
    const PRINTER_END_SPACE =
      getShopSettingFromLocalStorage("PRINTER_END_SPACE");
    const BILL_IP = getShopSettingFromLocalStorage("BILL_IP");

    if (isKOT) {
      if (printerType === PRINTER_TYPE.USB) {
        await usbPrinterForKOT(PRINTER_NAME, PRINTER_END_SPACE, printModal);
      } else if (printerType === PRINTER_TYPE.BLUETOOTH) {
        await bluetoothPrinterForKOT(
          PRINTER_NAME,
          PRINTER_END_SPACE,
          printModal,
        );
      } else if (printerType === PRINTER_TYPE.IP) {
        // HERE KOT Logic
        if (KITCHEN_IP?.split(":")?.length > 0) {
          const billKotPrinter = KITCHEN_IP?.split(":");
          await tcpPrinterForKOT(
            billKotPrinter[0],
            billKotPrinter[1],
            PRINTER_END_SPACE,
            printModal,
          );
        } else if (BILL_IP?.split(":")?.length > 0) {
          const billPrinter = BILL_IP?.split(":");
          await tcpPrinterForKOT(
            billPrinter[0],
            billPrinter[1],
            PRINTER_END_SPACE,
            printModal,
          );
        }
      }
    } else {
      if (printerType === PRINTER_TYPE.USB) {
        await usbPrinter(PRINTER_NAME, PRINTER_END_SPACE, printModal);
      } else if (printerType === PRINTER_TYPE.BLUETOOTH) {
        await bluetoothPrinter(PRINTER_NAME, PRINTER_END_SPACE, printModal);
      } else if (printerType === PRINTER_TYPE.IP) {
        if (BILL_IP?.split(":")?.length > 0) {
          const billPrinter = BILL_IP?.split(":");
          await tcpPrinter(
            billPrinter[0],
            billPrinter[1],
            PRINTER_END_SPACE,
            printModal,
          );
        }
      }
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("order-view-popup-order-view-details-titile")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Tabs
          defaultActiveKey="home"
          transition={false}
          id="noanim-tab-example"
        >
          <Tab eventKey="home" title={t("order-view-popup-about-order-tab-title")}>
            <br />
            <div>
              <div className={classes.listitem}>
                <b>{t("customer-name-label")} :</b>
                <div className={classes.item}>
                  {orderData?.name_of_customer || "-"}
                </div>
              </div>
              <div className={classes.listitem}>
                <b>{t("order-number-label")} :</b>
                <div className={classes.item}>{orderData?.id}</div>
                <b>{t("phone-label")} :</b>
                <div className={classes.item}>{orderData?.phone || "-"}</div>
              </div>
              <div className={classes.listitem}>
                <b>{t("order-view-popup-ordered-date")} :</b>
                <div className={classes.item}>
                  {moment(orderData?.createdDate).format("DD/MM/YYYY hh:mm A")}
                </div>
              </div>
            </div>
            <br />
            {orderData && orderData?.order_meal_items?.length > 0 ? (
              <>
                <Table bordered>
                  <thead className={classes.tablehead}>
                    <tr>
                      <th>#</th>
                      <th>{t("food-menu-label")}</th>
                      <th>{t("qty-label")}</th>
                      <th>{t("price-label")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData &&
                      orderData?.order_meal_items?.length > 0 &&
                      orderData?.order_meal_items.map((ele, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              padding: 0,
                              paddingLeft: 6,
                            }}
                          >
                            <p style={{ margin: 0 }}>{index + 1}</p>
                          </td>
                          <td
                            style={{
                              padding: 0,
                              paddingLeft: 6,
                            }}
                          >
                            <Box className={classes.avatar + 'sf-word-break-all'}>
                              {ele?.fooditem?.name}
                              {
                                (ele?.fooditem?.category?.name.length > 0 ? "( " + ele?.fooditem?.category?.name + " )" : "")
                              }
                              {ele?.food_variant?.name}
                              {addOnDetails(ele)}
                              {isJson(ele?.instruction_note)
                                ? JSON.parse(ele?.instruction_note)?.map(
                                  (data) => data?.label + ", ",
                                )
                                : ele?.instruction_note}
                              {/* <CustomHtmlToolTip
                                interactive
                                placement="top"
                                arrow
                                enterDelay={0}
                                title={
                                  <div>
                                    <ListGroup
                                      style={{
                                        color: "black",
                                      }}
                                    >
                                      <ListGroup.Item
                                        className={classes.listitem}
                                      >
                                        <div>
                                          <b>{t("category-tab-label")} :</b>
                                        </div>
                                        <div>
                                          {ele?.fooditem?.category?.name
                                            .length > 0 ? (
                                            ele?.fooditem?.category?.name
                                          ) : (
                                            <p>{t("no-data-message")}</p>
                                          )}
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item
                                        className={classes.listitem}
                                      >
                                        <div>
                                          <b>{t("food-menu-variants")} :</b>
                                        </div>
                                        <div>
                                          {ele?.food_variant?.name ||
                                            t("regular-label")}
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item
                                        className={classes.listitem}
                                      >
                                        <div>
                                          <b>{t("addons-title")} :</b>
                                        </div>
                                        <div>{addOnDetails(ele)}</div>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </div>
                                }
                              >
                                <InfoIcon
                                  style={{
                                    fontSize: "1rem",
                                    marginLeft: "10px",
                                  }}
                                />
                              </CustomHtmlToolTip> */}
                            </Box>
                          </td>
                          <td
                            style={{
                              padding: 0,
                              paddingLeft: 6,
                            }}
                          >
                            <p style={{ margin: 0 }}>{ele?.qty}</p>
                          </td>
                          <td
                            style={{
                              padding: 0,
                              paddingLeft: 6,
                            }}
                          >
                            <p style={{ margin: 0 }}>₹ {ele?.price}</p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <p>{t("record-not-found-message")}</p>
            )}
            <hr />
            <div className={classes.billDetail}>
              <div className={classes.listitem}>
                <b>{t("order-history-payment-type-label")} </b>
                <div className={classes.item}>
                  {orderData?.bill_type || "-"}
                </div>
              </div>
              <div className={classes.listitem}>
                <b>{t("sub-total-title")} </b>
                <div className={classes.item}>₹ {orderData?.sub_total}</div>
              </div>
              <div className={classes.listitem}>
                <b>{t("discount-title")} </b>
                <div className={classes.item}>
                  {orderData?.discount_sign === "INR" ? "₹ " : "% "}
                  {orderData?.discount === null ? "0" : orderData?.discount}
                </div>
              </div>
              <div className={classes.listitem}>
                <b>{t("tax-title")} </b>
                <div className={classes.item}>₹ {orderData?.tax}</div>
              </div>
              <div className={classes.listitem}>
                <b>{t("order-history-total-bill-label")} </b>
                <div className={classes.item}>₹ {orderData?.total_bill}</div>
              </div>
            </div>
          </Tab>
          {shopSettingListData && shopSettingListData?.KITCHAN_SERVICE && (
            <Tab eventKey="kitchen" title={t("order-view-popup-kitchen-info-tab-title")}>
              {orderData && orderData?.order_meal_items?.length > 0 ? (
                <Table bordered>
                  <thead className={classes.tablehead}>
                    <tr>
                      <th>{t("food-menu-label")}</th>
                      <th>{t("staus-type-title")}</th>
                      <th>{t("qty-label")}</th>
                      <th>{t("price-label")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData &&
                      orderData?.order_meal_items?.length > 0 &&
                      orderData?.order_meal_items.map((orderMeal, index) => {
                        return (
                          orderMeal &&
                          orderMeal?.kitchan_queues?.length > 0 &&
                          orderMeal?.kitchan_queues.map((kitchen) => (
                            <tr key={index}>
                              <td>{orderMeal?.fooditem?.name}</td>
                              <td>
                                <div className={classes.newbadge}>
                                  {kitchen?.status === "IN_PROGRESS" ? (
                                    <Label
                                      variant={
                                        theme.palette.mode === "light"
                                          ? "ghost"
                                          : "filled"
                                      }
                                      color={"success"}
                                    >
                                      {t("sales-history-in-progress-label")}
                                    </Label>
                                  ) : kitchen?.status === "DELIVERED" ? (
                                    <Label
                                      variant={
                                        theme.palette.mode === "light"
                                          ? "ghost"
                                          : "filled"
                                      }
                                      color={"success"}
                                    >
                                      {t("delivered-title")}
                                    </Label>
                                  ) : kitchen?.status === "DONE" ? (
                                    <Label
                                      variant={
                                        theme.palette.mode === "light"
                                          ? "ghost"
                                          : "filled"
                                      }
                                      color={"info"}
                                    >
                                      {t("complete-status-flag-label")}
                                    </Label>
                                  ) : (
                                    kitchen?.status
                                  )}

                                  {kitchen?.types === "NEW" ? (
                                    ""
                                  ) : kitchen?.types === "ADD_ON" ? (
                                    <Label
                                      variant={
                                        theme.palette.mode === "light"
                                          ? "ghost"
                                          : "filled"
                                      }
                                      color={"info"}
                                    >
                                      {t("item-add-status-flag-label")}
                                    </Label>
                                  ) : kitchen?.types === "DELETE" ? (
                                    <Label
                                      variant={
                                        theme.palette.mode === "light"
                                          ? "ghost"
                                          : "filled"
                                      }
                                      color={"error"}
                                    >
                                      {t("deleted-status-flag-label")}
                                    </Label>
                                  ) : (
                                    kitchen?.status
                                  )}
                                </div>
                              </td>
                              <td>{kitchen?.qty}</td>
                              <td>₹ {orderMeal?.price}</td>
                            </tr>
                          ))
                        );
                      })}
                  </tbody>
                </Table>
              ) : (
                <p>{t("record-not-found-message")}</p>
              )}
            </Tab>
          )}
        </Tabs>
        <br />

        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <div className="d-none">
              <PrintComponent
                ref={printComponentRef}
                billingData={billingData}
              />
            </div>
            <ReactToPrint
              bodyClass={"print-container"}
              trigger={() => (
                <ButtonIcon
                  id="kot-print"
                  isLoading={submittingBtnProcess}
                  buttonLabel="KOT"
                  variant="outline-dark"
                  icon={<PrintIcon />}
                />
              )}
              content={() => printComponentRef.current}
            /> */}
            {checkIfElectronLoaded() ? (
              <ElectronPrintFromOrder
                orderData={orderData}
                billingData={billingData.length > 0 && billingData[0]}
                printerSize={getShopSettingFromLocalStorage("PRINTER_SIZE")}
                printerName={getShopSettingFromLocalStorage("PRINTER_NAME")}
                buttonLabel={t("bill-button-label")}
              />
            ) : (
              isAndroid && (
                <ButtonIcon
                  id="kot-print"
                  // isLoading={submittingBtnProcess}
                  buttonLabel={t("bill-button-label")}
                  variant="outline-dark"
                  icon={<PrintIcon />}
                  onClick={async () => {
                    mobileBillPrint(false);
                  }}
                />
              )
            )}
            {
              isAndroid && (
                <div style={{ marginLeft: 10 }}>
                  <ButtonIcon
                    id="kot-print"
                    buttonLabel={t("order-detail-kot-label")}
                    variant="outline-dark"
                    icon={<CallToAction />}
                    onClick={async () => {
                      mobileBillPrint(true);
                    }}
                  />
                </div>
              )
            }
          </div>
        </div>

        {/* Bill Print Button Start */}

        {/* <div className={classes.billbtn}>
          <ButtonIcon
            disabled
            type="button"
            // onClick={() => saveAndPrintOrderBtnClick(values)}
            variant="outline-info"
            buttonLabel="BILL"
            icon={<PrintIcon />}
          />
        </div> */}

        {/* Bill Print Button End */}
      </Modal.Body>
    </Modal>
  );
};

export default OrderViewPopup;

import { api } from "./../utility/interceptor";
import configData from "../config.js";

var config = {
    headers: {'Access-Control-Allow-Origin': '*'},
    // crossDomain: true,
    // Accept: 'application/json',
};

//  Dining table Methods 

export const  getAllDiningTableList = async()=> {
    let res = await api.get(`/dining-table/`, config);
    let data = res.data;
    return data;
}

export const  getAllOrderHistory = async(orderDateBody)=> {
    let res = await api.post(`/order/history`, orderDateBody);
    let data = res.data;
    return data;
}

export const  submitOrder =  async (orderData) => {
    let res = await api.post(`/order`, orderData).catch((error)=> { return error});
    return res;
}

export const  getExcitingOrderById = async(id)=> {
    let res = await api.get(`/order/${id}`, config);
    let data = res.data;
    return data;
}

export const  getOrderByCustomerId = async(id)=> {
    let res = await api.get(`/order/customer_order/${id}`, config);
    let data = res.data;
    return data;
}
export const  updateOrder = async(orderId, orderData)=> {
    let res = await api.put(`/order/${orderId}`, orderData);
    let data = res.data;
    return data;
}

export const  deleteOrderById = async(orderId)=> {
    let res = await api.delete(`/order/${orderId}`);
    let data = res.data;
    return data;
}

export const  finishOrder = async(orderId, orderData, pendingAmount)=> {
    let res = await api.put(`/order/finish/${orderId}${pendingAmount ? '?pending_amount=' + pendingAmount || 0.0 : ''}`);
    let data = res.data;
    return data;
}

export const  orderTransfer = async(orderId, newTableId)=> {
    let res = await api.put(`/order/transfer/${orderId}`, { "table_id": newTableId });
    let data = res.data;
    return data;
}

export const  onlineOrderAccepted = async(orderId)=> {
    let res = await api.put(`/order/onlineaccepted/${orderId}`);
    let data = res.data;
    return data;
}

export const  onlineOrderDecline = async(orderId)=> {
    let res = await api.put(`/order/declineonlineorder/${orderId}`);
    let data = res.data;
    return data;
}

export const  settleAllOrder = async(orderDateRange)=> {
    try {
        let res = await api.post(`/order/finishall`, orderDateRange);
        let data = res?.data;
        return data;
    } catch (error) {
        return error;
    }
   
}

import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import jsPDF from "jspdf";
import sumBy from "lodash/sumBy";
// import InvoiceAnalytic from "./InvoiceAnalytic";
import { toast } from "react-hot-toast";
import { offSetToLocalDate } from "utility/helpers";
import {
  Chip,
  IconButton,
  TextField,
  Tab,
  MenuItem,
  Tabs,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Stack,
  FormControl,
  Button,
} from "@mui/material";

import {
  ViewList,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  FilterList as FilterListIcon,
  Delete,
  Cancel,
  ContactSupportOutlined,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import {
  Badge,
  Card,
  Button as BootstrapButton,
  Col,
  Row,
  Container,
} from "react-bootstrap";
import { useTheme } from "@material-ui/core/styles";
import "jspdf-autotable";

// API Calls
import {
  deleteOrderById,
  getAllOrderHistory,
  getOrderByCustomerId,
  settleAllOrder,
} from "services/order.service";
import OrderViewPopup from "../../../pages/Orders/OrderViewPopup";
// import AddEditOrderHistory from "./AddEditOrderHistory";
import TextInputMui from "components/TextInputMui";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../../assets/img/no-data-found.jpg";

import SkeletonLoader from "components/Loader/SkeletonLoader";
import { DeskTopContainer } from "themes/Device";
import { MobileContainer } from "themes/Device";
import MobileTitle from "components/MobileTitle";
import SearchBarInput from "components/SearchBarInput";
import MobileSalesHistory from "components/MobileSalesHistory";
import { useSelector, useDispatch } from "react-redux";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import PopUpModel from "components/Popup/PopUpModel";
import Label from "components/Label";
import useTabs from "hooks/useTabs";
import Scrollbar from "components/Scrollbar";
import { customerOrderData } from "./__mockApidata";

//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import Swal from "sweetalert2";
import Iconify from "components/Iconify";
import { onlineOrderAccepted } from "services/order.service";
import { onlineOrderDecline } from "services/order.service";
import { fetchOrderHistoryList } from "state/thunk-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const styles = (theme) => ({
  mobileviewdate: {
    display: "flex",
    padding: "6px 0px 16px",
  },
  customerHeaderAccordion: {
    backgroundColor: "#e8deec",
    borderRadius: "9px",
    borderColor: "#d7d7d7 !important",
    borderWidth: "1px",
    boxShadow: "0px !important",
    display: "flex",
    width: "10%",
    marginLeft: "auto!important",
    height: "40px",
    borderRadius: "inherit",
    minHeight: "40px !important",
    "& .MuiAccordionSummary-content": {
      margin: "0px",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      height: "45px",
    },
  },
  searchBarInput: {
    border: "unset!important",
    height: "2.8rem!important",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
  },
  tabel: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },
  searchfilterSection: {
    position: "relative",
  },
  mobileCard: {
    // padding: "5px",
    marginTop: "5px",
  },
  searchBarSection: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "92%",
  },

  sdateStyle: {
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px",
      display: "flex",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px",
      display: "flex",
    },

    [theme.breakpoints.up("sm")]: {
      padding: "10px 0px",
      display: "flex",
    },
  },
  edateStyle: {
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px",
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px",
      display: "flex",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "10px 0px",
      display: "flex",
    },
  },
  parentDiv: {
    display: "flex",
    justifyContent: "start !important",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "start",
    },
  },
  dineinbadge: {
    display: "flex",
  },
  dinintable: {
    marginRight: "5px",
    fontSize: "inherit",
  },
  parcelbadge: {
    fontSize: "inherit",
  },
  orderTypeMobileCard: {
    flex: "1 0 auto",
    marginLeft: "5px",
  },
});

const ORDER_FILTERS = {
  ALL: "ALL",
  IN_PROGRESS: "IN_PROGRESS",
  DINE_IN: "DINE_IN",
  PARCEL: "PARCEL",
  ONLINE: "ONLINE",
  COMPLETED: "COMPLETED",
};

// const ORDER_FILTERS_ICON = {
//   ALL: "ic:round-receipt",
//   IN_PROGRESS: "eva:checkmark-circle-2-fill",
//   DINE_IN: "eva:clock-fill",
//   PARCEL: "eva:bell-fill",
//   COMPLETED: "eva:file-fill",
// };

const useStyles = makeStyles(styles);

const CustomerHistory = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    currentTab: filterStatus,
    onChangeTab: onFilterStatus,
    setCurrentTab: setCustomCurrentTab,
  } = useTabs("IN_PROGRESS");
  const { t } = useTranslation();
  const [orderHistoryDataList, setOrderHistoryDataList] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [debt, setDebt] = useState(0);
  const [credit, setCredit] = useState(0);
  const [orderHistory, setOrderHistory] = useState([]);
  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const [orderViewData, setOrderViewData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [deletePopupshow, setDeletePopupshow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const theme = useTheme();
  const [searchHistory, setSearchHistory] = useState("");
  const [filterHistory, setFilterHistory] = useState([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  // const [selectValue, setSelectValue] = useState("");
  const [endDate, setEndDate] = useState(
    moment().add(1, "days").format("YYYY-MM-DD"),
  );
  const [addEditOrderHistoryModelShow, setAddEditOrderHistoryModelShow] =
    useState(false);
  const [addEditOrderHistoryId, setAddEditOrderHistoryId] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [activeFilterType, setActiveFilterType] = useState(
    ORDER_FILTERS.IN_PROGRESS,
  );
  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const [isDineInEnabled, setIsDineInEnabled] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState([]);
  const layoutDispatch = useLayoutDispatch();
  const location = useLocation();

  const customerId = location.pathname.split("/")[4];
  const countDisplay = (type) => {
    if (count && count.length > 0) {
      const allCount = count.find((countItem) => countItem.type === type);
      return allCount;
    }
    return 0;
  };
  const orderDateBody = {
    startDate: moment(startDate).utc().format("YYYY-MM-DD HH:mm:ss"), //  "endDate": "2021-03-30",
    endDate: moment(endDate).utc().format("YYYY-MM-DD HH:mm:ss"), // "startDate": "YYYY-MM-DD"
  };


  const TABS = [
    {
      value: "IN_PROGRESS",
      label: t("sales-history-in-progress-label"),
      color: "success",
      count: countDisplay("INPROGRESS")?.count || 0,
    },
    {
      value: "DINE_IN",
      label: t("dine-in-label"),
      color: "warning",
      count: countDisplay("DINE_IN")?.count || 0,
    },
    {
      value: "PARCEL",
      label: t("sales-history-parcel-label"),
      color: "error",
      count: countDisplay("PARCEL")?.count || 0,
    },
    {
      value: "ONLINE",
      label: t("sales-history-online-label"),
      color: "secondary",
      count: countDisplay("ONLINE")?.count || 0,
    },
    {
      value: "COMPLETED",
      label: t("sales-history-completed-label"),
      color: "info",
      count: countDisplay("COMPLETED")?.count || 0,
    },
    {
      value: "ALL",
      label: t("all-label"),
      color: "info",
      count: countDisplay("ALL")?.count || 0,
    },
  ];



  const generateInvoicePdf = (data) => {};

  useEffect(() => {
    if (customerId !== null && customerId !== undefined) {
      getOrderByCustomerId(customerId)
        .then((res) => {
          setOrderHistoryDataList(res.data);
        })
        .catch((err) => {
          toast.success(err?.message);
        });
    }
  }, [customerId]);
  useEffect(() => {
    if (orderHistoryDataList.length > 0) {
      const name = orderHistoryDataList[0]?.customer?.name;
      setCustomerName(name);
      
      let totalDebt = orderHistoryDataList[0]?.customer?.dept;
      let totalCredit = orderHistoryDataList[0]?.customer?.credit;
      setDebt(totalDebt);
      setCredit(totalCredit);
    }


  }, [orderHistoryDataList]);

  const deleteDataConfirm = (id) => {
    setDeleteId(id);
    setDeletePopupshow(true);
  };

  const acceptOnlineOrder = (orderID) => {
    try {
      onlineOrderAccepted(orderID)
        .then((res) => {
          if (res.status == 200) {
            toast.success(res?.message);
          } else {
            toast.error(res?.message);
          }
          dispatch(fetchOrderHistoryList(orderDateBody));
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const declineOnlineOrder = (orderID) => {
    try {
      onlineOrderDecline(orderID)
        .then((res) => {
          if (res.status == 200) {
            toast.success(res?.message);
          } else {
            toast.error(res?.message);
          }
          dispatch(fetchOrderHistoryList(orderDateBody));
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = (deleteid) => {
    setDeleteId(deleteid);
    setDeletePopupshow(true);
  };

  const printData = () => generateInvoicePdf();
  const editData = (editid) => {
    setAddEditOrderHistoryId(editid);
    setAddEditOrderHistoryModelShow(true);
  };

  const viewData = (orderDetail) => {
    setOrderViewData(orderDetail);
    setDetailsModalShow(true);
  };


  useEffect(() => {
    if (searchHistory) {
      let filterHistoryData = orderHistoryDataList.filter(
        (item) =>
          item.name &&
          item.name?.toLowerCase()?.includes(searchHistory.toLowerCase()),
      );
      setFilterHistory(filterHistoryData);
    } else {
      setFilterHistory(orderHistoryDataList);
    }
  }, [searchHistory]);

 


  const startEndDatePicker = () => {
    return (
      <div style={{ padding: "0px" }}>
        <div className={classes.parentDiv}>
          <div className={`${classes.sdateStyle} startdate`}>
            <TextField
              id="startDate"
              label={t("order-history-start-date")}
              type="date"
              variant="outlined"
              onChange={(e) => setStartDate(e.target.value)}
              defaultValue={startDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={`${classes.edateStyle} enddate`}>
            <TextField
              id="endDate"
              label={t("order-history-end-date")}
              variant="outlined"
              type="date"
              onChange={(e) => setEndDate(e.target.value)}
              defaultValue={endDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const filterButtons = () => {
    return (
      <>
        <DeskTopContainer>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onFilterStatus}
            sx={{
              px: 2,
              bgcolor: theme.palette.grey[200],
            }}
          >
            {TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                value={tab.value}
                icon={
                  <Label color={tab.color} sx={{ marginRight: "8px" }}>
                    {" "}
                    {tab.count}{" "}
                  </Label>
                }
                label={tab.label}
                sx={{
                  px: 2,
                  flexDirection: "row",
                  color: theme.palette.grey[600],
                  fontWeight: "600",
                  textTransform: "none",
                  minHeight: "48px",
                  marginRight: "40px",
                }}
              />
            ))}
          </Tabs>

          <Divider />
        </DeskTopContainer>
        <MobileContainer>
          <div className="history-filter-button">
          </div>
        </MobileContainer>
      </>
    );
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = `${t(
      "customer-sales-history-route-title",
    )} of ${startDate} to ${endDate}`;
    const headers = [
      [
        t("order-history-token-number-label"),
        t("order-number-label"),
        t("sales-report-order-type"),
        t("created-date-label"),
        t("customer-name-label"),
        t("order-history-payment-type-label"),
        t("order-history-total-bill-label"),
      ],
    ];

    const data = orderHistoryDataList.map((item) => [
      item?.token_no,
      item?.id,
      item?.order_type,
      item?.created_date &&
        moment(item?.created_date).format("DD/MM/YYYY hh:mm"),
      item?.name_of_customer,
      item?.bill_type,
      item?.total_bill,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    let total = 0;

    orderHistoryDataList.forEach((item) => {
      total += parseFloat(item?.total_bill);
    });
    total = total.toFixed(2);

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);

    let finalY = doc.lastAutoTable.finalY; // The y position on the page
    doc.text(40, finalY + 30, "Total Bill = " + total);

    doc.save("Customer-Report.pdf");
  };
  const getAllOrderHistoryList = () => {
    setDataLoading(true);
    dispatch(fetchOrderHistoryList(orderDateBody));
  };

  const deleteOrder = () => {
    if (deleteId > 0) {
      setIsDeleting(true);
      deleteOrderById(deleteId)
        .then((res) => {
          setIsDeleting(false);
          setDeletePopupshow(false);
          if (res?.data) {
            toast.success(res?.message);
          } else {
            toast.success(res?.message);
          }
          // getAllOrderHistoryList();
          if (customerId !== null && customerId !== undefined) {
            getOrderByCustomerId(customerId)
              .then((res) => {
                setOrderHistoryDataList(res.data);
              })
              .catch((err) => {
                toast.success(err?.message);
              });
          }
        })
        .catch((err) => {
          setIsDeleting(false);
          setDeletePopupshow(false);
          toast.success(err?.message);
        });
    }
  };
  return (
    <>
       <PopUpModel
        show={deletePopupshow}
        heading={t("order-history-order-delete-popup-message")}
        body={t("order-history-order-delete-popup-body")}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeletePopupshow(false)}
            >
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button variant="contained" color="secondary" onClick={deleteOrder}>
              <Delete /> {isDeleting ? <CircularProgress /> : t("delete")}
            </Button>
          </>
        }
        onHide={() => {
          setDeletePopupshow(false);
        }}
      />
      <DeskTopContainer>
        <div className={classes.tabel}>
          <MaterialTable
            style={{ padding: "20px" }}
            title={""}
            columns={[
              {
                title: t("order-history-token-number-label"),
                field: "token_no",
                cellStyle: {
                  width: "10%",
                  textAlign: "center",
                },
                defaultSort: "desc",
                render: (rowData) => (
                  <Label
                    variant={
                      theme.palette.mode === "light" ? "ghost" : "filled"
                    }
                    color={"info"}
                    style={{ fontSize: "20px!important" }}
                  >
                    {rowData?.token_no}
                  </Label>
                ),
              },
              {
                title: t("order-number-label"),
                field: "orderNo",
                cellStyle: {
                  width: "10%",
                },
                defaultSort: "desc",
              },
              {
                title: t("sales-report-order-type"),
                field: "diningTable",
                cellStyle: {
                  width: "13%",
                },
              },
              {
                title: t("created-date-label"),
                field: "createdDate",
                cellStyle: {
                  width: "13%",
                },
                render: (rowData) =>
                  moment(rowData?.createdDate).format("DD/MM/YYYY hh:mm A"),
              },
              {
                title: t("customer-name-label"),
                field: "customerName",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: t("order-history-payment-type-label"),
                field: "bill_type",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: t("order-history-payment-pending-label"),
                field: "pending_amount",
                cellStyle: {
                  width: "15%",
                },
                render: (rowData) => (
                  <Label
                    variant={
                      theme.palette.mode === "light" ? "ghost" : "filled"
                    }
                    color={"error"}
                    style={{ fontSize: "20px!important" }}
                  >
                    {rowData?.pending_amount}
                  </Label>
                ),
              },
              {
                title: t("order-history-total-bill-label"),
                field: "totalBill",
                cellStyle: {
                  width: "10%",
                },
              },
              {
                title: t("action"),
                field: "orderDetail",
                cellStyle: {
                  width: "15%",
                },
                render: (rowData) => (
                  <>
                    {!rowData?.isCompleted && (
                      <RoleBasedGuard
                        resource={RESOURCE.ORDER_HISTORY}
                        action={ACTION.VIEW}
                      >
                        <IconButton
                          color="primary"
                          title={t("order-history-preview-order-label")}
                          aria-label={t("order-history-preview-order-label")}
                          onClick={() => {
                            rowData?.orderDetail?.order_type === "PARCEL"
                              ? props.history.push({
                                  pathname:
                                    rowData?.id === 0 || rowData?.id == null
                                      ? `/app/order/parcel/`
                                      : `/app/order/parcel/${rowData?.id}`,
                                  tableId: "PARCEL",
                                })
                              : props.history.push({
                                  pathname:
                                    rowData?.id === 0 || rowData?.id == null
                                      ? `/app/dine-in/order/`
                                      : `/app/dine-in/order/${rowData?.id}`,
                                  tableId: rowData?.tableId,
                                });
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </RoleBasedGuard>
                    )}
                    {/* <RoleBasedGuard
                      resource={RESOURCE.ORDER_HISTORY}
                      action={ACTION.UPDATE}
                    >
                      <IconButton
                        color="primary"
                        aria-label="Edit Order"
                        onClick={() => {
                          setAddEditOrderHistoryId(rowData.id);
                          setAddEditOrderHistoryModelShow(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </RoleBasedGuard> */}
                    <RoleBasedGuard
                      resource={RESOURCE.ORDER_HISTORY}
                      action={ACTION.VIEW}
                    >
                      <IconButton
                        color="secondary"
                        title={t("order-history-view-order-details-label")}
                        aria-label={t("order-history-view-order-details-label")}
                        onClick={() => {
                          setOrderViewData(rowData.orderDetail);
                          setDetailsModalShow(true);
                        }}
                      >
                        <ViewList />
                      </IconButton>
                    </RoleBasedGuard>
                    <IconButton
                      color="secondary"
                      title={t("order-history-delete-order-label")}
                      aria-label={t("order-history-delete-order-label")}
                      onClick={() => deleteDataConfirm(rowData.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
              
                    {rowData?.is_online &&
                      (rowData?.is_online_accepted === "CREATED" ? (
                        <Stack
                          direction={"row"}
                          gap={1}
                          style={{ marginTop: "10px" }}
                        >
                          <Button
                            size="small"
                            onClick={() => acceptOnlineOrder(rowData?.id)}
                            sx={{ textTransform: "initial" }}
                            variant="contained"
                            endIcon={
                              <Iconify icon={"eva:checkmark-circle-2-fill"} />
                            }
                          >
                            Accept
                          </Button>
                          <Button
                            size="small"
                            onClick={() => declineOnlineOrder(rowData?.id)}
                            sx={{ textTransform: "initial" }}
                            variant="contained"
                            color="error"
                            endIcon={<Iconify icon={"eva:close-circle-fill"} />}
                          >
                            Decline
                          </Button>
                        </Stack>
                      ) : (
                        <Stack
                          direction={"row"}
                          gap={1}
                          style={{ marginTop: "10px" }}
                        >
                          <Label
                            variant={
                              theme.palette.mode === "light"
                                ? "ghost"
                                : "filled"
                            }
                            color={"error"}
                          >
                            {rowData?.is_online_accepted}
                          </Label>
                        </Stack>
                      ))}
                  </>
                ),
              },
            ]}
            data={
              orderHistoryDataList &&
              orderHistoryDataList
                .sort(function (a, b) {
                  return parseInt(a?.order_id) < parseInt(b?.order_id);
                })
                .map((item, index) => {
                  return {
                    orderNo: item?.id,
                    orderName: item?.diningtable
                      ? item?.diningtable?.name_of_table
                      : "Parcel Order",
                    tableId: item?.diningtable ? item?.diningtable?.id : 0,
                    diningTable: item?.diningtable ? (
                      <>
                        <div className={classes.dineinbadge}>
                          <Label
                            variant={
                              theme.palette.mode === "light"
                                ? "ghost"
                                : "filled"
                            }
                            color={"info"}
                          >
                            {item?.order_type === "DINE_IN"
                              ? "Dine-In"
                              : item?.order_type}
                          </Label>{" "}
                          &nbsp;&nbsp;
                          <span>{item?.diningtable?.name_of_table}</span>
                        </div>
                      </>
                    ) : (
                      <Label
                        variant={
                          theme.palette.mode === "light" ? "ghost" : "filled"
                        }
                        color={"warning"}
                      >
                        {item?.order_type}
                      </Label>
                    ),
                    customerName: item?.name_of_customer || "-",
                    phoneNo: item?.phone,
                    createdDate: item?.created_date || "-",
                    bill_type: item?.bill_type || "-",
                    totalBill: "₹ " + item?.total_bill,
                    id: item.id,
                    is_online_accepted: item?.is_online_accepted,
                    is_online: item?.is_online,
                    isCompleted: item?.is_complete,
                    orderDetail: item,
                    token_no: item?.token_no,
                    pending_amount: "₹ " + item?.pending_amount,
                  };
                })
            }
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: dataLoading ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>
                      {t("order-history-empty-data-message")}
                    </p>
                  </NotFoundComponent>
                ),
              },
            }}
            options={{
              paging: true,
              pageSize: 10,
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 20, 30],
              sorting: true,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                color: theme.palette.grey[500],
                borderColor: "rgba(0, 0, 0, 0.23)",
                border: "0.8px solid",
                borderRadius: "6px",
                padding: "11px",
                "&:before": {
                  borderBottom: "0px!important",
                },
                "&:after": {
                  borderBottom: "0px!important",
                },
              },

              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              },
              actionsColumnIndex: -1,
              // rowStyle: (rowData, index) => {
              //   if (index % 2) {
              //     return { backgroundColor: "#f2f2f2" };
              //   }
              // },
            }}
            components={{
              Toolbar: (props) => (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <h5>
                      <b>{t("customer-sales-history-route-title")}</b>
                    </h5>
                    <Button
                      variant="contained"
                      color="info"
                      type="button"
                      size="medium"
                      onClick={exportPDF}
                    >
                      Export PDF
                    </Button>
                  </div>
                  <div style={{ fontSize: "medium" }}>
                    <div className="row">
                      {customerName !== "" && (
                        <>
                          <div className="col-md-4">
                            <span>
                              <b>{` Name : ${customerName}`}</b>
                            </span>
                          </div>
                        </>
                      )}
                      {/* {credit !== null && (
                        <>
                          <div className="col-md-4">
                            <span>
                              <b>{` Credit : ₹ ${credit}`}</b>
                            </span>
                          </div>
                        </>
                      )} */}
                      {debt !== null && (
                        <>
                          <div className="col-md-4">
                            <span>
                              <b>{` Debt : ₹ ${debt}`}</b>
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* {filterButtons()} */}
                  <div className="custom-material-table-toolbar">
                    <MTableToolbar {...props} />
                    {startEndDatePicker()}
                  </div>
                </>
              ),
            }}
          />
        </div>
      </DeskTopContainer>

      <MobileContainer>
        <div className={classes.mobileCard}>
          <div className={classes.searchfilterSection}>
            <Accordion defaultExpanded={false}>
              <AccordionSummary
                expandIcon={<FilterListIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.customerHeaderAccordion}
              ></AccordionSummary>
              <AccordionDetails className={classes.mobileviewdate}>
                {startEndDatePicker()}
              </AccordionDetails>
            </Accordion>
            <div className={classes.searchBarSection}>
              <SearchBarInput
                onChange={(e) => {
                  setSearchHistory(e.target.value);
                }}
                clearTxt={() => setSearchHistory("")}
                className={classes.searchBarInput}
                value={searchHistory}
                placeholder={t("order-history-search-order-history-label")}
              />
            </div>
          </div>
          {filterButtons()}
          {dataLoading ? (
            <SkeletonLoader count={3} height="10rem" />
          ) : (
            <div>
              {filterHistory &&
                filterHistory.map((item, index) => (
                  <div className={classes.mobileCard} key={index}>
                    <MobileSalesHistory
                      orderId={item?.id}
                      customerName={item?.name_of_customer}
                      createdDate={moment(item?.created_date).format(
                        "DD/MM/YYYY hh:mm A",
                      )}
                      order_type={item?.order_type}
                      totalBill={"₹ " + item?.total_bill}
                      tableNo={item?.diningtable?.name_of_table || "-"}
                      deleteData={deleteData}
                      editData={editData}
                      viewData={viewData}
                      printData={printData}
                      orderDetail={item}
                      itemId={item?.id}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>

        {filterHistory.length < 1 && !dataLoading && (
          <NotFoundComponent imgUrl={noFoundImg}>
            <p style={{ fontWeight: 600 }}>
              {t("order-history-empty-data-message")}
            </p>
          </NotFoundComponent>
        )}
      </MobileContainer>

      <OrderViewPopup
        show={detailsModalShow}
        orderDetails={orderViewData}
        onHide={() => setDetailsModalShow(false)}
      ></OrderViewPopup>
    </>
  );
};

export default CustomerHistory;

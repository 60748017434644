import React, { useState, Children } from "react";
import { Card } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Form, Field } from "react-final-form";
import { Row, Col } from "react-bootstrap";
import {
  saveStoreThemeSetting,
  updateStoreThemeSetting,
  getStoreDesignSetting,
} from "services/setting.service";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-hot-toast";
import { FormControl, MenuItem } from "@material-ui/core";
import TextInputMui from "components/TextInputMui";
import Select, { components } from "react-select";
import ControlledEditor from "@monaco-editor/react";
import { useSelector, useDispatch } from "react-redux";
import { setShopThemeSettingData } from "state/actions/shopSetting.types";
import { AppendCustomCss } from "utility/helpers";
import { useTranslation } from "react-i18next";
const styles = (theme) => ({
  formcontrolwidth: {
    width: "100%",
    marginBottom: 10,
    "& > span": {
      maxWidth: 240,
      fontSize: 12,
    },
  },
  monacoEditorStyle: {
    borderColor: "hsl(0, 0%, 80%)",
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
  },
});

const useStyles = makeStyles(styles);

export const CustomValueContainer = (props) => {
  const { selectProps, children } = props;
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props}>
        {selectProps.placeholder}
      </components.Placeholder>
      {Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? child : null,
      )}
    </components.ValueContainer>
  );
};

const component = {
  ValueContainer: CustomValueContainer,
  ClearIndicator: null,
  IndicatorSeparator: null,
};

export default function AccountTheme() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const shopThemeSettingData = useSelector(
    (state) => state?.shopSettingReducer?.shopThemeSettingData,
  );
  const [initialFormData, setInitialFormData] = useState(() => {
    if (shopThemeSettingData) {
      return {
        ...shopThemeSettingData,
      };
    }
  });

  const dispatch = useDispatch();

  const reFetchTheme = async () => {
    const storeTheme = await getStoreDesignSetting();
    if (storeTheme && storeTheme.status === 200) {
      if (storeTheme && storeTheme?.data && storeTheme.data.length > 0) {
        dispatch(setShopThemeSettingData(storeTheme?.data[0]));
        AppendCustomCss(storeTheme?.data[0].custom_css);
        // window.location.reload();
      }
    }
  };
  const handlePassWord = async (values) => {
    setSubmittingBtnProcess(true);

    const themeData = {
      is_active: true,
      primary_color: values?.primary_color || null,
      secondary_color: values?.secondary_color || null,
      btn_primary_bg_color: values?.btn_primary_bg_color || null,
      btn_primary_text_color: values?.btn_primary_text_color || null,
      btn_secondary_bg_color: values?.btn_secondary_bg_color || null,
      btn_secondary_text_color: values?.btn_secondary_text_color || null,
      dine_In_table_style: values?.dine_In_table_style || null,
      order_food_menu_style: values?.order_food_menu_style || null,
      order_food_category_style: values?.order_food_category_style || null,
      custom_css: values?.custom_css || null,
      theme_type: values?.theme_type || null,
      order_details_layout: values?.order_details_layout || null,
    };
    if (initialFormData?.id && initialFormData.id > 0) {
      const res = await updateStoreThemeSetting(initialFormData.id, themeData);
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setSubmittingBtnProcess(false);
        await reFetchTheme();
      } else {
        setSubmittingBtnProcess(false);
        toast.error(res?.message);
      }
    } else {
      const res = await saveStoreThemeSetting(themeData);
      if (res.status === 201 || res?.data) {
        toast.success(res?.data?.message);
        setSubmittingBtnProcess(false);
        await reFetchTheme();
      } else {
        setSubmittingBtnProcess(false);
        toast.error(res?.message);
      }
    }
  };

  const colourStyles = () => ({
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? 0 : "50%",
      transition: `all 300ms`,
      fontSize: state.hasValue || state.selectProps.inputValue ? 12 : 16,
      background: "white",
      paddingLeft: 5,
      paddingRight: 5,
    }),
    control: (base, state) => ({
      ...base,
      height: 56,
      border: state.selectProps.error ? "1px solid red" : "",
    }),
    valueContainer: (provided) => ({
      ...provided,
      overflow: "visible",
      cursor: "text",
      minHeight: 56,
      padding: `0px 8px 2px 8px`,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  });

  return (
    <Card sx={{ p: 3 }}>
      <Form
        onSubmit={handlePassWord}
        initialValues={initialFormData}
        validate={(values) => {
          const errors = {};
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} md={6}>
                <Row>
                  <Col sm={12} md={6}>
                    <Field name="primary_color">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="color"
                            label={t("theme-settings-primary-color-label")}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6}>
                    <Field name="secondary_color">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="color"
                            label={t("theme-settings-secondary-color-label")}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6}>
                    <Field name="btn_primary_bg_color">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="color"
                            label={t("theme-settings-button-bg-color-label")}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6}>
                    <Field name="btn_primary_text_color">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="color"
                            label={t(
                              "theme-settings-button-primary-text-color-label",
                            )}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6}>
                    <Field name="btn_secondary_bg_color">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="color"
                            label={t(
                              "theme-settings-button-secondary-text-color-label",
                            )}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6}>
                    <Field name="btn_secondary_text_color">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="color"
                            label={t(
                              "theme-settings-button-secondary-text-color-label",
                            )}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6}>
                    <Field name="dine_In_table_style">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            select
                            label="Dine In Screen Layout"
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          >
                            <MenuItem value={"Style1"}>
                              {t("theme-settings-style-one-label")}
                            </MenuItem>
                            <MenuItem value={"Style2"}>
                              {t("theme-settings-style-two-label")}
                            </MenuItem>
                            <MenuItem value={"Style3"}>
                              {t("theme-settings-style-three-label")}
                            </MenuItem>
                          </TextInputMui>
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6}>
                    <Field name="order_food_menu_style">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            select
                            label={t(
                              "theme-settings-order-screen-food-menu-label",
                            )}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          >
                            <MenuItem value={"GRID4"}>
                              {`${t(
                                "theme-settings-style-four-label",
                              )}${"(Desktop Grid 4, Mobile Grid 2)"}`}
                            </MenuItem>
                            <MenuItem value={"GRID5"}>
                              {`${t(
                                "theme-settings-style-five-label",
                              )}${" (Desktop Grid 5, Mobile Grid 2)"}`}
                            </MenuItem>
                            <MenuItem value={"GRID6"}>
                              {`${t(
                                "theme-settings-style-six-label",
                              )}${" (Desktop Grid 6, Mobile Grid 2)"}`}
                            </MenuItem>
                          </TextInputMui>
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={12}>
                    <Field name="order_food_category_style">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            select
                            label={t(
                              "theme-settings-order-screen-food-category-label",
                            )}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          >
                            <MenuItem value={"Style1"}>
                              {t("theme-settings-style-one-label")}
                            </MenuItem>
                            <MenuItem value={"Style2"}>
                              {t("theme-settings-style-two-label")}
                            </MenuItem>
                            <MenuItem value={"Style3"}>
                              {t("theme-settings-style-three-label")}
                            </MenuItem>
                          </TextInputMui>
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <Field name="order_details_layout">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            select
                            label={t(
                              "theme-settings-order-screen-layout-label",
                            )}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          >
                            <MenuItem value={"STYLE1(L9-R3)"}>
                              {`${t(
                                "theme-settings-style-one-label",
                              )}${"(L9-R3)"}`}
                            </MenuItem>
                            <MenuItem value={"STYLE2(L8-R4)"}>
                              {`${t(
                                "theme-settings-style-two-label",
                              )}${"(L8-R4)"}`}
                            </MenuItem>
                          </TextInputMui>
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={6}>
                    <Field name="theme_type">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            select
                            label={t("theme-settings-theme-type-label")}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          >
                            <MenuItem value={"LIGHT"}>
                              {t("theme-settings-theme-type-light-label")}
                            </MenuItem>
                            <MenuItem value={"DARK"}>
                              {" "}
                              {t("theme-settings-theme-type-dark-label")}
                            </MenuItem>
                          </TextInputMui>
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6}>
                <Field name="custom_css">
                  {({ input, meta }) => (
                    <FormControl className={classes.formcontrolwidth}>
                      <label>{t("theme-settings-customize-css-label")}</label>
                      <ControlledEditor
                        height="50vh"
                        value={input.value || ""}
                        onChange={(e, v) => {
                          input.onChange(e);
                        }}
                        className={classes.monacoEditorStyle}
                        defaultLanguage="css"
                      />
                    </FormControl>
                  )}
                </Field>
              </Col>
            </Row>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#00AB55" }}
            >
              {submittingBtnProcess ? t("processing..") : t("save")}
            </LoadingButton>
          </form>
        )}
      />
    </Card>
  );
}

import { useQuery } from "react-query";
import { API_ENDPOINTS } from "utility/endpoints";
import { api } from "utility/interceptor";

const fetchExpenseQuery = async (date) => {
    if (date != undefined) {
        const { data } = await api.put(`${API_ENDPOINTS.ORDERS}/order_traking/${date}`);
        return data?.data;
    }
    else
        return {}
};
const useOrderTracking = (date) => {
    return useQuery([API_ENDPOINTS.ORDERS, date], () => fetchExpenseQuery(date));
};
export {
    useOrderTracking,
};

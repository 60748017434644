import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { useInMobile } from "themes/Device";
import { color } from "@mui/system";
import { BorderColor } from "@material-ui/icons";

export default makeStyles((theme) => ({
  logotype: {
    color: "white",
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    // fontWeight: 500,
    width: "240px",
    fontSize: 28,
    fontFamily: "none",
    fontStyle: "oblique",
    fontWeight: "bolder",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  muiDrawer: {
    '& .MuiDrawer-paper': {
      width:'30%'
    }
  },
  screenTitle: {
    width: "100%",
    fontSize: "18px",
    textAlign: "center",
  },
  navBarLogo: {
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "white",
    // position: 'absolute',
    // left: '10%',
    // transform: 'translate(-31px, 0px)'
  },
  appBar: {
    width: "100vw",
    // background: "#fff",
    position: "absolute",
    // height: "100px",
    // padding: "1.5rem 0",
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
    zIndex: 101,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: "35px",
    paddingTop: "15px",
    paddingBottom: "15px",
    backgroundColor: "#10b981",
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: 25,
    paddingLeft: theme.spacing(2.5),
    width: 36,
    backgroundColor: fade(theme.palette.common.black, 0),
    transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: fade(theme.palette.common.black, 0.08),
    },
  },
  searchFocused: {
    backgroundColor: fade(theme.palette.common.black, 0.08),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
  },
  searchIcon: {
    width: 36,
    right: 0,
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenu: {
    top: "0px!important",
    marginTop: theme.spacing(7),
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
      // color: "white",
    },
  },
  headerMenuButton: {
    // marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  headerMenuButtonSandwich: {
    marginLeft: 9,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    // marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: useInMobile ? 25 : 18,
    color: "",
  },
  phoneIcon: {
    fontSize: useInMobile ? 25 : 18,
    color: "",
    marginRight: "5px",
  },
  fullScreenIcon: {
    fontSize: useInMobile ? 25 : 18,
    color: "",
  },
  headerIconCollapse: {
    color: "",
  },
  profileMenu: {
    top: "-5px!important",
    maxWidth: 173,
    minWidth: 173,
    right: "0px!important",
    borderLeft: "3px solid ",
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: "none",
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
  purchaseBtn: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginRight: theme.spacing(3),
  },
  navLinkHeader: {
    padding: "3px!!important",
    fontSize: "12px",
  },
  iconButton: {
    padding: "0px 8px",
    color: "#fff",
  },
  trialDay: {
    fontSize: "18px",
    fontWeight: 500,
    margin: "auto",
  },
  mobileNavBar: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  sidebarmenu: {
    width: "100%",
    position: "relative",
    height:'100vh'
  },
  checkOutBtn: {
    position: "absolute",
    width: "96%",
    marginLeft: "2%",
    borderRadius: 5,
    height: 60,
    border: "none",
    backgroundColor: "#10b981",
    color: "white",
    fontWeight: "600",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    bottom:'10px'
  },
  checkOutBtnTotal: {
    marginTop:'15px',
    marginRight:'5%',
    backgroundColor:'white',
    color:'#10b981',
    height:35,
    width:70,
    borderRadius:7,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontWeight: "600",
    fontSize: "18px",
  },
  close: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#eef2ff",
    padding: "10px",
    "@media (max-width: 604px)": {
      width: "90vw",
    },
  },
  product: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft:'5%',
    paddingRight:'5%',
    "&:hover": {
      backgroundColor: "#eef2ff",
      // width: "90vw",
      "@media (max-width: 604px)": {
        width: "90vw",
      },
    },
  },
  itemsMenu:{
    height:'83%',
    overflow:'auto'
  },
  foodCatogary:{
    fontSize:'10px',
    fontWeight:'500',
    color:"#9b9b9b"
  },
  productImage: {
    borderRadius: "100%",
    marginRight:'10px'
  },
  productbutton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: "5px",
    backgroundColor: "#eee",
  },
  deliconButton: {
    color: "red",
  },
  Item: {
    fontSize: "10px",
    marginBottom: "5px",
  },
  Removeicon: {
    height: "5px",
    width: "5px",
  },
  qty: {
    fontSize: "16px",
  },
  itemNumber: {
    fontSize: "14px",
    color: "white",
    backgroundColor: "red",
    borderRadius: "100%",
    height: 20,
    width: 20,
    textAlign: "center",
    fontWeight: "700",
    border: "none",
    position: "absolute",
    zIndex: 1,
    right: "10%",
    top: "-12px",
  },
  cartMain: {
    position: "relative",
  },
  productDetails:{
    width:'60%',
    display:'flex',
    alignItems:'center',
    marginTop:'5%'
  }
}));

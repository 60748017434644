import ThermalPrinterPlugin from "thermal-printer-cordova-plugin/www/thermal-printer";

export const tcpPrinter = (
  ipAddress,
  port,
  printerEndSpaceStr,
  printerModel,
) => {
  ThermalPrinterPlugin.printFormattedTextAndCut(
    {
      type: "tcp",
      address: ipAddress,
      port: port,
      text: billPrintReceipt(printerEndSpaceStr, printerModel),
      id: "tcp-printer-001",
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      console.error("Printing error", error);
    },
  );
};

export const bluetoothPrinter = (
  blutoothDevice,
  printerEndSpaceStr,
  printerModel,
) => {
  ThermalPrinterPlugin.printFormattedText(
    {
      type: "bluetooth",
      id: blutoothDevice, // You can also use the identifier directly i. e. 00:11:22:33:44:55 (address) or name
      text: billPrintReceipt(printerEndSpaceStr, printerModel), // new lines with "\n"
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      console.error("Printing error", error);
    },
  );
};

export const usbPrinter = (usbDevice, printerEndSpaceStr, printerModel) => {
  // Permission granted - We can print!
  ThermalPrinterPlugin.printFormattedTextAndCut(
    {
      type: "usb",
      id: usbDevice, // Use an unique identifier for each printer i. e. address:port or name
      text: billPrintReceipt(printerEndSpaceStr, printerModel), // new lines with "\n"
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      alert(JSON.stringify(error));
    },
  );
};

export const orderPrintReceipt = (endSpace) => {
  let print = "";
  print += "[C]<font size='big'><b>ServoFeat</b></font>\n";
  print +=
    "[C]<font size='normal'>Opp. Harikrupa Society, Nikol Gam Road, Ahmedabad - 382350</font>\n\n";
  print += "[L]<font size='normal'><b>Name: </b>Yash Sonani</font>\n";
  print +=
    "[L]<font size='normal'><b>Date: </b>25/06/2022, 5:16:22 PM</font>\n";
  print += "[L]<font size='normal'><b>Bill Token: </b>1182 (Parcel)</font>\n";
  print += "[C]--------------------------------\n";
  print +=
    "[C]<font size='normal'><b>Items</b></font>[R]<font size='normal'><b>Qty</b></font>\n";
  print += "[C]<font size='normal'>--------------------------------</font>\n";
  print += "[L]<b>BEAUTIFUL SHIRT</b>[R]Rs 9.99\n";
  print += "[L]<b>AWESOME HAT</b>[R]Rs. 24.99\n";
  print += "[C]--------------------------------\n";
  print += "[R]SUB TOTAL :[R]Rs. 34.98\n";
  print += "[R]TAX :[R]Rs 4.23\n";
  print += "[R]DICSOUNT(%) :[R]Rs. 10.00\n";
  print += "[R]TOTAL :[R]Rs. 34.98\n";
  print += "[C]================================\n";
  print += "[C]<font size='normal'><b>THANK YOU VISIT AGAIN...</b></font>\n";
  print += endSpace;
  return print;
};

export const billPrintReceipt = (endSpace, printerModel) => {
  let print = "";
  if (printerModel?.shopName?.trim()) {
    print +=
      "[C]<font size='big'><b>" + printerModel.shopName + "</b></font>\n";
  }
  if (printerModel?.shopAddress?.trim()) {
    print +=
      "[C]<font size='normal'>" + printerModel.shopAddress + "</font>\n\n";
  }
  if (printerModel?.customerName?.trim()) {
    print +=
      "[L]<font size='normal'><b>Name: </b>" +
      printerModel.customerName +
      "</font>\n";
  }
  if (printerModel.date) {
    print +=
      "[L]<font size='normal'><b>Date: </b>" + printerModel.date + "</font>\n";
  }
  if (printerModel.tokenNo && !printerModel?.tokenNo?.includes("undefined")) {
    print +=
      "[L]<font size='normal'><b>Bill/Token No: </b>" +
      printerModel.tokenNo +
      "</font>\n";
  }
  print += "[C]--------------------------------\n";
  print +=
    "[C]<font size='normal'><b>Items</b></font>[R]<font size='normal'><b>Amt.</b></font>\n";
  print += "[C]<font size='normal'>--------------------------------</font>\n";

  printerModel?.printFoodItemArray.forEach((printFood) => {
    print +=
      "[L]<b>" +
      printFood.foodName +
      " (" +
      printFood.qty +
      ")</b>[R]Rs " +
      printFood.price +
      "\n";
  });
  // print += "[L]<b>AWESOME HAT</b>[R]Rs. 24.99\n";

  print += "[C]--------------------------------\n";
  print += "[R]SUB TOTAL :[R]Rs. " + printerModel.subTotal + "\n";
  if (printerModel?.tax?.trim()) {
    print += "[R]TAX :[R]Rs " + printerModel.tax + "\n";
  }
  if (printerModel.discount) {
    print += "[R]DISCOUNT(%) :[R]Rs. " + printerModel.discount + "\n";
  }
  print += "[R]TOTAL :[R]Rs. " + printerModel.netTotal + "\n";
  print += "[C]================================\n";
  print += "[C]<font size='normal'><b>THANK YOU VISIT AGAIN...</b></font>\n";
  console.log("endSpace", endSpace);
  print += endSpace;
  return print;
};

// Section for KOT print

export const kotPrintReceipt = (endSpace, printerModel) => {
  let print = "";
  if (printerModel?.customerName?.trim()) {
    print +=
      "[L]<font size='normal'><b>Name: </b>" +
      printerModel.customerName +
      "</font>\n";
  }
  if (printerModel.date) {
    print +=
      "[L]<font size='normal'><b>Date: </b>" + printerModel.date + "</font>\n";
  }
  if (printerModel.tokenNo && !printerModel?.tokenNo?.includes("undefined")) {
    print +=
      "[L]<font size='normal'><b>Bill/Token No: </b>" +
      printerModel.tokenNo +
      "</font>\n";
  }
  print += "[C]--------------------------------\n";
  print +=
    "[C]<font size='normal'><b>Items</b></font>[R]<font size='normal'><b>Qty</b></font>\n";
  print += "[C]<font size='normal'>--------------------------------</font>\n";

  printerModel?.printFoodItemArray.forEach((printFood) => {
    print +=
      "[L]" +
      printFood.foodName +
      "[R]" +
      printFood.qty +
      "\n";
  });

  print += "[C]--------------------------------\n";
  print += endSpace;
  return print;
};

export const usbPrinterForKOT = (usbDevice, printerEndSpaceStr, printerModel) => {
  // Permission granted - We can print!
  ThermalPrinterPlugin.printFormattedTextAndCut(
    {
      type: "usb",
      id: usbDevice, // Use an unique identifier for each printer i. e. address:port or name
      text: kotPrintReceipt(printerEndSpaceStr, printerModel), // new lines with "\n"
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      alert(JSON.stringify(error));
    },
  );
};

export const bluetoothPrinterForKOT = (
  blutoothDevice,
  printerEndSpaceStr,
  printerModel,
) => {
  ThermalPrinterPlugin.printFormattedText(
    {
      type: "bluetooth",
      id: blutoothDevice, // You can also use the identifier directly i. e. 00:11:22:33:44:55 (address) or name
      text: kotPrintReceipt(printerEndSpaceStr, printerModel), // new lines with "\n"
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      console.error("Printing error", error);
    },
  );
};

export const tcpPrinterForKOT = (
  ipAddress,
  port,
  printerEndSpaceStr,
  printerModel,
) => {
  ThermalPrinterPlugin.printFormattedTextAndCut(
    {
      type: "tcp",
      address: ipAddress,
      port: port,
      text: kotPrintReceipt(printerEndSpaceStr, printerModel),
      id: "tcp-printer-001",
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      console.error("Printing error", error);
    },
  );
};
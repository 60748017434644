import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  navBarLogo: {
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 1024px)": {
       margin:'auto',
       display:'block',
       paddingRight:'10px'
    },
  },
  navBarLogoM: {
    fontSize: "24px",
    marginLeft: "20%",
  },
  follow: {
    "@media (max-width: 1024px)": {
     marginLeft:'5%',
     textAlign:'center'
   },
  },
  call:{
    width:'100%'
  },
  contectNo: {
    marginTop:'20px',
    marginLeft:'10px'
  },
  navBarLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "8%",
    paddingRight: "8%",
    flexWrap: "wrap",
    "@media (max-width: 1024px)": {
      paddingLeft: "18%",
    },
  },
  address: {
    marginTop: "10px",
    fontWeight: 500,
    fontSize: "14px",
    color: "#8b8b8b",
    "@media (max-width: 604px)": {
      marginLeft:5,
      textAlign:'center'
    },
  },
  address2: {
    marginTop: "10px",
    fontWeight: 500,
    fontSize: "14px",
    color: "#8b8b8b",
    marginLeft: "20%",
  },
  socialMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "100%",
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingBottom: "2%",
  },
  icon: {
    marginRight: 5,
    marginLeft:'10px'
  },
  Contect: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "12%",
    marginTop: "2%",
    marginBottom: "2%",
    marginRight: "12%",
    backgroundColor: "#f9fafb",
    padding: "30px",
    borderRadius: "10px",
    flexWrap: "wrap",
    "@media (max-width: 1024px)": {
      marginRight: "4%",
      marginLeft: "4%",
    },
  },
  Links: {
    color: "#8b8b8b",
    textAlign: "center",
    lineHeight: 0.5,
  },
  LinksHeader: {
    color: "#555555",
    textAlign: "center",
  },
  number: {
    color: "#10b981",
  },
  copyRite: {
    color: "#10b981",
  },
  add: {
    width: "100%",
  },
}));

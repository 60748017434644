import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useInMobile } from "themes/Device";
import OrderReport from "./OrderReport";
import CustomerReport from "./CustomerReport";
import ExpenseReport from "./ExpenseReport";
import InventoryReport from "./InventoryReport";

const style = (theme) => ({
  TabName: {
    borderRadius: "0.75rem",
    fontWeight: "500",
    fontSize: "0.7rem",
    backgroundColor: "",
    marginTop: "10px",
    color: "white",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {},
  },
  mobileTab: {
    display: "flex",
    justifyContent: "space-between",
  },
  mobileCategoryList: {
    display: 'flex',
  },
  desktop_style: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const useStyles = makeStyles(style);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const ReportTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [windowWidth, setWindowWidth] = React.useState();
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobileView = useInMobile();

  const reSize = () => {
    setWindowWidth(window.innerWidth);
  };
  const [activeCategoryColor, setActiveCategoryColor] = useState("Sales");
  useEffect(() => {
    window.addEventListener("resize", reSize);
  });
  const getActiveCategoryColor = (activeCategoryColor, category) => {
    return activeCategoryColor === category
  }
  const onchangeFoodCategory = (selectedCategory) => {
    setActiveCategoryColor(selectedCategory);
  };
  return (
    <>
      <Container fluid>
        <Row style={{
          justifyContent: "center",
          marginBottom: 5,
          marginTop: isMobileView ? 15 : 0
        }}>
          <div className={classes.mobileCategoryList}>
            {["Sales", "Inventory", "Expense", "Customer"]
              .map((category, index) => {
                return (
                  (
                    <Card className="ml-1 mr-1 mb-1"
                      key={category}>
                      <MuiButton
                        className={`w-100`}
                        style={{
                          color: getActiveCategoryColor(activeCategoryColor, category) ? theme.palette.primary.contrastText : theme.palette.text.secondary,
                          padding: '15px',
                          fontSize: '15px',
                          backgroundColor: getActiveCategoryColor(activeCategoryColor, category) ? theme.palette.primary.main : ""
                        }}
                        variant="outlined"
                        onClick={() => onchangeFoodCategory(category)}
                      >
                        {category}
                      </MuiButton>
                    </Card>
                  )
                );
              })}
          </div>
        </Row>
        {activeCategoryColor == "Sales" ?
          <OrderReport /> :
          activeCategoryColor == "Inventory" ?
            <InventoryReport /> :
            activeCategoryColor == "Customer" ?
              <CustomerReport /> :
              activeCategoryColor == "Expense" ?
                <ExpenseReport /> :
                <></>
        }
      </Container>
    </>
  );
};

export default ReportTabs;

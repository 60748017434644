import React from "react";
import { makeStyles } from "@material-ui/styles";

import "./NotFoundComponent.styles.css";

const styles = {
  container: {
    margin: "5vh 0px",
    width: "100%",
    padding: "10px",
  },
  img: {
    maxWidth: 300,
    objectFit: "contain",
    textAlign: "center",
  },
  title: {
    margin: "5px 0px",
  },
  centerPropery: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop:"1rem"
  },
};

const useStyles = makeStyles(styles);

const NotFoundComponent = (props) => {
  const classes = useStyles();
  const { imgUrl, children } = props;
  return (
    <div className={classes.centerPropery}>
      <img className={classes.img} src={imgUrl} alt="" />
      <div className={classes.title}>{children}</div>
    </div>
  );
};

export default NotFoundComponent;

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CustomerList from "pages/AdditionalSetting/Customers/CustomerList";
import AddEditCustomer from "pages/AdditionalSetting/Customers/AddEditCustomer";
// import ReportExport from "pages/Report/ReportExport";
import CustomerQueueList from "pages/CustomerQueue/CustomerQueueList";
import AddEditCustomerQueue from "pages/CustomerQueue/AddEditCustomerQueue";
import SettingList from "pages/Setting/SettingList";
import AddEditPrinter from "pages/AdditionalSetting/Printer/AddEditPrinter";
import StaffList from "pages/Staffs/StaffList";
import AddEditStaff from "pages/Staffs/AddEditStaff";
import DiningTableList from "pages/DiningTable/DiningTableList";
import AddEditDiningTable from "pages/DiningTable/AddEditDiningTable";
import PriorityDiningTable from "pages/DiningTable/PriorityDiningTable";
import FoodCategoryList from "pages/FoodCategory/FoodCategoryList";
import AddEditFoodCategory from "pages/FoodCategory/AddEditFoodCategory";

import ExpenseCategoryList from "pages/ExpenseCategory/ExpenseCategoryList";
import AddEditExpenseCategory from "pages/ExpenseCategory/AddEditExpenseCategory";

import InventoryCategoryList from "pages/InventoryCategory/InventoryCategoryList";
import AddEditInventoryCategory from "pages/InventoryCategory/AddEditInventoryCategory";

import ExpenseList from "pages/Expense/ExpenseList";
import AddEditExpense from "pages/Expense/AddEditExpense";

import FoodMenuList from "pages/FoodMenu/FoodMenuList";
import AddEditFoodMenu from "pages/FoodMenu/AddEditFoodMenu";
import KitchenQueue from "pages/Kitchen";

// import Dashboard from "pages/dashboard/Dashboard";
import OrderHistory from "pages/Orders/OrderHistory";
import ReportTabs from "pages/Report/ReportTabs";
import InventoryList from "pages/Inventory/InventoryList";
import { RESOURCE } from "common/constant";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { ACTION } from "common/constant";
import NotAutherize from "components/NotAutherize";
import RoleList from "pages/Role/RoleList";
import AddEditRole from "pages/Role/AddEditRole";
import UserAccount from "pages/UserProfile/UserAccount";
import AddEditInventory from "pages/Inventory/AddEditInventory";
import MobilePrinter from "pages/MobilePrinter/MobilePrinter";
import PricingComp from "pages/dashboard/PricingComp";
import CustomerHistory from "pages/AdditionalSetting/Customers/CustomerHistory";

const DineInSection = React.lazy(() => import("pages/Orders/DineInSection"));
const OrderDetails = React.lazy(() => import("pages/Orders/OrderDetails"));

const Routes = () => {
  const AuthorizedRoutes = (props) => {
    const { resourceName, component } = props;

    return (
      <RoleBasedGuard
        resource={resourceName}
        action={ACTION.VIEW}
        componentToRender={<NotAutherize />}
      >
        {React.createElement(component)}
      </RoleBasedGuard>
    );
  };

  return (
    <React.Suspense fallback={<h2>loading..</h2>}>
      <Switch>
        {/* <Route exact path="/" render={() => <Redirect to="/" />} /> */}
        {/* <Route path="/app/dashboard" component={Dashboard} exact />
        <Route path="/app/pricing" component={PricingComp} exact />

        <Route exact path="/app/dine-in" component={DineInSection} />
        <Route
          exact
          path="/app/dine-in/:tableName/new"
          component={OrderDetails}
        />
        <Route exact path="/app/dine-in/order/:id" component={OrderDetails} />

        <Route exact path="/app/order/new" component={OrderDetails} />
        <Route exact path="/app/order/dine-in/:id" component={OrderDetails} />
        <Route exact path="/app/order/parcel/:id" component={OrderDetails} />
        <Route exact path="/app/order/:orderId" component={OrderDetails} />

        <Route path="/app/history/order" component={OrderHistory} />

        <AuthorizedRoutes
          resourceName={RESOURCE.DINE_IN_TABLE}
          path="/app/dining-table"
          component={DiningTableList}
          exact
        />

        <Route
          path="/app/dining-table/add"
          component={AddEditDiningTable}
          exact
        />
        <Route
          path="/app/dining-table/edit/:id"
          component={AddEditDiningTable}
          exact
        />
        <Route
          path="/app/dining-table/priority"
          component={PriorityDiningTable}
          exact
        />
        <AuthorizedRoutes
          resourceName={RESOURCE.STAFF_MEMBERS}
          path="/app/staff"
          component={StaffList}
          exact
        />

        <Route path="/app/staff/add" component={AddEditStaff} exact />
        <Route path="/app/staff/edit/:id" component={AddEditStaff} exact />

        <AuthorizedRoutes
          path="/app/food-category"
          resourceName={RESOURCE.FOOD_CATEGORY}
          component={FoodCategoryList}
          exact
        />
        <Route
          path="/app/food-category/add"
          component={AddEditFoodCategory}
          exact
        />
        <Route
          path="/app/food-category/edit/:id"
          component={AddEditFoodCategory}
          exact
        />

        <AuthorizedRoutes
          path="/app/food-menu"
          resourceName={RESOURCE.FOOD_ITEMS}
          component={FoodMenuList}
          exact
        />

        <Route path="/app/food-menu/add" component={AddEditFoodMenu} exact />
        <Route
          path="/app/food-menu/edit/:id"
          component={AddEditFoodMenu}
          exact
        />
        <Route path="/app/report" component={ReportTabs} exact />
        <AuthorizedRoutes
          path="/app/customers"
          resourceName={RESOURCE.CUSTOMERS}
          component={CustomerList}
          exact
        />
        <AuthorizedRoutes
          path="/app/customers/history/:id"
          resourceName={RESOURCE.CUSTOMER_HISTORY}
          component={CustomerHistory}
          exact
        />

        <Route path="/app/roles" component={RoleList} exact></Route>
        <Route path="/app/roles/add" component={AddEditRole} exact></Route>
        <Route path="/app/roles/edit/:id" component={AddEditRole} exact></Route>
        <Route
          path="/app/mobile-printer"
          component={MobilePrinter}
          exact
        ></Route>

        <Route path="/app/customer/add" component={AddEditCustomer} exact />
        <Route
          path="/app/customer/edit/:customerId"
          component={AddEditCustomer}
          exact
        />
        <Route path="/app/printer" component={AddEditPrinter} exact />
        <Route path="/app/mail" component={CustomerList} exact />
        <Route path="/app/sms" component={CustomerList} exact />
        <Route path="/app/profile" component={UserAccount} exact />
        <Route path="/app/setting" component={SettingList} exact />
        <Route
          exact
          path="/app/ui"
          render={() => <Redirect to="/app/ui/icons" />}
        />
        <Route path="/app/kitchen" component={KitchenQueue} />

        <AuthorizedRoutes
          resourceName={RESOURCE.WAITING_QUEUE}
          path="/app/customer-queue"
          component={CustomerQueueList}
          exact
        />

        <Route
          path="/app/customer-queue/add"
          component={AddEditCustomerQueue}
          exact
        />
        <Route
          path="/app/customer-queue/edit/:id"
          component={AddEditCustomerQueue}
          exact
        />

        <AuthorizedRoutes
          path="/app/expense-category"
          resourceName={RESOURCE.DASHBOARD}
          component={ExpenseCategoryList}
          exact
        />
        <Route
          path="/app/expense-category/add"
          component={AddEditExpenseCategory}
          exact
        />
        <Route
          path="/app/expense-category/edit/:id"
          component={AddEditExpenseCategory}
          exact
        />

        <AuthorizedRoutes
          path="/app/inventory-category"
          resourceName={RESOURCE.DASHBOARD}
          component={InventoryCategoryList}
          exact
        />
        <Route
          path="/app/inventory-category/add"
          component={AddEditInventoryCategory}
          exact
        />
        <Route
          path="/app/inventory-category/edit/:id"
          component={AddEditInventoryCategory}
          exact
        />
        <Route path="/app/inventory" component={InventoryList} exact />
        <Route path="/app/inventory/add" component={AddEditInventory} exact />
        <Route
          path="/app/inventory/edit/:id"
          component={AddEditInventory}
          exact
        />

        <Route path="/app/expense" component={ExpenseList} exact />
        <Route path="/app/expense/add" component={AddEditExpense} exact />
        <Route path="/app/expense/edit/:id" component={AddEditExpense} exact />

        <Route
          path="/app/*"
          render={() => {
            return <Redirect to="/app/order/new" />;
          }}
        /> */}
      </Switch>
    </React.Suspense>
  );
};

export default Routes;

import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    maxWidth: "100vw",
    overflow: "hidden",
    height: '100%'
  },
  content: {
    // flexGrow: 1,
    // padding: 12,
    // paddingTop: 30,
    // width: `calc(100vw - 240px)`,
    maxHeight: `calc(100% - 61px)`,
    height: '100%',
    width: '100%',
    position: 'absolute',
    padding: '6px',
    overflow: 'auto',
    top: '56px'
  },
  onlineContent: {
    maxHeight: `calc(100% - 61px)`,
    height: '100%',
    width: '100%',
    position: 'absolute',
    padding: '6px',
    overflow: 'auto',
    top: '85px'
  },

  onlineContentFooter: {
    padding: "10px 10px",
    margin: "auto",
    width: "100%",
    backgroundColor:'#fff',
    marginTop:'50px'
  },

  contentShift: {
    // width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
    minHeight: '30px!important'
  },
  link: {
    '&:not(:first-child)': {
      paddingLeft: 15
    }
  }
}));

import { SET_USER_INFO, SET_USER_PERMISSION } from "./action.types";

export const SetUserInfo = (payload) => ({
  type: SET_USER_INFO,
  payload,
});

export const SetUserPermission = (payload) => ({
  type: SET_USER_PERMISSION,
  payload,
});
import moment from "moment";

export const signOut = (props) => {
  localStorage.removeItem("token");
  localStorage.removeItem("billing");
  localStorage.removeItem("access");
  localStorage.clear();
  window.location.href = "/login";
  // props?.history?.push("/login");
};

export const  getSubdomain = (hostname) => {
  try {
    if(hostname === 'localhost')
    {
     return "sachin" 
    }
    
    var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    var urlParts = regexParse.exec(hostname);
    return hostname.replace(urlParts[0],'')?.slice(0, -1);
  }
  catch (error)
  {
    console.log('%chelpers.js line:25 error', 'color: #007acc;', error);
    return  "sachin"
  }
 
}


export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const getGroupBy = (inputArray, key) => {
  return inputArray.reduce(function (a, e) {
    // GROUP BY estimated key (estKey), well, may be a just plain key
    // a -- Accumulator result object
    // e -- sequentally checked Element, the Element that is tested just at this itaration

    // new grouping name may be calculated, but must be based on real value of real field
    let estKey = e[key];

    (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
    return a;
  }, {});
};

export const getShopSettingFromKey = (shopSettingList, customKey) => {
  return (
    shopSettingList?.length > 0 &&
    shopSettingList.find(({ key, value }) => key === customKey)
  );
};

export const setShopSettingInLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getShopSettingFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const handleImageLoadingError = (currentTarget, newSrc) => {
  currentTarget.onerror = null;
  currentTarget.src = newSrc;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
};

export const getTimeDifference = (createdDate) => {
  const createDate = moment(createdDate);
  const currentDate = moment();

  const milliseconds = currentDate.diff(createDate);

  let s = Math.floor((milliseconds / 1000) % 60);
  let m = Math.floor((milliseconds / 1000 / 60) % 60);
  let h = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;

  return { hour: h, minute: m, second: s };
};


export const validEmail = new RegExp(
  '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
);
export const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');
export const validName = new RegExp("^[a-zA-Z0-9? ,_-]*$");
export const validUserName = new RegExp("^[a-zA-Z0-9]*$");
export const checkSpaceAndRegex = (name) => {
  if (name?.trim() == '') {
    return "Empty name is not allowed.";
  }
  else if (!validName.test(name)) {
    return "Special character is not allowed";
  }
  return '';
}

export const checkUserName = (name) => {
  if (name?.trim() == '') {
    return "Empty name is not allowed.";
  }
  else if (!validUserName.test(name)) {
    return "Special character or space is not allowed";
  }
  return '';
}

export const ServiceEnabled = ({ envVariableList, serviceName, children }) => {
  if (!envVariableList?.[serviceName]) {
    return ""
  }
  return children;
}

export const AppendCustomCss = (css) => {
  const head = document.head || document.getElementsByTagName('head')[0];
  let style = document.createElement('style');


  style.type = 'text/css';
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
  head.appendChild(style);

}

// here offset will be +5:30 or -5:30
export const offSetToLocalDate = (offSet) => {
  var d = new Date(new Date().toUTCString());
  //* for test add 1day next
  var x = new Date(
    d.valueOf() +
    3600000 * offSet,
  );
  console.log("Local date from utils date");
  console.log(x);
  return x;
}

export const checkIfElectronLoaded = () => {
  try {
    if (window.require("electron")) {
      return true
    }
    return false
  } catch (error) {
    return false;
  }
}

export const textTruncate = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};
import { api } from "./../utility/interceptor";
import configData from "../config.js";

var config = {
  headers: { "Access-Control-Allow-Origin": "*" },
  // crossDomain: true,
  // Accept: 'application/json',
};

//  Payment Methods

export const initPayment = async (orderDetails) => {
  let res = await api
    .post(`/payment/init-order`, orderDetails)
    .catch((error) => {
      return error;
    });
  return res;
};

export const verifyPayment = async (data) => {
  let res = await api.post(`/payment/verify`, data).catch((error) => {
    return error;
  });
  return res;
};

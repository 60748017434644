import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  img: {
    height: "50px",
    width: "50px",
  },
  container: {
    height: "100%",
    maxHeight: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",

    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },

  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  passwordSpace: {
    marginTop: 10,
  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },

  leftWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    maxWidth: 464,
    position: "relative",
    zIndex: 0,
    padding: "15% 0px",
    color: "#212b36",
    borderRadius: "16px",
    boxShadow: "#919eab33 0px 0px 2px 0px, #919eab1f 0px 12px 24px -4px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    height: "750px",
    backgroundColor: "white",
  },

  heading: {
    fontWeight: 700,
    fontSize: "1.9rem",
    lineHeight: 1.5,
    padding: "5px 10px",
    margin: "15px 0px 20px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  subHeading: {
    color: "#637381",
    fontWeight: 400,
    marginTOp: 15,
  },
  loginImg: {
    width: "100%",
    display: "block",
    objectFit: "contain",
  },
  loginContainer: {
    maxWidth: 400,
    maxHeight: "100%",
    padding: theme.spacing(10, 0),
    position: "relative",
  },
  // cake:{
  //   position:'absolute',
  //   width:"6rem",
  //   top:"20px",
  //   left:"30rem",
  //   },
  //   slice:{
  //     position:'absolute',
  //     width:"5rem",
  //     bottom: "40px",
  //     left: "34rem",
  //   },
  //   pizza:{
  //     position:'absolute',
  //     width:"5rem",
  //     top:"6rem",
  //     right: "7rem",
  //   },
  //   coffee:{
  //     position:"absolute",
  //     width:"6rem",
  //     bottom: "50px",
  //     right: "5rem",

  //   },
  muiBtn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    outline: "0",
    border: "0",
    margin: "0",
    borderRadius: "8px",
    padding: "8px 22px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    MozAppearance: "none",
    WebkitAppearance: "none",
    textDecoration: "none",
    color: "#fff",
    fontWeight: "700",
    lineHeight: "1.71",
    fontSize: "0.9375rem",
    textTransform: "capitalize",
    minWidth: "64px",
    width: "100%",
    height: "38px",
  },
  outlineBtn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    WebkitTapHighlightColor: "transparent",
    backgroundColor: "transparent",
    outline: "0px",
    border: "0px",
    margin: "5px 0px 0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontWeight: "700",
    lineHeight: "1.71429",
    fontSize: "0.9375rem",
    textTransform: "capitalize",
    minWidth: "64px",
    padding: "8px 11px",
    borderRadius: "8px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    color: "",
    width: "100%",
    height: "48px",
  },
  linkText: {
    margin: 0,
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    textDecoration: "none",
    color: "",
    cursor: "pointer",
  },
}));

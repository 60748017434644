import { api } from "./../utility/interceptor";
import configData from "../config.js";

var config = {
  headers: { "Access-Control-Allow-Origin": "*" },
};

export const GenerateOnlineOrder = async (id, foodOrderData) => {
  let res = await api
    .post(`/public/customer/${id}`, foodOrderData)
    .catch((error) => {
      return error;
    });
  return res;
};

export const RefereshToken = async (data) => {
  let res = await api.post('/auth/referesh_token', data).catch((err) => {
    return err
  });
  return res.data;
}
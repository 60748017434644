// @mui
import {
  Grid, Container, Card as MUICARD,
  CardHeader, CardContent
} from '@mui/material';
// hooks
import {
  _analyticPost,
  _analyticOrderTimeline,
  _analyticTraffic
} from './../../_mock';
import {
  AnalyticsOrderTimeline,
} from './../../sections/@dashboard/general/analytics';
import { useParams } from 'react-router-dom';
import { useOrderTracking } from 'hooks/react-query/useOrder';
import { Card, Row, Col } from 'react-bootstrap';
import { Typography } from '@material-ui/core';
import PageLoader from 'components/Loader/PageLoader';

export default function TrackingOrder() {
  const param = useParams()
  const { data, isLoading } = useOrderTracking(`${param?.username}@${param?.orderId}`);

  return (
    <Container>
      {!isLoading ?
        <>
          <Grid xs={12} md={6}
            mt={2}
            lg={4}>
            <MUICARD>
              <CardHeader title={"Your Order Item's"} subheader={""} />
              <CardContent>
                <Row style={{
                  padding: 10
                }}>
                  <Col lg={12}>
                    <Row>
                      {
                        data?.order &&
                        data?.order?.order_meal_items?.map((item, index) => {
                          return ((
                            <Col
                              lg={4}
                              key={index}
                              style={{
                                padding: 0,
                              }}
                            >
                              <Card
                                style={{
                                  margin: 4,
                                  padding: 10,
                                }}>
                                <div style={{ textAlign: "center" }}>
                                  <div
                                    className={`${"food-item-name-box"}`}
                                  >
                                    <div className="sf-foodmenu-name">
                                      {item?.fooditem?.name}
                                    </div>
                                    <span className="sf-category-name">
                                      {item?.fooditem?.category?.name}
                                    </span>
                                  </div>
                                </div>
                                <div className="px-2">
                                  <Typography
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 600,
                                      textAlign: "center",
                                    }}
                                  >
                                    {item?.qty} * ₹ {item?.price}{" "}={parseFloat(item?.qty) * parseFloat(item?.price)}
                                  </Typography>
                                </div>
                              </Card>
                            </Col>
                          )
                          );
                        })
                      }
                    </Row>
                  </Col>
                </Row>
                <Typography
                  style={{
                    fontSize: 24,
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Total order Bill: {data?.order?.total_bill}
                </Typography>
              </CardContent>
            </MUICARD>

          </Grid>
          <Grid xs={12} md={6}
            mt={2}
            lg={4}>
            <AnalyticsOrderTimeline title="Your Order Timeline" list={data?.step} />
          </Grid>
        </>
        :
        <PageLoader />
      }

    </Container>
  );
}

//   <Mobilefoodmenucard
                //     foodName={item?.fooditem?.name}
                //     category={item?.fooditem?.category?.name}
                //     price={"₹" + parseFloat(item?.fooditem?.price).toFixed(2)}
                //     description={item?.instruction_note || "-"}
                //     active={item.is_active}
                //     is_non_veg={item?.is_non_veg}
                //     itemId={item?.id}
                //   />
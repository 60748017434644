import axios from "axios";
import { signOut } from "./helpers";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";

console.log(process.env.REACT_APP_SERVER_URL)
const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  // responseType: 'json',
  // headers: {
  //   "Content-type": "application/json"
  // }
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token !== "undefined") {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      toast.error("You are not authorized");
      return Promise.reject("You are not authorized");
    }

    if (response?.data?.status === 404) {
      if(response?.data?.message)
      {
        toast.error(response?.data?.message);
      }
      if(response?.data?.data?.upgrade)
      {
        Swal.fire({
          title: "Plan limit reached",
          text: "Please upgrade your plan or contact to our support on +91 977-342-6554",
          icon: "info",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Okay!'
        })
      }
        return response;
    }
    return response;
  },
  (error) => {
    console.log(error)
    if (error && error?.response && (error?.response?.status === 403 || error.response.status === 401)) // unauthorized
    {
      signOut();
    }
    if (error?.response && error?.response?.status === 500) // internal server error
    {
      toast.error(error?.response?.data?.message);
      return Promise.reject(error?.response?.data?.message);
    }
    if (error?.response && error?.response?.status === 404) // method or api not found
    {
      if(error?.response?.data.error)
      {
        toast.error(error?.response?.data?.error);
        return Promise.reject(error?.response?.data.error);
      } else if(error?.response?.data?.message)
      {
        toast.error(error?.response?.data?.message);
        return Promise.reject(error?.response?.data?.message);
      }
    }
    return Promise.reject(error);
  }
);

export const api = instance;

import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";

// API Calls
import { updateStoreSetting } from "services/setting.service";
import { fetchShopSettingInfo } from "state/thunk-async";
import EditSettingPopup from "./EditSettingPopup";
import { setShopSettingInLocalStorage } from "utility/helpers";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import { useInMobile } from "themes/Device";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
  },
};

const useStyles = makeStyles(styles);

const SettingList = () => {
  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const isMobile = useInMobile();
  const isLoading = useSelector(
    (state) => state.shopSettingReducer.shopSettingLoading,
  );
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleToggleChange = (value, rowdata) => {
    setIsUpdating(true);
    let setingupdateBody = {
      value: value.toString(),
      name: rowdata.name,
      key: rowdata.key,
      type: rowdata.type,
    };
    updateStoreSetting(setingupdateBody, rowdata.id)
      .then((res) => {
        setIsUpdating(false);
        dispatch(fetchShopSettingInfo());
        // window.location.reload();
      })
      .catch((err) => {
        setIsUpdating(false);
        console.log(err);
      });
  };

  useEffect(() => {
    Object.keys(shopSettingListData.data).forEach((element) => {
      console.log("element", element);
      setShopSettingInLocalStorage(element?.key, element?.value);
    });
  }, [shopSettingListData]);

  return (
    <>
      {shopSettingListData && shopSettingListData.length > 0 ? (
        <>
          <MaterialTable
            style={{ padding: "20px" }}
            title="Shop Setting"
            columns={[
              {
                title: "Name",
                field: "name",
                cellStyle: {
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  width: "40%",
                  textAlign: "left",
                },
                headerStyle: {
                  width: "40%",
                  textAlign: "left",
                },
              },
              {
                title: "Value",
                field: "value",
                cellStyle: {
                  width: "30%",
                  textAlign: "right",
                },
                headerStyle: {
                  width: "30%",
                  textAlign: "right",
                },
                render: (rowData) => (
                  <div className="d-flex align-item-center justify-content-end">
                    <div>{rowData?.value}</div>
                    {isMobile && (
                      <EditSettingPopup
                        fieldData={rowData}
                        isUpdating={isUpdating}
                        handleToggleChange={handleToggleChange}
                      />
                    )}
                  </div>
                ),
              },
              {
                title: "Last Updated Date",
                width: "10%",
                cellStyle: {
                  width: "10%",
                  display: isMobile ? "none" : "",
                },
                headerStyle: {
                  display: isMobile ? "none" : "",
                },
                field: "updated_date",
                render: (rowData) =>
                  moment(rowData.updated_date).format("DD/MM/YYYY HH:MM"),
              },
              {
                title: "Edit",
                field: "action",
                cellStyle: {
                  width: "10%",
                  display: isMobile ? "none" : "",
                },
                headerStyle: {
                  display: isMobile ? "none" : "",
                },
                render: (rowData) => (
                  <EditSettingPopup
                    fieldData={rowData}
                    isUpdating={isUpdating}
                    handleToggleChange={handleToggleChange}
                  />
                ),
              },
            ]}
            data={
              shopSettingListData &&
              shopSettingListData
                ?.filter((setting) => !setting?.is_admin)
                .map((item, index) => {
                  return {
                    name: item?.name,
                    key: item?.key,
                    updated_date: item.updated_date,
                    value: item.value,
                    type: item.type,
                    id: item.id,
                  };
                })
            }
            options={{
              rowStyle: (rowData, index) => {
                if (index % 2) {
                  return { backgroundColor: "#f2f2f2" };
                }
              },
              paging: false,
              pageSize: 50,
              emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
              pageSizeOptions: [50, 100, 200],
              sorting: true,
              actionsColumnIndex: -1,
            }}
          />
        </>
      ) : isLoading ? (
        <SkeletonLoader />
      ) : (
        <NotFoundComponent imgUrl={noFoundImg}>
          <p style={{ fontWeight: 600 }}>
            No Data available Please Contact admin !
          </p>
        </NotFoundComponent>
      )}
    </>
  );
};

export default SettingList;

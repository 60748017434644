import React, { useEffect, useState, Children } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-hot-toast";
import { useDropzone } from "react-dropzone";
import {
  Button,
  FormControl,
  IconButton,
  Checkbox,
  InputLabel,
  Stepper,
  Step,
  StepButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
} from "@material-ui/core";
import {
  useInventory,
  useDeleteInventoryMutation,
} from "hooks/react-query/useInventory";
import Select, { components } from "react-select";
import {
  Save as SaveIcon,
  BackupOutlined as FileUploadIcon,
  Add as AddIcon,
  NavigateNext as NextIcon,
  Delete as DeleteIcon,
  ArrowBackIos,
} from "@material-ui/icons";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import clsx from "clsx";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import {
  createFoodMenu,
  getFoodMenuById,
  updateFoodMenu,
} from "services/foodItemMenu.service";
import { fetchFoodMealList } from "state/thunk-async";
import configData from "../../config.js";
import TextInputMui from "components/TextInputMui";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import {
  checkSpaceAndRegex,
  checkSpaceandregex,
  getShopSettingFromKey,
  validName,
} from "../../utility/helpers";
import "../../assets/css/foodcategory.css";
import DropZoneField from "components/DropZoneFileUpload/index.js";
import { useInMobile } from "themes/Device";
import { uploadFile } from "services/upload-files.service.js";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
    height: "100%",
  },
  formcontrolwidth: {
    width: "100%",
    marginBottom: 10,
    "& > span": {
      maxWidth: 240,
      fontSize: 12,
    },
  },
  formcontrolwidth2: {
    width: "100%",
    marginBottom: 10,
    marginTop: 10,
    maxWidth: 240,
    "& > span": {
      maxWidth: 240,
      fontSize: 12,
    },
  },
  customCheckbox: {
    display: "flex",
    alignItems: "center",
    "& span": {
      padding: "0",
      margin: "0",
    },
    "& label": {
      padding: "0",
      margin: "0",
      marginLeft: "5px",
      fontSize: 12,
    },
  },
  selectBoxControl: {
    width: "100%",
    "& .basic-single": {
      "& .select__control": {
        height: "38px !important",
        "& .select__value-container": {
          minHeight: "38px !important",
          "& .select__placeholder": {
            fontSize: "12px",
            padding: 0,
            margin: 0,
          },
        },
      },
    },
  },
  textField: {
    width: 200,
  },
  button: {
    background: "",
    color: "#fff",
    margin: 5,
    height: 30,
  },
  backButton: {
    background: "",
    margin: 5,
    height: 30,
  },
  chooseFile: {
    minHeight: "100%",
    height: "100%",
    width: "100%",
    display: "flex",
    padding: "20px 10px",
    border: "2px dashed #eaeaea",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "& p": {
      marginBottom: "0",
      fontSize: "12px",
      textAlign: "center",
    },
  },
  previewContainer: {
    borderRadius: 5,
    border: "2px solid #eaeaea",
    width: "100%",
    height: "100%",
    minHeight: "100%",
    padding: 4,
    boxSizing: "border-box",
  },
  image: {
    objectFit: "cover",
    width: "100%",
  },
  tableContainer: {
    maxHeight: 800,
  },
  table: {
    // border: "1px solid pink",
    "& thead": {
      height: "40px",
      "& tr": {
        "& th": {
          fontSize: "14px",
        },
      },
    },
    "& thead tr": {
      height: "40px",
      padding: "0 10px",
    },
    "& thead tr th": {
      padding: "0 5px",
      height: "40px",
    },
    "& tbody tr": {
      padding: "0 5px",
      height: "40px",
    },
    "& tbody tr td": {
      padding: "0 5px",
      height: "40px",
    },
  },
  StepperMain: {
    padding: 0,
    "& .MuiStep-horizontal": {
      padding: 0,
      "& button": {
        margin: 0,
        padding: 0,
        marginRight: 8,
        "& .MuiStepLabel-horizontal": {
          "& .MuiStepLabel-iconContainer": {
            "& .MuiStepIcon-root.MuiStepIcon-active": {
              // color: "rgb(210, 90, 198)",
            },
          },
        },
      },
    },
  },
  catTextBox: {
    display: "flex",
    alignItems: "center",
    "& h4, p": {
      margin: 0,
    },
    "& p": {
      margin: 0,
      marginLeft: 5,
    },
  },
  centerText: { display: "flex", justifyContent: "center", marginTop: "2px" },
  foodCatfixFooter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "0 15px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      display: "flex",
      position: "relative",
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
    },
  },
  nextButton: {
    margin: "0 20px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
});

const useStyles = makeStyles(styles);

export const CustomValueContainer = (props) => {
  const { selectProps, children } = props;
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props}>
        {selectProps.placeholder}
      </components.Placeholder>
      {Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? child : null,
      )}
    </components.ValueContainer>
  );
};

const component = {
  ValueContainer: CustomValueContainer,
  ClearIndicator: null,
  IndicatorSeparator: null,
};

const initialFoodVariantData = {
  id: 0,
  is_active: true,
  is_default: true,
  name: "",
  price: "",
};

const addFoodVariant = {
  ...initialFoodVariantData,
  is_default: false,
};

const initialFoodAddOnsData = {
  id: 0,
  is_active: true,
  is_non_veg: false,
  name: "",
  price: "",
};

const AddEditFoodMenu = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isMobileView = useInMobile();
  const [foodMenuId, setFoodMenuId] = useState(
    props?.match?.params?.id ?? props?.foodMenuId,
  );
  const foodCategoryDataList = useSelector((state) => state.api.category);
  const [initialFoodMenuData, setInitialFoodMenuData] = useState({
    food_variants: [],
    food_addons: [],
    food_inventory: [],
  });
  const [msg, setMsg] = useState(true);
  const [msg2, setMsg2] = useState(true);
  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const isNew =
    props?.match?.params?.id > 0 || props?.foodMenuId > 0 ? false : true;
  const { drawerClose } = props;
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [imgSrc, setImageSrc] = useState(null);
  const [uploadFileData, setUploadFileData] = useState(null);
  const dispatch = useDispatch();
  const param = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const layoutDispatch = useLayoutDispatch();
  const { data: inventoryDataList, loading: isLoading } = useInventory();
  console.log(inventoryDataList);
  const [selectedInventory, setSelectedInventory] = useState();
  const { t } = useTranslation();
  const steps = [t("menu"), t("add-ons"), t("inventory")];
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [],
      "image/jpg": [],
      "image/jpeg": [],
    },
    maxFiles: 1,
    maxSize: 1000000,
    onDrop: (acceptedFiles) => {
      setImageSrc(URL.createObjectURL(acceptedFiles[0]));
      setUploadFileData(acceptedFiles[0]);
    },
  });

  useEffect(() => {
    if (param && isMobileView) {
      let screenTitle = "";
      if (param.id > 0) {
        screenTitle = t("food-menu-edit");
      } else {
        screenTitle = t("food-menu-add");
      }

      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: screenTitle,
      });
      return () => {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      };
    }
  }, []);

  useEffect(() => {
    if (foodMenuId > 0) {
      setFetching(true);
      getFoodMenuById(foodMenuId)
        .then((res) => {
          let { food_item_addons, ...formInitialData } = res.data;

          if (food_item_addons && food_item_addons.length > 0) {
            formInitialData.food_addons = food_item_addons;
          } else {
            formInitialData.food_addons = [];
          }
          if (
            formInitialData.food_variants &&
            formInitialData.food_variants.length <= 0
          ) {
            formInitialData.food_variants = [];
          }
          if (
            formInitialData.food_inventory &&
            formInitialData.food_inventory.length <= 0
          ) {
            formInitialData.food_inventory = [];
          }
          setInitialFoodMenuData({
            ...formInitialData,
          });
          if (res?.data?.food_image) {
            setImageSrc(`${configData.S3_BUCKET}${res.data.food_image}`);
          } else {
            setImageSrc(null);
          }
          setFetching(false);
        })
        .catch(() => {
          setFetching(false);
        });
    }
  }, [foodMenuId]);

  const isVegStore = shopSettingListData.IS_SHOP_VEGETARIAN;

  const redirectToMainPage = () => {
    if (isMobileView) {
      history.location?.state?.backToMain();
    } else {
      drawerClose();
    }
  };

  const onSubmit = async (values) => {
    setSubmittingBtnProcess(true);
    if (uploadFileData) {
      const formData = new FormData();
      formData.append("file", uploadFileData);
      const { data } = await uploadFile(formData);
      values.food_image = data?.data?.key;
    }

    //Pass random string for avoid validation error
    if (isNew) {
      const result = await createFoodMenu(values);
      dispatch(fetchFoodMealList());
      if (result.status === 201 || result?.data?.status === 200) {
        setSubmittingBtnProcess(false);
        toast.success(result.data.message);
        redirectToMainPage();
      } else if (result.status === 400) {
        setSubmittingBtnProcess(false);
        toast.error(result?.message);
      }
    } else {
      const result = await updateFoodMenu(foodMenuId, values);
      dispatch(fetchFoodMealList());

      if (result.status === 200 || result?.data?.status === 200) {
        setSubmittingBtnProcess(false);
        toast.success(result.data.message);
        redirectToMainPage();
      } else if (result.status === 400) {
        setSubmittingBtnProcess(false);
        toast.error(result?.message);
      }
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  console.log(
    "food_inventory",
    inventoryDataList?.filter(function (array_el) {
      return (
        initialFoodMenuData?.food_inventory?.filter(function (anotherOne_el) {
          return anotherOne_el?.inventory_id == array_el?.id;
        })?.length == 0
      );
    }),
  );
  return (
    <Card className="foodcat-card">
      <CardBody>
        {foodMenuId > 0 && fetching ? (
          <>
            <SkeletonLoader count={5} height={50} />
            <SkeletonLoader count={1} height={70} />
          </>
        ) : (
          <Form
            onSubmit={onSubmit}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={initialFoodMenuData}
            validate={(values) => {
              const errors = {};
              // const checkerror = checkSpaceAndRegex(values?.name);
              // if (checkerror != "") errors.name = checkerror;
              if (!values.name?.trim()) {
                errors.name = t("food-menu-name-validation-message");
              } else if (values?.name?.length > 50) {
                errors.name = t("maximumlength-validation-message");
              }
              if (!values.category_id) {
                errors.category_id = t("food-menu-category-validation-message");
              }
              if (!values.price) {
                errors.price = t("food-category-price-validation-message");
              } else if (isNaN(values.price)) {
                errors.price = t("price-validation-message");
              } else if (parseInt(values.price) <= 0) {
                errors.price = t("price-minimum-validation-message");
              }

              if (values?.description && values?.description?.length > 300) {
                errors.description = t("maximum-length-validation-message");
              }
              return errors;
            }}
            render={({
              handleSubmit,
              form: {
                mutators: { push, pop },
                change,
                reset,
                restart,
              },
              submitting,
              pristine,
              values,
              errors,
            }) => (
              <form
                onSubmit={(event) => {
                  handleSubmit(event).then(() => {
                    reset();
                    restart();
                  });
                }}
              >
                <div className="foodcatstep">
                  <div>
                    <Stepper
                      className={classes.StepperMain}
                      nonLinear
                      activeStep={activeStep}
                    >
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepButton
                            onClick={handleStep(index)}
                            completed={completed[index]}
                          >
                            {label}
                          </StepButton>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </div>
                {activeStep === 0 && (
                  <>
                    <Row>
                      <Col xs={12} md={6}>
                        <Field name="category_id" defaultValue="">
                          {({ input, meta }) => (
                            <FormControl
                              className={clsx(
                                classes.selectBoxControl,
                                classes.formcontrolwidth,
                              )}
                            >
                              <TextInputMui
                                {...input}
                                select
                                defaultValue=""
                                label={t("select-category")}
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              >
                                {foodCategoryDataList?.map(
                                  (foodCategoryitem) => (
                                    <MenuItem value={foodCategoryitem?.id}>
                                      {foodCategoryitem?.name}
                                    </MenuItem>
                                  ),
                                )}
                              </TextInputMui>
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                        {isMobileView && (
                          <Field name="name">
                            {({ input, meta }) => (
                              <FormControl className={classes.formcontrolwidth}>
                                <TextInputMui
                                  {...input}
                                  type="text"
                                  variant="outlined"
                                  label={t("food-item-name-label")}
                                  error={
                                    meta.error && meta.touched ? true : false
                                  }
                                />
                                {meta.error && meta.touched && (
                                  <span style={{ color: "red" }}>
                                    {meta.error}
                                  </span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        )}

                        <Field name="price">
                          {({ input, meta }) => (
                            <FormControl className={classes.formcontrolwidth}>
                              <TextInputMui
                                {...input}
                                type="number"
                                variant="outlined"
                                label={t("price-field-label")}
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                        <Field name="description">
                          {({ input, meta }) => (
                            <FormControl className={classes.formcontrolwidth}>
                              <TextInputMui
                                {...input}
                                type="textarea"
                                multiline={true}
                                variant="outlined"
                                label={t("description-label")}
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              />
                              {/* <TextField
                                {...input}
                                type="textarea"
                                multiline
                                variant="outlined"
                                label="Description"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              /> */}
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Col>
                      <Col xs={12} md={6}>
                        {!isMobileView && (
                          <Field name="name">
                            {({ input, meta }) => (
                              <FormControl className={classes.formcontrolwidth}>
                                <TextInputMui
                                  {...input}
                                  type="text"
                                  variant="outlined"
                                  label={t("food-item-name-label")}
                                  error={
                                    meta.error && meta.touched ? true : false
                                  }
                                />
                                {meta.error && meta.touched && (
                                  <span style={{ color: "red" }}>
                                    {meta.error}
                                  </span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        )}
                        <Row>
                          <Col xs={12} md={6}>
                            <section>
                              <div {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} />
                                <div className={classes.chooseFile}>
                                  <div>
                                    <FileUploadIcon style={{ fontSize: 40 }} />
                                  </div>
                                  <p>
                                    <strong>{t("image-select")}</strong>{" "}
                                    {t("drag-image-statement")}
                                  </p>
                                </div>
                              </div>
                            </section>
                            {/* <DropZoneField/> */}
                          </Col>
                          <Col xs={12} md={6}>
                            <div className={classes.previewContainer}>
                              <img
                                className={classes.image}
                                src={imgSrc}
                                onLoad={() => {
                                  URL.revokeObjectURL(imgSrc);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {!isVegStore ? (
                      <Field name="is_non_veg" type="checkbox">
                        {({ input, meta }) => (
                          <div className={classes.customCheckbox}>
                            <Checkbox color="primary" {...input} />
                            <InputLabel htmlFor="is_non_veg">
                              {t("is-non-vegitable-label")}
                            </InputLabel>
                          </div>
                        )}
                      </Field>
                    ) : null}

                    {foodMenuId > 0 && (
                      <>
                        <Field name="is_active" type="checkbox">
                          {({ input, meta }) => (
                            <div style={{ display: "flex" }}>
                              <Checkbox
                                color="primary"
                                {...input}
                                style={{ padding: "0px" }}
                              />
                              <InputLabel
                                htmlFor="is_active"
                                style={{ marginTop: "12px" }}
                              >
                                {t("is-available-label")}
                              </InputLabel>
                            </div>
                          )}
                        </Field>
                        <p>
                          <b>{t("note-label")}: </b>
                          {t("food-menu-note-body")}
                        </p>
                      </>
                    )}

                    <div className="mt-2">
                      <div className="d-flex align-items-center justify-content-between flex-wrap foodcategorysticky">
                        <div className={classes.catTextBox}>
                          <h4 className="mb-0">➛ {t("food-menu-variants")} </h4>
                          <p>{t("regular-statement")}</p>
                        </div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (errors?.["food_variants"]) {
                              return;
                            }
                            push("food_variants", addFoodVariant);
                            setMsg(false);
                          }}
                          className={classes.button}
                          type="button"
                          disabled={errors?.["food_variants"] ? true : false}
                          startIcon={<AddIcon />}
                        >
                          {t("add-button-label")}
                        </Button>
                      </div>
                      <FieldArray name="food_variants">
                        {({ fields }) => (
                          <TableContainer className={classes.tableContainer}>
                            <Table
                              className={classes.table}
                              aria-label={t("food-menu-variants")}
                              stickyHeader
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="justify">
                                    {t("name-label")}
                                  </TableCell>
                                  <TableCell align="justify">
                                    {t("price-label")}
                                  </TableCell>
                                  {/* <TableCell align="justify">Default</TableCell> */}
                                  <TableCell align="justify">
                                    {t("available-label")}
                                  </TableCell>
                                  <TableCell align="justify">
                                    {t("delete")}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {fields.map((name, index) => (
                                  <TableRow key={name}>
                                    {fields.length > 0
                                      ? setMsg(false)
                                      : setMsg(true)}
                                    <TableCell style={{ minWidth: 200 }}>
                                      <Field
                                        name={`${name}.name`}
                                        validate={(value, allValues, meta) => {
                                          // const checkError =
                                          //   checkSpaceAndRegex(value);
                                          // if (!value) {
                                          //   return "Name is required.";
                                          // } else if (checkError != "") {
                                          //   return checkError;
                                          // } else {
                                          //   return undefined;
                                          // }
                                          if (!value?.trim()) {
                                            return t(
                                              "name-field-validation-message",
                                            );
                                          } else {
                                            return undefined;
                                          }
                                        }}
                                      >
                                        {({ input, meta }) => (
                                          <FormControl
                                            className={
                                              classes.formcontrolwidth2
                                            }
                                          >
                                            <TextInputMui
                                              {...input}
                                              type="text"
                                              variant="outlined"
                                              placeholder={t("name-label")}
                                              error={
                                                meta.error && meta.touched
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {meta.error && meta.touched && (
                                              <span style={{ color: "red" }}>
                                                {meta.error}
                                              </span>
                                            )}
                                          </FormControl>
                                        )}
                                      </Field>
                                    </TableCell>
                                    <TableCell style={{ minWidth: 150 }}>
                                      <Field
                                        name={`${name}.price`}
                                        validate={(value, allValues, meta) => {
                                          if (!value) {
                                            return t(
                                              "food-category-price-validation-message",
                                            );
                                          } else if (parseInt(value) <= 0) {
                                            return t(
                                              "price-minimum-validation-message",
                                            );
                                          } else {
                                            return undefined;
                                          }
                                        }}
                                      >
                                        {({ input, meta }) => (
                                          <FormControl
                                            className={
                                              classes.formcontrolwidth2
                                            }
                                          >
                                            <TextInputMui
                                              {...input}
                                              type="number"
                                              variant="outlined"
                                              placeholder={t("price-label")}
                                              error={
                                                meta.error && meta.touched
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                const array = {
                                                  ...fields.value[index],
                                                  price: e.target.value,
                                                };
                                                if (
                                                  fields.value[index].is_default
                                                ) {
                                                  change(
                                                    "price",
                                                    e.target.value,
                                                  );
                                                }
                                                fields.update(index, array);
                                              }}
                                            />
                                            {/* <TextField
                                        {...input}
                                        type="number"
                                        variant="outlined"
                                        label="price"
                                        error={
                                          meta.error && meta.touched
                                            ? true
                                            : false
                                        }
                                      /> */}
                                            {meta.error && meta.touched && (
                                              <span style={{ color: "red" }}>
                                                {meta.error}
                                              </span>
                                            )}
                                          </FormControl>
                                        )}
                                      </Field>
                                    </TableCell>
                                    {/* ####### IS DEFAULT CODE ########## */}
                                    {/*   <TableCell align="right">
                                      <Field
                                        name={`${name}.is_default`}
                                        type="checkbox"
                                      >
                                        {({ input, meta }) => (
                                          <Checkbox
                                            color="primary"
                                            {...input}
                                            style={{ paddingLeft: "0px" }}
                                            onChange={(e) => {
                                              fields.forEach((f, i) => {
                                                if (i === index) {
                                                  const array = {
                                                    ...fields.value[i],
                                                    is_default:
                                                      !fields.value[i]
                                                        .is_default,
                                                  };
                                                  change(
                                                    "price",
                                                    fields.value[i].price,
                                                  );
                                                  fields.update(index, array);
                                                } else {
                                                  const arrayWithDefault = {
                                                    ...fields.value[i],
                                                    is_default: false,
                                                  };
                                                  fields.update(
                                                    i,
                                                    arrayWithDefault,
                                                  );
                                                }
                                              });
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </TableCell> */}
                                    <TableCell align="right">
                                      <Field
                                        name={`${name}.is_active`}
                                        type="checkbox"
                                      >
                                        {({ input, meta }) => (
                                          <Checkbox
                                            color="primary"
                                            {...input}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                        )}
                                      </Field>
                                    </TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        color="secondary"
                                        title={t("cancel-variant")}
                                        aria-label={t("cancel-variant")}
                                        onClick={() => {
                                          fields.remove(index);
                                          setMsg(true);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </FieldArray>
                      {msg && (
                        <div className={classes.centerText}>
                          <p style={{ fontSize: "15px", fontWeight: "500" }}>
                            {t("food-menu-add-variants")}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className={classes.foodCatfixFooter}>
                      <div className="d-flex">
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          type="submit"
                          disabled={submitting}
                          style={{
                            background: "",
                            color: "#fff",
                          }}
                          startIcon={<SaveIcon />}
                        >
                          {submittingBtnProcess ? t("processing") : t("save")}
                        </Button>
                        <div className="ml-2">
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.backButton}
                            type="button"
                            onClick={() => {
                              if (isMobileView) history.push("/app/food-menu");
                              else if (!isMobileView && drawerClose)
                                drawerClose();
                            }}
                            startIcon={<ArrowBackIos />}
                          >
                            {t("back")}
                          </Button>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={`${classes.button} ${classes.nextButton}`}
                        endIcon={<NextIcon />}
                      >
                        {t("next")}
                      </Button>
                    </div>
                  </>
                )}
                {/* {activeStep ===  && <></>} */}
                {activeStep === 1 && (
                  <div>
                    <div className="d-flex align-items-center justify-content-between flex-wrap foodcategorysticky">
                      <div>
                        <h4>➛ {t("add-ons-title")} </h4>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (errors?.["food_addons"]) {
                            return;
                          }
                          push("food_addons", initialFoodAddOnsData);
                        }}
                        className={classes.button}
                        type="button"
                        disabled={errors?.["food_addons"] ? true : false}
                        startIcon={<AddIcon />}
                      >
                        {t("add-button-label")}
                      </Button>
                    </div>
                    <hr />
                    <FieldArray name="food_addons">
                      {({ fields }) => (
                        <TableContainer className={classes.tableContainer}>
                          <Table
                            className={classes.table}
                            aria-label="Addons"
                            stickyHeader
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell align="justify">
                                  {t("name-label")}
                                </TableCell>
                                <TableCell align="justify">
                                  {t("price-label")}
                                </TableCell>
                                <TableCell align="justify">
                                  {t("available-label")}
                                </TableCell>
                                {!isVegStore ? (
                                  <TableCell align="justify">
                                    {t("non-veg-label")}
                                  </TableCell>
                                ) : null}
                                <TableCell align="justify">
                                  {t("delete")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {fields.map((name, index) => (
                                <TableRow key={name}>
                                  {fields.length > 0
                                    ? setMsg2(false)
                                    : setMsg2(true)}
                                  <TableCell style={{ minWidth: 200 }}>
                                    <Field
                                      name={`${name}.name`}
                                      validate={(value, allValues, meta) => {
                                        if (!value) {
                                          return t("valid-name-error-message");
                                        } else if (parseInt(value) <= 0) {
                                          return t("price-validation-message");
                                        } else {
                                          return undefined;
                                        }
                                      }}
                                    >
                                      {({ input, meta }) => (
                                        <FormControl
                                          className={classes.formcontrolwidth2}
                                        >
                                          <TextInputMui
                                            {...input}
                                            type="text"
                                            variant="outlined"
                                            placeholder={t("name-label")}
                                            error={
                                              meta.error && meta.touched
                                                ? true
                                                : false
                                            }
                                          />
                                          {meta.error && meta.touched && (
                                            <span style={{ color: "red" }}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </TableCell>
                                  <TableCell style={{ minWidth: 150 }}>
                                    <Field
                                      name={`${name}.price`}
                                      validate={(value, allValues, meta) => {
                                        if (!value) {
                                          return t(
                                            "food-menu-price-error-message",
                                          );
                                        } else if (parseInt(value) <= 0) {
                                          return t(
                                            "price-minimum-validation-message",
                                          );
                                        } else {
                                          return undefined;
                                        }
                                      }}
                                    >
                                      {({ input, meta }) => (
                                        <FormControl
                                          className={classes.formcontrolwidth2}
                                        >
                                          <TextInputMui
                                            {...input}
                                            type="number"
                                            variant="outlined"
                                            placeholder={t("price-label")}
                                            error={
                                              meta.error && meta.touched
                                                ? true
                                                : false
                                            }
                                          />

                                          {meta.error && meta.touched && (
                                            <span style={{ color: "red" }}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Field
                                      name={`${name}.is_active`}
                                      type="checkbox"
                                    >
                                      {({ input, meta }) => (
                                        <Checkbox
                                          color="primary"
                                          {...input}
                                          style={{ paddingLeft: "0px" }}
                                        />
                                      )}
                                    </Field>
                                  </TableCell>
                                  {!isVegStore ? (
                                    <TableCell align="right">
                                      <Field
                                        name={`${name}.is_non_veg`}
                                        type="checkbox"
                                      >
                                        {({ input, meta }) => (
                                          <Checkbox
                                            color="primary"
                                            {...input}
                                            style={{ paddingLeft: "0px" }}
                                          />
                                        )}
                                      </Field>
                                    </TableCell>
                                  ) : null}
                                  <TableCell align="right">
                                    <IconButton
                                      color="secondary"
                                      title={t("cancel-add-on")}
                                      aria-label={t("cancel-add-on")}
                                      onClick={() => {
                                        fields.remove(index);
                                        setMsg2(true);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </FieldArray>
                    {msg2 && (
                      <div className={classes.centerText}>
                        <p style={{ fontSize: "15px", fontWeight: "500" }}>
                          {t("add-ons-add-message")}
                        </p>
                      </div>
                    )}
                    <div className="food-catfix-footer">
                      <div className="d-flex">
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          type="submit"
                          disabled={submitting}
                          style={{
                            background: "",
                            color: "#fff",
                          }}
                          startIcon={<SaveIcon />}
                        >
                          {submittingBtnProcess ? t("processing") : t("save")}
                        </Button>
                        <div className="ml-2">
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.backButton}
                            type="button"
                            onClick={handleBack}
                            startIcon={<ArrowBackIos />}
                          >
                            {t("back")}
                          </Button>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={`${classes.button} ${classes.nextButton}`}
                        endIcon={<NextIcon />}
                      >
                        {t("next")}
                      </Button>
                    </div>
                  </div>
                )}
                {activeStep === 2 && (
                  <div>
                    <div
                      style={{ marginTop: 20 }}
                      className="d-flex align-items-center justify-content-between "
                    >
                      {inventoryDataList?.filter(function (array_el) {
                        return (
                          initialFoodMenuData?.food_inventory?.filter(function (
                            anotherOne_el,
                          ) {
                            return anotherOne_el?.inventory_id == array_el?.id;
                          })?.length == 0
                        );
                      })?.length <= 0 ? (
                        <div className="col-12">
                          <h4>{t("inventory-all-added")}</h4>
                        </div>
                      ) : (
                        <>
                          <div className="col-6">
                            <TextInputMui
                              select
                              value={selectedInventory}
                              onChange={(e) =>
                                setSelectedInventory(e?.target?.value)
                              }
                              label={t("inventory")}
                              variant="outlined"
                            >
                              {inventoryDataList
                                ?.filter(function (array_el) {
                                  return (
                                    initialFoodMenuData?.food_inventory?.filter(
                                      function (anotherOne_el) {
                                        return (
                                          anotherOne_el?.inventory_id ==
                                          array_el?.id
                                        );
                                      },
                                    )?.length == 0
                                  );
                                })
                                ?.map((item) => (
                                  <MenuItem
                                    value={
                                      item?.id +
                                      "#" +
                                      item?.unit_type +
                                      "#" +
                                      item?.name
                                    }
                                  >
                                    {item?.name}
                                  </MenuItem>
                                ))}
                            </TextInputMui>
                          </div>
                          <div className="col-6">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                // if (errors?.["food_inventory"]) {
                                //   return;
                                // }
                                push("food_inventory", {
                                  inventory_name:
                                    selectedInventory?.split("#")[2],
                                  inventory_id:
                                    selectedInventory?.split("#")[0],
                                  unit_type: selectedInventory?.split("#")[1],
                                });
                              }}
                              className={classes.button}
                              type="button"
                              // disabled={
                              //   errors?.["food_inventory"] ? true : false
                              // }
                              startIcon={<AddIcon />}
                            >
                              {t("add-button-label")}
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                    <hr />
                    <FieldArray name="food_inventory">
                      {({ fields }) => (
                        <TableContainer className={classes.tableContainer}>
                          <Table
                            className={classes.table}
                            aria-label="Addons"
                            stickyHeader
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell align="justify">
                                  {t("name-label")}
                                </TableCell>
                                <TableCell align="justify">
                                  {t("unit-flag-title")}
                                </TableCell>
                                <TableCell align="justify">
                                  {t("unit-type-title")}
                                </TableCell>
                                <TableCell align="justify">
                                  {t("description-label")}
                                </TableCell>
                                <TableCell align="justify">
                                  {t("delete")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {fields.map((name, index) => (
                                <TableRow key={name}>
                                  {fields.length > 0
                                    ? setMsg2(false)
                                    : setMsg2(true)}
                                  <TableCell style={{ minWidth: 200 }}>
                                    <Field
                                      name={`${name}.inventory_name`}
                                      validate={(value, allValues, meta) => {
                                        if (!value) {
                                          return t("valid-name-error-message");
                                        } else {
                                          return undefined;
                                        }
                                      }}
                                    >
                                      {({ input, meta }) => (
                                        <FormControl
                                          className={classes.formcontrolwidth2}
                                        >
                                          <TextInputMui
                                            {...input}
                                            type="text"
                                            disabled
                                            variant="outlined"
                                            placeholder={t("name-label")}
                                            error={
                                              meta.error && meta.touched
                                                ? true
                                                : false
                                            }
                                          />
                                          {meta.error && meta.touched && (
                                            <span style={{ color: "red" }}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </TableCell>
                                  <TableCell style={{ minWidth: 100 }}>
                                    <Field
                                      name={`${name}.unit`}
                                      validate={(value, allValues, meta) => {
                                        if (!value) {
                                          return t("unit-error-message");
                                        } else if (value <= 0) {
                                          return t(
                                            "unit-minimum-validation-message",
                                          );
                                        } else {
                                          return undefined;
                                        }
                                      }}
                                    >
                                      {({ input, meta }) => (
                                        <FormControl
                                          className={classes.formcontrolwidth2}
                                        >
                                          <TextInputMui
                                            {...input}
                                            type="number"
                                            variant="outlined"
                                            placeholder={t("unit-flag-title")}
                                            error={
                                              meta.error && meta.touched
                                                ? true
                                                : false
                                            }
                                          />

                                          {meta.error && meta.touched && (
                                            <span style={{ color: "red" }}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </TableCell>
                                  <TableCell style={{ minWidth: 75 }}>
                                    <Field name={`${name}.unit_type`}>
                                      {({ input, meta }) => (
                                        <FormControl
                                          className={classes.formcontrolwidth2}
                                        >
                                          <TextInputMui
                                            {...input}
                                            type="text"
                                            disabled
                                            variant="outlined"
                                            placeholder={t("price-label")}
                                            error={
                                              meta.error && meta.touched
                                                ? true
                                                : false
                                            }
                                          />

                                          {meta.error && meta.touched && (
                                            <span style={{ color: "red" }}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </TableCell>
                                  <TableCell style={{ minWidth: 150 }}>
                                    <Field name={`${name}.description`}>
                                      {({ input, meta }) => (
                                        <FormControl
                                          className={classes.formcontrolwidth2}
                                        >
                                          <TextInputMui
                                            {...input}
                                            type="text"
                                            variant="outlined"
                                            placeholder={t("description-label")}
                                            error={
                                              meta.error && meta.touched
                                                ? true
                                                : false
                                            }
                                          />

                                          {meta.error && meta.touched && (
                                            <span style={{ color: "red" }}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      color="secondary"
                                      title={t("cancel-add-on")}
                                      aria-label={t("cancel-add-on")}
                                      onClick={() => {
                                        fields.remove(index);
                                        setMsg2(true);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </FieldArray>
                    {msg2 && (
                      <div className={classes.centerText}>
                        <p style={{ fontSize: "15px", fontWeight: "500" }}>
                          {t("add-ons-add-message")}
                        </p>
                      </div>
                    )}
                    <div className="food-catfix-footer">
                      <div className="d-flex">
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          type="submit"
                          disabled={submitting}
                          style={{
                            background: "",
                            color: "#fff",
                          }}
                          startIcon={<SaveIcon />}
                        >
                          {submittingBtnProcess ? t("processing") : t("save")}
                        </Button>
                        <div className="ml-2">
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.backButton}
                            type="button"
                            onClick={handleBack}
                            startIcon={<ArrowBackIos />}
                          >
                            {t("back")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            )}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default AddEditFoodMenu;

import SearchBarInput from 'components/SearchBarInput';
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap';
import AddEditOnlineOrder from './AddEditOnlineOrder';
import { Tooltip, Box, Button } from "@material-ui/core";
import { ShoppingBasketSharp, ShoppingCartSharp } from "@material-ui/icons";
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

const AddProduct = (props) => {
    const history = useHistory();
    return (
        <Row className="order-detail-screen">
            <Col className="smart-rest-order-menu">
                <Box
                >
                    <SearchBarInput
                        onChange={(e) => props.setSearchFoodItem(e?.target?.value)}
                        placeholder="Search Food Item"
                        value={props.searchFoodItem}
                    />
                </Box>
                <hr/>
                <AddEditOnlineOrder
                    foodItemsArrayAdded={props.foodItemsArrayAdded}
                    foodItemOptions={props.foodItems}
                    categoryData={props.categoryList}
                    plusItemQty={props.plusItemQty}
                    minusItemQty={props.minusItemQty}
                    searchFoodItem={props.searchFoodItem}
                    selectedFoodItemData={props.selectedFoodItemData}
                />
            </Col>
            <Button
                variant="contained"
                color="primary"
                size="large"
                className="addcart-footer p-2 sf-addtocartbtn"
                // disabled = {!shopSettingOnRefereshData?.shop?.env_variable_new?.ONLINE_ORDER_START}
                onClick={() => {
                    props.shopSettingOnRefereshData?.shop?.env_variable_new
                        ?.ONLINE_ORDER_START
                        ? history.push('/checkout')
                        : Swal.fire(
                            "Oops!",
                            "Currently! this store is not accepting online order. please contact to store owner",
                            "info",
                        );
                }}
            >
                {props.shopSettingOnRefereshData?.shop?.env_variable_new
                    ?.ONLINE_ORDER_START ? (
                    <>
                        <div> {props.selectedFoodItemData?.length} items Added</div>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <ShoppingCartSharp />
                    </>
                ) : (
                    "Sorry! Currently this store is not taking online order. please contact to store owner."
                )}
            </Button>
        </Row>
    )
}


export default AddProduct

import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Field } from "react-final-form";
import { toast } from "react-hot-toast";
import { Save as SaveIcon, Print } from "@material-ui/icons";
import { isElectron } from "react-device-detect";
import {
  Button,
  FormControl,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { updateShopInfo, getShopInfo } from "services/shop.service";
import { Col, Row, Container } from "react-bootstrap";
import { useTheme } from "@material-ui/core/styles";
import { useInMobile } from "themes/Device";
import { useTranslation } from "react-i18next";
import UserQrPrints from "./UserQrPrints";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { DeskTopContainer } from "../../themes/Device";

const styles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-se\rif',
    marginBottom: "3px",
    textDecoration: "none",
  },
  formcontrolwidth: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      margin: "5px",
      marginTop: "15px",
    },
  },
  cardInfo: {
  },
  profileInfo: {
    marginTop: "0px",
  },
  printIcon: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
});

const useStyles = makeStyles(styles);

export default function ShopInfo() {
  const classes = useStyles();
  const theme = useTheme();
  const [initialShopData, setInitialShopData] = useState();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const isMobileView = useInMobile();
  const refQRCODE = useRef();
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.user.userData);

  useEffect(() => {
    getShopInfo()
      .then((res) => {
        setInitialShopData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const onSubmit = async (values) => {
    setSubmittingBtnProcess(true);
    const object = {
      email: values?.email,
      name: values?.name,
      phone: values?.phone,
      email_notification: values.email_notification,
      email_notification_time: values.email_notification_time,
    };

    updateShopInfo(object)
      .then((res) => {
        if (res?.data) {
          setSubmittingBtnProcess(false);
          toast.success(t("shop-update-success-toast-message"));
        } else {
          setSubmittingBtnProcess(false);
          toast.success(t("something-went-wrong-toast-message"));
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
        toast.success(t("something-went-wrong-toast-message"));
      });
  };


  const downloadQR = () => {
    const qrCodeURL = document
      .getElementById("qrCodeE2")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <div>
      {initialShopData === undefined ||
        initialShopData === null ||
        initialShopData === {} ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
          <br></br>
          <b>{t("data-fetch-message")}</b>
        </div>
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialShopData}
          validate={(values) => {
            const errors = {};
            const emailRegex =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            // const checkerror = checkSpaceAndRegex(values?.name);
            // if (checkerror != "") errors.name = checkerror;

            if (!values.name?.trim()) {
              errors.name = t("restaurant-validation-message");
            } else if (values?.name?.length > 50) {
              errors.name = t("maximumlength-validation-message");
            }

            if (!values?.email) {
              errors.email = t("email-validation-message");
            } else if (values?.email?.length > 50) {
              errors.email = t("maximumlength-validation-message");
            } else if (!emailRegex.test(values?.email)) {
              errors.email = t("email-invalid-validation-message");
            }

            if (!values?.phone) {
              errors.phone = t("phone-number-error-message");
            } else if (isNaN(values?.phone)) {
              errors.phone = t("phone-number-validation-message");
            } else if (values?.phone?.length !== 10) {
              errors.phone = t("phone-number-digit-validation-message");
            }

            return errors;
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Container style={{ padding: '0px' }}>
                <Card className={classes.cardInfo}>
                  <CardHeader
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    <h4 className={classes.cardTitleWhite}>{t("shop-info-title")}</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={8}>
                        <GridContainer>
                          <Field name="name">
                            {({ input, meta }) => (
                              <FormControl className={classes.formcontrolwidth}>
                                <TextField
                                  {...input}
                                  label={t("shop-owner-title")}
                                  variant="outlined"
                                  error={
                                    meta.error && meta.touched ? true : false
                                  }
                                />
                                {meta.error && meta.touched && (
                                  <span style={{ color: "red" }}>
                                    {meta.error}
                                  </span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        </GridContainer>
                        <DeskTopContainer>
                          <br />
                        </DeskTopContainer>
                        <GridContainer>
                          <Field name="phone">
                            {({ input, meta }) => (
                              <FormControl className={classes.formcontrolwidth}>
                                <TextField
                                  {...input}
                                  label={t("phone-label")}
                                  variant="outlined"
                                  error={
                                    (meta.touched || meta.visited) && meta.error
                                      ? true
                                      : false
                                  }
                                />
                                {(meta.touched || meta.visited) && meta.error && (
                                  <span style={{ color: "red" }}>
                                    {meta.error}
                                  </span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        </GridContainer>
                        <DeskTopContainer>
                          <br />
                        </DeskTopContainer>
                        <GridContainer>
                          <Field name="email">
                            {({ input, meta }) => (
                              <FormControl className={classes.formcontrolwidth}>
                                <TextField
                                  {...input}
                                  label={t("email")}
                                  variant="outlined"
                                  error={
                                    meta.error && meta.touched ? true : false
                                  }
                                />
                                {meta.error && meta.touched && (
                                  <span style={{ color: "red" }}>
                                    {meta.error}
                                  </span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        </GridContainer>
                        <GridContainer>
                          <Field name="email_notification">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event?.target?.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={t("send-email-notification-flag-label")}
                              />
                            )}
                          </Field>
                          {values?.email_notification && (
                            <Field name="email_notification_time" type="number">
                              {({ input, meta }) => (
                                <FormControl className={classes.formcontrolwidth}>
                                  <TextField
                                    {...input}
                                    label={t("email-notification-time-label")}
                                    variant="outlined"
                                    disabled
                                    error={
                                      (meta.touched || meta.visited) && meta.error
                                        ? true
                                        : false
                                    }
                                  />
                                  {(meta.touched || meta.visited) &&
                                    meta.error && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                </FormControl>
                              )}
                            </Field>
                          )}
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <UserQrPrints
                          ref={refQRCODE}
                          shopId={values?.shop_id}
                          orderUrl={`${isElectron ? 'https://app.servofeat.com' : window.location.origin}/#/online-order/parcel?username=${userInfo?.user?.username}`}
                        />
                        <a
                          href={`${isElectron ? 'https://app.servofeat.com' : window.location.origin}/#/online-order/parcel?username=${userInfo?.user?.username}`}
                          target="_blank"
                          rel="noreferrer"
                        >{`${isElectron ? 'https://app.servofeat.com' : window.location.origin}/#/online-order/parcel?username=${userInfo?.user?.username}`}</a>

                        <Row>
                          <Col xs={6} sm={6} md={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              className="mr-3"
                              style={{
                                background: "",
                                color: "#fff",
                              }}
                              onClick={downloadQR}
                            >
                              DOWNLOAD
                            </Button>
                          </Col>
                          <Col xs={6} sm={6} md={6}>

                            <ReactToPrint
                              trigger={() => (
                                <Button variant="contained" color="primary">
                                  <Print className={classes.printIcon} /> Print
                                </Button>
                              )}
                              content={() => refQRCODE.current}
                            />
                          </Col>
                        </Row>
                      </GridItem>
                    </GridContainer>

                    <br />
                    <GridContainer style={{ justifyContent: "center" }}>
                      <Button
                        style={{
                          margin: "2%",
                          background: "",
                          color: "#fff",
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        type="submit"
                        startIcon={<SaveIcon />}
                      >
                        {submittingBtnProcess ? t("processing") : t("save")}
                      </Button>
                    </GridContainer>
                  </CardBody>
                </Card>
              </Container>
            </form>
          )}
        />
      )}
    </div>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";

import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Group,
  Delete,
  Cancel,
} from "@material-ui/icons";
import SkeletonLoader from "components/Loader/SkeletonLoader";
//context
import {
  useLayoutState,
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
// API Calls
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";

import TemporaryDrawer from "components/TemporaryDrawer";
import AddEditExpense from "./AddEditExpense";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeskTopContainer } from "themes/Device";
import { MobileContainer } from "themes/Device";
import SearchBarInput from "components/SearchBarInput";
import MobileExpenseCard from "components/MobileExpenseCard";
import MobileTitle from "components/MobileTitle";
import PopUpModel from "components/Popup/PopUpModel";
import { useInMobile } from "themes/Device";
import {
  useExpense,
  useDeleteExpenseMutation,
} from "hooks/react-query/useExpense";
import { useTheme } from "@material-ui/core/styles";
import Label from "components/Label";
import { useTranslation } from "react-i18next";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
  },
  tabel: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
      "& div": {
        padding: "0",
      },
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },
  mobileCard: {
    padding: "2px",
  },
};

const useStyles = makeStyles(styles);

const ExpenseList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [deletePopupshow, setDeletePopupshow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [ExpenseDataList, setExpenseDataList] = useState([]);
  const [drawerShow, setDrawerShow] = useState(false);
  const [ExpenseId, setExpenseId] = useState(0);
  const [searchExpense, setSearchExpense] = useState("");
  const [filterExpenseList, setFilterExpenseist] = useState([]);
  const history = useHistory();
  const isMobileView = useInMobile();
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (layoutState?.currentScreenTitle !== "Expense") {
      addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "Expense" });
    }
    return () => {
      if (layoutState?.currentScreenTitle === "Expense") {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      }
    };
  }, []);

  const { data: expenseDataList, loading: isLoading } = useExpense();
  const { mutate: deleteExpense } = useDeleteExpenseMutation();

  useEffect(() => {
    setExpenseDataList(expenseDataList);
  }, [expenseDataList]);

  const deleteExpenseData = () => {
    if (deleteId > 0) {
      setIsDeleting(true);
      deleteExpense(deleteId);
      setIsDeleting(false);
      setDeletePopupshow(false);
    }
  };

  const deleteDataConfirm = (id) => {
    setDeleteId(id);
    setDeletePopupshow(true);
  };

  const drawerClose = () => {
    if (isMobileView) {
      history.push("/app/expense");
    } else {
      setDrawerShow(false);
      setExpenseId(0);
    }
  };

  const deleteData = (deleteid) => {
    setDeleteId(deleteid);
    setDeletePopupshow(true);
  };

  const editData = (editid) => {
    history.push({
      pathname: `/app/expense/edit/${editid}`,
      state: {
        ExpenseId: editid,
        backToMain: () => history.push(`/app/expense`),
      },
    });
  };

  const addDrawerShow = () => {
    history.push({
      pathname: `/app/expense/add`,
      state: {
        ExpenseId: 0,
        backToMain: () => history.push(`/app/expense`),
      },
    });
  };

  const clearTxt = () => {
    setSearchExpense("");
  };

  useEffect(() => {
    setFilterExpenseist(ExpenseDataList);
  }, [ExpenseDataList]);

  useEffect(() => {
    if (searchExpense) {
      let filterInvList = filterExpenseList?.filter(
        (item) =>
          item.type &&
          item.type?.toLowerCase()?.includes(searchExpense.toLowerCase()),
      );
      setFilterExpenseist(filterInvList);
    } else {
      setFilterExpenseist(ExpenseDataList);
    }
  }, [searchExpense]);

  return (
    <>
      <PopUpModel
        show={deletePopupshow}
        heading={t("expense-management-delete-popup-title")}
        body={t("expense-management-delete-popup-body")}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeletePopupshow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={deleteExpenseData}
            >
              {" "}
              <Delete /> {isDeleting ? <CircularProgress /> : t("delete")}
            </Button>
          </>
        }
        onHide={() => setDeletePopupshow(false)}
      />
      <TemporaryDrawer
        isOpen={drawerShow}
        drawerClose={drawerClose}
        icon={<FontAwesomeIcon icon={faBoxOpen} />}
        title={t("expense-title")}
      >
        <AddEditExpense ExpenseId={ExpenseId} drawerClose={drawerClose} />
      </TemporaryDrawer>
      <DeskTopContainer>
        <div className={classes.tabel}>
          <MaterialTable
            title={
              <h5>
                <b className="mr-3">{t("expense-management-title")}</b>
              </h5>
            }
            columns={[
              {
                title: t("name-label"),
                field: "name",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: t("sales-report-amount"),
                field: "amount",
                cellStyle: {
                  width: "10%",
                },
              }, {
                title: t("sales-report-amount"),
                field: "type",
                cellStyle: {
                  width: "10%",
                },
              },
              {
                title: t("category-tab-label"),
                field: "category_id",
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("description-label"),
                field: "description",
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("from-label"),
                field: "from_date",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) =>
                  rowData?.from_date &&
                  moment(rowData?.from_date).format("DD/MM/YYYY"),
              },
              {
                title: t("to-label"),
                field: "to_date",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) =>
                  rowData?.to_date &&
                  moment(rowData?.to_date).format("DD/MM/YYYY"),
              },
              {
                title: t("action"),
                field: "action",
                cellStyle: {
                  width: "40%",
                },
                render: (rowData) => (
                  <Fragment>
                    <IconButton
                      color="primary"
                      title={t("edit-expense-title")}
                      aria-label={t("edit-expense-title")}
                      onClick={() => {
                        setExpenseId(rowData.id);
                        setDrawerShow(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      title={t("expense-management-delete-label")}
                      aria-label={t("expense-management-delete-label")}
                      onClick={() => deleteDataConfirm(rowData.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Fragment>
                ),
              },
            ]}
            data={
              ExpenseDataList &&
              ExpenseDataList?.map((item) => {
                return {
                  name: item?.name,
                  description: item?.description,
                  amount: item?.amount,
                  type: item?.type,
                  category_id: item?.category?.name,
                  id: item?.id,
                  from_date: item?.from_date,
                  to_date: item?.to_date,
                };
              })
            }
            options={{
              paging: true,
              pageSize: 10,
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 20, 30],
              sorting: true,
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              searchFieldStyle: { color: theme.palette.grey[500] },
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              },
              // rowStyle: (rowData, index) => {
              //   if (index % 2) {
              //     return { backgroundColor: "#f2f2f2" };
              //   }
              // },
            }}
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: isLoading ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>
                      {t("expense-management-empty-data-message")}
                    </p>
                  </NotFoundComponent>
                ),
              },
            }}
            // other props
            components={{
              Toolbar: (props) => (
                <div className="custom-material-table-toolbar ">
                  <MTableToolbar {...props} />
                  <div style={{ padding: "0px 10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<AddCircleOutlineIcon />}
                      style={{ background: "", color: "#fff" }}
                      onClick={() => {
                        setDrawerShow(true);
                      }}
                    >
                      {t("expense-management-add-expense-label")}
                    </Button>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </DeskTopContainer>
      <MobileContainer>
        <div style={{ paddingTop: "5px" }}>
          <MobileTitle title={t("expense-title")} addButton={addDrawerShow} />
        </div>
        <SearchBarInput
          onChange={(e) => {
            setSearchExpense(e.target.value);
          }}
          value={searchExpense}
          placeholder={t("expense-management-search-expnce-title")}
          clearTxt={clearTxt}
        />
        {isLoading ? (
          <SkeletonLoader count={4} height="7rem" />
        ) : (
          <div>
            {filterExpenseList &&
              filterExpenseList?.map((item, index) => (
                <div key={index} style={{ marginTop: index === 0 ? 4 : 0, marginBottom: 4 }}>
                  <MobileExpenseCard
                    data={item}
                    deleteData={deleteData}
                    editData={editData}
                    itemId={item?.id}
                  />
                </div>
              ))}
          </div>
        )}
        {filterExpenseList?.length < 1 && !isLoading && (
          <NotFoundComponent imgUrl={noFoundImg}>
            <p style={{ fontWeight: 600 }}>
              {t("expense-management-empty-data-message")}
            </p>
          </NotFoundComponent>
        )}
      </MobileContainer>
    </>
  );
};
export default ExpenseList;

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
const CustomHtmlToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main || '#337AB7',
    color:  theme.palette.primary.contrastText || '#fff',
    maxWidth: 300,
    fontSize: 14,
  }
}))(Tooltip);

export default CustomHtmlToolTip;

import { useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Typography, Container, Box, Stack } from "@mui/material";

// assets
import loginImg from "../../assets/img/login_vector.png";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(11, 0),
}));

const HeaderStyle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: 22,
  },
}));

const SubHederStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));

// ----------------------------------------------------------------------

export default function Welcome(props) {
  useEffect(() => {
    if (!props.location.isWelcomePage) {
      props.history.push("/");
    }
  }, []);
  return (
    <Container>
      <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <img
            disabledEffect
            src={
              "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
            }
            style={{ width: 40, height: 40 }}
          />
          <HeaderStyle variant="h4" paragraph>
            Welcome to Servofeat
          </HeaderStyle>
        </Stack>
        <Stack>
          <SubHederStyle sx={{ my: 2 }}>
            Kindly check your email "xyz@gmail.com". You will get a username and
            password.
          </SubHederStyle>
        </Stack>
        <Box sx={{ my: { xs: 5 } }}>
          <img src={loginImg} style={{ weight: 200, height: 200 }} />
        </Box>

        <LoadingButton
          size="medium"
          type="button"
          variant="contained"
          onClick={() => props.history.push("/")}
        >
          Go to Login
        </LoadingButton>
      </ContentStyle>
    </Container>
  );
}

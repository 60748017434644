import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";
import { Row, Col, Card } from "react-bootstrap";
import {
  Button,
  FormControl,
  Checkbox,
  InputLabel,
  IconButton,
  Stepper,
  StepButton,
  Step,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
} from "@material-ui/core";
import Label from "components/Label";
import {
  Save as SaveIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  NavigateNext as NextIcon,
  BackupOutlined as FileUploadIcon,
  ArrowBackIos,
  AddSharp,
} from "@material-ui/icons";

import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  CreateFoodCategory,
  getFoodCategoryById,
  updateFoodCategory,
} from "services/foodCategory.service";
import { fetchCategoryList } from "state/thunk-async";
import TextInputMui from "components/TextInputMui";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import Paper from "@material-ui/core/Paper";
import "../../assets/css/foodcategory.css";
import { useSelector } from "react-redux";
import { useInMobile } from "themes/Device";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  header: {
    padding: "0 !important",
  },
  previewContainer: {
    borderRadius: 5,
    border: "2px solid #eaeaea",
    width: "100%",
    height: "100%",
    minHeight: "100%",
    padding: 4,
    boxSizing: "border-box",
  },
  formcontrolwidth: {
    width: "100%",
    marginBottom: 10,
    "& > span": {
      maxWidth: 240,
      fontSize: 12,
    },
  },
  formcontrolwidth2: {
    width: "100%",
    marginBottom: 10,
    marginTop: 10,
    maxWidth: 240,
    "& > span": {
      maxWidth: 240,
    },
  },
  textField: {
    width: 200,
  },
  button: {
    
  },
  chooseFile: {
    minHeight: "100%",
    height: "100%",
    width: "100%",
    display: "flex",
    padding: "20px 10px",
    border: "2px dashed #eaeaea",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "& p": {
      marginBottom: "0",
      fontSize: "12px",
      textAlign: "center",
    },
  },
  image: {
    objectFit: "cover",
    width: "100%",
  },
  tableContainer: {
    maxHeight: 270,
  },
  table: {
    // border: "1px solid pink",
    "& thead": {
      height: "40px",
      "& tr": {
        "& th": {
          fontSize: "14px",
        },
      },
    },
    "& thead tr": {
      height: "40px",
      padding: "0 10px",
    },
    "& thead tr th": {
      padding: "0 5px",
      height: "40px",
    },
    "& tbody tr": {
      padding: "0 5px",
      height: "40px",
    },
    "& tbody tr td": {
      padding: "0 5px",
      height: "40px",
    },
  },
  StepperMain: {
    padding: 0,
    "& .MuiStep-horizontal": {
      padding: 0,
      "& button": {
        margin: 0,
        padding: 0,
        marginRight: 8,
        "& .MuiStepLabel-horizontal": {
          "& .MuiStepLabel-iconContainer": {
            "& .MuiStepIcon-root.MuiStepIcon-active": {
              // color: "rgb(210, 90, 198)",
            },
          },
        },
      },
    },
  },
  catTextBox: {
    display: "flex",
    alignItems: "center",
    "& h4, p": {
      margin: 0,
    },
    "& p": {
      margin: 0,
      marginLeft: 5,
    },
  },
  headingNum: {
    backgroundColor: "",
    fontSize: "12px",
    color: "#fff",
    padding: "5px 9px 5px 9px",
    borderRadius: " 50%",
    marginLeft: "3px",
    marginRight: "2px",
  },
  centerText: { display: "flex", justifyContent: "center", marginTop: "5px" },
  btnSection: {
    justifyContent: 'center',
    display: 'flex',
    position: 'sticky',
    bottom: '6px',
    background: '#fff'

  },
  foodCatfixFooter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "0 15px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      display: "flex",
      position: "relative",
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
    },
  },
});

const useStyles = makeStyles(styles);

const initialCategoryAddOnsData = {
  id: 0,
  is_active: true,
  is_non_veg: false,
  name: "",
  price: "",
};

const AddEditFoodCategory = (props) => {
  const [selectedImage, setSelectedImage] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [fetching, setFetching] = useState(false);
  const [msg, setMsg] = useState(true);
  const [initialFoodCategoryData, setInitialFoodCategoryData] = useState({
    category_addons: [],
  });
  const classes = useStyles();
  const history = useHistory();
  const param = useParams();
  const { drawerClose } = props;
  const [foodCategoryId, setFoodCategoryId] = useState(
    props?.match?.params?.id ?? props?.categoryId,
  );
  const isNew =
    props?.match?.params?.id > 0 || props?.categoryId > 0 ? false : true;
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const dispatch = useDispatch();
  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const layoutDispatch = useLayoutDispatch();
  const isMobileView = useInMobile();
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [],
      "image/jpg": [],
      "image/jpeg": [],
    },
    maxFiles: 1,
    maxSize: 1000000,
    onDrop: (acceptedFiles) => {
      setSelectedImage(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }),
      );
    },
  });

  useEffect(() => {
    if (isMobileView) {
      let screenTitle = "";
      if (param.id > 0) {
        screenTitle = t("food-category-edit-mobileview-message");
      } else {
        screenTitle = t("food-category-add-mobileview-message");
      }

      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: screenTitle,
      });
      return () => {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      };
    }
  }, []);

  useEffect(() => {
    if (foodCategoryId > 0) {
      setFetching(true);
      getFoodCategoryById(foodCategoryId).then((res) => {
        let { food_item_addons, ...formInitialData } = res.data;
        if (food_item_addons && food_item_addons.length > 0) {
          formInitialData.category_addons = food_item_addons;
          formInitialData.is_AddOn = true
        } else {
          formInitialData.category_addons = [];
          formInitialData.is_AddOn = false
        }
        setInitialFoodCategoryData(formInitialData);
        setFetching(false);
      });
    }
  }, [foodCategoryId]);

  const redirectToMainPage = () => {
    if (isMobileView) {
      history.location?.state?.backToMain();
    } else {
      drawerClose();
    }
  };

  const onSubmit = async (values) => {
    setSubmittingBtnProcess(true);
    console.log(values);
    if (isNew) {
      const result = await CreateFoodCategory(values);
      dispatch(fetchCategoryList());
      if (result.status === 200 || result?.data?.status === 200) {
        setSubmittingBtnProcess(false);
        toast.success(result.data.message);
        // setInitialFoodCategoryData();
        redirectToMainPage();
      } else if (result.status === 400) {
        setSubmittingBtnProcess(false);
        toast.error(result?.message);
      }
    } else if (foodCategoryId > 0) {
      // update food category
      const result = await updateFoodCategory(foodCategoryId, values);
      dispatch(fetchCategoryList());
      if (result.status === 200 || result?.data?.status === 200) {
        setSubmittingBtnProcess(false);
        toast.success(result?.data?.message);
        redirectToMainPage();
      } else if (result.status === 400) {
        setSubmittingBtnProcess(false);
        toast.error(result?.message);
      }
    }
  };

  return (
    <Card className="foodcat-card">
      <Card.Body>
        {foodCategoryId > 0 && fetching ? (
          <>
            <SkeletonLoader count={5} height={50} />
            <SkeletonLoader count={1} height={70} />
          </>
        ) : (
          <Form
            onSubmit={onSubmit}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={initialFoodCategoryData}
            validate={(values) => {
              const errors = {};
              if (!values?.name?.trim()) {
                errors.name = t("food-category-name-validation-message");
              } else if (values?.name?.length > 50) {
                errors.name = t("maximumlength-validation-message");
              }

              return errors;
            }}
            render={({
              handleSubmit,
              form: {
                mutators: { push, pop },
                reset,
                restart,
              }, // injected from final-form-arrays above
              submitting,
              pristine,
              values,
              errors,
            }) => (
              <form
                onSubmit={(event) => {
                  handleSubmit(event).then(() => {
                    reset();
                    restart();
                  });
                }}
              >
                <div className="foodcatstep">
                  <div className="d-flex align-items-center">
                    <Label color={"success"}  variant="filled" sx={{ marginRight: "8px" }}>
                    1
                  </Label>
                    <h4
                      className="mb-0"
                      style={{
                        fontSize: "0.85rem",
                        fontWeight: "500",
                        marginLeft: "6px",
                      }}
                    >
                      {t("category-tab-label")}
                    </h4>
                  </div>
                </div>
                    <Row>
                      <Col sm={12} md={6}>
                        <Field name="name">
                          {({ input, meta }) => (
                            <FormControl className={classes.formcontrolwidth}>
                              <TextInputMui
                                {...input}
                                type="text"
                                variant="outlined"
                                label={t("food-category-name-label")}
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Col>
                      <Col sm={12} md={6}>
                        <Field name="description">
                          {({ input, meta }) => (
                            <FormControl className={classes.formcontrolwidth}>
                              <TextInputMui
                                {...input}
                                type="text"
                                variant="outlined"
                                label={t("description-label")}
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Col>
                      {/* <Col sm={12} md={6}>
                        <Row>
                          <Col sm={12} md={6} className="pl-0">
                            <section>
                              <div {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} />
                                <div className={classes.chooseFile}>
                                  <div>
                                    <FileUploadIcon style={{ fontSize: 40 }} />
                                  </div>
                                  <p>
                                    <strong>Choose Image</strong> or drag it
                                    here
                                  </p>
                                </div>
                              </div>
                            </section>
                          </Col>
                          <Col sm={12} md={6} className="pl-0">
                            <div className={classes.previewContainer}>
                              <img
                                className={classes.image}
                                src={selectedImage.preview}
                                onLoad={() => {
                                  URL.revokeObjectURL(selectedImage.preview);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col> */}
                    </Row>

                    {foodCategoryId > 0 && (
                      <>
                        <Field name="is_active" type="checkbox">
                          {({ input, meta }) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Checkbox
                                color="primary"
                                {...input}
                                style={{ paddingLeft: "0px" }}
                              />
                              <InputLabel
                                htmlFor="is_active"
                                style={{ marginBottom: "0px" }}
                              >
                                {t("is-available-label")}
                              </InputLabel>
                            </div>
                          )}
                        </Field>
                        <p>
                          <b>{t("note-label")} :</b>
                          {t("food-category-note")}
                        </p>
                      </>
                    )}
                    <Field name="is_AddOn" type="checkbox">
                          {({ input, meta }) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                             <FormControlLabel 
                              control={ <Checkbox
                              color="primary"
                              {...input}
                              style={{ paddingLeft: "0px" }}
                            />} label={`Add on? ${t("food-category-add-ons-example")}`} />
                            </div>
                          )}
                        </Field>
                        {
                          values.is_AddOn && <div style={{background: '#eee', padding: '14px'}}>
                              <div className="d-flex align-items-center justify-content-between align-items-center flex-wrap foodcategorysticky">
                            <div className={classes.catTextBox}>  
                              <div className="d-flex align-items-center">
                                <h4
                                  className="mb-0"
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    marginLeft: "6px",
                                  }}
                                >
                                  {t("food-category-add-ons")}
                                </h4>
                              </div>
                              <p></p>
                            </div>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                if (errors?.["category_addons"]) {
                                  return;
                                }
                                push("category_addons", initialCategoryAddOnsData);
                              }}
                              size="small"
                              type="button"
                              disabled={errors?.["category_addons"] ? true : false}
                              startIcon={<AddSharp/>}
                            >
                              {t("add-button-label")}
                            </Button>
                          </div>
                          <FieldArray name="category_addons">
                            {({ fields }) => (
                              <Paper>
                                <TableContainer className={classes.tableContainer}>
                                  <Table
                                    className={classes.table}
                                    aria-label="Addons"
                                    stickyHeader
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="justify">
                                          {t("name-label")}
                                        </TableCell>
                                        <TableCell align="justify">
                                          {t("price-label")}
                                        </TableCell>
                                        <TableCell align="justify">
                                          {t("available-label")}
                                        </TableCell>
                                        {!shopSettingListData?.IS_SHOP_VEGETARIAN ? (
                                          <TableCell align="justify">
                                            <p style={{ marginTop: "12px" }}>
                                              {t("non-veg-label")}
                                            </p>
                                          </TableCell>
                                        ) : null}
                                        <TableCell align="justify">
                                          {t("delete")}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {fields.map((name, index) => (
                                        <TableRow key={name}>
                                          {fields.length > 0
                                            ? setMsg(false)
                                            : setMsg(true)}
                                          <TableCell style={{ minWidth: 200 }}>
                                            <Field
                                              name={`${name}.name`}
                                              validate={(
                                                value,
                                                allValues,
                                                meta,
                                              ) => {
                                                if (!value?.trim()) {
                                                  return t(
                                                    "name-field-validation-message",
                                                  );
                                                } else {
                                                  return undefined;
                                                }
                                              }}
                                            >
                                              {({ input, meta }) => (
                                                <FormControl
                                                  className={
                                                    classes.formcontrolwidth2
                                                  }
                                                >
                                                  <TextInputMui
                                                    {...input}
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder={t("name-label")}
                                                    error={
                                                      meta.error && meta.touched
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {meta.error && meta.touched && (
                                                    <span style={{ color: "red" }}>
                                                      {meta.error}
                                                    </span>
                                                  )}
                                                </FormControl>
                                              )}
                                            </Field>
                                          </TableCell>
                                          <TableCell style={{ minWidth: 150 }}>
                                            <Field
                                              name={`${name}.price`}
                                              validate={(
                                                value,
                                                allValues,
                                                meta,
                                              ) => {
                                                if (!value) {
                                                  return t(
                                                    "food-category-price-validation-message",
                                                  );
                                                } else if (parseInt(value) <= 0) {
                                                  return t(
                                                    "price-minimum-validation-message",
                                                  );
                                                } else {
                                                  return undefined;
                                                }
                                              }}
                                            >
                                              {({ input, meta }) => (
                                                <FormControl
                                                  className={
                                                    classes.formcontrolwidth2
                                                  }
                                                >
                                                  <TextInputMui
                                                    {...input}
                                                    type="number"
                                                    variant="outlined"
                                                    placeholder={t("price-label")}
                                                    error={
                                                      meta.error && meta.touched
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {meta.error && meta.touched && (
                                                    <span style={{ color: "red" }}>
                                                      {meta.error}
                                                    </span>
                                                  )}
                                                </FormControl>
                                              )}
                                            </Field>
                                          </TableCell>
                                          <TableCell align="right">
                                            <Field
                                              name={`${name}.is_active`}
                                              type="checkbox"
                                            >
                                              {({ input, meta }) => (
                                                <Checkbox
                                                  color="primary"
                                                  {...input}
                                                  style={{ paddingLeft: "0px" }}
                                                />
                                              )}
                                            </Field>
                                          </TableCell>
                                          {!shopSettingListData?.IS_SHOP_VEGETARIAN ? (
                                            <TableCell align="right">
                                              <Field
                                                name={`${name}.is_non_veg`}
                                                type="checkbox"
                                              >
                                                {({ input, meta }) => (
                                                  <Checkbox
                                                    color="primary"
                                                    {...input}
                                                    style={{ paddingLeft: "0px" }}
                                                  />
                                                )}
                                              </Field>
                                            </TableCell>
                                          ) : null}
                                          <TableCell align="right">
                                            <IconButton
                                              color="secondary"
                                              title={t("Cancel variant")}
                                              aria-label={t("cancel-variant")}
                                              onClick={() => {
                                                fields.remove(index);
                                                setMsg(true);
                                              }}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Paper>
                            )}
                          </FieldArray>
                          {msg && (
                            <div className={classes.centerText}>
                              <p style={{ fontSize: "15px", fontWeight: "500" }}>
                                {t("add-ons-add-message")}
                              </p>
                            </div>
                          )}
                      </div>
                        }
                        <hr/>
                         <div className={classes.btnSection}>
                         <div className="ml-2">
                        <Button
                          variant="outlined"
                          color="secondary"
                          type="button"
                          size="large"
                          onClick={() => {
                            if (isMobileView) history.push("/app/food-category");
                            else if (!isMobileView && drawerClose)
                              drawerClose();
                          }}
                          startIcon={<ArrowBackIos />}
                        >
                          {t("back")}
                        </Button>
                      </div>
                      <div className="ml-2">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          size="large"
                          disabled={submitting}
                          startIcon={<SaveIcon />}
                        >
                          {submittingBtnProcess ? t("processing") : t("save")}
                        </Button>
                      </div>
                     
                    </div>
              </form>
            )}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default AddEditFoodCategory;

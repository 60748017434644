// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// components
import Label from '../../../../components/Label';
// import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../../components/SvgIconStyle';
import { RESOURCE } from "common/constant";
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'analytics', path: PATH_DASHBOARD.servofeat.analytics, icon: ICONS.analytics },
      { title: 'table', path: PATH_DASHBOARD.servofeat.table, icon: ICONS.banking, resource: RESOURCE.DINE_IN_TABLE },
      { title: 'new', path: PATH_DASHBOARD.servofeat.neworder, icon: ICONS.booking, resource: RESOURCE.DASHBOARD },
      { title: 'sales', path: PATH_DASHBOARD.servofeat.sales, icon: ICONS.ecommerce, resource: RESOURCE.SALES_REPORT },
      { title: 'kitchen', path: PATH_DASHBOARD.servofeat.kitchen, icon: ICONS.ecommerce, resource: RESOURCE.KITCHEN_QUEUE },
      // { title: 'Queue', path: PATH_DASHBOARD.servofeat.queue, icon: ICONS.user },
      { title: 'Report', path: PATH_DASHBOARD.servofeat.salesReport, icon: ICONS.invoice },
      { title: 'Customers', path: PATH_DASHBOARD.servofeat.customers, icon: ICONS.user },
    ],
  },

];

export default navConfig;

import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { BorderBottom, CloseRounded } from "@material-ui/icons";
import { IconButton, Box, makeStyles } from "@material-ui/core";
import "./TemporaryDrawer.styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

const TemporaryDrawer = (props) => {
  const {
    anchor = "right",
    isOpen,
    drawerClose,
    drawerWidth = "40%",
    children,
    title = "title",
    icon,
  } = props;

  const styles = (theme) => ({
    header: {
      display: "flex",
      justifyContent: "flex-start !important",
      padding: "5px 15px",
      width: "100%",
      "& button": {
        padding: 0,
        marginRight: "5px",
      },
    },
    paper: {
      width: drawerWidth,
      paddingBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    titleMain: {
      display: "flex",
      margin: "auto",
      justifyContent: "center",
      borderBottom: "2px solid #9e9e9e",
    },
    titleIcon: {
      color: "",
    },
    titleText: {
      color: "",
      marginTop: "3px",
    },
  });

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      anchor={anchor}
      open={isOpen}
      onClose={drawerClose}
      variant="temporary"
    >
      <Box className={classes.header}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className={classes.titleMain}>
            <IconButton className={classes.titleIcon}>{icon}</IconButton>
            <h5 className={classes.titleText}>{title}</h5>
          </div>
          <IconButton
            aria-label="close"
            size="medium"
            color="primary"
            onClick={drawerClose}
          >
            <CloseRounded fontSize="medium" />
          </IconButton>
        </div>
      </Box>
      {children}
    </Drawer>
  );
};

export default TemporaryDrawer;

import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Importing toastify module
import { Card, Row, Col, Badge, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import {
  CircularProgress,
  FormControl,
  TextField,
  MenuItem,
  Button,
  Stack,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getSalesReportData } from "services/report.service";
import noFoundImg from "../../assets/img/no-data-found.jpg";

// API Calls
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Form, Field } from "react-final-form";
import TextInputMui from "components/TextInputMui";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import NotFoundComponent from "components/NotFoundComponent";
import { RESOURCE } from "common/constant";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTheme } from "@material-ui/core/styles";
import Label from "components/Label";
import { useTranslation } from "react-i18next";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
  },
  tabel: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },
  header: {
    backgroundColor: "white",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    padding: "10px",
    marginBottom: "10px",
  },
  searchbtn: {
    fontWeight: 500,
    fontSize: "1rem",
    marginTop: "10px",
    height: "50px",
    lineHeight: "2px",
  },
};

const useStyles = makeStyles(styles);
const OrderReport = () => {
  const unit = "pt";
  const size = "A4";
  const orientation = "portrait";

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  require("jspdf-autotable");
  const theme = useTheme();
  const classes = useStyles();
  const [salesReportData, setSalesReportData] = useState([]);
  const [initialReportDate, setInitialReportDate] = useState({
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().add(1, "days").format("YYYY-MM-DD"),
  });
  const [filter, setFilter] = useState({
    order_type: "",
    bill_type: ""
  });
  const [loading, setLoading] = useState(false);
  const foodCategoryLoader = useSelector(
    (state) => state.api.foodCategoryLoader,
  );
  const [selectValue, setSelectValue] = useState("TODAY");
  const userPermission = useSelector((state) => state.user.userPermission);
  const layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();
  const hasExportPermission =
    userPermission.length > 0
      ? JSON.parse(
        userPermission?.find((a) => a.resource === RESOURCE.SALES_REPORT)
          ?.permission,
      )?.DOWNLOAD
      : true;
  const [reportInfo, setReportInfo] = useState([]);

  useEffect(() => {
    let initialInterval = {
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().add(1, "days").format("YYYY-MM-DD"),
    };
    setInitialReportDate(initialInterval);
    salesReportFun(initialInterval);
    addCurrentScreenTitle(layoutDispatch, {
      currentScreenTitle: t("sales-report-title-mobile-screen"),
    });
    return () => {
      addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
    };
  }, []);

  const salesReportFun = (body) => {
    setInitialReportDate(body);
    let newData = {
      fromDate: moment(body?.fromDate).format("YYYY-MM-DD"),
      toDate: moment(body?.toDate).format("YYYY-MM-DD"),
    };

    setLoading(true);
    getSalesReportData(newData)
      .then((res) => {
        setSalesReportData(res?.data);
        setReportInfo(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleFilter = (event) => {
    var filterVal = event?.target?.value;
    let orderHistorydata;
    setFilter({ ...filter, order_type: filterVal })
    switch (filterVal) {
      case "All":
        orderHistorydata = reportInfo;
        break;
      case "Dine In":
        orderHistorydata = reportInfo.filter(
          (item) => item?.order_type === "Dine In",
        );
        break;
      case "Parcel/Take away":
        orderHistorydata = reportInfo.filter(
          (item) => item?.order_type === "Parcel/Take away",
        );
        break;
      default:
        orderHistorydata = reportInfo;
        break;
    }
    setSalesReportData(orderHistorydata);
  };

  const handlePaymentFilter = (event) => {
    var filterVal = event?.target?.value;
    let orderHistorydata;
    setFilter({ ...filter, bill_type: filterVal });
    switch (filterVal) {
      case "All":
        orderHistorydata = reportInfo;
        break;
      case "UPI":
        orderHistorydata = reportInfo.filter(
          (item) => item?.bill_types === "UPI",
        );
        break;
      case "CASH":
        orderHistorydata = reportInfo.filter(
          (item) => item?.bill_types === "CASH",
        );
        break;
      default:
        orderHistorydata = reportInfo;
        break;
    }
    setSalesReportData(orderHistorydata);
  };

  const handleReportByInterval = (event) => {
    var intervalPeriod = event?.target?.value;
    setSelectValue(intervalPeriod);
    if (intervalPeriod === "WEEKLY") {
      let initialInterval = {
        fromDate: moment().startOf("week").format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    } else if (intervalPeriod === "MONTHLY") {
      let initialInterval = {
        fromDate: moment().startOf("month").format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    } else if (intervalPeriod === "YEARLY") {
      let initialInterval = {
        fromDate: moment().startOf("year").format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    } else if (intervalPeriod === "YESTERDAY") {
      let initialInterval = {
        fromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    } else {
      let initialInterval = {
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().add(1, "days").format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    }
  };

  const exportPDF = () => {
    doc.setFontSize(15);

    const title = `${t("sales-report-title-mobile-screen")} of ${initialReportDate?.fromDate} to ${initialReportDate?.toDate}`;
    const headers = [
      [
        t("sales-report-billno"),
        t("sales-report-bill-date"),
        t("sales-report-bill-type"),
        t("sales-report-order-type"),
        t("sales-report-amount"),
      ],
    ];


    const data = salesReportData.map((item) => [
      item?.bill_no,
      item?.bill_date && moment(item?.bill_date).format("DD/MM/YYYY hh:mm"),
      item?.bill_types,
      item?.order_type,
      item?.amount,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Sales-Report.pdf");
  };

  return (
    <Container fluid>
      <div className={classes.tabel}>
        <div className={classes.header}>
          <Form
            onSubmit={salesReportFun}
            initialValues={initialReportDate}
            validate={(values) => {
              const errors = {};

              if (
                values.fromDate > values.toDate &&
                values.toDate &&
                values.fromDate
              ) {
                errors.fromDate = t("date-error-message");
              }

              return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={3} sm={4}>
                    <FormControl style={{ width: "100%", marginTop: "10px" }}>
                      <TextInputMui
                        select
                        label={t("calendar-wise-label")}
                        variant="outlined"
                        labelId="demo-simple-select-label"
                        value={selectValue}
                        onChange={handleReportByInterval}
                      >
                        <MenuItem value="TODAY">
                          {t("analytics-chart-today-label")}
                        </MenuItem>
                        <MenuItem value="YESTERDAY">
                          {t("analytics-chart-yesterday-label")}
                        </MenuItem>
                        <MenuItem value="WEEKLY">
                          {t("analytics-current-week")}
                        </MenuItem>
                        <MenuItem value="MONTHLY">
                          {t("analytics-current-month")}
                        </MenuItem>
                        <MenuItem value="YEARLY">
                          {t("analytics-current-year")}
                        </MenuItem>
                      </TextInputMui>
                    </FormControl>
                  </Col>
                  <Col md={3} sm={4}>
                    <Field
                      name="fromDate"
                      type="date"
                      initialValue={moment().format("YYYY-MM-DD")}
                    >
                      {({ input, meta }) => (
                        <FormControl
                          style={{ width: "100%", marginTop: "10px" }}
                        >
                          <TextInputMui
                            {...input}
                            type="date"
                            defaultValue={moment().format("YYYY-MM-DD")}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={t("analytics-from-date")}
                            error={meta.error && meta.touched ? true : false}
                          />

                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col md={3} sm={4}>
                    <Field name="toDate">
                      {({ input, meta }) => (
                        <FormControl
                          style={{ width: "100%", marginTop: "10px" }}
                        >
                          <TextInputMui
                            {...input}
                            type="date"
                            variant="outlined"
                            label={t("analytics-to-date")}
                            error={meta.error && meta.touched ? true : false}
                          />
                          {/* <TextField
                            {...input}
                            type="date"
                            variant="outlined"
                            label="To Date"
                            error={meta.error && meta.touched ? true : false}
                          /> */}
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                  <Col md={3} sm={4} className="align-self-center h-100">
                    <Stack direction={"row"} gap={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          backgroundColor: theme?.palette?.primary?.main,
                          textTransform: 'none',
                          paddingY: 2,
                          marginTop: 1
                        }}
                        type="submit"
                        size="medium"
                      >
                        {loading ? t("search-processing-label") : t("search")}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: theme?.palette?.primary?.servofeat,
                          textTransform: 'none',
                          paddingY: 2,
                          marginTop: 1
                        }}
                        type="button"
                        size="medium"
                        onClick={exportPDF}
                      >
                        Export PDF
                      </Button>
                    </Stack>
                  </Col>
                </Row>
              </form>
            )}
          />
          <hr />
          <Row>
            <Col md={3} sm={4}>
              <FormControl style={{ width: "100%", marginTop: "10px" }}>
                <TextInputMui
                  select
                  label={t("sales-report-order-type")}
                  variant="outlined"
                  labelId="demo-simple-select-label"
                  value={filter.order_type}
                  onChange={handleFilter}
                >
                  <MenuItem value="All">{t("all-label")}</MenuItem>
                  <MenuItem value="Dine In">{t("dine-in-label")}</MenuItem>
                  <MenuItem value="Parcel/Take away">
                    {t("parcel-label")}
                  </MenuItem>
                </TextInputMui>
              </FormControl>
            </Col>
            <Col md={3} sm={4}>
              <FormControl style={{ width: "100%", marginTop: "10px" }}>
                <TextInputMui
                  select
                  label={t("sales-report-bill-type")}
                  variant="outlined"
                  labelId="demo-simple-select-label"
                  value={filter.bill_type}
                  onChange={handlePaymentFilter}
                >
                  <MenuItem value="All">{t("all-label")}</MenuItem>
                  <MenuItem value="UPI">{t("upi-label")}</MenuItem>
                  <MenuItem value="CASH">{t("cash-label")}</MenuItem>
                </TextInputMui>
              </FormControl>
            </Col>
          </Row>
          <MaterialTable
            style={{ padding: "20px" }}
            title={
              <h5>
                <b>{t("sales-report-title-mobile-screen")}</b>
              </h5>
            }
            columns={[
              {
                title: t("sales-report-bill-number-label"),
                field: "bill_no",
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("sales-report-bill-date"),
                field: "bill_date",
                cellStyle: {
                  width: "20%",
                },
                // render: (rowData) =>
                //   rowData?.bill_date &&
                //   moment(rowData?.bill_date).format("DD/MM/YYYY hh:mm"),
              },
              {
                title: t("sales-report-bill-type"),
                field: "bill_types",
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("sales-report-order-type"),
                field: "order_type",
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("sales-report-amount"),
                field: "amount",
                cellStyle: {
                  width: "20%",
                },
                render: (rowData) =>
                  rowData.bill_types === "Total amount" ? (
                    <Label
                      variant={
                        theme.palette.mode === "light" ? "ghost" : "filled"
                      }
                      color={"info"}
                    >
                      {"₹ " +
                        (parseFloat(rowData?.amount) > 0
                          ? parseFloat(rowData?.amount).toFixed(2)
                          : "0.0")}
                    </Label>
                  ) : (
                    <>
                      {"₹ " +
                        (parseFloat(rowData?.amount) > 0
                          ? parseFloat(rowData?.amount).toFixed(2)
                          : "0.0")}
                    </>
                  ),
              },
            ]}
            data={
              salesReportData &&
              salesReportData?.map((item, index) => {
                return {
                  bill_no: item.bill_no,
                  bill_date:
                    item?.bill_date &&
                    moment(item?.bill_date).format("DD/MM/YYYY hh:mm"),
                  bill_types: item.bill_types,
                  amount: item.amount,
                  order_type: item.order_type && (
                    <>
                      <div>
                        <Label
                          variant={
                            theme.palette.mode === "light" ? "ghost" : "filled"
                          }
                          color={
                            item?.order_type === "Dine In" ? "info" : "success"
                          }
                        >
                          {item?.order_type}
                        </Label>
                      </div>
                    </>
                  ),
                };
              })
            }
            options={{
              exportButton: hasExportPermission
                ? {
                  pdf: false,
                  csv: true,
                }
                : false,
              exportFileName: `salesReport-${moment().format("DD-MM-YYYY")}`,
              searchFieldStyle: {
                color: theme.palette.grey[500],
                borderColor: "rgba(0, 0, 0, 0.23)",
                border: "0.8px solid",
                borderRadius: "6px",
                padding: "10px",
                "&:before": {
                  borderBottom: "0px!important",
                },
                "&:after": {
                  borderBottom: "0px!important",
                },
              },
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              },
              pageSize: 10,
              paging: false,
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 20, 30],
              sorting: true,
              actionsColumnIndex: -1,
            }}
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: foodCategoryLoader ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>
                      {t("record-not-found-message")}
                    </p>
                  </NotFoundComponent>
                ),
              },
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default OrderReport;


import { useMediaQuery } from 'react-responsive'

export const useInDeskTopOrLaptop = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
      return isDesktopOrLaptop;
}

export const useInDeskTop = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 990px)'
      })
      return isDesktopOrLaptop;
}

export const useInTabletOrMobile = () => {
    const isTableOrMobile = useMediaQuery({
        query: '(max-width: 990px)'
      })
      return isTableOrMobile;
}

export const useInMobile = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 990px)'
      })
      return isMobile;
}

export const DeskTopContainer = ({children}) => {
    const isDeskTop = useInDeskTop();
    return isDeskTop ? children : null;
}

export const MobileContainer = ({ children }) => {
  const isMobile = useInMobile();
  return isMobile ? children : null;
}


import React from "react";
import { checkIfElectronLoaded, isJson } from "utility/helpers";

export const printSettleStructureData = (printModal, printerSize) => {
  return [
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.shopName,
      style: `text-align:center;font-family: 'Calibri', serif;`,
      css: { "font-weight": "700", "font-size": printerSize > 180 ? "18px" : "15px"},
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.shopAddress,
      style: `text-align:center;border-bottom: 1px solid black;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Name : ${printModal?.customerName}`,
      style: `text-align:left;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Date : ${printModal?.date}`,
      style: `text-align:left;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value:  `Token No: ${printModal?.tokenNo + "  " + printModal?.orderType}`,
      style: `text-align:center; font-weight:900;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value:  `Order No: ${printModal?.orderNo}`,
      style: `text-align:center; font-weight:900;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "table",
      style: "border: 1px solid #ddd;font-family: 'Calibri', serif;", // style the table
      // list of the columns to be rendered in the table header
      tableHeader: [
        { type: "text", value: "ITEM" },
        { type: "text", value: "QTY" },
        { type: "text", value: "AMT" },
      ],
      // multi dimensional array depicting the rows and columns of the table body
      tableBody:  printModal?.printFoodItemArray?.length > 0 && printModal?.printFoodItemArray?.map((item, index) => {
        return (
          [
            { type: "text",
            value: item?.foodName,
            style: `text-align:left;font-family: 'Calibri', serif;`,
            css: { "font-size": printerSize > 180 ? "15px" : "12px" } 
          },
          { type: "text",
           value: item?.qty,
            style: `text-align:center;font-family: 'Calibri', serif;`,
            css: { "font-size": printerSize > 180 ? "15px" : "12px" } },
          {
            type: "text",
            value: item?.price,
            style: `text-align:center;font-family: 'Calibri', serif;`,
            css: { "font-size": printerSize > 180 ? "15px" : "12px" }
          }]
        );
      }),
      // list of columns to be rendered in the table footer
      // tableFooter: [{ type: "text", value: "Total Amount" }],
      // custom style for the table header
      tableHeaderStyle: "color: #000; font-weight: 'bold'; font-family: 'Calibri', serif;",
      // custom style for the table body
      tableBodyStyle: "border: 0.5px solid #ddd;font-family: 'Calibri', serif;",
      // custom style for the table footer
      // tableFooterStyle: "background-color: #000; color: white;",
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Sub Total: ₹ ${printModal?.subTotal}`,
      style: `text-align:right;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `<div>${printModal?.tax}</div>`,
      style: `text-align:right;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "13px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.discount,
      style: `text-align:right;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "13px" : "12px" }
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Net Total : ₹ ${printModal?.netTotal}`,
      style: `text-align:right;background-color: #eee;padding: 2px 4px;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "16px" : "13px"},
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `<b> Thank you. Visit Again </b>`,
      style: `text-align:center;border-top: 1px solid black;margin-bottom: 2px;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
  ];
};

export const kotPrintStructureData = (printModal, printerSize) => {
  return [
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.shopName,
      style: `text-align:center;font-family: 'Calibri', serif;`,
      css: { "font-weight": "700", "font-size":printerSize > 180 ? "18px" : "15px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Name : ${printModal?.customerName}`,
      style: `text-align:left;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Date : ${printModal?.date}`,
      style: `text-align:left;font-family: 'Calibri', serif;`,
      css: { "font-size":printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value:  `Token No: ${printModal?.tokenNo + "  " + printModal?.orderType}`,
      style: `text-align:center; font-weight:900;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value:  `Order No: ${printModal?.orderNo}`,
      style: `text-align:center; font-weight:900;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "table",
      style: "border: 1px solid #ddd;font-family: 'Calibri', serif;", // style the table
      // list of the columns to be rendered in the table header
      tableHeader: [
        { type: "text", value: "ITEM" },
        { type: "text", value: "QTY" },
      ],
      // multi dimensional array depicting the rows and columns of the table body
      tableBody: printModal?.printFoodItemArray?.map((item, index) => {
        return (
          [
            { type: "text",
            value: item?.foodName,
            style: `text-align:left;font-family: 'Calibri', serif;`,
            css: { "font-size": printerSize > 180 ? "15px" : "12px" } 
          },
          { type: "text",
           value: item?.qty,
            style: `text-align:center;font-family: 'Calibri', serif;`,
            css: { "font-size": printerSize > 180 ? "15px" : "12px"} }
         ]
        );
      }),
      // list of columns to be rendered in the table footer
      // tableFooter: [{ type: "text", value: "Total Amount" }],
      // custom style for the table header
      tableHeaderStyle: "color: #000; font-weight: 'bold'; color: #000;font-family: 'Calibri', serif;",
      // custom style for the table body
      tableBodyStyle: "border: 0.5px solid #ddd;font-family: 'Calibri', serif;",
      // custom style for the table footer
      // tableFooterStyle: "background-color: #000; color: white;",
    }
  ];
};


export const electronPrintFormatData = (billingData, orderData, printerName, printerSize) => {
      if(orderData)
      {
        let orderFoodItemData = [];
        if(orderData?.order_meal_items?.length > 0)
        {
          orderFoodItemData =  orderData?.order_meal_items
        }
        else if(orderData?.fooditems?.length > 0)
        {
          orderFoodItemData =  orderData?.fooditems
        }
        const printFoodItemArray =
        orderFoodItemData?.length > 0 && orderFoodItemData?.map((item) => {
            let foodFullName = "";
            let foodItemName = item?.fooditem?.name
              ? item.fooditem?.name
              : item?.food_item_id?.label;
            let foodVariantName = "";
            let foodAddOnObj = isJson(item?.addons)
              ? JSON.parse(item?.addons)
                  ?.map((addOn) => addOn.name)
                  .join(", ")
              : "";
            if (item?.varient_id > 0 && item?.fooditem?.food_variants?.length > 0) {
              foodVariantName = item?.fooditem?.food_variants.find(
                ({ id }) => id === item?.varient_id,
              )?.name;
            }
    
            foodFullName = `${
              foodItemName +
              (foodVariantName ? "( " + foodVariantName + ") " : "") +
              foodAddOnObj
            }`;
    
            return {
              foodName: foodFullName,
              qty: item?.qty,
              price: `₹ ${parseFloat(item?.sub_total).toFixed(2)}`,
            };
          });
    
        const printModal = {
          shopName: billingData?.name,
          shopAddress: billingData?.address
            ? billingData?.address + "- " + billingData?.phone
            : billingData?.phone
            ? billingData?.phone
            : "",
          customerName: orderData?.name_of_customer + "  " + orderData?.phone,
          date: new Date().toDateString() + " " + new Date().toLocaleTimeString(),
          tokenNo: parseInt(orderData?.token_no) ? parseInt(orderData?.token_no) : "",
          orderNo: orderData?.id ? parseInt(orderData?.id) : parseInt(orderData?.token),
          orderType: " (" + orderData?.order_type + ")",
          printFoodItemArray: printFoodItemArray,
          subTotal: orderData?.sub_total,
          tax:
            parseFloat(orderData?.tax) > 0
              ? `GST :  ₹ ${parseFloat(orderData?.tax)}`
              : " ",
          discount: orderData?.discount
            ? `Discount ${
              orderData?.discount_sign == null ||
              orderData?.discount_sign === "INR"
                  ? "₹"
                  : orderData?.discount_sign
              }: ` + orderData?.discount
            : "",
          netTotal: orderData?.total_bill,
        };

        let billFormatStructure = printSettleStructureData(printModal, printerSize);
          console.log(printerName, printerSize);
          if (typeof window?.printData != "undefined" && checkIfElectronLoaded()) {
            window?.printData(
                printerName,
                printerSize ? printerSize + "px" : "200px",
                billFormatStructure,
                false,
            );
            return "successfully trigger"
          }
          else {
            return "Failed"
          }
      }
}


export const printArray = [
  {
    type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
    value: "||---",
    style: `text-align:left;`,
    css: { "font-size": "12px" },
  },
  {
    type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
    value: "HEADER",
    style: `text-align:center;`,
    css: { "font-weight": "700", "font-size": "18px" },
  },
  // {
  //   type: "image",
  //   path: path.join(__dirname, "assets/img_test.png"), // file path
  //   position: "center", // position of image: 'left' | 'center' | 'right'
  //   width: "auto", // width of image in px; default: auto
  //   height: "60px", // width of image in px; default: 50 or '50px'
  // },
  // {
  //   type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
  //   value:
  //     "Lorem ipsum<br><br> . , ; : ( ) - + = ! # % \" ' <br><br> ã Ã ç Ç $ & @ ê Ê í Í<br><br> 0 1 2 3 4 5 6 7 8 9 <br>a b c d e f g h i j k l m n o p q r s t u v w x y z<br>A B C D E F G H I J K L M N O P Q R S T U V W X Y Z<br><br><hr><br>elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation \n ullamco \n laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum<br>",

  //   css: {
  //     "font-size": "12px",
  //     "font-family": "sans-serif",
  //     "text-align": "center",
  //   },
  // },
  // {
  //   type: "barCode", // Do you think the result is ugly? Me too. Try use an image instead...
  //   value: "HB4587896",
  //   height: 12,
  //   width: 1,
  //   displayValue: true, // Display value below barcode
  //   fontsize: 8,
  // },
  // {
  //   type: "qrCode",
  //   value: "https://github.com/fssonca",
  //   height: 80,
  //   width: 80,
  //   style: "margin-left:50px",
  // },
  //{
  //     type: 'table',
  //     // style the table
  //     style: 'border: 1px solid #ddd',
  //     // list of the columns to be rendered in the table header
  //     tableHeader: ['Animal', 'Age'],
  //     // multi dimensional array depicting the rows and columns of the table body
  //     tableBody: [
  //         ['Cat', 2],
  //         ['Dog', 4],
  //         ['Horse', 12],
  //         ['Pig', 4],
  //     ],
  //     // list of columns to be rendered in the table footer
  //     tableFooter: ['Animal', 'Age'],
  //     // custom style for the table header
  //     tableHeaderStyle: 'background-color: #000; color: white;',
  //     // custom style for the table body
  //     tableBodyStyle: 'border: 0.5px solid #ddd',
  //     // custom style for the table footer
  //     tableFooterStyle: 'background-color: #000; color: white;',
  //  },{
  //     type: 'table',
  //     style: 'border: 1px solid #ddd',             // style the table
  //     // list of the columns to be rendered in the table header
  //     tableHeader: [{type: 'text', value: 'Animal'}, {type: 'image', path: path.join(__dirname, 'icons/animal.png')}],
  //     // multi dimensional array depicting the rows and columns of the table body
  //     tableBody: [
  //         [{type: 'text', value: 'Cat'}, {type: 'image', path: './animals/cat.jpg'}],
  //         [{type: 'text', value: 'Dog'}, {type: 'image', path: './animals/dog.jpg'}],
  //         [{type: 'text', value: 'Horse'}, {type: 'image', path: './animals/horse.jpg'}],
  //         [{type: 'text', value: 'Pig'}, {type: 'image', path: './animals/pig.jpg'}],
  //     ],
  //     // list of columns to be rendered in the table footer
  //     tableFooter: [{type: 'text', value: 'Animal'}, 'Image'],
  //     // custom style for the table header
  //     tableHeaderStyle: 'background-color: #000; color: white;',
  //     // custom style for the table body
  //     tableBodyStyle: 'border: 0.5px solid #ddd',
  //     // custom style for the table footer
  //     tableFooterStyle: 'background-color: #000; color: white;',
  //  },
];

const initialState = {
  foodMenuData: [],
  selectItem: [],
  finelBill: 0.00,
  checkoutPage: false
};

const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FOOD_MENU":
      return { ...state, foodMenuData: action.payload };
    case "GET_SELECT_ITEM":
      return { ...state, selectItem: action.payload };
    case "GET_FINEL_BILL":
      console.log(action.payload)
      return { ...state, finelBill: action.payload };
    case "GET_CHECKOUT":
      console.log(action.payload)
      return { ...state, checkoutPage: action.payload };
    default:
      return state;
  }
};

export default CustomerReducer;

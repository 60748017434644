export const customerOrderData = {
    "status": 200,
    "message": "Order fetch Successfully",
    "data": [
      {
        "id": 533,
        "created_date": "2022-09-06T11:12:55.591Z",
        "updated_date": "2022-09-06T11:12:55.591Z",
        "name_of_customer": "Test",
        "phone": "1231232121",
        "no_of_person": null,
        "total_bill": "443.10",
        "bill_type": "CASH",
        "is_complete": false,
        "discount_sign": "INR",
        "discount": null,
        "tax": "21.10",
        "shop_id": 7,
        "sub_total": "422.00",
        "order_type": "PARCEL",
        "token": null,
        "bill_note": null,
        "is_auto_kot_print": null,
        "razor_pay_id": null,
        "is_delete": false,
        "is_online": false,
        "is_online_accepted": "CREATED",
        "staff_id": null,
        "table_id": 0,
        "token_no": 5,
        "staff": null,
        "diningtable": null,
        "customer": {
          "id": 5,
          "created_date": "2022-07-23T10:20:24.613Z",
          "updated_date": "2022-07-23T10:20:24.613Z",
          "name": "Test",
          "address": null,
          "mobile": "1231232121",
          "email": "test@gmail.com",
          "shop_id": 7,
          "dept": null,
          "credit": null,
          "isBlocked": false
        },
        "order_meal_items": [
          {
            "id": 1620,
            "created_date": "2022-09-06T11:12:55.621Z",
            "updated_date": "2022-09-06T11:12:55.621Z",
            "qty": 1,
            "price": 12,
            "total_qty_price": 12,
            "addon_price": 0,
            "total_addon_qty_price": 0,
            "sub_total": 12,
            "shop_id": 7,
            "is_non_veg": false,
            "addons": "",
            "food_item_id": 162,
            "varient_id": 0,
            "order_id": 533,
            "instruction_note": "",
            "fooditem": {
              "id": 162,
              "created_date": "2022-09-05T16:05:28.109Z",
              "updated_date": "2022-09-05T16:05:28.109Z",
              "category_id": 25,
              "name": "Hello",
              "price": 12,
              "description": "1234",
              "is_active": true,
              "is_non_veg": false,
              "food_image": null,
              "shop_id": 7,
              "is_priority": null,
              "food_item_addons": [],
              "food_variants": [],
              "food_inventory": [],
              "category": {
                "id": 25,
                "created_date": "2022-09-05T15:43:14.311Z",
                "updated_date": "2022-09-05T15:43:14.311Z",
                "name": "Helo",
                "shop_id": 7,
                "is_active": true,
                "category_image": null,
                "description": "Hello 2",
                "is_priority": null,
                "food_item_addons": []
              }
            },
            "kitchan_queues": [
              {
                "id": 1622,
                "created_date": "2022-09-06T11:12:55.627Z",
                "updated_date": "2022-09-06T11:12:55.627Z",
                "qty": 1,
                "status": "IN_PROGRESS",
                "types": "NEW",
                "shop_id": 7,
                "table_id": 0,
                "varient_id": null,
                "order_id": 533,
                "food_meal_id": 1620
              }
            ]
          },
          {
            "id": 1621,
            "created_date": "2022-09-06T11:12:55.648Z",
            "updated_date": "2022-09-06T11:12:55.648Z",
            "qty": 1,
            "price": 50,
            "total_qty_price": 50,
            "addon_price": 0,
            "total_addon_qty_price": 0,
            "sub_total": 50,
            "shop_id": 7,
            "is_non_veg": false,
            "addons": "",
            "food_item_id": 40,
            "varient_id": 0,
            "order_id": 533,
            "instruction_note": "",
            "fooditem": {
              "id": 40,
              "created_date": "2022-07-21T05:17:14.290Z",
              "updated_date": "2022-07-21T05:17:14.290Z",
              "category_id": 7,
              "name": "Cheese Puff",
              "price": 50,
              "description": null,
              "is_active": true,
              "is_non_veg": false,
              "food_image": null,
              "shop_id": 7,
              "is_priority": 0,
              "food_item_addons": [],
              "food_variants": [],
              "food_inventory": [],
              "category": {
                "id": 7,
                "created_date": "2022-07-21T05:15:17.162Z",
                "updated_date": "2022-07-21T05:15:17.162Z",
                "name": "PUFF",
                "shop_id": 7,
                "is_active": true,
                "category_image": null,
                "description": null,
                "is_priority": 23,
                "food_item_addons": []
              }
            },
            "kitchan_queues": [
              {
                "id": 1623,
                "created_date": "2022-09-06T11:12:55.653Z",
                "updated_date": "2022-09-06T11:12:55.653Z",
                "qty": 1,
                "status": "IN_PROGRESS",
                "types": "NEW",
                "shop_id": 7,
                "table_id": 0,
                "varient_id": null,
                "order_id": 533,
                "food_meal_id": 1621
              }
            ]
          },
          {
            "id": 1622,
            "created_date": "2022-09-06T11:12:55.670Z",
            "updated_date": "2022-09-06T11:12:55.670Z",
            "qty": 1,
            "price": 150,
            "total_qty_price": 150,
            "addon_price": 0,
            "total_addon_qty_price": 0,
            "sub_total": 150,
            "shop_id": 7,
            "is_non_veg": false,
            "addons": "",
            "food_item_id": 13,
            "varient_id": 0,
            "order_id": 533,
            "instruction_note": "",
            "fooditem": {
              "id": 13,
              "created_date": "2022-07-21T05:01:33.170Z",
              "updated_date": "2022-07-21T05:01:33.170Z",
              "category_id": 4,
              "name": "Club Sandwich",
              "price": 150,
              "description": null,
              "is_active": true,
              "is_non_veg": false,
              "food_image": null,
              "shop_id": 7,
              "is_priority": 0,
              "food_item_addons": [],
              "food_variants": [],
              "food_inventory": [],
              "category": {
                "id": 4,
                "created_date": "2022-07-21T04:59:43.864Z",
                "updated_date": "2022-07-21T04:59:43.864Z",
                "name": "Sp GRILL SANDWICH",
                "shop_id": 7,
                "is_active": true,
                "category_image": null,
                "description": null,
                "is_priority": 16,
                "food_item_addons": []
              }
            },
            "kitchan_queues": [
              {
                "id": 1624,
                "created_date": "2022-09-06T11:12:55.677Z",
                "updated_date": "2022-09-06T11:12:55.677Z",
                "qty": 1,
                "status": "IN_PROGRESS",
                "types": "NEW",
                "shop_id": 7,
                "table_id": 0,
                "varient_id": null,
                "order_id": 533,
                "food_meal_id": 1622
              }
            ]
          },
          {
            "id": 1623,
            "created_date": "2022-09-06T11:12:55.700Z",
            "updated_date": "2022-09-06T11:12:55.700Z",
            "qty": 1,
            "price": 150,
            "total_qty_price": 150,
            "addon_price": 0,
            "total_addon_qty_price": 0,
            "sub_total": 150,
            "shop_id": 7,
            "is_non_veg": false,
            "addons": "",
            "food_item_id": 21,
            "varient_id": 0,
            "order_id": 533,
            "instruction_note": "",
            "fooditem": {
              "id": 21,
              "created_date": "2022-07-21T05:04:34.217Z",
              "updated_date": "2022-07-21T05:04:34.217Z",
              "category_id": 4,
              "name": "Tanduri Panner Sandwich",
              "price": 150,
              "description": null,
              "is_active": true,
              "is_non_veg": false,
              "food_image": null,
              "shop_id": 7,
              "is_priority": 0,
              "food_item_addons": [],
              "food_variants": [],
              "food_inventory": [],
              "category": {
                "id": 4,
                "created_date": "2022-07-21T04:59:43.864Z",
                "updated_date": "2022-07-21T04:59:43.864Z",
                "name": "Sp GRILL SANDWICH",
                "shop_id": 7,
                "is_active": true,
                "category_image": null,
                "description": null,
                "is_priority": 16,
                "food_item_addons": []
              }
            },
            "kitchan_queues": [
              {
                "id": 1625,
                "created_date": "2022-09-06T11:12:55.705Z",
                "updated_date": "2022-09-06T11:12:55.705Z",
                "qty": 1,
                "status": "IN_PROGRESS",
                "types": "NEW",
                "shop_id": 7,
                "table_id": 0,
                "varient_id": null,
                "order_id": 533,
                "food_meal_id": 1623
              }
            ]
          },
          {
            "id": 1624,
            "created_date": "2022-09-06T11:12:55.719Z",
            "updated_date": "2022-09-06T11:12:55.719Z",
            "qty": 1,
            "price": 60,
            "total_qty_price": 60,
            "addon_price": 0,
            "total_addon_qty_price": 0,
            "sub_total": 60,
            "shop_id": 7,
            "is_non_veg": false,
            "addons": "",
            "food_item_id": 65,
            "varient_id": 0,
            "order_id": 533,
            "instruction_note": "",
            "fooditem": {
              "id": 65,
              "created_date": "2022-07-21T05:36:24.376Z",
              "updated_date": "2022-07-21T05:36:24.376Z",
              "category_id": 1,
              "name": "Veg Cheese Sandwich",
              "price": 60,
              "description": null,
              "is_active": true,
              "is_non_veg": false,
              "food_image": null,
              "shop_id": 7,
              "is_priority": 0,
              "food_item_addons": [],
              "food_variants": [
                {
                  "id": 8,
                  "created_date": "2022-07-21T05:36:24.385Z",
                  "updated_date": "2022-07-21T05:36:24.385Z",
                  "name": "Gril",
                  "price": 70,
                  "description": null,
                  "is_active": true,
                  "is_default": false,
                  "shop_id": 7
                }
              ],
              "food_inventory": [],
              "category": {
                "id": 1,
                "created_date": "2022-07-21T04:42:19.786Z",
                "updated_date": "2022-07-21T04:42:19.786Z",
                "name": "Reguler SANDWICH",
                "shop_id": 7,
                "is_active": true,
                "category_image": null,
                "description": null,
                "is_priority": 18,
                "food_item_addons": []
              }
            },
            "kitchan_queues": [
              {
                "id": 1626,
                "created_date": "2022-09-06T11:12:55.724Z",
                "updated_date": "2022-09-06T11:12:55.724Z",
                "qty": 1,
                "status": "IN_PROGRESS",
                "types": "NEW",
                "shop_id": 7,
                "table_id": 0,
                "varient_id": null,
                "order_id": 533,
                "food_meal_id": 1624
              }
            ]
          }
        ]
      }
    ]
  }
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React from "react";
// import { Row, Col, Container, Badge } from "react-bootstrap";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import salePrice from "../../assets/img/sale-price.png";
import "./MobileSalesHistory.styles.css";
// import MenuButtonEditDelete from "components/MenuButtonEditDelete";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import {
  Delete,
  // Edit,
  ViewList,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import { makeShortText } from "utility/makeShortText";
import { useTranslation } from "react-i18next";
import { Card, Stack } from "@mui/material";
import Label from "components/Label";
// import CardFooter from "components/Card/CardFooter";

const useStyles = makeStyles((theme) => ({
  customerHeader: {
    backgroundColor: "#eee",
    borderRadius: "9px",
    borderColor: "#d7d7d7 !important",
    borderWidth: "1px",
    boxShadow: "0px !important",
    display: "flex",
    height: "45px",
    minHeight: "45px !important",
    "& .MuiAccordionSummary-content": {
      margin: "0px",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      height: "45px",
    },
  },
  date: {
    marginLeft: "185px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "11px",
    marginBottom: "0",
  },
  orderType: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#636363",
  },
  iconSize: {
    fontSize: "1rem",
    padding: "0.5rem",
  },
  badge: {
    fontStyle: "normal",
    fontSize: "inherit",
    marginTop: "2px !important",
  },
  orderlbl: {
  },
  visibilityicon: {
    right: "5px",
    position: "absolute",
    fontSize: "28px",
  },
  createddate: {
    fontSize: "12px",
  },
  cardTitle: {
    display: "flex",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "22px",
    width: "25%",
  },
  totalbill: {
    display: "flex",
    width: "30%",
  },
  eye: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "11px",
    marginBottom: "0",
    width: "10%",
    right: "6px",
  },
  orderCard: {
    padding: '7px 7px'
  },
}));

const MobileSalesHistory = (props) => {
  const {
    orderId,
    deleteData,
    // editData,
    itemId,
    customerName,
    createdDate,
    order_type,
    totalBill,
    tableNo,
    viewData,
    // printData,
    orderDetail,
  } = props;
  const classes = useStyles();
  let history = useHistory();
  const { t } = useTranslation();

  return (
    <Card className="mb-3">
      <Stack
        gap={1}
        className={classes.orderCard}
        style={{ background: orderDetail?.is_complete ? 'rgb(0 128 0 / 10%)' : '#eee' }}
        onClick={() => {
          if (orderDetail.is_complete) return;
          orderDetail?.orderDetail?.order_type === "PARCEL"
            ? history.push({
              pathname:
                orderDetail?.id === 0 || orderDetail?.id == null
                  ? `/app/order/parcel/`
                  : `/app/order/parcel/${orderDetail?.id}`,
              tableId: "PARCEL",
            })
            : history.push({
              pathname:
                orderDetail?.id === 0 || orderDetail?.id == null
                  ? `/app/dine-in/order/`
                  : `/app/dine-in/order/${orderDetail?.id}`,
              tableId: orderDetail?.tableId,
            });
        }}>
        <Stack gap={2} direction="row" className="align-items-center justify-content-between">
          <div>
            <strong>TOKEN No:</strong>&nbsp; {orderDetail?.token_no}
          </div>
          <div>
            <strong>{t("order-label")}:- </strong>
            <Label color="info">
              {orderId}
            </Label>
          </div>
          <div className={classes.customername}>
            {customerName && makeShortText(customerName)}
          </div>
          <div>{totalBill}</div>
        </Stack>
        <Stack gap={2} direction="row" className="justify-content-between">
          <Stack>
            <div className={classes.orderType}>
              {t("sales-report-order-type")}: <span>{order_type}</span>
              {
                (tableNo && order_type !== "PARCEL") && <>
                  {t("table-no-label")} <span>&nbsp; {tableNo}</span> </>}
            </div>
          </Stack>
          <Stack>
            <div className={classes.createddate}>{createdDate}</div>
          </Stack>
        </Stack>
      </Stack>
      <hr style={{ margin: '1px' }} />
      <div style={{ paddingLeft: 6, paddingRight: 6 }}>
        {orderDetail?.is_complete && <Label color="success">{orderDetail?.is_complete ? "COMPLETED" : ""}</Label>}
        <RoleBasedGuard
          resource={RESOURCE.ORDER_HISTORY}
          action={ACTION.VIEW}
        >
          <IconButton
            color="secondary"
            title={t("order-history-view-order-details-label")}
            aria-label={t("order-history-view-order-details-label")}
            onClick={() => {
              viewData(orderDetail);
            }}
            className={classes.iconSize}
          >
            <ViewList />
          </IconButton>
        </RoleBasedGuard>
        <RoleBasedGuard
          resource={RESOURCE.ORDER_HISTORY}
          action={ACTION.DELETE}
        >
          <IconButton
            color="secondary"
            title={t("order-history-delete-order-label")}
            aria-label={t("order-history-delete-order-label")}
            onClick={() => {
              deleteData(itemId);
            }}
            className={classes.iconSize}
          >
            <Delete />
          </IconButton>
        </RoleBasedGuard>
        <RoleBasedGuard
          resource={RESOURCE.ORDER_HISTORY}
          action={ACTION.VIEW}
        >
          {!orderDetail?.is_complete && (
            <IconButton
              color="primary"
              title={t("order-history-preview-order-label")}
              aria-label={t("order-history-preview-order-label")}
              onClick={() => {
                orderDetail?.orderDetail?.order_type === "PARCEL"
                  ? history.push({
                    pathname:
                      orderDetail?.id === 0 || orderDetail?.id == null
                        ? `/app/order/parcel/`
                        : `/app/order/parcel/${orderDetail?.id}`,
                    tableId: "PARCEL",
                  })
                  : history.push({
                    pathname:
                      orderDetail?.id === 0 || orderDetail?.id == null
                        ? `/app/dine-in/order/`
                        : `/app/dine-in/order/${orderDetail?.id}`,
                    tableId: orderDetail?.tableId,
                  });
              }}
            >
              <VisibilityIcon className={classes.visibilityicon} />
            </IconButton>
          )}
        </RoleBasedGuard>
      </div>
    </Card>
  );
};

export default MobileSalesHistory;

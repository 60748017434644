// @mui
import { Card, Typography, CardHeader, CardContent } from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';

export default function AnalyticsOrderTimeline({ title, subheader, list, ...other }) {
    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} />

            <CardContent
                sx={{
                    '& .MuiTimelineItem-missingOppositeContent:before': {
                        display: 'none',
                    },
                }}
            >
                <Timeline>
                    {list?.map((item, index) => (
                        <OrderItem key={item?.id} item={item} isLast={index === list.length - 1} />
                    ))}
                </Timeline>
            </CardContent>
        </Card>
    );
}

// ----------------------------------------------------------------------


function OrderItem({ item, isLast }) {
    const { data, name, id, color } = item;
    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot
                    color={color}
                />
                {isLast ? null : <TimelineConnector />}
            </TimelineSeparator>

            <TimelineContent>
                <Typography variant="subtitle2">{name}</Typography>

                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {data}
                </Typography>
            </TimelineContent>
        </TimelineItem>
    );
}

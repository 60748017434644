import io from "socket.io-client";
import numeral from "numeral";
import { format, getTime } from "date-fns";

export const OrderStatus = Object.create({
  IN_PROGRESS: "IN_PROGRESS",
  DELIVERED: "DELIVERED",
  DONE: "DONE",
});

export const OrderType = Object.create({
  NEW: "NEW",
  DELETE: "DELETE",
  ADD_ON: "ADD",
});

export const QuantityType = Object.create({
  INCREMENT: "INCREMENT",
  DECREMENT: "DECREMENT",
});

export const OrderDetailCommonMessage = Object.create({
  MIN_QUANTITY: "It requires minimum 1 quantity",
  MIN_ONE_FOODITEM_SELECTED: "Please select atleast one food item.",
  QUANTITY_ADDED: "Quantity added.",
});

export const shopSettingKeys = () => {
  return {
    key: "IS_SHOP_VEGETARIAN",
    name: "Is Vegetarian Shop",
  };
};

export const RESOURCE = {
  DASHBOARD: "Dashboard",
  DINE_IN_TABLE: "DineIn Table",
  PARCEL_ORDER: "Parcel Order",
  ORDER_HISTORY: "Order History",
  KITCHEN_QUEUE: "Kitchen Queue",
  FOOD_CATEGORY: "Food Category",
  FOOD_ITEMS: "Food Items",
  ONLINE_ORDER: "Online Order",
  WAITING_QUEUE: "Waiting Queue",
  SALES_REPORT: "Sales Report",
  CUSTOMERS: "Customers",
  CUSTOMER_HISTORY: "Customer History",
  STAFF_MEMBERS: "Staff Members",
  PROFILE: "Profile",
  SETTING: "Setting",
  SUPPORT: "Support",
  ROLES: "Roles",
  ORDER_BILLING: "Order Billing",
};

export const ACTION = {
  VIEW: "VIEW",
  ADD: "ADD",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  PRINT: "PRINT",
  DOWNLOAD: "DOWNLOAD",
  SETTLE: "SETTLE",
};

export const ROLE_PERMISSIONS = [
  {
    RESOURCE: RESOURCE.DASHBOARD,
    VIEW: true,
  },
  {
    RESOURCE: RESOURCE.DINE_IN_TABLE,
    VIEW: true,
    ADD: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    RESOURCE: RESOURCE.PARCEL_ORDER,
    ADD: true,
  },
  {
    RESOURCE: RESOURCE.ORDER_HISTORY,
    VIEW: true,
    UPDATE: true,
    DELETE: true,
    PRINT: true,
  },
  {
    RESOURCE: RESOURCE.KITCHEN_QUEUE,
    VIEW: true,
    UPDATE: true,
  },
  {
    RESOURCE: RESOURCE.FOOD_CATEGORY,
    VIEW: true,
    ADD: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    RESOURCE: RESOURCE.FOOD_ITEMS,
    VIEW: true,
    UPDATE: true,
  },
  {
    RESOURCE: RESOURCE.ONLINE_ORDER,
    VIEW: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    RESOURCE: RESOURCE.WAITING_QUEUE,
    VIEW: true,
    ADD: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    RESOURCE: RESOURCE.SALES_REPORT,
    VIEW: true,
    DOWNLOAD: true,
  },
  {
    RESOURCE: RESOURCE.CUSTOMERS,
    VIEW: true,
    ADD: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    RESOURCE: RESOURCE.STAFF_MEMBERS,
    VIEW: true,
    ADD: true,
    UPDATE: true,
    DELETE: true,
  },
];

export const ORDER_DETAILS_BUTTON = {
  KOT__PRINT_BUTTON: "KOT__PRINT_BUTTON",
  SAVE_ORDER_BUTTON: "SAVE_ORDER_BUTTON",
  BILL_ORDER_BUTTON: "BILL_ORDER_BUTTON",
  FINISH_ORDER_BUTTON: "FINISH_ORDER_BUTTON",
  ELECTRON_PRINT_BUTTON: "ELECTRON_PRINT_BUTTON",
  MOBILE_BUTTON: "MOBILE_BUTTON",
};

const SocketUrl = process.env.REACT_APP_SOCKET_URL;
export const SocketEndPoint = io.connect(SocketUrl);

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? "₹0,0" : "₹0,0.00");
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format("0.00a").replace(".00", "");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

const PRIMARY_NAME = ["A", "N", "H", "L", "Q", "9", "8"];
const INFO_NAME = ["F", "G", "T", "I", "J", "1", "2", "3"];
const SUCCESS_NAME = ["K", "D", "Y", "B", "O", "4", "5"];
const WARNING_NAME = ["P", "E", "R", "S", "C", "U", "6", "7"];
const ERROR_NAME = ["V", "W", "X", "M", "Z"];

function getFirstCharacter(name) {
  return name && name.charAt(0).toUpperCase();
}

function getAvatarColor(name) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return "primary";
  if (INFO_NAME.includes(getFirstCharacter(name))) return "info";
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return "success";
  if (WARNING_NAME.includes(getFirstCharacter(name))) return "warning";
  if (ERROR_NAME.includes(getFirstCharacter(name))) return "warning";
  return "default";
}

export default function createAvatar(name) {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name),
  };
}

export const PRINTER_TYPE = {
  IP: "ip",
  BLUETOOTH: "bluetooth",
  USB: "usb",
};

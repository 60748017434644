import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Save as SaveIcon, ArrowBackIos } from "@material-ui/icons";
import { toast } from "react-hot-toast";
import ListIcon from "@material-ui/icons/List";
import { useDispatch, useSelector } from "react-redux";
import { fetchDiningTable } from "state/thunk-async";
import { updateDiningTableByOrder } from "services/diningTable.service";
import { useInMobile } from "themes/Device";
import { useHistory } from "react-router-dom";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";

const styles = {
  btn: {
    // position: "absolute",
    // right: "30px",
    // top: "15px",
    height: "50px",
  },
  header: {
    marginLeft: "20px",
    fontSize: "15px",
    fontFamily: "Roboto",
  },
  col: {
    fontSize: "15px",
    fontFamily: "Roboto",
  },
};

const useStyles = makeStyles(styles);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 12;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  color: "white",
  padding: "10px",
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "#20c997" : "#FF5C93",
  ...draggableStyle,
});

const PriorityDiningTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileView = useInMobile();
  const history = useHistory();
  const { drawerClose, diningTableDataList, onSubmitDiningTablePriority } =
    props;
  const diningTableData = useSelector((state) => state.api.diningTable);
  const layoutDispatch = useLayoutDispatch();

  const [items, setItems] = useState([]);
  const { t } = useTranslation();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);

  useEffect(() => {
    if (diningTableDataList) {
      setItems(diningTableDataList);
    }
    if (diningTableData) {
      setItems(diningTableData);
    }
    if (isMobileView) {
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: t("priority-label"),
      });
      return () => {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      };
    }
  }, []);

  const redirectToMainPage = () => {
    if (isMobileView) {
      history.push(`/app/dining-table`);
    } else {
      drawerClose();
    }
  };

  const onSubmit = () => {
    setSubmittingBtnProcess(true);
    updateDiningTableByOrder(items)
      .then((res) => {
        if (res) {
          setSubmittingBtnProcess(false);
          toast.success(t("dining-table-priority-update-toast-message"));
          dispatch(fetchDiningTable());
          redirectToMainPage();
        } else {
          toast.success(t("dining-table-priority-failed-toast-message"));
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
        toast.success(t("dining-table-priority-failed-toast-message"));
      });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    console.log({ reorderedItems });
    setItems(reorderedItems);
  };

  return (
    <Card>
      <Card.Body>
        <Container fluid>
          <Card.Title>
            <h5>{t("dining-table-drawer-title")}</h5>
          </Card.Title>
          <hr />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {items?.map((item, index) => (
                    <Draggable
                      key={item?.name_of_table + item?.id}
                      draggableId={item?.name_of_table + item?.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="card"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <Row className="mt-1 mb-1">
                            <Col>
                              <ListIcon /> {item?.name_of_table}
                            </Col>
                            {/* <Col className={classes.col}>{item?.capacity}</Col> */}
                            {/* <Col className={classes.col}>
                              {item.is_active ? (
                                <Badge
                                  pill
                                  style={{
                                    backgroundColor: "green",
                                    height: "20px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  Available{" "}
                                </Badge>
                              ) : (
                                <Badge
                                  pill
                                  variant="danger"
                                  style={{ height: "20px", fontSize: "15px" }}
                                >
                                  {" "}
                                  Unavailable{" "}
                                </Badge>
                              )}
                            </Col> */}
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <hr />
          <div className="d-flex">
            <Button
              variant="contained"
              onClick={onSubmit}
              color="primary"
              type="submit"
              style={{
                background: "",
                color: "#fff",
              }}
              startIcon={<SaveIcon />}
            >
              {submittingBtnProcess ? t("processing") : t("save")}
            </Button>
            <div className="ml-2">
              <Button
                variant="outlined"
                color="secondary"
                type="button"
                onClick={() => {
                  if (isMobileView) history.push("/app/dining-table");
                  else if (!isMobileView && drawerClose) drawerClose();
                }}
                startIcon={<ArrowBackIos />}
              >
                {t("back")}
              </Button>
            </div>
          </div>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default PriorityDiningTable;

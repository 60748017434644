import { SET_STORE_THEME, SET_SHOP_REFERESH_DATA } from "./action.types";

export const setShopSettingData = (dispatch) => ({
  type: "SET_SHOP_SETTING",
  payload: dispatch,
});

export const setShopThemeSettingData = (dispatch) => ({
  type: SET_STORE_THEME,
  payload: dispatch,
});

export const setShopDetailsRefereshData = (dispatch) => ({
  type: SET_SHOP_REFERESH_DATA,
  payload: dispatch,
});
import React, { useEffect, useState, Children } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-hot-toast";
import {
  Button,
  FormControl,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Save as SaveIcon, ArrowBackIos } from "@material-ui/icons";
import { Form, Field } from "react-final-form";
import { Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { CreateStaff, getStaffById, updateStaff } from "services/staff.service";
import TextInputMui from "components/TextInputMui";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import { checkSpaceAndRegex } from "utility/helpers";
import { getRoleList } from "services/role.service";
import Select, { components } from "react-select";
import clsx from "clsx";
import { useInMobile } from "themes/Device";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      borderRadius: "8px",
      padding: "1rem 0",
    },
  },
  formcontrolwidth: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "6px",
    },
  },

  buttonStyle: {
    display: "flex",
    justifyContent: "end",
    marginTop: "10px",
    marginBottom: "10px",
  },

  mainContainer: {
    margin: "0px !important",
    padding: "0px !important",
  },

  buttonPosition: {
    display: "flex",
    justifyContent: "end",
  },

  footerButton: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    position: "absolute",
    bottom: "0",
    left: "0",
    padding: "0 15px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      position: "relative",
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
    },
  },

  footerInner: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginBottom: "auto",
      marginLeft: "6px",
    },
  },
  button: {
    marginBottom: "20px",
  },
});

const useStyles = makeStyles(styles);

export const CustomValueContainer = (props) => {
  const { selectProps, children } = props;
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props}>
        {selectProps.placeholder}
      </components.Placeholder>
      {Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? child : null,
      )}
    </components.ValueContainer>
  );
};

const component = {
  ValueContainer: CustomValueContainer,
  ClearIndicator: null,
  IndicatorSeparator: null,
};

const AddEditStaff = (props) => {
  const classes = useStyles();
  let { drawerClose, getStaffData } = props;
  const param = useParams();
  const [staffId, setStaffId] = useState(props.staffId);
  const [loading, setLoading] = useState(true);
  const [initialStaffData, setInitialStaffData] = useState();
  const isNew = staffId > 0 || param?.id > 0 ? false : true;
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [role, setRole] = useState([]);
  const isMobileView = useInMobile();
  const history = useHistory();
  const layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (param && isMobileView) {
      if (param.id > 0) {
        setStaffId(param.id);
      }
      let screenTitle = "";
      if (param.id > 0) {
        screenTitle = t("staff-edit-member-label");
      } else {
        screenTitle = t("add-staff-member-label");
        getList();
      }

      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: screenTitle,
      });
      return () => {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      };
    }
  }, []);

  const getList = async () => {
    const res = await getRoleList({
      username: localStorage.getItem("username"),
      shop_id: localStorage.getItem("shopId"),
    });
    setRole(res?.data);
    return res?.data;
  };

  const redirectToMainPage = (data) => {
    if (isMobileView) {
      history.location?.state?.backToMain();
    } else {
      getStaffData();
      drawerClose(data);
    }
  };

  const onSubmit = (values) => {
    // values.role_id = values.role_id.value;
    setSubmittingBtnProcess(true);
    if (isNew) {
      CreateStaff(values)
        .then((res) => {
          if (res?.status === 200 || res?.data || res?.data?.status === 200) {
            setSubmittingBtnProcess(false);
            toast.success(res.data.message);
            redirectToMainPage();
          } else {
            setSubmittingBtnProcess(false);
            toast.success(res?.message);
          }
        })
        .catch((err) => {
          setSubmittingBtnProcess(false);
          toast.success(err?.message);
        });
    } else {
      values.joining_date =
        values?.joining_date === "Invalid date" ? null : values.joining_date;
      values.birth_date =
        values?.birth_date === "Invalid date" ? null : values.birth_date;
      if (values.updatePassword) {
        values.password = values.newpassword;
        delete values["newpassword"];
        delete values["updatePassword"];
      } else {
        delete values["newpassword"];
        delete values["password"];
        delete values["updatePassword"];
      }

      updateStaff(staffId, values)
        .then((res) => {
          if (res?.data) {
            setSubmittingBtnProcess(false);
            toast.success(res?.message);
            redirectToMainPage();
          } else {
            setSubmittingBtnProcess(false);
            toast.success(res?.message);
          }
        })
        .catch((err) => {
          setSubmittingBtnProcess(false);
          toast.success(err?.message);
        });
    }
  };

  useEffect(() => {
    async function fetchData() {
      await getList();
      if (staffId > 0) {
        setLoading(true);

        await getStaffById(staffId).then((res) => {
          res.data.joining_date = moment(res.data.joining_date).format(
            "YYYY-MM-DD",
          );
          res.data.birth_date = moment(res.data.birth_date).format(
            "YYYY-MM-DD",
          );
          // const roleId = res?.data?.role_id;
          // const newData = {
          //   ...res.data,
          //   role_id: {
          //     value: roleId,
          //     label: roleList?.find((a) => a.id === roleId)?.name,
          //   },
          // };
          setInitialStaffData(res.data);
          setLoading(false);
        });
      }
    }
    fetchData();
  }, [staffId]);

  return (
    <div className={classes.root}>
      {loading && staffId > 0 && !initialStaffData ? (
        <SkeletonLoader />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialStaffData}
          validate={(values) => {
            const errors = {};
            const whitespaceRegExp = /^$|\s+/;
            const poorRegExp = /[a-z]/;
            const weakRegExp = /(?=.*?[0-9])/;
            const strongRegExp = /(?=.*?[#?!@$%^&*-])/;

            const poorPassword = poorRegExp.test(values.password);
            const weakPassword = weakRegExp.test(values.password);
            const strongPassword = strongRegExp.test(values.password);
            const whiteSpace = whitespaceRegExp.test(values.password);

            if (values.updatePassword && !isNew) {
              const updatepoorPassword = poorRegExp.test(values.newpassword);
              const updateweakPassword = weakRegExp.test(values.newpassword);
              const updatestrongPassword = strongRegExp.test(
                values.newpassword,
              );
              const updatewhiteSpace = whitespaceRegExp.test(
                values.newpassword,
              );

              if (!values.newpassword) {
                errors.newpassword = t("password-validation-message");
              } else if (updatewhiteSpace) {
                errors.newpassword = t("password-whitespace-validatin-message");
              } else if (
                values.newpassword.length <= 3 &&
                (updatepoorPassword ||
                  updateweakPassword ||
                  updatestrongPassword)
              ) {
                errors.newpassword = t("password-poor-validation-message");
              }
            }

            const emailRegex =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // const checkerror = checkSpaceAndRegex(values?.first_name);
            // if (checkerror != "") errors.first_name = checkerror;
            if (!values.first_name?.trim()) {
              errors.first_name = t("first-name-validation-message");
            } else if (values?.first_name?.length > 50) {
              errors.first_name = t("maximumlength-validation-message");
            }

            // const checkerrorforlname = checkSpaceAndRegex(values?.last_name);
            // if (checkerrorforlname != "") errors.last_name = checkerrorforlname;

            if (!values.last_name?.trim()) {
              errors.last_name = t("last-name-validation-message");
            } else if (values?.last_name?.length > 50) {
              errors.last_name = t("maximumlength-validation-message");
            }

            // const checkerrorforuname = checkSpaceAndRegex(values?.user_name);
            // if (checkerrorforuname != "") errors.user_name = checkerrorforuname;

            if (!values.user_name?.trim()) {
              errors.user_name = t("user-name-validation-message");
            } else if (values?.user_name?.length > 50) {
              errors.user_name = t("maximumlength-validation-message");
            }
            if (isNew) {
              if (!values.password) {
                errors.password = t("password-validation-message");
              } else if (whiteSpace) {
                errors.password = t("password-whitespace-validatin-message");
              } else if (
                values.password.length <= 3 &&
                (poorPassword || weakPassword || strongPassword)
              ) {
                errors.password = t("password-poor-validation-message");
              }
            }

            if (!values?.email) {
              errors.email = t("email-validation-message");
            } else if (values?.email?.length > 50) {
              errors.email = t("maximumlength-validation-message");
            } else if (!emailRegex.test(values?.email)) {
              errors.email = t("email-validation-message");
            }

            if (!values?.phone) {
              errors.phone = t("phone-number-error-message");
            } else if (isNaN(values?.phone)) {
              errors.phone = t("phone-number-validation-message");
            } else if (values?.phone?.length !== 10) {
              errors.phone = "phone-number-digit-validation-message";
            }

            if (values?.address && values?.address?.length > 120) {
              errors.address = t("address-maximum-validation-message");
            }
            if (
              values.birth_date > values.joining_date &&
              values.joining_date &&
              values.birth_date
            ) {
              errors.birth_date = t("birthdate-joiningdate-validation-message");
            }

            const today = moment();
            if (values.birth_date) {
              if (moment(today).isBefore(values.birth_date)) {
                errors.birth_date = t(
                  "birthdate-futuredate-validation-message",
                );
              }
            }

            return errors;
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              onSubmit={(event) => {
                handleSubmit(event).then(() => {
                  form.reset();
                  form.restart();
                });
              }}
              className="mt-4"
            >
              <Row className="m-0">
                <Col xs={12} sm={12} md={isNew ? 6 : 12}>
                  <Field name="user_name">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="text"
                          autoComplete="new-password"
                          label={t("user-name")}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                {isNew && (
                  <Col xs={12} sm={12} md={6}>
                    <Field name="password">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            autoComplete="new-password"
                            type="password"
                            label={t("password-label")}
                            variant="outlined"
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                )}
              </Row>
              {!isMobileView && (
                <>
                  <br></br>
                </>
              )}
              <Row className="m-0">
                <Col xs={12} sm={12} md={6}>
                  <Field name="first_name">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="text"
                          label={t("first-name-label")}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        />
                        {/* <TextField
                                {...input}
                                type="text"
                                label="First Name*"
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              /> */}
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Field name="last_name">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="text"
                          label={t("last-name-label")}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        />
                        {/* <TextField
                                {...input}
                                type="text"
                                label="Last Name*"
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              /> */}
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
              </Row>
              {!isMobileView && (
                <>
                  <br></br>
                </>
              )}
              <Row className="m-0">
                <Col xs={12} sm={12} md={6}>
                  <Field name="email">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="email"
                          label={t("email")}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Field name="role_id">
                    {({ input, meta }) => (
                      <FormControl
                        className={clsx(
                          classes.selectBoxControl,
                          classes.formcontrolwidth,
                        )}
                      >
                        <TextInputMui
                          {...input}
                          select
                          label={t("staff-role-label")}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        >
                          {role?.map((item) => (
                            <MenuItem value={item.id}>{item?.name}</MenuItem>
                          ))}
                        </TextInputMui>
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
              </Row>
              {!isMobileView && (
                <>
                  <br></br>
                </>
              )}
              <Row className="m-0">
                <Col xs={12} sm={12} md={12}>
                  <Field name="address">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="textarea"
                          label={t("staff-address-label")}
                          variant="outlined"
                          error={meta?.error && meta?.touched ? true : false}
                        />
                        {meta?.error && meta?.touched && (
                          <span style={{ color: "red" }}>{meta?.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
              </Row>
              {!isMobileView && (
                <>
                  <br></br>
                </>
              )}
              <Row className="m-0">
                <Col xs={12} sm={12} md={6}>
                  <Field name="joining_date" allowNull={true}>
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          label={t("staff-joining-date-label")}
                          type="date"
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Field name="birth_date" allowNull={true}>
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          variant="outlined"
                          label={t("staff-birth-date-label")}
                          type="date"
                          error={meta.error && meta.touched ? true : false}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
              </Row>
              {!isMobileView && (
                <>
                  <br></br>
                </>
              )}
              <Row className="m-0">
                <Col xs={12} sm={12} md={6}>
                  <Field name="phone">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="number"
                          label={t("phone-label")}
                          variant="outlined"
                          error={
                            (meta.touched || meta.visited) && meta.error
                              ? true
                              : false
                          }
                        />
                        {(meta.touched || meta.visited) && meta.error && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
              </Row>
              {!isNew && (
                <>
                  <Row className="m-0">
                    <Col xs={12} sm={12} md={6}>
                      <Field name="updatePassword">
                        {({ input, meta }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  Boolean(input.value) || Boolean(input.checked)
                                }
                                color="primary"
                                value={Boolean(input.value)}
                                onChange={(event) => {
                                  input.onChange(event.target.checked);
                                }}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                              />
                            }
                            label={t("staff-password-update-label")}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row className="m-2">
                    {values.updatePassword && (
                      <Col xs={12} sm={12} md={6}>
                        <Field name="newpassword">
                          {({ input, meta }) => (
                            <FormControl className={classes.formcontrolwidth}>
                              <TextInputMui
                                {...input}
                                type="password"
                                label={t("password-label")}
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              <div className={classes.footerButton}>
                <div className={classes.footerInner}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    type="submit"
                    disabled={submitting}
                    style={{
                      background: "",
                      color: "#fff",
                    }}
                    startIcon={<SaveIcon />}
                  >
                    {submittingBtnProcess ? t("processing..") : t("save")}
                  </Button>
                  <div className="ml-2">
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.button}
                      type="button"
                      onClick={() => {
                        if (isMobileView) history.push("/app/staff");
                        else if (!isMobileView && drawerClose) drawerClose();
                      }}
                      startIcon={<ArrowBackIos />}
                    >
                      {t("back")}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      )}

      {/* </Paper> */}
    </div>
  );
};

export default AddEditStaff;
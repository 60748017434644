export const setCategoryList = (dispatch) => ({
  type: "CATEGORY_LIST",
  payLoad: dispatch,
});

export const setPermissionList = (dispatch) => ({
  type: "PERMISSION_LIST",
  payload: dispatch,
});

export const setFoodMealList = (dispatch) => ({
  type: "FOOD_MEAL_LIST",
  payLoad: dispatch,
});

export const setDiningTableList = (dispatch) => ({
  type: "DINING_TABLE",
  payLoad: dispatch,
});

export const setDiningTableLoader = (dispatch) => ({
  type: "DINING_TABLE_LOADER",
  payLoad: dispatch,
});

export const setFoodVariantLoader = (dispatch) => ({
  type: "FOOD_VARIANT_LOADER",
  payLoad: dispatch,
});

export const setKitchenQueue = (dispatch) => ({
  type: "KITCHEN_QUEUE",
  payLoad: dispatch,
});

export const setKitchenQueueLoaded = (dispatch) => ({
  type: "KITCHEN_QUEUE_LOADER",
  payLoad: dispatch,
});

export const setBillingInfo = (dispatch) => ({
  type: "GET_BILLING_INFO",
  payLoad: dispatch,
});

export const setOrderHistoryData = (dispatch) => ({
  type: "GET_ORDER_HISTORY_LIST",
  payLoad: dispatch,
});
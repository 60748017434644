import { Add, AddShoppingCartSharp, Remove } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  Badge,
  Table,
} from "react-bootstrap";
import {
  Typography,
  makeStyles,
  IconButton,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchQRCodeFoodMenuList } from "state/thunk-async";

import OnlineCheckout from "./OnlineCheckout";
import PopUpModel from "components/Popup/PopUpModel";
import SmartRestaurantImg from "assets/img/smart-restaurant-logo.jpg";
import blankImage100 from "assets/img/dummyplaceholderImg/dummy_100.svg";
import "assets/css/onlineorder.css";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
  },
  formcontrolwidth: {
    width: "100%",
  },
  textField: {
    width: 200,
  },
  image: {
    position: "relative",
    height: 100,
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.5,
      },
      "& $imageMarked": {
        opacity: 0.2,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "black",
    opacity: 0.8,
    transition: 0.4,
  },
  imageTitle: {
    position: "relative",
    padding: "4px 8px",
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: "white",
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: 0.7,
  },
};

const useStyles = makeStyles(styles);

function QRCodeOrder(props) {
  const classes = useStyles();
  const [selectedFoodItemData, setSelectedFoodItemData] = useState([]);
  const [checkoutPage, setCheckoutPage] = useState(true);
  const [foodItems, setFoodItems] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const foodMenuData = useSelector((state) => state.customer.foodMenuData);
  const [variantModalShow, setVariantModalShow] = useState(false);
  const [selectedFoodVariant, setSelectedFoodVariant] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchQRCodeFoodMenuList(props.match.params.id));
  }, []);

  useEffect(() => {
    setFoodItems(foodMenuData?.foodItems);
  }, [foodMenuData]);

  useEffect(() => {
    if (foodMenuData?.category) {
      let categoryData = [{ name: "ALL", id: 0, is_active: true }, ...foodMenuData?.category];
      setCategoryList(categoryData)
    }
  }, [foodMenuData]);

  const SelectFoodItems = (foodItemData, updateQuantity, isVariant) => {
    let foodItemID = foodItemData?.id;
    let newSelectedFoodItem = JSON.parse(JSON.stringify(selectedFoodItemData));
    if (isVariant) {
      foodItemData.varient_id = foodItemData.id
    }

    if (newSelectedFoodItem?.some((data) => data.id === foodItemID) && !updateQuantity) {
      var filterData = newSelectedFoodItem.filter((data) => data.id !== foodItemID);
      setSelectedFoodItemData(filterData);
    } else {
      let selectedFoodItemId = newSelectedFoodItem.findIndex((data) => data.id === foodItemID);
      if (updateQuantity === "INCREMENT" && selectedFoodItemId > -1) {
        newSelectedFoodItem[selectedFoodItemId].qty = newSelectedFoodItem[selectedFoodItemId].qty + 1;
      } else if (updateQuantity === "DECREMENT" && selectedFoodItemId > -1) {
        if (newSelectedFoodItem[selectedFoodItemId].qty === 1) {
          newSelectedFoodItem = newSelectedFoodItem.filter((data) => data.id !== foodItemID);
        } else {
          newSelectedFoodItem[selectedFoodItemId].qty = newSelectedFoodItem[selectedFoodItemId].qty - 1;
        }
      } else {
        foodItemData.qty = 1;
        newSelectedFoodItem = [...newSelectedFoodItem, foodItemData]
        // setSelectedFoodItemData([...selectedFoodItemData, foodItemData]);
      }
      setSelectedFoodItemData(newSelectedFoodItem);
    }
  };

  const selectedCategory = (selectCategory) => {
    let filterFoodItemData;
    if (selectCategory.name === "ALL" && selectCategory.id === 0) {
      filterFoodItemData = foodMenuData?.foodItems;
    }
    else {
      filterFoodItemData = foodMenuData?.foodItems?.filter((item) => item.category_id === selectCategory.id);
    }
    setFoodItems(filterFoodItemData);
  };

  const foodVariantPopup = (selectedfoodItem) => {
    setSelectedFoodVariant(selectedfoodItem);
    setVariantModalShow(true);
  };

  const isAddedOnCart = (foodItem, isVariant) => {
    let foodItemID = isVariant ? foodItem.food_item_id : foodItem?.id;
    if (selectedFoodItemData.some((data) => data.id === foodItemID)) {
      return true;
    }
    return false;
  };


  const onChangeOfQtyField = (qtyValue, foodItem) => {
    let copyofselectedFoodItemData = [...selectedFoodItemData];
    let findCurrentIndex = copyofselectedFoodItemData?.findIndex((data) => data.id === foodItem.id);
    if (findCurrentIndex > -1 && parseInt(qtyValue) === 0) {
      _.remove(copyofselectedFoodItemData, function (data) {
        return data.id === foodItem.id;
      });
    }
    else {
      if (findCurrentIndex > -1) {
        copyofselectedFoodItemData[findCurrentIndex].qty = parseInt(qtyValue);
      }
    }
    setSelectedFoodItemData(copyofselectedFoodItemData);
  }

  return (
    <Container>
      <PopUpModel
        show={variantModalShow}
        heading={"Select Variant"}
        body={
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Variant</th>
                <th>Price</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {selectedFoodVariant?.food_variants?.map((foodVariant, index) => (
                foodVariant.is_active &&
                <tr key={`popover-${index}`}>
                  <td>{foodVariant?.name}</td>
                  <td>{parseFloat(foodVariant?.price).toFixed(2)}</td>
                  <td>
                    {
                      isAddedOnCart(foodVariant) && selectedFoodItemData.length > 0 ? (
                        <div className="mr-2 qty-box">
                          <Remove
                            onClick={() =>
                              SelectFoodItems(foodVariant, "DECREMENT", true)
                            }
                          />
                          <TextField
                            style={{ textAlign: "center" }}
                            id="standard-number"
                            name="qty"
                            value={selectedFoodItemData.find((data) => data.id === foodVariant.id).qty}
                            onChange={(event) => onChangeOfQtyField(event?.target?.value, foodVariant)}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Add
                            onClick={() =>
                              SelectFoodItems(foodVariant, "INCREMENT", true)
                            }
                          />
                        </div>
                      )
                        :
                        <IconButton
                          aria-label="Add"
                          onClick={() => SelectFoodItems(foodVariant, "", true)}
                          style={{ backgroundColor: "green", color: "#fff" }}
                          variant="contained"
                          size="small"
                        >
                          <Add />
                        </IconButton>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
        onHide={() => setVariantModalShow(false)}
      />
      {checkoutPage ? (
        <OnlineCheckout
          resetSelectedFoodItemData={setSelectedFoodItemData}
          selectedFoodItemData={selectedFoodItemData}
          setSelectedFoodItem={setSelectedFoodItemData}
          shopId={props.match.params.id}
          setCheckoutPageToggle={setCheckoutPage}
        />
      ) : (
        <>
          <div className="sf-food-category-scroll">
            {categoryList?.sort().map((category, index) => {
              return (
                category?.is_active &&
                <Card>
                  <Button
                    className="w-100"
                    variant="outline-primary"
                    // className="sf-food-category-btn"
                    onClick={() => selectedCategory(category)}
                  >
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      className={classes.imageTitle}
                    >
                      {category?.name}
                    </Typography>
                  </Button>
                </Card>
              );
            })}
          </div>
          <hr />
          <Row>
            <Col md={12} className="sf-food-list-section">
              {foodItems?.map((foodItem, index) => (
                foodItem.is_active &&
                <Card className="mb-2 sf-food-card" key={index + "card"}>
                  <div className="sf-food-list">
                    <div className="mr-2">
                      <img
                        src={
                          foodItem?.food_image
                            ? foodItem?.food_image
                            : blankImage100
                        }
                        className="sf-food-img"
                        alt="test"
                      />
                      {
                        !foodItem?.is_non_veg ?
                          <img src="https://img.icons8.com/color/16/000000/vegetarian-food-symbol.png" alt="" className="sf-veg-nonveg-icon-qrcode" /> :
                          <img src="https://img.icons8.com/color/16/000000/non-vegetarian-food-symbol.png" alt="" className="sf-veg-nonveg-icon-qrcode" />
                      }
                    </div>
                    <div className="mr-2" style={{ flex: '1 1 auto' }}>
                      <b>{foodItem?.name}</b>
                      <br /><span style={{ fontSize: '12px' }}>{foodItem?.category?.name}</span>
                      <p>
                        <Badge pill variant="info">
                          ₹ {parseFloat(foodItem?.price)}
                        </Badge>
                      </p>
                    </div>
                    <div>
                      {isAddedOnCart(foodItem) && selectedFoodItemData.length > 0 &&
                        !foodItem?.food_variants?.length > 0 ? (
                        <div
                          className="mr-2 qty-box"
                        >
                          <Remove
                            onClick={() =>
                              SelectFoodItems(foodItem, "DECREMENT")
                            }
                          />
                          <TextField
                            style={{ textAlign: "center" }}
                            id={`standard-number-${index}`}
                            name="qty"
                            value={selectedFoodItemData.find((data) => data.id === foodItem.id).qty}
                            onChange={(event) => onChangeOfQtyField(event.target.value, foodItem)}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Add
                            onClick={() =>
                              SelectFoodItems(foodItem, "INCREMENT")
                            }
                          />
                        </div>
                      ) : (
                        <>
                          <Button
                            variant="success"
                            className="sf-addtocartbtn"
                            onClick={() => {
                              foodItem?.food_variants?.length > 0
                                ? foodVariantPopup(foodItem)
                                : SelectFoodItems(foodItem);
                            }}
                          >
                            Add
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  {
                    foodItem?.food_variants?.length > 0 &&
                    <div className="customizable-product"> {"* customizable product"}</div>
                  }
                </Card>
              ))}
            </Col>
          </Row>
          <div className="addcart-footer p-2">
            <div>
              <AddShoppingCartSharp />
              {selectedFoodItemData?.length} item selected</div>
            <Button
              variant="success"
              className="sf-addtocartbtn"
              onClick={() => setCheckoutPage(true)}
            >
              Checkout
            </Button>
          </div>
        </>
      )}
    </Container>
  );
}

export default QRCodeOrder;

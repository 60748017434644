import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { makeStyles, useTheme } from "@material-ui/styles";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import "./MobileTitle.styles.css";
// import { Button, useTheme } from "@mui/material";
import { Button } from "@material-ui/core";
import { AddCircleOutline, AddCircleSharp, LowPrioritySharp } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const styles = {
  title: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#1F1F24",
    margin: "0",
  },
  centerProperty: {
    display: "flex",
    justifyContent: "start",
  },
  titleContainer: {
    bottom: '80px',
    position: 'fixed',
    marginTop: "7px",
    width: '100%',
    zIndex: 10
  },
  buttonBox: {
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
  },
  buttonPostion: {
    display: "flex",
    justifyContent: "end !important",
    bottom: "10px",
  },
  btnPostion: {
    display: "flex",
    justifyContent: "start !important",
    bottom: "10px",
  },
  priorityButton: {
    justifyContent: "end !important",
  },
  mobilebutton: {
    height: '100%',
    border: "1px solid ",
    fontSize: "13px",
    padding: "3px 10px",
    justifyContent: "start",
  },
};

const useStyles = makeStyles(styles);
const MobileTitle = (props) => {
  const { addButton, title = "", setPriorityButton } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div className={classes.titleContainer}>
<div className={classes.buttonBox}>
<div>
          {setPriorityButton && (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.priorityButton}
              startIcon={<LowPrioritySharp  style={{fontSize:'30px'}}/>}
              onClick={setPriorityButton}
              style={{
                marginRight: '5px',
                padding:'8px 35px',
                boxShadow: '-1px 5px 8px rgb(0 0 0 / 22%)'
              }}
            >
              {t("mobile-screen-priority-label")}
            </Button>
          )}
        </div>
        <div>
          {addButton && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                padding:'8px 35px',
                boxShadow: '-1px 5px 8px rgb(0 0 0 / 22%)'
              }}
              startIcon={<AddCircleSharp style={{fontSize:'30px'}}/>}
              onClick={addButton}
            >
              {t("add-button-label")}
            </Button>
          )}
        </div>
        
      </div>
    </div>
      
  );
};

export default MobileTitle;

import { SET_USER_INFO, SET_USER_PERMISSION } from "../actions/action.types";

const init = {
  userData: {},
  userPermission: [],
};

const UserReducers = (state = init, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return { 
        ...state, 
        userData: action.payload,
      };
    case SET_USER_PERMISSION:
        return { 
          ...state, 
          userPermission: action.payload,
        };
    default:
      return state;
  }
};

export default UserReducers;

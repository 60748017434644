import React from "react";

import "./mobilefoodmenucard.styles.css";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import MenuButtonWithIconMui from "components/MenuButtonWithIconMui";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import img1 from "../../assets/img/foods/img1.png";
import veg from "../../assets/img/veg.png";
import nonVeg from "../../assets/img/non-vegetarian-food-symbol.png";
import MenuButtonEditDelete from "components/MenuButtonEditDelete/MenuButtonEditDelete";
import { Stack } from "@mui/material";

function Mobilefoodmenucard(props) {
  const {
    foodName,
    category,
    price,
    description,
    is_non_veg,
    active,
    food_image,
    deleteData,
    editData,
    itemId,
  } = props;
  return (
    <Card style={{ padding: '4px 9px' }}>
      <Stack direction={'row'} gap={2} className="d-flex justify-content-between align-items-center">
        <div style={{ display: "flex" }}>
          {/* <img src={img1} /> */}
          <div>
            <p className="para">{category}</p>
            <h6>{foodName}</h6>
          </div>
        </div>
        <div>
          <div>
            {is_non_veg ? (
              <img src={nonVeg} className="veg" />
            ) : (
              <img src={veg} className="veg" />
            )}

            {active ? (
              <button className="btns"> Available </button>
            ) : (
              <button className="btnr"> Unavailable </button>
            )}
          </div>
          <div className="rupee ml-auto">{price}</div>
        </div>
      </Stack>

      <Stack direction={'row'} className="d-flex justify-content-between align-items-center">
        {description}
      </Stack>
      <MenuButtonEditDelete
        deleteClick={deleteData}
        editClick={editData}
        itemId={itemId}
        isMenuBar={true}
      />
    </Card>
  );
}
export default Mobilefoodmenucard;

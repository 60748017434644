import { api } from "./../utility/interceptor";
import configData from "../config.js";
var config = {
    headers: { "Access-Control-Allow-Origin": "*" },
};


export const getKitchen = async (req) => {
    let res = await api.post(`/kitchan`, req, config);
    let data = res.data;
    return data;
};

export const updateKitchen = async (req, id) => {
    let res = await api.put(`/kitchan/${id}`, req, config);
    return res;
}

export const kitchenOrderDone = async (orderID) => {
    return await api.get(`/kitchan/done/${orderID}`, config);
}

export const kitchenOrderItemDone = async (itemID) => {
    return await api.get(`/kitchan/done-item/${itemID}`, config);
}

export const CompletedAllKitchenItems = async () => {
    let res = await api.get(`/kitchan/doneall`);
    let data = res.data;
    return data;
}


import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";

import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Group,
  Delete,
  Cancel,
} from "@material-ui/icons";
import SkeletonLoader from "components/Loader/SkeletonLoader";
//context
import {
  useLayoutState,
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
// API Calls
import { getAllInventory } from "services/inventory.service";
import { deleteInventory } from "services/inventory.service";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";

import TemporaryDrawer from "components/TemporaryDrawer";
import AddEditInventory from "./AddEditInventory";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeskTopContainer } from "themes/Device";
import { MobileContainer } from "themes/Device";
import SearchBarInput from "components/SearchBarInput";
import Mobileinventorycard from "components/MobileInventoryCard/mobileinventorycard";
import MobileTitle from "components/MobileTitle";
import PopUpModel from "components/Popup/PopUpModel";
import { useInMobile } from "themes/Device";
import {
  useInventory,
  useDeleteInventoryMutation,
} from "hooks/react-query/useInventory";
import { useTheme } from "@material-ui/core/styles";
import Label from "components/Label";
import { useTranslation } from "react-i18next";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
  },
  tabel: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
      "& div": {
        padding: "0",
      },
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },
  mobileCard: {
    padding: "2px",
  },
};

const useStyles = makeStyles(styles);

const InventoryList = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [deletePopupshow, setDeletePopupshow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [inventoryDataList, setInventoryDataList] = useState([]);
  const [drawerShow, setDrawerShow] = useState(false);
  const [inventoryId, setInventoryId] = useState(0);
  const [searchInventry, setSearchInventery] = useState("");
  const [filterInventeryList, setFilterInventeryist] = useState([]);
  const history = useHistory();
  const isMobileView = useInMobile();
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (layoutState?.currentScreenTitle !== "Inventory") {
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: t("inventory"),
      });
    }
    return () => {
      if (layoutState?.currentScreenTitle === "Inventory") {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      }
    };
  }, []);

  const { data: expenseDataList, loading: isLoading } = useInventory();
  const { mutate: deleteExpense } = useDeleteInventoryMutation();

  useEffect(() => {
    setInventoryDataList(expenseDataList);
  }, [expenseDataList]);

  const deleteInventoryData = () => {
    if (deleteId > 0) {
      setIsDeleting(true);
      deleteExpense(deleteId);
      setIsDeleting(false);
      setDeletePopupshow(false);
    }
  };

  const deleteDataConfirm = (id) => {
    setDeleteId(id);
    setDeletePopupshow(true);
  };

  const drawerClose = () => {
    if (isMobileView) {
      history.push("/app/inventory");
    } else {
      setDrawerShow(false);
      setInventoryId(0);
    }
  };

  const deleteData = (deleteid) => {
    setDeleteId(deleteid);
    setDeletePopupshow(true);
  };

  const editData = (editid) => {
    history.push({
      pathname: `/app/inventory/edit/${editid}`,
      state: {
        inventoryId: editid,
        backToMain: () => history.push(`/app/inventory`),
      },
    });
  };

  const addDrawerShow = () => {
    history.push({
      pathname: `/app/inventory/add`,
      state: {
        inventoryId: 0,
        backToMain: () => history.push(`/app/inventory`),
      },
    });
  };

  const clearTxt = () => {
    setSearchInventery("");
  };

  useEffect(() => {
    setFilterInventeryist(inventoryDataList);
  }, [inventoryDataList]);

  useEffect(() => {
    if (searchInventry) {
      let filterInvList = filterInventeryList?.filter(
        (item) =>
          item.type &&
          item.type?.toLowerCase()?.includes(searchInventry.toLowerCase()),
      );
      setFilterInventeryist(filterInvList);
    } else {
      setFilterInventeryist(inventoryDataList);
    }
  }, [searchInventry]);

  return (
    <>
      <PopUpModel
        show={deletePopupshow}
        heading={t("inventory-management-inventory-remove-popup-title")}
        body={t("inventory-management-inventory-remove-popup-body")}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeletePopupshow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={deleteInventoryData}
            >
              {" "}
              <Delete /> {isDeleting ? <CircularProgress /> : t("delete")}{" "}
            </Button>
          </>
        }
        onHide={() => setDeletePopupshow(false)}
      />
      <TemporaryDrawer
        isOpen={drawerShow}
        drawerClose={drawerClose}
        icon={<FontAwesomeIcon icon={faBoxOpen} />}
        title={t("inventory")}
      >
        <AddEditInventory inventoryId={inventoryId} drawerClose={drawerClose} />
      </TemporaryDrawer>
      <DeskTopContainer>
        <div className={classes.tabel}>
          <MaterialTable
            title={
              <h5>
                <b className="mr-3">{t("inventory-management-title")}</b>
              </h5>
            }
            columns={[
              {
                title: t("name-label"),
                field: "name",
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("unit-flag-title"),
                field: "unit",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) => (
                  <Label
                    variant={
                      theme.palette.mode === "light" ? "ghost" : "filled"
                    }
                    color={parseFloat(rowData?.unit) > 0 ? "info" : "warning"}
                    style={{ fontSize: "20px!important" }}
                  >
                    {rowData?.unit}
                  </Label>
                ),
              },
              {
                title: t("unit-type-title"),
                field: "unit_type",
                cellStyle: {
                  width: "10%",
                },
              },
              {
                title: t("price-label"),
                field: "price",
                cellStyle: {
                  width: "10%",
                },
              },
              {
                title: t("inventory-management-alert-unit-label"),
                field: "minimum_alert_unit",
                cellStyle: {
                  width: "10%",
                },
              },
              {
                title: t("category-tab-label"),
                field: "category_id",
                cellStyle: {
                  width: "10%",
                },
              },
              {
                title: t("description-label"),
                field: "description",
                cellStyle: {
                  width: "10%",
                },
              },
              {
                title: t("analytics-from-date"),
                field: "from_date",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) =>
                  rowData?.from_date &&
                  moment(rowData?.from_date).format("DD/MM/YYYY"),
              },
              {
                title: t("analytics-to-date"),
                field: "to_date",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) =>
                  rowData?.to_date &&
                  moment(rowData?.to_date).format("DD/MM/YYYY"),
              },
              {
                title: t("action"),
                field: "action",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) => (
                  <Fragment>
                    <IconButton
                      color="primary"
                      title={t("inventory-management-edit-label")}
                      aria-label={t("inventory-management-edit-label")}
                      onClick={() => {
                        setInventoryId(rowData.id);
                        setDrawerShow(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      title={t("inventory-management-delete-inventory-label")}
                      aria-label={t(
                        "inventory-management-delete-inventory-label",
                      )}
                      onClick={() => deleteDataConfirm(rowData.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Fragment>
                ),
              },
            ]}
            data={
              inventoryDataList &&
              inventoryDataList?.map((item) => {
                return {
                  name: item?.name,
                  description: item?.description,
                  unit: item?.unit,
                  unit_type: item?.unit_type,
                  minimum_alert_unit: item?.minimum_alert_unit,
                  category_id: item?.category?.name,
                  id: item?.id,
                  price: item?.price,
                  from_date: item?.from_date,
                  to_date: item?.to_date,
                };
              })
            }
            options={{
              paging: true,
              pageSize: 10,
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 20, 30],
              sorting: true,
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              searchFieldStyle: { color: theme.palette.grey[500] },
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              },
              // rowStyle: (rowData, index) => {
              //   if (index % 2) {
              //     return { backgroundColor: "#f2f2f2" };
              //   }
              // },
            }}
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: isLoading ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>Inventory is Empty!</p>
                  </NotFoundComponent>
                ),
              },
            }}
            // other props
            components={{
              Toolbar: (props) => (
                <div className="custom-material-table-toolbar ">
                  <MTableToolbar {...props} />
                  <div style={{ padding: "0px 10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<AddCircleOutlineIcon />}
                      style={{ background: "", color: "#fff" }}
                      onClick={() => {
                        setDrawerShow(true);
                      }}
                    >
                      {t("inventory-management-add-label")}
                    </Button>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </DeskTopContainer>
      <MobileContainer>
        <div className={classes.mobileCard}>
          <MobileTitle title={t("inventory")} addButton={addDrawerShow} />
          <SearchBarInput
            onChange={(e) => {
              setSearchInventery(e.target.value);
            }}
            value={searchInventry}
            placeholder={t("inventory-management-search-inventory-option")}
            clearTxt={clearTxt}
          />
          {isLoading ? (
            <SkeletonLoader count={4} height="7rem" />
          ) : (
            <div>
              {filterInventeryList &&
                filterInventeryList?.map((item, index) => (
                  <div className={classes.mobileCard} key={index}>
                    <Mobileinventorycard
                      data={item}
                      deleteData={deleteData}
                      editData={editData}
                      itemId={item?.id}
                    />
                  </div>
                ))}
            </div>
          )}
          {filterInventeryList?.length < 1 && !isLoading && (
            <NotFoundComponent imgUrl={noFoundImg}>
              <p style={{ fontWeight: 600 }}>
                {t("inventory-management-empty-inventory-message")}
              </p>
            </NotFoundComponent>
          )}
        </div>
      </MobileContainer>
    </>
  );
};

export default InventoryList;

import { matchPath } from 'react-router';

// ----------------------------------------------------------------------

export { default as NavSectionVertical } from './vertical';
export { default as NavSectionHorizontal } from './horizontal';

export function isExternalLink(path) {
  return path.includes('http');
}

export function getActive(path, pathname) {
  const match = matchPath(path, {
    path: pathname,
    exact: true,
    end: false 
  });
  // console.log(!!match)
  return path ? !!match : false;
}

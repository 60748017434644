import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import { NotificationImportant } from "@material-ui/icons";
import addNotification from 'react-push-notification';
import {
  Stack
} from '@mui/material';

// components
import Header from "../Header";
import Routes from "../../Routes";
import {
  fetchBillingInfo,
  fetchDiningTable,
  fetchShopSettingInfo,
  fetchShopDesign,
  fetchCategoryList,
  fetchFoodMealList,
  fetchKitchenQueue,
  fetchOrderHistoryList
} from "state/thunk-async";
import { setDiningTableLoader } from "state/actions/api.action";
import Sidebar from "../Sidebar";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "context/LayoutContext";
import '@fortawesome/fontawesome-svg-core/styles.css'
import useStyles from "./styles";
import { SocketEndPoint, OrderStatus } from "common/constant";
import moment from "moment";
import MobileFooterComp from "components/MobileFooterComp/MobileFooterComp";
import { MobileContainer } from "themes/Device";
import { useInMobile } from "themes/Device";
import { toast } from "react-hot-toast";
import NotificationOne from "components/Notification/NotificationOne";
import { onlineOrderAccepted } from "services/order.service";
import { onlineOrderDecline } from "services/order.service";
import { NotificationItem } from "components/Layouts/dashboard/header/NotificationsPopover";
// import smartlookClient from 'smartlook-client'

function Layout(props) {
  var classes = useStyles();
  const [username, setShopUsername] = useState(localStorage.getItem("username"));
  const startDate = moment().format("YYYY-MM-DD");
  const endDate =  moment().add(1, "days").format("YYYY-MM-DD");
  const orderDateBody = {
    startDate: moment(startDate).utc().format("YYYY-MM-DD HH:mm:ss"), //  "endDate": "2021-03-30",
    endDate:  moment(endDate).add(1, "days").utc().format("YYYY-MM-DD HH:mm:ss"), // "startDate": "YYYY-MM-DD"
  };
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDiningTableLoader(true));
    dispatch(fetchDiningTable());
    dispatch(fetchCategoryList());
    dispatch(fetchFoodMealList());
    dispatch(fetchBillingInfo());
    dispatch(fetchShopSettingInfo());
    dispatch(fetchShopDesign());
    dispatch(fetchOrderHistoryList(orderDateBody));
    // smartlookClient.identify(username, {
    //   username: username
    // });
    // smartlookClient.init('9ebda0842075061dbcc3ed4658816cd6153c8b13')
  }, []);

  useEffect(() => {
    const searchReq = {
      startDate: moment().format("YYYY-MM-DD"),
      status: [OrderStatus.IN_PROGRESS, OrderStatus.DELIVERED],
    };
    SocketEndPoint.emit("clientconnection", username)
    SocketEndPoint.on("shop", shop => {
      
    })
    SocketEndPoint.on("createOrder", (payload) => {
      dispatch(fetchKitchenQueue(searchReq));
      dispatch(fetchDiningTable());
      dispatch(fetchOrderHistoryList(orderDateBody));
      // handleClick();
      if(payload?.order?.is_online)
      {
        showNotificationToast(payload?.order);
        addNotification({
          title: 'Online Order',
          subtitle: 'online order comes via QR code',
          message: 'This is a very long message',
          theme: 'darkblue',
          native: true // when using native, your OS will handle theming.
         });
      }
    })

    SocketEndPoint.on("updateOrder", (payload) => {
      dispatch(fetchKitchenQueue(searchReq));
      dispatch(fetchDiningTable());
    })
    SocketEndPoint.on("deleteOrder", (payload) => {
      dispatch(fetchKitchenQueue(searchReq));
      dispatch(fetchDiningTable());
    })
    SocketEndPoint.on("createOrderOnQr", (payload) => {
      dispatch(fetchKitchenQueue(searchReq));
      dispatch(fetchDiningTable());
    })

    SocketEndPoint.on("releaseTable", (payload) => {
      dispatch(fetchKitchenQueue(searchReq));
      dispatch(fetchDiningTable());
    })
    SocketEndPoint.on("finishOrder", (payload) => {
      dispatch(fetchKitchenQueue(searchReq));
      dispatch(fetchDiningTable());
    })
    SocketEndPoint.on("priorityDineInTable", (payload) => {
      dispatch(fetchDiningTable());
    })
    SocketEndPoint.on("priorityFoodMenu", (payload) => {
      dispatch(fetchFoodMealList());
    })
    SocketEndPoint.on("priorityFoodCategory", (payload) => {
      dispatch(fetchCategoryList());
    })
    SocketEndPoint.on("referesh_token", (payload) => {
      localStorage.removeItem("token");
      localStorage.setItem("token", payload.token);
    })


  }, [SocketEndPoint])

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  const acceptOnlineOrder = (orderID, toastConfig) => {
    try {
      onlineOrderAccepted(orderID)
        .then((res) => {
          if(res.status == 200)
          {
            toast.success(res?.message);
          }
          else {
            toast.error(res?.message);
          }
          toast.dismiss(toastConfig?.id);
          dispatch(fetchOrderHistoryList(orderDateBody));
          dispatch(fetchDiningTable());
        })
        .catch((err) => {
          toast.dismiss(toastConfig?.id);
          toast.error(err?.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const declineOnlineOrder = (orderID, toastConfig) => {
    try {
      onlineOrderDecline(orderID)
        .then((res) => {
          if(res.status == 200)
          {
            toast.success(res?.message);
          }
          else {
            toast.error(res?.message);
          }
          toast.dismiss(toastConfig?.id);
          dispatch(fetchOrderHistoryList(orderDateBody));
          // dispatch(fetchDiningTable());
        })
        .catch((err) => {
          toast.dismiss(toastConfig?.id);
          toast.error(err?.message);
        });
    } catch (error) {
      console.log(error);
    }
  };


  const showNotificationToast = (orderData) => {
    toast((toastConfig) => (
      <Stack>
        <NotificationItem
         orderData = {orderData}
          acceptOnlineOrder = {(data) => acceptOnlineOrder(data, toastConfig)}
         declineOnlineOrder = {(data) => declineOnlineOrder(data, toastConfig)}
        />
      </Stack>
    ),
    {
      position: "bottom-right",
      duration: 10000,
    }
    );
  }

  return (
    <div className={classes.root}>
      <NotificationOne
       open= {open}
       setOpen ={setOpen}
       />
      <Header history={props.history} className={classes.header} />
      <Sidebar />
      <div
        onClick={() =>
          layoutState.isSidebarOpened && toggleSidebar(layoutDispatch)
        }
        style={{ top: useInMobile() ? '40px' : '', paddingBottom: useInMobile() ? '56px' : '' }}
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        {/* Online Offline Notification ON ELECTRON */}
        <Alert variant="warning" className="online-offline-alert">
          <NotificationImportant /> <span>
            You are offline. Your data can’t be saved and may not be up to date. Until you’re back online.
          </span>
        </Alert>
        <Routes />
      </div>
      <MobileContainer>
        <MobileFooterComp />
      </MobileContainer>
    </div>
  );
}

export default withRouter(Layout);

import React, { Component } from 'react';
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";

export default class DineInQrPrint extends Component {
    
    render() {
        return (
            <>
            <div>
               <QRCode
                id="qrCodeEl"
                value={this?.props?.orderUrl ? this?.props?.orderUrl : "https://www.servofeat.com/"}
                size={120}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
                // renderAs={"svg"}
                // imageSettings={{
                //   src: "https://static.zpao.com/favicon.png",
                //   x: null,
                //   y: null,
                //   height: 24,
                //   width: 24,
                //   excavate: true,
                // }}
                /> 
            </div>
            </>
        )
    }
}
